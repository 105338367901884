.hide-error-message {
	p.eitje-form-2-error {
		display: none;
	}
}

.show-error-border {
	.eitje-form-2-field.eitje-form-2-field-error {
		.ant-picker {
			border: 1px solid @medium-red;
		}
	}
}

@import (reference) 'styles/app/app.less';

.max-list-container {
	padding-bottom: 18px;

	.read-more-button {
		.pointer-cursor;
		.column-with-border-and-padding(xs, xs);
		padding: 8px;

		p {
			color: @dark-grey;
			line-height: 1.5;
		}
	}
}

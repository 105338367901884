@import (reference) 'styles/app/app.less';

.eitje-avatar {
	.var(radius);

	.eitje-text {
		font-size: calc(@var-value * 0.4) !important;
	}

	img {
		.square-size(100%);
		object-fit: cover;
	}
}
@import (reference) 'styles/app/app.less';

.grid-layout {
	display: grid;
	width: 100%;

	&-child {
		padding-top: var(--padding-top);
		padding-bottom: var(--padding-bottom);
	}

	&-first-child {
		padding-left: var(--padding-left);
	}

	&-last-child {
		padding-right: var(--padding-right);
	}
}

.search-input {
    position: relative;

    input {
        padding-left: 36px;
        padding-right: 36px;
        height: 40px;
        width: 100%;
        border-radius: inherit;
        border: none;
        outline: none;
        background-color: var(--background-color);

        &::placeholder {
            color: var(--color);
        }
    }

    >.eitje-icon:first-of-type {
        position: absolute;
        left: 12px;
        pointer-events: none;
    }

    >.eitje-icon:last-of-type {
        position: absolute;
        right: 12px;
        cursor: pointer;
    }
}
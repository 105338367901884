@import (reference) 'styles/app/app.less';


.eitje-rich-text-editor-content {
    .ProseMirror {
        padding-inline: 33px; // 21px drag-handle width + 6px margin left and right
    }

    .drag-handle-menu {
        .square-size(21px);
        align-items: center;
        border-radius: 3px;
        cursor: grab !important;
        display: flex;
        justify-content: center;

        &:hover {
            background: @midtone-grey;

            path {
                fill: @black !important; // forcefully overwrite both the inherited color within the rich_text_editor and any parent Layout's colouring
            }
        }

        .eitje-layout-has-hover.eitje-layout-has-hover:hover {
            cursor: grab !important;
        }
    }
}
@import (reference) 'styles/app/app.less';

.new-contract-end-date-picker,
.date-picker-contract-options {
	.ant-picker-footer-extra p {
		.pointer-cursor;
		.border(top);
		text-align: center;
		padding: 0 12px;
		line-height: 38px;

		&:first-child {
			border-top: none; // prevent double border
		}
	}
}

.date-picker-contract-options {
	p {
		line-height: 30px;
	}
}

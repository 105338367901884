@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/index.less';

@modal-header-height: 70px;
@modal-header-gap: 8px;

/////////////////////////////
// general header styling
/////////////////////////////

.eitje-modal-2-header {
	.border(bottom);
	height: @modal-header-height;
	padding: 0 24px;
	display: flex;
	justify-content: space-between;
}

/////////////////////////////
// left side styling
/////////////////////////////

.eitje-modal-2-header .eitje-modal-2-header-left {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;

	.eitje-modal-2-left-header-titles {
		display: inline-flex;
		gap: @modal-header-gap;
		align-items: center;

		.eitje-modal-2-subtitle {
			padding-top: 4px; // align to the same baseline of the title
		}
	}

	.eitje-modal-2-left-header-docs {
		.border(@color: @medium-blue);
		.border-radius;
		.blue;
		padding: 4px 8px;
		display: inline-flex;
		gap: 4px;
		margin: 4px 0 0 0;
	}
}

/////////////////////////////
// right side styling
/////////////////////////////

.eitje-modal-2-header .eitje-modal-2-header-right {
	display: inline-flex;
	gap: @modal-header-gap;
	align-items: center;

	.modal-close-icon {
		.pointer-cursor;
	}
}

/////////////////////////////
// Dropdown: possibly present as form field
/////////////////////////////

.eitje-modal-2-header-right .eitje-form-2-field {
	.eitje-form-2-dropdown-as-button;
}

/////////////////////////////
// Dropdown: possibly present as route toggle
/////////////////////////////

.eitje-modal-2-header-right .elementContainer {
	.eitje-form-2-dropdown-as-button;

	// forcefully unset all styling done by legacy 'elementContainer'...
	padding: unset !important;
	border: unset !important;

	.ant-select {
		margin-right: unset;
	}
}

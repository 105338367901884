@import (reference) 'styles/app/app.less';

.environment-availability {
	.border(top);
	margin-top: 12px;
	width: 100%;

	@footer-height: 64px;

	&:nth-last-child(2) {
		padding-bottom: @footer-height;
	}
}

@import (reference) 'styles/app/app.less';

///////////////////////////////////////
// Default modal styling
///////////////////////////////////////

@header: 84px;
@body: calc(512px - @template-summary-row);
@footer: 72px;
@template-summary-row: 65px;
@modal: calc(@header + @body + @template-summary-row + @footer);
@extra-conflict-height: 180px;

.load-template-modal {
	.eitje-modal(@modal);
	.load-form,
	.conflict-form {
		.eitje-modal-body(@body);
	}

	.ant-modal-footer {
		.eitje-modal-footer(@footer);
		justify-content: flex-end;
	}

	&.conflict {
		.eitje-modal(calc(@modal + @extra-conflict-height));
	}

	.conflict-form {
		.eitje-modal-body(calc(@body + @extra-conflict-height));
	}

	.load-form-shift-warning {
		.red-text;
		display: inline-block;
		font-size: 10px;
	}
}

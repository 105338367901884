@import (reference) 'styles/app/app.less';

.export2-tables-filters {
	display: flex;
	justify-content: space-between;

	.export2-tables-filter-block {
		.border;
		.border-radius;
		width: calc((100% - 16px) / 2);
		background-color: @light-grey;
		padding: 12px;

		.max-popover-list-container {
			margin: 8px 0 0 0;

			.exports-filter-card {
				.border;
				.border-radius;
				display: inline-block;
				background-color: @white;
				padding: 4px 8px;
				margin: 0 4px 4px 0;

				.footer-card-icon {
					.square-size(14px);
					margin: -2px 0 0 4px;
				}
			}
		}
	}
}

.default-modal-padding {
	padding: 12px 24px 24px 24px;
}

.modal-data-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.border(bottom);
	padding: 16px 0px;
}

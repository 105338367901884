.ant-input:focus {
	box-shadow: none;
}

.ant-input-affix-wrapper.search_input {
	background-color: @light-grey;
	border: 1px solid ~'@{medium-border}50';
}

.ant-input-affix-wrapper:hover {
	border-color: ~'@{medium-border}50' !important;
}

.ant-input.ant-input-borderless {
	padding-left: 0;
	padding-right: 24px; // this allows the character count down to remain in the right place
	padding-top: 0;
	padding-bottom: 0;
}

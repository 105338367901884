@import (reference) 'styles/app/app.less';
@import (reference) 'mixins/stretch';

.eitje-form-2-field.eitje-list-picker {
	.pointer-cursor;
	min-height: @field-height;

	.form-trigger-container {
		min-height: @field-height;
		position: absolute;
		inset: -@field-vertical-padding -@field-horizontal-padding auto -@field-horizontal-padding;
		padding: calc(16px + 18px) @field-horizontal-padding @field-horizontal-padding @field-horizontal-padding;
	}

	.eitje-form-2-select-all {
		position: absolute;
		right: @field-horizontal-padding;
		top: 50%;
	}

	.eitje-form-2-error {
		margin-top: 22px;
	}

	.list-picker-trigger {
		width: 100%;

		.easy-remove-container {
			// Easy remove container has recently been made into a Layout with padding. Overwrite here with force
			// so that it only applies to form instances, instead of all Easy Remove containers.
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}
}

@import (reference) 'styles/app/app.less';

/////////////////////////////
// General styling
/////////////////////////////

.user-card {
	flex-grow: 1;
	display: flex;
	align-items: stretch;
	width: 100%;

	&.user-card-with-dropdown {
		.pointer-cursor;
	}

	.user-avatar-wrapper {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
	}

	.inner-cell-contents-wrapper {
		.border;
		.border-radius;
		width: calc(100% - 10px);
		margin: 4px;
		display: inline-flex;
		gap: 8px;
		padding: 0 4px;
		min-height: 50px;
		position: relative;

		.eitje-hover-toggle,
		.user-card-sub-wrapper {
			max-width: calc(100% - 44px);
			display: inline-flex;
			flex-direction: column;
			justify-content: center;

			.user-name {
				.bold;
				.prevent-text-overflow;
				color: @black;

				width: 100%;
			}

			p {
				color: @dark-grey;
				font-size: 10px;
			}
		}

		.user-dropdown-trigger {
			.border;
			.border-radius;
			background-color: @white;
			top: calc(50% - 12px);
			position: absolute;
			font-weight: bold;
			right: 4px;
			line-height: 10px;
			padding: 4px 10px 12px 10px;
		}
	}
}

/////////////////////////////
// 'size' prop
/////////////////////////////

.user-card.user-card-large {
	.user-name {
		font-size: 14px;
	}
}

/////////////////////////////
// Class based mixins to change the default styling
/////////////////////////////

.user-card-without-borders {
	.inner-cell-contents-wrapper {
		border: unset;
		border-radius: unset;
	}
}

.user-card-as-list-item {
	border-top: unset;
	border-left: unset;
	border-right: unset;
	border-radius: unset;
	margin-left: unset;
	margin-right: unset;
	padding-left: unset;
	padding-right: unset;
	width: 100%;
}

.user-card-as-form-field {
	.user-card-without-borders;
	.border(bottom);

	.inner-cell-contents-wrapper {
		margin: 0 24px;
		padding: unset;
		height: 72px;
	}
}

.user-hover-card {
	display: inline-flex;
	height: 100%;
}

@import (reference) 'styles/app/app.less';

.horizontal {
	.row-with-justify-center;
}

@breadcrumb-margin-top: 24px;

.back-breadcrumb {
	position: fixed;
	display: flex;
	gap: 4px;
	left: 24px;
	top: calc(@header-main-height + @breadcrumb-margin-top);

	.back-breadcrumb-text {
		.text-capitalize;
	}
}

.has-onboarding-header .back-breadcrumb {
	top: calc(@header-main-height + @breadcrumb-margin-top + @header-start-eitje-height);
}

.eitje-error {
	@margin: 24px;

	.border;
	.big-shadow;
	.border-radius;
	display: inline-flex;
	align-items: center;
	width: calc(100% - 2 * @margin);
	margin: 0 0 16px @margin;
	height: 36px;

	.eitje-error-text {
		.prevent-text-overflow;
		margin: 0 0 0 12px;
		line-height: 36px;
	}
}

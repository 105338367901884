@import (reference) 'styles/app/app.less';

.google-places-suggestions {
	.border;
	position: absolute;
	z-index: 3;
	background-color: @white;
	margin: -16px 24px 0 24px;

	.google-location {
		.border(bottom);
		.pointer-cursor;
		padding: 8px 16px;

		// The first part of an address is a 'strong', the second part a 'small'.
		// The strong and small lack a space between, so compensate with below margin.
		strong {
			margin-right: 4px;
		}

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			background-color: @light-blue;
		}
	}
}

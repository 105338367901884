@import (reference) 'styles/app/app.less';

.template-summaries {
	overflow-x: auto;

	.template-summary {
		.row;
		.border(top);
		gap: 4px;
		padding: 12px 24px;

		.template-team {
			.border;
			.border-radius;
			.big-shadow;
			display: inline-block;
			height: 40px;
			padding: 4px 4px 0 12px;
			width: 100px;
			min-width: 100px;

			&.template-team-env-name {
				background: @light-grey;

				h6 {
					.bold;
				}
			}

			h6 {
				.prevent-text-overflow;
				color: @dark-grey;
			}

			h5 {
				.bold;
				.prevent-text-overflow;
				color: @black;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

////////////////////////////
// Defaults
////////////////////////////

.eitje-circle {
	display: inline-flex;
	justify-content: center;

	.eitje-circle-text {
		.bold;
		color: @white;
	}
}

////////////////////////////
// 'shape' prop
////////////////////////////

.eitje-circle.eitje-circle-shape-round {
	.border-radius(round);
}

.eitje-circle.eitje-circle-shape-square {
	border-radius: 25%;
}

////////////////////////////
// 'color' prop
////////////////////////////

.eitje-circle.eitje-circle-color-red {
	background-color: @medium-red;
}

.eitje-circle.eitje-circle-color-blue {
	background-color: @medium-blue;
}

////////////////////////////
// 'borderColor' prop
////////////////////////////

.eitje-circle.eitje-circle-has-border {
	border: 1px solid;
}

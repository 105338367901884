@import (reference) 'styles/app/app.less';

.message-headers {
	* {
		.pointer-cursor-important; // corrects the Text hover
	}

	.popout-card {
		width: 100%; // corrects the wrapper's absence of `display contents`
	}
}

@import (reference) 'styles/app/app.less';

.availability-of-environment-table {
	.border(top);
	.border(bottom);

	////////////////////////////////////////////
	// Header row of the table
	////////////////////////////////////////////

	.availability-of-environment-table-header {
		.row-with-justify-between;
		.border(top);
		.border(bottom);
		background-color: @white;
		margin: 24px 0 4px 0;
		height: 64px;
		padding: 0px 24px;

		.right-side-header-options {
			.row;
			width: fit-content;
			gap: 4px;
		}

		.new-team-button {
			.border;
			.border-radius;
			padding: 0 20px;

			.plus-icon {
				.button-icon-small;
				margin-top: -2px;
			}

			h5 {
				.bold;
				color: @black;
				line-height: 32px;
			}

			&:hover {
				background-color: @light-blue;
			}
		}
	}

	////////////////////////////////////////////
	// Body row of the table: first cell
	////////////////////////////////////////////

	.availability-of-environment-table-row {
		.row;

		.availability-of-environment-table-first-cell-of-row {
			.border;
			.border-radius;
			min-width: 196px;
			height: 48px;
			margin: 0 0 0 4px;
			padding: 8px 0 0 12px;
			background: @white;

			.team-name {
				.bold;
				.prevent-text-overflow;
				color: @black;
			}
		}

		////////////////////////////////////////////
		// Body row of the table: other cells
		////////////////////////////////////////////

		.availability-of-environment-table-cell {
			.row;
			padding: 4px;
			background: @white;
			min-height: 54px;
			padding: 0px 4px;

			.availability-of-environment-table-inner-cell {
				.border;
				.border-radius;
				.row;
				.justify-content-center;
				height: 50px;
				padding: 4px;
				gap: 12px;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.availability-form-wrapper {
	.form-row {
		.border(bottom);
		display: flex;
	}

	input.ant-input {
		// unfortunately we need to set this here... ideally this should automatically
		// be handled correctly by the form library. Reason why we do this? There is still
		// some centralized legacy that very aggressively inserts negative margins.
		margin-top: 6px;
	}
}

@import (reference) 'styles/app/app.less';

.ant-modal-wrap.modal-wrapper {
	.ant-modal-content {
		overflow: hidden;

		.ant-modal-body {
			display: grid;
			grid-template-columns: 240px auto;
			height: 540px;
			.modal-col {
				.column;
				.border(right);
			}

			> :nth-child(2) {
				overflow: scroll;
			}
			.modal-col-body-area {
				display: flex;
				flex-direction: column;

				> :first-child {
					.vertical-modal-distribution;
					.modal-form-field-area {
						flex: 1;
					}
				}
			}
		}
	}

	.modal-inner-footer {
		position: sticky;
		bottom: 0px;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		align-items: center;
		background-color: white;
		padding: 12px;
		.border(top);
		box-shadow: 0px -5px 15px 0px ~'@{medium-grey}15';
		min-height: 65px;

		// DISABLE PRESET ELEMENTCONTAINERS
		.elementContainer {
			padding: 0px;
			min-height: 0px;
			border: none;

			&.eitje-image-picker-container {
				min-width: fit-content;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.eitje-range-picker {
	.big-shadow;

	&-small.ant-picker {
		padding: 4px;
		height: 32px;

		.ant-picker-input {
			width: 82px;

			input {
				font-size: 14px !important;
				font-weight: 500;
			}
		}

		.ant-picker-range-separator {
			padding: 0px 4px;

			&:before {
				content: '-';
			}

			.ant-picker-separator {
				display: none;
			}
		}

		.ant-picker-suffix {
			display: none;
		}

		.ant-picker-input input {
			font-size: 12px;
			line-height: 1;
		}
	}
}

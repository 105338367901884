@import (reference) 'styles/app/app.less';

.user-options-dropdown-popout {
	left: calc(50vw - 50px) !important;
	right: auto !important;

	// Set a custom box-shadow to increase the visibility of the dropdown, since we always spawn it in the
	// horizontal centre of the viewport.
	box-shadow: 5px 5px 50px 5px ~'@{black}30' !important;
}

#active-users-page,
#inactive-users-page {
	.advanced-table-body {
		padding-bottom: 120px;
	}
}

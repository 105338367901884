@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/index.less';

/////////////////////////////
// Modal styling
/////////////////////////////

@header-height: 84px;
@body-height: 367px;
@footer-height: 72px;
@modal-height: calc(@header-height + @body-height + @footer-height);

@body-right-column-width: 240px;
@body-left-column-width: calc(100% - 240px);

.time-shift-modal {
	.eitje-modal(calc(@modal-height + @footer-height));

	.ant-modal {
		padding: unset;
	}

	.time-registration-modal-header {
		.eitje-modal-header(@header-height);
	}

	.time-registration-modal-body {
		.eitje-modal-body(@body-height);
		display: flex;

		.time-shift-form-body {
			width: @body-left-column-width;
			height: @body-height;
			overflow-y: scroll;
		}

		.time-registration-modal-body-right-column {
			width: @body-right-column-width;
		}
	}

	.time-registration-modal-footer {
		.eitje-modal-footer(@footer-height);
	}
}

/////////////////////////////
// Form styling
/////////////////////////////

.time-shift-form-body {
	.no-user-placeholder {
		.eitje-form-2-fake-field;
	}
}

@import (reference) 'styles/app/app.less';

// Styling that is used by the main forms
.post-form:not(.edit):not(.has-event) {
	.column-with-border-and-padding(24px, 12px);
	background-color: @white;
	align-items: initial;

	.post-form-header-container {
		.row;
		.border-radius(large);
		.eitje-emoji-input-container {
			.border-radius(large);
			flex: 1;
			.elementContainer {
				display: flex;

				textarea {
					flex: 1;
				}
			}
		}
	}

	.post-form-body-container {
		margin-top: 12px;
		margin-bottom: 12px;

		.elementContainer {
			display: flex;

			textarea {
				flex: 1;
				height: 60px;
			}
		}

		// because of the collapsing post form, the body
		// needs to handle top space else non collasped has extra margin
	}

	.poll-form-container {
		.poll-form-header-container {
			.row;
			align-items: initial;
			flex: 1;
			margin-bottom: 12px;

			.poll-form-title-container {
				.row;
				align-items: self-end;
				gap: 8px;
				.poll-form-title {
					font-size: 14px;
					.bold;
				}
				.poll-form-subtitle {
					.eitje-text-secondary;
				}
			}
			.poll-remove-text {
				.eitje-text-secondary;
				align-self: end;
				white-space: nowrap;
			}
		}
	}

	.poll-form-question {
		.row-with-border-and-padding(16px, 12px);
		margin-bottom: 12px;

		.eitje-input-container {
			flex: 1;
		}
	}

	.poll-form-answers-container {
		.column-with-border-and-padding(0px, 0px);
		margin-bottom: 12px;

		> * {
			width: 100%;
		}

		> :not(:last-child) {
			.border(bottom);
		}
		.poll-form-answer {
			.row;
			padding-left: 16px;
			padding-top: 16px;
			padding-bottom: 16px;
			.eitje-input-container {
				flex: 1;
			}
		}
	}

	.poll-form-duration-container {
		.row-with-justify-end;
		margin-bottom: 12px;

		.eitje-dropdown-container {
			.row;
			gap: 8px;

			width: initial;
		}
	}
	.poll-form-asset-details-container {
		> .elementContainer:first-child {
			margin-top: 12px;
		}
	}

	.post-form-schedule-details {
		.border-radius(large);
		margin-top: 12px;
		.row-with-border;
		min-height: 56px;
		padding-right: 12px;
	}

	// REMOVE THIS
	margin-bottom: 12px;
}

// Styling that is used by the main and modal forms
.post-form {
	.post-form-asset-submit-container {
		.row;
		.post-form-asset-picker-container {
			.row;
			gap: 4px;
			flex: 1;
		}
		.post-form-submit-container {
			.row;
			width: initial;
			gap: 4px;
		}
	}
	.post-form-body-container {
		textarea {
			.grey-text;
			resize: none;
		}
	}
}

// Styling that is used by just the modal/edit
.post-form.edit {
	> :nth-child(1) {
		.border(bottom);
	}
	.post-form-submit-container {
		position: absolute;
		right: 24px;
	}
}

// Styling used by all modals
.post-form.edit,
.post-form.has-event {
	.post-form-asset-submit-container {
		.border(top);
		padding: 12px 24px;
	}

	.post-form-schedule-details-container {
		padding: 0px 24px;
		.post-form-schedule-details {
			.row-with-border-and-padding(16px,8px);
			margin-bottom: 12px;
		}
	}

	.poll-form-asset-details-container {
		padding-left: 24px;
		padding-right: 24px;
		.eitje-picker-details-container {
			justify-content: space-between;
			.row-with-border-and-padding(16px,8px);
			margin-bottom: 12px;
		}
	}
}

#chat-page .str-chat__channel,
#chat-window .str-chat__channel {
  min-width: 0;
  flex: 1;
  width: 100%;
}
#chat-page .str-chat__channel *,
#chat-window .str-chat__channel * {
  text-transform: unset;
  text-transform: unset !important;
}
#chat-page .str-chat__channel *:first-letter,
#chat-window .str-chat__channel *:first-letter {
  text-transform: unset;
}
#chat-page .str-chat__channel *:first-letter,
#chat-window .str-chat__channel *:first-letter {
  text-transform: unset !important;
}
#chat-page .str-chat__unread-messages-separator,
#chat-window .str-chat__unread-messages-separator {
  background-color: unset;
  border-bottom: 1px solid #dddddd;
  color: #000000;
}
#chat-page .str-chat__list,
#chat-window .str-chat__list {
  scrollbar-color: #aaaaaa transparent;
  scrollbar-width: thin;
}
#chat-page .str-chat__channel-list-react .str-chat__channel-list-messenger-react,
#chat-window .str-chat__channel-list-react .str-chat__channel-list-messenger-react {
  padding-bottom: unset;
}
#chat-page .str-chat__message-actions-list-item,
#chat-window .str-chat__message-actions-list-item {
  font-size: 14px;
  height: 40px;
}
#chat-page .str-chat__message-attachment-actions-form,
#chat-window .str-chat__message-attachment-actions-form {
  margin: 8px 8px 0 4px;
  gap: 4px;
  justify-content: center;
}
#chat-page .str-chat__message-attachment-actions-form .str-chat__message-attachment-actions-button,
#chat-window .str-chat__message-attachment-actions-form .str-chat__message-attachment-actions-button {
  padding: 0 8px;
  height: 32px;
  font-size: 14px;
  color: #000000;
  border-radius: 4px;
  width: unset;
  border-inline: unset;
  box-shadow: unset;
  border: 1px solid #dddddd;
}
#chat-page .str-chat__message-attachment-actions-form .str-chat__message-attachment-actions-button:hover,
#chat-window .str-chat__message-attachment-actions-form .str-chat__message-attachment-actions-button:hover,
#chat-page .str-chat__message-attachment-actions-form .str-chat__message-attachment-actions-button:active,
#chat-window .str-chat__message-attachment-actions-form .str-chat__message-attachment-actions-button:active {
  background-color: #e7f5ff;
  border-color: #0096ff;
  color: #0096ff;
}
#chat-page .str-chat__list-notifications:not(:has(*)),
#chat-window .str-chat__list-notifications:not(:has(*)) {
  margin: 0;
}
#chat-page .channel-list > *,
#chat-window .channel-list > * {
  flex-shrink: unset !important;
}
#chat-page .channel-list .str-chat__channel-list,
#chat-window .channel-list .str-chat__channel-list {
  width: 100%;
  border-right: none;
}
#chat-page .channel-list .str-chat__load-more-button,
#chat-window .channel-list .str-chat__load-more-button {
  padding: 0 12px;
}
#chat-page .channel-list .str-chat__load-more-button .str-chat__load-more-button__button,
#chat-window .channel-list .str-chat__load-more-button .str-chat__load-more-button__button {
  border-radius: 4px;
  height: 40px;
  font-weight: 600;
  width: 100%;
  background-color: #e7f5ff;
  color: #0096ff;
  border: 1px solid #0096ff;
}
#chat-page .channel-list .str-chat__load-more-button .str-chat__load-more-button__button:hover,
#chat-window .channel-list .str-chat__load-more-button .str-chat__load-more-button__button:hover {
  background-color: #0096ff;
  color: #ffffff;
  border: 1px solid #0673f4;
}
#chat-window {
  box-shadow: 0 0 10px 10px #aaaaaa25;
  overflow: hidden !important;
}
#chat-window .channel {
  min-height: 0;
}
#chat-window .str-chat__message-input .str-chat__attachment-list-scroll-container {
  max-height: 86px;
  overflow-y: auto;
  flex-direction: column;
  row-gap: 4px;
}
#chat-page .str-chat,
#chat-window .str-chat {
  --str-chat__message-actions-box-border-radius: 4px;
  --str-chat__message-actions-box-item-hover-background-color: #e7f5ff;
}
#chat-page .str-chat .str-chat__message-bubble,
#chat-window .str-chat .str-chat__message-bubble {
  border: 1px solid #dddddd;
}
#chat-page .str-chat .str-chat__message-actions-box .str-chat__message-actions-list-item-button,
#chat-window .str-chat .str-chat__message-actions-box .str-chat__message-actions-list-item-button {
  border-bottom: 1px solid #dddddd;
}
#chat-page .str-chat .str-chat__message-actions-box .str-chat__message-actions-list-item-button:hover,
#chat-window .str-chat .str-chat__message-actions-box .str-chat__message-actions-list-item-button:hover {
  color: #0096ff;
  border-bottom: 1px solid #0096ff;
}
#chat-page .str-chat .str-chat__message-actions-box .str-chat__message-actions-list-item-button:last-child,
#chat-window .str-chat .str-chat__message-actions-box .str-chat__message-actions-list-item-button:last-child {
  border-bottom: unset;
}
#chat-page .str-chat .str-chat__message-reaction,
#chat-window .str-chat .str-chat__message-reaction {
  border: 1px solid #dddddd;
}
#chat-page .str-chat .str-chat__message-reaction *,
#chat-window .str-chat .str-chat__message-reaction * {
  cursor: pointer;
}
#chat-page .str-chat .str-chat__message-metadata,
#chat-window .str-chat .str-chat__message-metadata {
  padding-bottom: 12px;
  gap: 8px;
}
#chat-page .str-chat .str-chat__message-simple-status.str-chat__message-status .str-chat__message-sender-avatar,
#chat-window .str-chat .str-chat__message-simple-status.str-chat__message-status .str-chat__message-sender-avatar {
  align-self: unset;
}
#chat-page .str-chat__message-input,
#chat-window .str-chat__message-input {
  padding: 0;
}
#chat-page .str-chat__message-input .eitje-layout.popout-card,
#chat-window .str-chat__message-input .eitje-layout.popout-card,
#chat-page .str-chat__message-input .eitje-layout.popout-card-trigger,
#chat-window .str-chat__message-input .eitje-layout.popout-card-trigger,
#chat-page .str-chat__message-input .eitje-button,
#chat-window .str-chat__message-input .eitje-button {
  align-self: stretch;
}
#chat-page .str-chat__message-input .eitje-button,
#chat-window .str-chat__message-input .eitje-button {
  height: unset !important;
}
#chat-page .str-chat__message-input .str-chat__file-input-container,
#chat-window .str-chat__message-input .str-chat__file-input-container {
  align-self: stretch;
  width: unset;
  height: unset;
}
#chat-page .str-chat__message-input .str-chat__file-input-container .str-chat__file-input-label,
#chat-window .str-chat__message-input .str-chat__file-input-container .str-chat__file-input-label {
  align-self: stretch;
}
#chat-page .str-chat__attachment-list-scroll-container,
#chat-window .str-chat__attachment-list-scroll-container {
  max-height: 172px;
  overflow-y: auto;
  flex-direction: column;
  row-gap: 4px;
}
#chat-page .str-chat__quoted-message-preview,
#chat-window .str-chat__quoted-message-preview {
  --str-chat__quoted-message-bubble-background-color: var(--str-chat__own-message-bubble-background-color);
  width: stretch;
}
#chat-page .str-chat__quoted-message-preview.mine,
#chat-window .str-chat__quoted-message-preview.mine {
  --str-chat__quoted-message-bubble-background-color: var(--str-chat__message-bubble-background-color);
}
#chat-page .str-chat__quoted-message-preview .str-chat__quoted-message-bubble,
#chat-window .str-chat__quoted-message-preview .str-chat__quoted-message-bubble {
  max-width: unset;
  border: 1px solid #dddddd;
}
#chat-page .str-chat__message-input .str-chat__quoted-message-preview,
#chat-window .str-chat__message-input .str-chat__quoted-message-preview {
  --str-chat__quoted-message-inside-message-input-max-height: 100px;
  margin: 8px;
}
#chat-page .str-chat__message-input .str-chat__quoted-message-preview *,
#chat-window .str-chat__message-input .str-chat__quoted-message-preview * {
  cursor: default !important;
}
#chat-page .str-chat__message-input .str-chat__quoted-message-preview .str-chat__quoted-message-bubble,
#chat-window .str-chat__message-input .str-chat__quoted-message-preview .str-chat__quoted-message-bubble {
  overflow: auto;
  background: #f6f6f6;
  padding-right: 0px;
  pointer-events: unset;
  cursor: default !important;
}
#chat-page .str-chat__message-input .str-chat__quoted-message-preview .str-chat__quoted-message-bubble .str-chat__quoted-message-text,
#chat-window .str-chat__message-input .str-chat__quoted-message-preview .str-chat__quoted-message-bubble .str-chat__quoted-message-text {
  min-height: unset;
}
#chat-page .search-container,
#chat-window .search-container {
  width: 100%;
}
#chat-page .str-chat__channel-search.str-chat__channel-search--inline,
#chat-window .str-chat__channel-search.str-chat__channel-search--inline {
  min-height: unset;
  height: unset;
}
#chat-page .str-chat__channel-search.str-chat__channel-search--inline:has( > :last-child:nth-child(2)),
#chat-window .str-chat__channel-search.str-chat__channel-search--inline:has( > :last-child:nth-child(2)) {
  min-height: 0;
}
#chat-page .str-chat,
#chat-window .str-chat {
  --str-chat__background-color: 'transparent';
  --str-chat__message-input-background-color: 'transparent';
  --str-chat__primary-color: #0096ff;
  --str-chat__active-primary-color: #0673f4;
  --str-chat__surface-color: #dddddd;
  --str-chat__message-textarea-background-color: #ffffff;
  --str-chat__message-textarea-border-radius: 4px;
  --str-chat__message-bubble-border-radius: 8px;
  --str-chat__message-bubble-background-color: #ffffff;
  --str-chat__own-message-bubble-background-color: #e7f5ff;
  --str-chat__channel-search-input-wrapper-border-radius: 4px;
  --str-chat__channel-search-input-wrapper-active-border-radius: 4px;
  --str-chat__channel-search-input-wrapper-active-color: #0096ff;
  --str-chat__channel-search-input-wrapper-active-background-color: #e7f5ff;
  --str-chat__attachment-preview-list-border-radius: 0px;
}

@import (reference) 'styles/app/app.less';

.eitje-tabs {
	.border;
	.border-radius();
	height: 40px;
	padding: 4px;
	background: @light-grey;
	display: inline-flex;

	.eitje-tab {
		@tab-height: 32px;
		.border-radius();
		height: @tab-height;
		line-height: @tab-height;
		text-align: center;
		padding: 0 12px;

		&:hover:not(&-active) {
			.pointer-cursor;

			outline: 1px solid @medium-blue;
			color: @medium-blue;
			background: @light-blue;
		}

		&-active {
			background: @medium-blue;
			color: @white;
		}
	}

	&-small {
		height: 32px;

		.eitje-tab {
			@small-tab-height: 24px;
			height: @small-tab-height;
			line-height: @small-tab-height;
		}
	}

	&-disabled {
		pointer-events: none;
	}
}

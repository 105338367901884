@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .search-container {
        width: 100%;
    }

    .str-chat__channel-search {
        &.str-chat__channel-search--inline {
            // need to unset the min-height to prevent the search bar to get pushed outside its parent
            min-height: unset;
            height: unset;

            // need to set the min-height again since the search results are spawned 
            // inside the channel-search component. Otherwise the results are not scrollable.
            &:has(> :last-child:nth-child(2)) {
                min-height: 0;
            }
        }
    }
}
@import (reference) 'styles/app/app.less';

.advanced-table-header {
	.border(top);
	position: sticky;
	top: 0;
	z-index: 12;
	background-color: @white;

	& > .advanced-table-header-cell-wrapper {
		.var(left-offset);

		&-pinned {
			position: sticky;
			left: @var-value;
			z-index: 12;
			box-shadow: 0 -1px 0 0 @medium-border; /* Top shadow so non sticky boxshadow doesnt overlay */
		}

		&-last-pinned .advanced-table-header-cell {
			border-right-color: @black !important;
		}

		.advanced-table-header-cell {
			.border(bottom);
			.border(right);

			&.active,
			&:hover {
				box-shadow: 0 -1px 0 0 @medium-blue, /* Top shadow */ -1px 0 0 0 @medium-blue; /* Left shadow */
			}
		}

		&:last-child:not(&-pinned) .advanced-table-header-cell {
			.unset-border(right);
		}
	}
}

.select-box {
	.column;
	.pointer-cursor;
	.align-items-center;
	justify-content: space-evenly;
	width: 460px;
	height: 300px;
	padding: 20px 0px;
	.border(@style: dashed, @width: 4px);
	.border-radius(large);
	text-align: center;
	.transition;

	&:hover {
		cursor: pointer;
		transform: scale(1.02);
	}

	&.active {
		border-color: @medium-blue;
	}
}

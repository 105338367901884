@import (reference) 'styles/app/app.less';

.empty-list {
	justify-content: center;
	text-align: center;

	.empty-list-image {
		width: 200px;
		display: inline-block;
		margin: 100px 0 0 0;
	}

	.empty-list-title,
	.empty-list-subtitle {
		text-align: center;
		padding: 0 24px;
	}

	.empty-list-title {
		margin: 40px 0 0 0;
	}

	.empty-list-subtitle {
		margin: 8px 0 0 0;
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) '../../styles/index.less';

.exports-tables-body {
	max-width: @exports-table-index-page-width;
	min-width: @exports-table-index-page-width;
	margin: 0 auto 0px auto;
	padding-bottom: 120px;

	.exports-tables-block {
		margin: 36px 0;

		.exports-tables-block-title-container {
			.border(bottom);
			display: flex;
			justify-content: space-between;
			padding: 0 0 16px 0;
		}
	}
}

///////////////////////
// Empty list state
///////////////////////

.exports-tables-body .exports-tables-empty {
	.border;
	.border-radius;
	background: @light-grey;
	margin: 24px 0 0 0;
	display: flex;
	justify-content: space-between;
	height: 100px;
	padding: 0 40px;
	align-items: center;
}

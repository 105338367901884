@import (reference) 'styles/app/app.less';

.time-shift-form-body .fake-user-card-field {
	height: 70px;

	.user-image-wrapper {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		margin: 0 4px 0 0;
	}
	.user-info {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		vertical-align: top;
		min-height: 36px;

		h4 {
			.prevent-text-overflow;
			max-width: 100%;
			margin: 4px 0 0 0;
		}

		p {
			font-size: 12px;
			color: @dark-grey;
			line-height: 1;
		}
	}

	.change-user-button {
		position: absolute;
		right: 12px;
		top: calc((100% - 32px) / 2);
	}
}

.time-shift-modal {
	.edit-shift .fake-user-card-field .user-info {
		width: 125px; // form field width without user image, button and gutters
	}

	.create-shift .fake-user-card-field .user-info {
		width: 210px;
	}
}

@import (reference) 'styles/app/app.less';

/////////////////////////////////////////////
// This file is legacy popvover styling:
// has to be cleaned up and get rid of specific
// popover styling

// -> see popovers.less for new styling!
/////////////////////////////////////////////

.popover :not(.formstyle-form) {
	.elementContainer {
		.default_popover_field_container;
	}
}

.skills_popover {
	width: 280px;

	.ant-select {
		width: 100%;
		margin-top: 10px;
	}
}

.ant-popover {
	.eitje-form:not(.formstyle-form) {
		.eitje-label {
			margin-bottom: -8px;
		}
		.form-row {
			display: grid;
			align-items: center;
			grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
			.border(right);
			.elementContainer {
				height: 100%;
				padding: 12px 24px;

				.eitje-input-container {
					height: 40px;
				}
			}

			.form-container-left {
				flex: 1;
			}

			> * {
				border: none;
				&:not(:last-child) {
					.border(right);
				}
			}
		}
	}

	.ant-popover-content .ant-popover-title {
		padding: 12px 24px;
	}
}

@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .str-chat__message-input {
        padding: 0;

        .eitje-layout.popout-card,
        .eitje-layout.popout-card-trigger,
        .eitje-button {
            align-self: stretch;
        }

        .eitje-button {
            height: unset !important;
        }

        .str-chat__file-input-container {
            align-self: stretch;
            width: unset;
            height: unset;

            .str-chat__file-input-label {
                align-self: stretch;
            }
        }
    }

    .str-chat__attachment-list-scroll-container {
        max-height: 172px; // 4 items + 3 gaps
        overflow-y: auto;
        flex-direction: column;
        row-gap: 4px;
    }
}
@import (reference) 'styles/app/app.less';

.export-tables-show-subs {
	.border;
	.border-radius;
	background-color: @white;
	margin: 0 0 12px 0;
	padding: 12px;

	/////////////////////////////
	// header, count & actions
	/////////////////////////////

	.subscriptions-header {
		display: flex;
		width: 100%;
		gap: 12px;
		align-items: center;

		.count {
			.border;
			.border-radius;
			.square-size(38px);
			display: inline-block;
			line-height: 38px;
			text-align: center;
		}

		a {
			margin-left: auto;
		}
	}

	/////////////////////////////
	// subscriptions index
	/////////////////////////////

	.flat-list {
		margin: 8px 0 0 0;

		.list-item {
			@margin: 4px;
			position: relative;

			.border;
			.border-radius;
			.big-shadow;
			.hover-effect;
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			height: 60px;
			width: calc((100% - @margin) / 2);
			padding: @margin 16px;
			margin: @margin 0 0 0;

			h4 {
				margin: 0 0 4px 0;
			}

			&:nth-child(odd) {
				margin-right: @margin;
			}

			.footer-card-icon {
				.square-size(12px);
				margin: 0 0 0 8px;
			}

			.injected-svg {
				@size: 14px;
				.square-size(@size);
				position: absolute;
				right: @size;
				top: calc((100% - @size) / 2);
			}
		}
	}
}

/////////////////////////////
// subscriptions popover
/////////////////////////////

.basic-list {
	.reader-list-header {
		h3,
		p {
			font-size: 14px;
			display: inline-block;
			margin-right: 4px;
		}

		h3 {
			margin-top: 8px;
		}
	}

	.list-container {
		padding: 0 0 8px 0;

		.user-row {
			.row-with-justify-start;
			margin-top: 8px;

			.user-name {
				font-size: 14px;
				line-height: 32px;
				color: @dark-grey;
				margin-left: 12px;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.week-table-row {
	align-items: stretch !important; // TO DO afterworks: add to layout

	.border(top);
	.border(bottom);

	.week-table-column,
	.week-table-tile {
		.border(right);

		.on-hover-focus({
	       .border(@type: outline);
	     });
	}

	.week-table-column .week-table-tile {
		// if a tile appears in a column (e.g. planning per week, where sometimes shifts may occur within a column as card, but not in the days header
		// where no columns exist), it should not get a border, because the column itself is already bordered.
		.unset-border(right);
	}
}

@import (reference) 'styles/app/app.less';


.eitje-rich-text-editor-content {
    [data-type='horizontalRule'] {
        padding: 1rem 0;
        transition: all 100ms ease-in-out;
        cursor: pointer;

        hr {
            .unset-border;
            .border(@side: top, @width: 2px, @color: @medium-grey);
            background-color: @light-grey;
            margin: 0;
        }

        &.ProseMirror-selectednode {
            background-color: @light-border;
        }

        &:hover:not(&.ProseMirror-selectednode) {
            background-color: @light-border;
        }
    }
}
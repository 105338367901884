@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/index.less';

////////////////////////////
// Apply fake fields
////////////////////////////

.shift-modal .eitje-form-2 {
	.eitje-form-2-field-published {
		.eitje-form-2-unfield;
	}

	.user-shift-card {
		.eitje-form-2-fake-field;
	}
}

////////////////////////////
// Specific form styling
////////////////////////////

.shift-modal .eitje-form-2-double-field {
	padding: 14px 12px 12px 24px;
	justify-content: space-between; // temp fix until we remove double-field
	height: 71px;

	.eitje-form-2-field-dates.eitje-button-select-container.eitje-button-select-container-small {
		min-height: unset;
	}

	.eitje-form-2-field-dates,
	.eitje-form-2-field-amt_shifts {
		.eitje-form-2-label,
		.eitje-button-select,
		.eitje-simple-input-number {
			float: left;
			clear: both;
		}
	}

	.eitje-form-2-field-amt_shifts {
		flex-grow: 0;

		.eitje-form-2-label {
			min-width: 42px;
			top: -4px;
		}
	}
}

.shift-modal .eitje-form-2-field-published {
	.eitje-form-2-label {
		color: @black;
	}
}

.shift-modal .user-shift-card {
	align-items: center;
	padding: 14px 14px 14px 24px !important;
	height: 71px;

	.user-shift-card-text {
		font-weight: 600;
		height: 100%;
		color: @black;
		line-height: 42px;
		vertical-align: center;
	}

	.skill-set-picker {
		a {
			button.ant-btn {
				width: calc(100% - 24px) !important;
				margin: 12px;
			}
		}
	}
}

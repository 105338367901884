@import (reference) 'styles/app/app.less';

@footer-height: 90px;

.table-edit-footer {
	.border(top);
	height: @footer-height;
	background-color: @white;
	width: 100%;
	display: flex;
	padding: 0 100px 0 16px;
	align-items: center;
	justify-content: space-between;

	.table-edit-footer-progress {
		@width: 700px;
		width: 600px;
		position: absolute;
		left: calc((100% - @width) / 2);

		.ant-steps-item-icon {
			.square-size(20px);

			svg {
				width: 10px;
			}

			span.ant-steps-icon {
				font-size: 10px;
				top: -4px;
			}
		}

		.ant-steps-item-content {
			margin-top: unset;

			.ant-steps-item-title {
				.bold;
				font-size: 14px;
				height: 14px;
			}

			.ant-steps-item-description,
			.ant-steps-item-description span {
				font-size: 12px;
				height: 12px;

				.footer-card-icon {
					.square-size(12px);
				}
			}
		}
	}

	.table-edit-footer-right {
		display: inline-flex;
		gap: 12px;
		align-items: center;

		.next-step-text {
			color: @medium-blue;
		}
	}
}

@import (reference) 'styles/app/app.less';

@form-row-field-height: 32px;

.eitje-form-2-list-form {
	// show every label next to its input, by making every eitje-form-2-field a flexbox
	.eitje-form-2-field {
		display: inline-flex;
		justify-content: space-between;
		width: 100%;
	}

	// make buttons smaller in width, could be wrapped into the button component
	button {
		.pointer-cursor;
		min-width: unset;
	}

	// make buttons smaller in height, could be wrapped into the button component
	button {
		height: 30px;
		padding: 0 12px;
	}

	// remove default huge padding, since it won't fit into the row
	.eitje-form-2-field {
		padding: unset;
	}

	// Only show the dropdown icon.
	.hide-clear;

	// There is no space for error messages... show a red border instead.
	.hide-error-message;
	.show-error-border;

	// make all form field elements smaller in height
	.eitje-form-2-field {
		.eitje-form-2-label,
		.ant-select-selection-placeholder {
			.prevent-text-overflow;
			line-height: @form-row-field-height;
		}
		.ant-select-selector,
		.ant-select-selector input.ant-select-selection-search-input {
			height: @form-row-field-height;
		}
	}

	//
	.eitje-form-2-label {
		width: 50%;
	}

	// Placeholder styling: give paddings since the row-form is horizontally oriented

	.eitje-form-2-field .ant-select-selection-placeholder,
	.eitje-form-2-field input::placeholder {
		padding: 0 8px;
	}
}

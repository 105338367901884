////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason is very simple: since LESS does not allow us to
// reuse dynamically created classes within other CSS classes as mixins,
// they may only be used directly within HTML – but that goes against
// our philosophy of keeping the JS files clean and concise.
////////////////////////////////////////////////////

@import (reference) 'styles/app/app.less';

each(@size-names, {
  .column-with-border-and-padding-@{value} {
    .column-with-border-and-padding(@size[$@value], @size[$@value]);

    &.bor-dashed {
    .border(@style: dashed, @width: 4px);
    .border-radius(large);
    }
  }

});

each(@size-names, {
  .row-with-border-and-padding-@{value} {
    .row-with-border-and-padding(@size[$@value], @size[$@value]);

    &.bor-dashed {
    .border(@style: dashed, @width: 4px);
    .border-radius(large);
    }
  }

});

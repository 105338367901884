@import (reference) 'styles/app/app.less';

.availability-default-locks-modal {
	.modal-info-banner {
		margin: 24px 24px 0 24px;
		width: calc(100% - 48px);
	}

	.default-locks-table {
		padding: 24px 0 0 0;

		////////////////////////////////////////////
		// Header row of the table
		////////////////////////////////////////////
		.default-locks-table-header {
			.default-locks-table-row;
			.border(top);
			.border(bottom);
			padding: 0 0 0 215px;

			.day-of-week-header {
				.border(right);
				.flex-grow-one;
				height: 63px;
				text-align: center;

				&:first-child {
					.border(left);
					margin-left: -1px;
				}

				h4 {
					line-height: 63px;
				}
			}
		}

		////////////////////////////////////////////
		// Body row of the table
		////////////////////////////////////////////
		.default-locks-table-row {
			.row;

			.default-locks-table-first-cell-of-row {
				width: 196px;
				// wrapper div already has a padding-left of 4px, so added together makes the standard 12px
				padding: 0 0 0 8px;
				h4,
				h5 {
					.prevent-text-overflow;
					max-width: 170px;
				}

				h5 {
					line-height: 1;
				}
			}

			.default-locks-table-cell {
				.row;
				.border(right);
				min-height: 54px;
				padding: 0px 4px;

				.default-locks-table-inner-cell {
					.justify-content-center;
					.border;
					.border-radius;
					.row;
					height: 50px;
					padding: 4px;
					gap: 12px;
				}
			}
		}
	}
}

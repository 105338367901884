.eitje-fixed-range-picker {
	.ant-picker-borderless {
		border: none;
		padding: 0px;
		width: 0px;
		height: 0px;
		line-height: 0;
		visibility: hidden;
		display: block;
	}

	.date-container {
		// This logic is to make the coloring of the 'shared' border, of the date-container and buttons, work
		&:hover {
			position: relative;
			z-index: 3;
		}

		p {
			white-space: nowrap;
		}
	}

	.eitje-button:first-of-type {
		z-index: 2;
		margin-right: -1px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.eitje-button:last-of-type {
		z-index: 2;
		margin-left: -1px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}

.ant-modal-body {
	.border-radius;

	.eitje-form {
		.form-row {
			display: grid;
			align-items: center;
			grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
			.border(right);
			.border(bottom);
			.elementContainer {
				height: 100%;
			}

			.form-container-left {
				flex: 1;
			}

			> * {
				border: none;
				&:not(:last-child) {
					.border(right);
				}
			}
		}

		p,
		input {
			line-height: 1.5;
		}
	}
}

.ant-modal-wrap {
	height: 100%;

	.ant-modal-body {
		.modal-form-flex-row {
			display: flex;

			> * {
				border: unset;
				&:not(:last-child) {
					.border(right);
				}
			}
		}
	}
}

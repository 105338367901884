@import (reference) 'styles/app/app.less';

///////////////////////////////////
// General styling of the modal
///////////////////////////////////

#billing-sign-page {
	height: 540px;
	overflow: hidden;
}

///////////////////////////////////
// Shared styling, mixins
///////////////////////////////////

.billing-sign-modal-half-page {
	display: inline-block;
	width: 50%;
	height: 100%;
	vertical-align: top;
}

///////////////////////////////////
// Left column styling of the modal
///////////////////////////////////

#billing-sign-page .billing-sign-modal-left-side {
	.billing-sign-modal-half-page;
	.border(right);

	.billing-sign-modal-title {
		padding: 60px 40px 0px 40px;
	}

	.billing-sign-modal-text {
		.border(bottom);
		padding: 0 40px 40px 40px;
	}

	.eitje-form {
		.eitje-field-signer_name {
			input {
				border: none;
				padding: 0;

				&::placeholder {
					color: @black;
				}
			}
		}
	}

	.sign-instructions-text {
		padding: 16px 24px;
	}

	.signature-canvas {
		width: 100%;
		height: 250px;
	}

	.clear-signature-canvas-button {
		@footer-height: 73px;
		position: absolute;
		right: calc(50% + 24px);
		bottom: calc(@footer-height + 24px);
		z-index: 10;
	}
}

///////////////////////////////////
// Right column styling of the modal
///////////////////////////////////

#billing-sign-page .billing-sign-modal-right-side {
	.billing-sign-modal-half-page;
	.vertical-scroll;
	padding: 24px 34px 0 34px;

	.pdf-container {
		width: 100%;

		.pdf-controls-container {
			position: fixed;
			top: 550px;
			width: 220px;
			height: 48px;

			.pdf-toggle-container:first-child img {
				transform: rotate(90deg);
			}
		}
	}
}

///////////////////////////////////
// Right column: "waiting for pdf..." placeholder when PDF is being generated
///////////////////////////////////

.waiting-for-pdf-text {
	text-align: center;
	margin-top: 50%;
}

// nest .pdf-destroy-icon within .ant-modal-body to always target it in full screen
// PDF occurrences, but never outside it (on normal pages).
.ant-modal-body .pdf-destroy-icon {
	.square-size(30px);
	.border;
	.pointer-cursor;
	.big-shadow;
	text-align: center;
	background-color: @white;
	position: fixed;
	top: 50px;
	padding: 7px;
	right: calc((100% - 900px) / 2 + 24px);
	.border-radius(round);
	z-index: 10;
}

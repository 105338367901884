/*

/////////////////////////////////////////////////////////////////

IMPORTANT

Below files are merely legacy support, don't add to them, or depend
on then, since the goal is to get rid of them as soon as possible!

/////////////////////////////////////////////////////////////////

*/

/* Main page */
.cardHandboek[data-v-c1eb0f75]:hover {
  background-color: #e6f7ff;
}
.cardHandboek:hover *[data-v-c1eb0f75] {
  cursor: pointer;
}
.cardHandboek[data-v-c1eb0f75] {
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eeeeee70;
}
.paddLarge[data-v-c1eb0f75] {
  padding: 16px 0px 16px 24px;
}
.borderBottom[data-v-c1eb0f75] {
  padding-bottom: 2px;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.containerShow[data-v-c1eb0f75] {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
}
.arrowUp[data-v-c1eb0f75] {
  margin-top: 17px;
  margin-right: 8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #0496ff;
}
.arrowDown[data-v-c1eb0f75] {
  margin-top: 17px;
  margin-right: 8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #0496ff;
}
.containerShow figure img[data-v-c1eb0f75] {
  margin-left: -3rem;
  margin-bottom: 3rem;
  margin-top: 1rem;
}
@media screen and (max-width: 1100px) {
.containerShow figure img[data-v-c1eb0f75] {
    width: 500px;
}
}

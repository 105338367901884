@import (reference) 'styles/app/app.less';

.check-in-popover {
	.popout-card-trigger {
		display: block;
	}
}

.check-in-popover-popout {
	.popout-card,
	.popout-card-trigger {
		width: unset !important;
	}
}

@import (reference) 'styles/app/app.less';

.basic-table-header {
	.border();
	border-radius: 4px 4px 0 0;
	z-index: 3; // to overlay the hovered table row
	position: sticky;
	top: 0px;

	&-cell {
		.border(right);

		&.eitje-layout-has-hover:hover {
			.border(@type: outline);
			.unset-border(right);
			z-index: 3;
		}

		&:first-child {
			border-top-left-radius: 4px;
		}

		&:last-child {
			border-top-right-radius: 4px;
			.unset-border(right);
		}
	}
}

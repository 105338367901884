@import (reference) 'styles/app/app.less';

.exports-subscription-frequency-picker {
	.border(top);
	.border(bottom);
	box-shadow: unset;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 8px;
	padding: 12px 24px 0 24px;

	.exports-subscription-picker-sentence {
		.eitje-form-2-field {
			padding: 0 8px 16px 8px;
		}

		.eitje-time-picker,
		.eitje-date-picker {
			position: absolute;
			height: 32px;
		}

		.eitje-form-2-field.eitje-list-picker {
			min-height: unset;
		}
	}
}

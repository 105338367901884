.eitje-rich-text-editor-content {

    h1,
    h2,
    h3,
    p,
    span:not(.eitje-icon) {
        text-wrap: pretty;
        white-space: pre-wrap;
        word-break: break-word;
        padding: 3px 0;
        width: 100%;
    }

    h1,
    h2,
    h3 {
        font-weight: 600;
    }

    h1 {
        margin: 2rem 0 4px 0;
        font-size: 1.875em;
    }

    h2 {
        margin: 1.4rem 0 1px 0;
        font-size: 1.5em;
    }

    h3 {
        margin: 1rem 0 1px 0;
        font-size: 1.25em;
    }

    p {
        margin: 1px 0;
    }

    span {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    ul,
    ol {
        padding: 3px 1rem;
        margin: 0;

        li p {
            margin: 0.25em 0;
        }
    }

    a {
        color: @medium-blue !important;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: @dark-blue !important;
            text-decoration: underline;
        }
    }

    blockquote {
        .border(@side: left, @width: 3px, @color: @medium-blue);
        margin: 1.5rem 0;
        padding-left: 1rem;

        > p {
            padding: 1px 0px;
        }
    }

    mark {
        .border-radius();
        box-decoration-break: clone;
        padding: 0.1rem 0.2rem;
    }
}
.ant-picker-borderless {
	padding-left: 0px;
}

.ant-picker-borderless.sync-moment-input {
	width: 0px !important;
	height: 0px !important;

	* {
		width: 0px !important;
		height: 0px !important;
	}
}

.ant-picker-footer-extra {
	padding: 0px;
}

.ant-picker-dropdown.has-extra-footer {
	.date-extra-footer-item {
		.pointer-cursor;
		.hover-effect;
		.text-center;
		.border(bottom);
	}
}

@import (reference) 'styles/app/app.less';

@modal-border-radius: 4px;

.eitje-modal-2 {
	position: relative;
	transition: top 0.5s ease 0s;
}

/////////////////////////////
// unset inherited ant & legacy styling
/////////////////////////////

.eitje-modal-2 {
	padding-bottom: unset !important;
}

/////////////////////////////
// Remove default ant top offset when the modal does not fit in Viewport height
/////////////////////////////

.eitje-modal-2.ant-modal.small-top-offset {
	top: 16px;
}

@import (reference) 'styles/app/app.less';

/////////////////////////////////////////////
// This file is the new popvover styling:
// make reusable mixins and apply them to
// specific popovers

// -> see popover.less for legacy, general styling!
/////////////////////////////////////////////

/////////////////////////////////////////////
// Define general classes
/////////////////////////////////////////////

.popover-remove-inner-content-padding {
	.ant-popover-content .ant-popover-inner .ant-popover-inner-content {
		padding: 0;
	}
}

.popover-make-buttons-full-width {
	.ant-popover-content .ant-popover-inner .ant-popover-inner-content button {
		width: 100%;
	}
}

.ant-modal {
	&.modal-fullscreen {
		min-width: calc(100vw - 32px);
		top: 24px;

		.modal-fullscreen-header {
			display: flex;
			align-items: center;
			height: 72px;
			padding: 0px 24px;
			position: sticky;
			top: 0;
			.border(bottom);
			z-index: 10;
			background-color: @white;

			.modal-fullscreen-header-left {
				display: flex;
				justify-content: flex-start;
				flex: 1;
				gap: 4px;

				.ant-modal-title {
					margin-right: 56px;
				}
			}

			.modal-fullscreen-header-center {
				display: flex;
				justify-content: center;
				gap: 4px;
			}

			.modal-fullscreen-header-right {
				display: flex;
				justify-content: flex-end;
				flex: 1;
				gap: 4px;
			}
		}

		.ant-modal-close {
			z-index: 20;
		}

		.modal-fullscreen-body {
			overflow: scroll;
			height: calc(100vh - 120px);
		}

		.ant-modal-body {
			height: calc(100vh - 48px);
			border-radius: 16px;
			overflow: scroll;
		}

		&.footer {
			.ant-modal-body {
				height: calc(100vh - 120px);
				.modal-fullscreen-body {
					height: calc(100vh - 193px);
				}
			}
		}
	}
	&.left-footer {
		.ant-modal-footer {
			min-height: 73px;
			button:first-of-type {
				float: left;
			}
		}
	}

	&.hide-close {
		.ant-modal-close {
			display: none;
		}
	}
}

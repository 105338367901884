@import (reference) 'styles/app/app.less';

.duration-block {
	.border;
	.border-radius;
	.big-shadow;
	padding: 0 8px;
	background: @white;

	.duration-block-text {
		.bold;
		line-height: 34px;
		color: @black;
	}
}

@import (reference) 'styles/app/app.less';

////////////////////////////////////
// Base styling
////////////////////////////////////

.team-panel .team-panel-balance {
	// ...
}

////////////////////////////////////
// Header styling
////////////////////////////////////

.team-panel .team-panel-balance .team-panel-balance-header {
	.border(bottom);
	.row;
	justify-content: space-between;
	padding: 16px 80px 16px 28px;

	h4 {
		display: inline-block;
		font-size: 12px;
	}
}

////////////////////////////////////
// State styling
////////////////////////////////////

.team-panel .team-panel-balance .team-panel-balance-empty {
	.empty-message;
	height: 100%;
}

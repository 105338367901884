@import (reference) 'styles/app/app.less';

.skills-page-area {
	.var(page-top-offset);
	@extra-margin: 80px;
	height: calc(100vh - @var-value - @extra-margin);
}

.skill-column {
	.vertical-padding(12px);
	flex-direction: column;
	display: flex;
	gap: 12px;
	width: 300px;
	height: 100%;
}

.remove-item {
	.popout-card {
		width: fit-content;

		.eitje-layout {
			max-width: 100%;

			.eitje-button {
				max-width: 100%;
				height: unset !important;
				min-height: 32px;

				.eitje-button-text {
					flex: 1 1;

					.eitje-text {
						width: 100%;
						text-align: left;
						white-space: normal;
					}
				}
			}
		}
	}
}

.skill-title-container {
	.border-radius;
	background-color: @white;

	.eitje-text {
		max-width: calc(100% - 16px);
	}
}

.skill-input-area {
	.row-with-border-and-padding(12px, 12px);
	.transition;
	.remove-item;
	flex-wrap: wrap;
	align-content: flex-start;
	overflow-y: scroll;
	overflow-x: hidden;
	flex: 1;
	grid-gap: 4px;
}

// Placeholder Column
.placeholder-col {
	.skill-column;

	.placeholder-title-row {
		.skill-title-container;
		.border(@style: dashed, @width: 4px);
		.border-radius(large);
		box-shadow: 0px 0px 0px 0px ~'@{medium-grey}20';

		&:hover {
			.white-background-and-big-shadow;

			.placeholder-text {
				opacity: 1;
			}
		}

		.placeholder-text {
			.transition;
			font-size: 14px;
			.bold;
			.grey-text;
			opacity: 0.5;
		}
	}

	.placeholder-input-area {
		.column-with-border-and-padding(12px, 12px);
		.transition;
		overflow: scroll;
		flex: 1;
		.border(@style: dashed, @width: 4px);
		.border-radius(large);
		box-shadow: 0px 0px 0px 0px ~'@{medium-grey}20';
		margin-bottom: 28px;
		justify-content: center;

		&:hover {
			.white-background-and-big-shadow;
			.placeholder-text {
				opacity: 1;
			}
		}

		&:focus-within {
			.white-background-and-big-shadow;
			.placeholder-text {
				opacity: 1;
			}
		}

		input {
			.transition;
			text-align: center;
			.bold;
		}
	}
}

#skills-page .skills-page-area {
	width: 100vw;
}

// Skills useList style
.skills-list {
	.h-list(12px);
	.horizontal-padding(12px);
	overflow-x: unset;
	display: flex;
	height: 100%;

	:is(.list-container-draggable) {
		height: 100%;

		:is(.list-container) {
			height: 100%;

			:is(.flat-list) {
				height: 100%;

				:is(.list-item) {
					height: 100%;
					padding-bottom: 16px;
					:is(.drag-element) {
						height: 100%;
						display: flex;
						flex-direction: column;

						.skill-column {
							padding-top: 0px;
							height: 100%;
							display: flex;
							flex-direction: column;

							.skill-list-input-container {
								.column-with-border-and-padding(12px, 12px);
								gap: 12px;
								height: 100%;
								overflow: hidden;

								.list-picker-dropdown-container {
									width: 100%;
								}
								.skill-list-input-area {
									width: 100%;

									overflow-y: scroll;

									:is(.selected-area-container) {
										display: flex;
										flex-wrap: wrap;
										gap: 4px;
										align-content: flex-start;
										width: 100%;
									}

									.remove-item;
								}
							}
						}
					}
				}
			}
		}
	}
}

.warning-banner-container {
	width: 100%;
	background: @light-grey;
	.border;
	.border-radius;
	padding: 12px 20px;
	margin-bottom: 12px;

	.warning-banner-emph {
		.bold;
		font-size: 12px;
		width: fit-content;
		white-space: nowrap;
		margin-right: 4px;
	}

	.warning-banner-body {
		.eitje-text-secondary;
	}
}

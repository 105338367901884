@cross-size-base: 24px;
@cross-size-small: 16px;

.check-cross-mark-img {
	width: @cross-size-base;
	min-width: @cross-size-base;
	max-height: @cross-size-base;
	height: @cross-size-base;
	&.small {
		width: @cross-size-small;
		min-width: @cross-size-small;
		max-height: @cross-size-small;
		height: @cross-size-small;
	}
}

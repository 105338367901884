.eitje-form-2-text-input {
  cursor: pointer;
}
.eitje-form-2-text-input .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-text-input:hover .eitje-form-2-field-icon,
.eitje-form-2-text-input:active .eitje-form-2-field-icon,
.eitje-form-2-text-input:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-text-input * {
  cursor: pointer;
}
.eitje-form-2-text-input * {
  cursor: pointer;
}
.eitje-form-2-text-input input,
.eitje-form-2-text-input .ant-input-password {
  border-color: unset;
  border-width: unset;
  border-style: unset;
  background-color: unset;
  padding: 0;
  height: 21px;
  border: 0px solid;
  border-right-width: 0px !important;
}
.eitje-form-2-text-input input.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.eitje-form-2-text-input .ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.eitje-form-2-text-input input.ant-input-affix-wrapper.ant-input-affix-wrapper-focused:hover,
.eitje-form-2-text-input .ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-focused:hover {
  box-shadow: unset !important;
  border-right-width: 0px !important;
}
.eitje-form-2-text-input .ant-input-password .anticon-eye,
.eitje-form-2-text-input .ant-input-password .anticon-eye-invisible {
  top: -4px;
}
.eitje-form-2-text-input .char-counter.char-counter-warning {
  color: #ffa405;
}
.eitje-form-2-text-input .char-counter.char-counter-danger {
  color: #ec1314;
}
.eitje-form-2-multiline-input .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-multiline-input:hover .eitje-form-2-field-icon,
.eitje-form-2-multiline-input:active .eitje-form-2-field-icon,
.eitje-form-2-multiline-input:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-multiline-input textarea {
  height: 21px;
  min-height: 21px;
  resize: none;
}
.eitje-form-2-multiline-input textarea::-webkit-scrollbar {
  display: none;
}
.eitje-form-2-multiline-input textarea::-webkit-scrollbar {
  display: none;
}
.eitje-form-2-multiline-input textarea::placeholder {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 2 * 24px);
}
.eitje-form-2-number-input {
  display: flex;
  align-items: center;
}
.eitje-form-2-number-input .ant-select,
.eitje-form-2-number-input .ant-picker,
.eitje-form-2-number-input textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2-number-input .ant-select *,
.eitje-form-2-number-input .ant-picker *,
.eitje-form-2-number-input textarea * {
  cursor: pointer !important;
}
.eitje-form-2-number-input .ant-select *,
.eitje-form-2-number-input .ant-picker *,
.eitje-form-2-number-input textarea * {
  cursor: pointer !important;
}
.eitje-form-2-number-input .ant-select .ant-picker-input,
.eitje-form-2-number-input .ant-picker .ant-picker-input,
.eitje-form-2-number-input textarea .ant-picker-input,
.eitje-form-2-number-input .ant-select .ant-select-selector,
.eitje-form-2-number-input .ant-picker .ant-select-selector,
.eitje-form-2-number-input textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2-number-input .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2-number-input .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2-number-input textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2-number-input .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2-number-input .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2-number-input textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2-number-input .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-number-input .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-number-input textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-number-input .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-number-input .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-number-input textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-number-input .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-number-input .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-number-input textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-number-input .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-number-input .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-number-input textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2-number-input .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-number-input .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-number-input textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-number-input .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-number-input .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-number-input textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2-number-input .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2-number-input .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2-number-input .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2-number-input textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2-number-input .eitje-form-2-field-icon,
.eitje-form-2-number-input .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-number-input:hover .eitje-form-2-field-icon,
.eitje-form-2-number-input:active .eitje-form-2-field-icon,
.eitje-form-2-number-input:focus .eitje-form-2-field-icon,
.eitje-form-2-number-input:hover .eitje-form-2-field-clear,
.eitje-form-2-number-input:active .eitje-form-2-field-clear,
.eitje-form-2-number-input:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-number-input .eitje-form-2-extra-label {
  position: absolute;
  top: calc(16px + 18px);
}
.eitje-form-2-number-input .eitje-simple-input-number {
  border: 1px solid #dddddd;
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  border-radius: 4px;
  position: relative;
}
.eitje-form-2-number-input .eitje-simple-input-number:hover {
  border: 1px solid #dddddd;
}
.eitje-form-2-number-input .eitje-simple-input-number input {
  font-size: 12px;
}
.eitje-form-2-number-input .eitje-simple-input-number .left-arrow {
  position: absolute;
  left: 4px;
  z-index: 10;
}
.eitje-form-2-number-input .eitje-simple-input-number .right-arrow {
  position: absolute;
  right: 4px;
  z-index: 10;
}
.eitje-form-2-number-input .eitje-simple-input-number {
  position: absolute;
  right: 24px;
}
.eitje-form-2-number-input .eitje-simple-input-number input {
  width: 50px;
}
.eitje-form-2-number-input .ant-input-affix-wrapper {
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  width: auto;
  position: absolute;
  right: 24px;
  z-index: 2;
  background: #ffffff;
}
.eitje-form-2-number-input .ant-input-affix-wrapper .ant-input {
  font-weight: 600;
  width: 30px;
  font-size: 12px;
}
.eitje-form-2-number-input .ant-input-affix-wrapper .ant-input-suffix {
  font-weight: 600;
  font-size: 12px;
}
.eitje-form-2-multi-input {
  cursor: pointer;
  display: block;
  min-height: 71px;
}
.eitje-form-2-multi-input * {
  cursor: pointer;
}
.eitje-form-2-multi-input * {
  cursor: pointer;
}
.eitje-form-2-multi-input *:focus {
  outline: none;
}
.eitje-form-2-multi-input .eitje-form-2-label {
  width: 100%;
}
.eitje-form-2-multi-input .ant-select {
  width: 100%;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector {
  cursor: pointer !important;
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  margin-bottom: -14px;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector .ant-select-selection-overflow {
  padding: 4px 0 14px 0;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
  align-self: flex-start;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item {
  border: 1px solid #dddddd;
  margin: 0 4px 4px 0;
  padding: 0 8px;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-content {
  font-size: 14px;
  line-height: 26px;
  margin: 0 4px 0 0;
  padding: 0 2px 0 0;
  color: #000000;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove .anticon {
  line-height: 28px;
  font-size: 10px;
  color: #dddddd;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: none;
}
.eitje-form-2-multi-input .ant-select .ant-select-selector .ant-select-selection-placeholder {
  left: 0;
  top: 10px;
  height: 21px;
  line-height: 21px;
}
.eitje-form-2-multi-input:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all {
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  right: 24px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  padding: 0 16px;
  width: 130px;
  text-align: center;
  top: calc(16px + 18px + 4px);
  background-color: #ffffff;
  z-index: 10;
}
.eitje-form-2-multi-input:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all * {
  cursor: pointer;
}
.eitje-form-2-multi-input:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all * {
  cursor: pointer;
}
.eitje-form-2-multi-input:not(.eitje-form-2-field-has-icon) .ant-select-selection-overflow,
.eitje-form-2-multi-input:not(.eitje-form-2-field-has-icon) .ant-select-selection-placeholder {
  max-width: calc(100% - 130px - 4px);
}
.eitje-form-2-checkbox-input {
  position: relative;
}
.eitje-form-2-checkbox-input .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-checkbox-input:hover .eitje-form-2-field-icon,
.eitje-form-2-checkbox-input:active .eitje-form-2-field-icon,
.eitje-form-2-checkbox-input:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-checkbox-input .eitje-form-2-label {
  margin-right: calc(16px + 36px);
}
.eitje-form-2-checkbox-input .ant-checkbox-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.eitje-form-2-checkbox-input .ant-checkbox-wrapper .ant-checkbox {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 24px;
  top: calc((100% - 16px) / 2);
}
.eitje-form-2-dropdown .ant-select,
.eitje-form-2-dropdown .ant-picker,
.eitje-form-2-dropdown textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2-dropdown .ant-select *,
.eitje-form-2-dropdown .ant-picker *,
.eitje-form-2-dropdown textarea * {
  cursor: pointer !important;
}
.eitje-form-2-dropdown .ant-select *,
.eitje-form-2-dropdown .ant-picker *,
.eitje-form-2-dropdown textarea * {
  cursor: pointer !important;
}
.eitje-form-2-dropdown .ant-select .ant-picker-input,
.eitje-form-2-dropdown .ant-picker .ant-picker-input,
.eitje-form-2-dropdown textarea .ant-picker-input,
.eitje-form-2-dropdown .ant-select .ant-select-selector,
.eitje-form-2-dropdown .ant-picker .ant-select-selector,
.eitje-form-2-dropdown textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2-dropdown .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2-dropdown .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2-dropdown textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2-dropdown .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2-dropdown .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2-dropdown textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2-dropdown .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-dropdown .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-dropdown textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-dropdown .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-dropdown .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-dropdown textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-dropdown .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2-dropdown .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2-dropdown .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2-dropdown .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2-dropdown .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2-dropdown textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2-dropdown-as-button {
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  cursor: pointer;
  height: 40px;
  padding: unset;
  min-height: unset;
  display: inline-flex;
  flex: unset;
}
.eitje-form-2-dropdown-as-button .ant-select,
.eitje-form-2-dropdown-as-button .ant-picker,
.eitje-form-2-dropdown-as-button textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2-dropdown-as-button .ant-select *,
.eitje-form-2-dropdown-as-button .ant-picker *,
.eitje-form-2-dropdown-as-button textarea * {
  cursor: pointer !important;
}
.eitje-form-2-dropdown-as-button .ant-select *,
.eitje-form-2-dropdown-as-button .ant-picker *,
.eitje-form-2-dropdown-as-button textarea * {
  cursor: pointer !important;
}
.eitje-form-2-dropdown-as-button .ant-select .ant-picker-input,
.eitje-form-2-dropdown-as-button .ant-picker .ant-picker-input,
.eitje-form-2-dropdown-as-button textarea .ant-picker-input,
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector,
.eitje-form-2-dropdown-as-button .ant-picker .ant-select-selector,
.eitje-form-2-dropdown-as-button textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2-dropdown-as-button .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2-dropdown-as-button .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2-dropdown-as-button textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2-dropdown-as-button .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2-dropdown-as-button textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2-dropdown-as-button .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-dropdown-as-button .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-dropdown-as-button textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-dropdown-as-button .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-dropdown-as-button textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-dropdown-as-button .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2-dropdown-as-button .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-dropdown-as-button textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2-dropdown-as-button .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2-dropdown-as-button .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2-dropdown-as-button textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2-dropdown-as-button * {
  cursor: pointer;
}
.eitje-form-2-dropdown-as-button * {
  cursor: pointer;
}
.eitje-form-2-dropdown-as-button .ant-select {
  position: relative;
  padding: unset;
  display: flex;
  height: unset;
  height: 40px;
}
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector {
  position: unset;
  padding: unset;
  height: unset;
}
.eitje-form-2-dropdown-as-button .ant-select .ant-select-selector .ant-select-selection-item {
  font-weight: 600;
  padding: 0 calc(20px + 10px) 0 20px;
  line-height: 40px;
}
.eitje-form-2-dropdown-as-button .eitje-form-2-field-icon {
  right: 12px;
}
.eitje-form-2-time-picker .ant-select,
.eitje-form-2-time-picker .ant-picker,
.eitje-form-2-time-picker textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2-time-picker .ant-select *,
.eitje-form-2-time-picker .ant-picker *,
.eitje-form-2-time-picker textarea * {
  cursor: pointer !important;
}
.eitje-form-2-time-picker .ant-select *,
.eitje-form-2-time-picker .ant-picker *,
.eitje-form-2-time-picker textarea * {
  cursor: pointer !important;
}
.eitje-form-2-time-picker .ant-select .ant-picker-input,
.eitje-form-2-time-picker .ant-picker .ant-picker-input,
.eitje-form-2-time-picker textarea .ant-picker-input,
.eitje-form-2-time-picker .ant-select .ant-select-selector,
.eitje-form-2-time-picker .ant-picker .ant-select-selector,
.eitje-form-2-time-picker textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2-time-picker .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2-time-picker .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2-time-picker textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2-time-picker .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2-time-picker .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2-time-picker textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2-time-picker .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-time-picker .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-time-picker textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-time-picker .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-time-picker .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-time-picker textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-time-picker .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-time-picker .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-time-picker textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-time-picker .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-time-picker .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-time-picker textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2-time-picker .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-time-picker .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-time-picker textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-time-picker .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-time-picker .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-time-picker textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2-time-picker .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2-time-picker .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2-time-picker .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2-time-picker textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2-file-upload .eitje-form-2-field-title-upload-container {
  border: 1px solid #dddddd;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 24px;
  text-align: center;
  background-color: #fbfcfd;
}
.eitje-form-2-file-upload .eitje-form-2-field-title-upload-container * {
  cursor: pointer;
}
.eitje-form-2-file-upload .eitje-form-2-field-title-upload-container * {
  cursor: pointer;
}
.eitje-form-2-file-upload .eitje-form-2-field-title-upload-container .eitje-form-2-field-title-upload-icon svg {
  font-size: 40px;
  color: #0096ff;
  margin: 0 0 12px 0;
}
.eitje-form-2-file-upload .link-button-container {
  float: right;
  margin: 4px 0 0 0;
}
.eitje-form-2-toggle {
  min-height: 72px;
  display: inline-flex;
  align-items: center;
}
.eitje-form-2-toggle .eitje-form-2-field-icon,
.eitje-form-2-toggle .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-toggle:hover .eitje-form-2-field-icon,
.eitje-form-2-toggle:active .eitje-form-2-field-icon,
.eitje-form-2-toggle:focus .eitje-form-2-field-icon,
.eitje-form-2-toggle:hover .eitje-form-2-field-clear,
.eitje-form-2-toggle:active .eitje-form-2-field-clear,
.eitje-form-2-toggle:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-toggle .eitje-form-2-label {
  width: calc(100% - 44px);
}
.eitje-form-2-toggle button {
  position: absolute;
  right: 24px;
}
.eitje-form-2-unfield {
  min-height: unset;
  padding: unset;
  flex: unset;
}
.eitje-form-2-unfield .eitje-form-2-field-icon,
.eitje-form-2-unfield .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-unfield:hover .eitje-form-2-field-icon,
.eitje-form-2-unfield:active .eitje-form-2-field-icon,
.eitje-form-2-unfield:focus .eitje-form-2-field-icon,
.eitje-form-2-unfield:hover .eitje-form-2-field-clear,
.eitje-form-2-unfield:active .eitje-form-2-field-clear,
.eitje-form-2-unfield:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-date-picker .ant-select,
.eitje-form-2-date-picker .ant-picker,
.eitje-form-2-date-picker textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2-date-picker .ant-select *,
.eitje-form-2-date-picker .ant-picker *,
.eitje-form-2-date-picker textarea * {
  cursor: pointer !important;
}
.eitje-form-2-date-picker .ant-select *,
.eitje-form-2-date-picker .ant-picker *,
.eitje-form-2-date-picker textarea * {
  cursor: pointer !important;
}
.eitje-form-2-date-picker .ant-select .ant-picker-input,
.eitje-form-2-date-picker .ant-picker .ant-picker-input,
.eitje-form-2-date-picker textarea .ant-picker-input,
.eitje-form-2-date-picker .ant-select .ant-select-selector,
.eitje-form-2-date-picker .ant-picker .ant-select-selector,
.eitje-form-2-date-picker textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2-date-picker .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2-date-picker .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2-date-picker textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2-date-picker .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2-date-picker .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2-date-picker textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2-date-picker .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-date-picker .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-date-picker textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-date-picker .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-date-picker .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-date-picker textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-date-picker .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-date-picker .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-date-picker textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-date-picker .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-date-picker .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-date-picker textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2-date-picker .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-date-picker .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-date-picker textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-date-picker .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-date-picker .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-date-picker textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2-date-picker .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2-date-picker .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2-date-picker .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2-date-picker textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2-days-picker {
  min-height: 71px;
}
.eitje-form-2-days-picker .eitje-form-2-field-icon,
.eitje-form-2-days-picker .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-days-picker:hover .eitje-form-2-field-icon,
.eitje-form-2-days-picker:active .eitje-form-2-field-icon,
.eitje-form-2-days-picker:focus .eitje-form-2-field-icon,
.eitje-form-2-days-picker:hover .eitje-form-2-field-clear,
.eitje-form-2-days-picker:active .eitje-form-2-field-clear,
.eitje-form-2-days-picker:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-days-picker .eitje-button-select {
  position: absolute;
  left: 24px;
  top: 38px;
}
.eitje-form-2-button-select {
  height: 71px;
  text-align: center;
}
.eitje-form-2-button-select .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-button-select:hover .eitje-form-2-field-icon,
.eitje-form-2-button-select:active .eitje-form-2-field-icon,
.eitje-form-2-button-select:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-button-select .ant-select,
.eitje-form-2-button-select .ant-picker,
.eitje-form-2-button-select textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2-button-select .ant-select *,
.eitje-form-2-button-select .ant-picker *,
.eitje-form-2-button-select textarea * {
  cursor: pointer !important;
}
.eitje-form-2-button-select .ant-select *,
.eitje-form-2-button-select .ant-picker *,
.eitje-form-2-button-select textarea * {
  cursor: pointer !important;
}
.eitje-form-2-button-select .ant-select .ant-picker-input,
.eitje-form-2-button-select .ant-picker .ant-picker-input,
.eitje-form-2-button-select textarea .ant-picker-input,
.eitje-form-2-button-select .ant-select .ant-select-selector,
.eitje-form-2-button-select .ant-picker .ant-select-selector,
.eitje-form-2-button-select textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2-button-select .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2-button-select .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2-button-select textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2-button-select .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2-button-select .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2-button-select textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2-button-select .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-button-select .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-button-select textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-button-select .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-button-select .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-button-select textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-button-select .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-button-select .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-button-select textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-button-select .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-button-select .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-button-select textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2-button-select .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-button-select .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-button-select textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-button-select .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-button-select .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-button-select textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2-button-select .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2-button-select .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2-button-select .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2-button-select textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2-button-select .eitje-button-select-large {
  position: absolute;
  right: 24px;
}
.eitje-form-2-button-select .eitje-button-select-no-label.eitje-button-select-large {
  position: relative;
  right: unset;
}
.eitje-form-2-modal-link {
  position: relative;
  min-height: 72px;
  display: inline-flex;
  align-items: center;
}
.eitje-form-2-modal-link .link {
  position: absolute;
  top: 20px;
  right: 24px;
}
.eitje-form-2-modal-link button {
  position: absolute;
  right: 24px;
}
.eitje-form-2-tabs {
  height: 71px;
  text-align: center;
}
.eitje-form-2-tabs .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-tabs:hover .eitje-form-2-field-icon,
.eitje-form-2-tabs:active .eitje-form-2-field-icon,
.eitje-form-2-tabs:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2-tabs .eitje-tabs {
  position: absolute;
  right: 24px;
}
.eitje-form-2-tabs .eitje-form-2-tabs-no-label {
  position: relative;
  right: unset;
}
.eitje-form-2-field.eitje-list-picker {
  cursor: pointer;
  min-height: 71px;
}
.eitje-form-2-field.eitje-list-picker * {
  cursor: pointer;
}
.eitje-form-2-field.eitje-list-picker * {
  cursor: pointer;
}
.eitje-form-2-field.eitje-list-picker .form-trigger-container {
  min-height: 71px;
  position: absolute;
  inset: -16px -24px auto -24px;
  padding: calc(16px + 18px) 24px 24px 24px;
}
.eitje-form-2-field.eitje-list-picker .eitje-form-2-select-all {
  position: absolute;
  right: 24px;
  top: 50%;
}
.eitje-form-2-field.eitje-list-picker .eitje-form-2-error {
  margin-top: 22px;
}
.eitje-form-2-field.eitje-list-picker .list-picker-trigger {
  width: 100%;
}
.eitje-form-2-field.eitje-list-picker .list-picker-trigger .easy-remove-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.eitje-form-2-hour-minute-picker .ant-select,
.eitje-form-2-hour-minute-picker .ant-picker,
.eitje-form-2-hour-minute-picker textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2-hour-minute-picker .ant-select *,
.eitje-form-2-hour-minute-picker .ant-picker *,
.eitje-form-2-hour-minute-picker textarea * {
  cursor: pointer !important;
}
.eitje-form-2-hour-minute-picker .ant-select *,
.eitje-form-2-hour-minute-picker .ant-picker *,
.eitje-form-2-hour-minute-picker textarea * {
  cursor: pointer !important;
}
.eitje-form-2-hour-minute-picker .ant-select .ant-picker-input,
.eitje-form-2-hour-minute-picker .ant-picker .ant-picker-input,
.eitje-form-2-hour-minute-picker textarea .ant-picker-input,
.eitje-form-2-hour-minute-picker .ant-select .ant-select-selector,
.eitje-form-2-hour-minute-picker .ant-picker .ant-select-selector,
.eitje-form-2-hour-minute-picker textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2-hour-minute-picker .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2-hour-minute-picker .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2-hour-minute-picker textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2-hour-minute-picker .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2-hour-minute-picker .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2-hour-minute-picker textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2-hour-minute-picker .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-hour-minute-picker .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-hour-minute-picker textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2-hour-minute-picker .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-hour-minute-picker .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-hour-minute-picker textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2-hour-minute-picker .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2-hour-minute-picker .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2-hour-minute-picker textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2-hour-minute-picker .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2-hour-minute-picker .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2-hour-minute-picker .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2-hour-minute-picker textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2-hour-minute-picker .eitje-form-2-field-icon,
.eitje-form-2-hour-minute-picker .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-hour-minute-picker:hover .eitje-form-2-field-icon,
.eitje-form-2-hour-minute-picker:active .eitje-form-2-field-icon,
.eitje-form-2-hour-minute-picker:focus .eitje-form-2-field-icon,
.eitje-form-2-hour-minute-picker:hover .eitje-form-2-field-clear,
.eitje-form-2-hour-minute-picker:active .eitje-form-2-field-clear,
.eitje-form-2-hour-minute-picker:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-hour-minute-picker .eitje-hour-minute-picker-input {
  background-color: unset;
  border: unset;
  justify-content: flex-start;
}
.eitje-form-2-hour-minute-picker .eitje-hour-minute-picker-input input {
  color: #000000;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field {
  display: flex;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field:focus .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field:hover .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field:active .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-form-2-field {
  border-right: 0px;
  padding: 0;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-simple-input-number {
  border: 1px solid #dddddd;
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 18px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-simple-input-number:hover {
  border: 1px solid #dddddd;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-simple-input-number input {
  font-size: 12px;
  height: 22px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-simple-input-number .left-arrow {
  position: absolute;
  left: 4px;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-simple-input-number .right-arrow {
  position: absolute;
  right: 4px;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field .eitje-button-select.eitje-button-select-small {
  left: 0;
  top: 22px;
}
.eitje-form-2-inputs-list {
  margin: 12px 24px;
}
.eitje-form-2-inputs-list .inputs-list-wrapper {
  display: inline-block;
}
.eitje-form-2-inputs-list .inputs-list-wrapper-active input {
  font-weight: 600;
}
.eitje-form-2-inputs-list .inputs-list-wrapper .eitje-form-2-field {
  display: block;
  padding: 0px;
}
.eitje-form-2-inputs-list .inputs-list-wrapper .eitje-input {
  padding: 0px;
}
.eitje-form-2-inputs-list .inputs-list-wrapper input {
  margin-top: 4px;
  color: #000000;
}
.eitje-form-2-inputs-list .eitje-checkbox-container {
  padding: 4px 8px;
}
.eitje-form-2-inputs-list .eitje-form-2-row {
  border: 1px solid #dddddd;
  border-bottom-width: 0px;
  padding: 4px 8px;
  height: 42px;
}
.eitje-form-2-inputs-list .eitje-form-2-row:first-of-type {
  border-radius: 4px 4px 0px 0px;
}
.eitje-form-2-inputs-list .eitje-form-2-row:last-of-type {
  border-bottom-width: 1px;
  border-radius: 0px 0px 4px 4px;
}
.strech-input-to-full-size .ant-select,
.strech-input-to-full-size .ant-picker,
.strech-input-to-full-size textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.strech-input-to-full-size .ant-select *,
.strech-input-to-full-size .ant-picker *,
.strech-input-to-full-size textarea * {
  cursor: pointer !important;
}
.strech-input-to-full-size .ant-select *,
.strech-input-to-full-size .ant-picker *,
.strech-input-to-full-size textarea * {
  cursor: pointer !important;
}
.strech-input-to-full-size .ant-select .ant-picker-input,
.strech-input-to-full-size .ant-picker .ant-picker-input,
.strech-input-to-full-size textarea .ant-picker-input,
.strech-input-to-full-size .ant-select .ant-select-selector,
.strech-input-to-full-size .ant-picker .ant-select-selector,
.strech-input-to-full-size textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.strech-input-to-full-size .ant-select .ant-picker-input .ant-select-selection-item,
.strech-input-to-full-size .ant-picker .ant-picker-input .ant-select-selection-item,
.strech-input-to-full-size textarea .ant-picker-input .ant-select-selection-item,
.strech-input-to-full-size .ant-select .ant-select-selector .ant-select-selection-item,
.strech-input-to-full-size .ant-picker .ant-select-selector .ant-select-selection-item,
.strech-input-to-full-size textarea .ant-select-selector .ant-select-selection-item,
.strech-input-to-full-size .ant-select .ant-picker-input .ant-select-selection-placeholder,
.strech-input-to-full-size .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.strech-input-to-full-size textarea .ant-picker-input .ant-select-selection-placeholder,
.strech-input-to-full-size .ant-select .ant-select-selector .ant-select-selection-placeholder,
.strech-input-to-full-size .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.strech-input-to-full-size textarea .ant-select-selector .ant-select-selection-placeholder,
.strech-input-to-full-size .ant-select .ant-picker-input .ant-select-selection-search-input,
.strech-input-to-full-size .ant-picker .ant-picker-input .ant-select-selection-search-input,
.strech-input-to-full-size textarea .ant-picker-input .ant-select-selection-search-input,
.strech-input-to-full-size .ant-select .ant-select-selector .ant-select-selection-search-input,
.strech-input-to-full-size .ant-picker .ant-select-selector .ant-select-selection-search-input,
.strech-input-to-full-size textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.strech-input-to-full-size .ant-select .ant-picker-input .ant-select-selection-search-input,
.strech-input-to-full-size .ant-picker .ant-picker-input .ant-select-selection-search-input,
.strech-input-to-full-size textarea .ant-picker-input .ant-select-selection-search-input,
.strech-input-to-full-size .ant-select .ant-select-selector .ant-select-selection-search-input,
.strech-input-to-full-size .ant-picker .ant-select-selector .ant-select-selection-search-input,
.strech-input-to-full-size textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.strech-input-to-full-size .eitje-form-2-label {
  margin-bottom: 21px;
}
.strech-input-to-full-size .ant-select .ant-select-selector {
  height: 100%;
}
.strech-input-to-full-size .ant-picker-focused {
  box-shadow: unset;
}
.strech-input-to-full-size textarea {
  padding-top: calc(16px + 18px);
}
.hide-all-icons .eitje-form-2-field-icon,
.hide-all-icons .eitje-form-2-field-clear {
  display: none;
}
.hide-all-icons:hover .eitje-form-2-field-icon,
.hide-all-icons:active .eitje-form-2-field-icon,
.hide-all-icons:focus .eitje-form-2-field-icon,
.hide-all-icons:hover .eitje-form-2-field-clear,
.hide-all-icons:active .eitje-form-2-field-clear,
.hide-all-icons:focus .eitje-form-2-field-clear {
  display: none;
}
.hide-icon .eitje-form-2-field-icon {
  display: none;
}
.hide-icon:hover .eitje-form-2-field-icon,
.hide-icon:active .eitje-form-2-field-icon,
.hide-icon:focus .eitje-form-2-field-icon {
  display: none;
}
.hide-clear .eitje-form-2-field-clear {
  display: none;
}
.hide-clear:hover .eitje-form-2-field-clear,
.hide-clear:active .eitje-form-2-field-clear,
.hide-clear:focus .eitje-form-2-field-clear {
  display: none;
}
.hide-error-message p.eitje-form-2-error {
  display: none;
}
.show-error-border .eitje-form-2-field.eitje-form-2-field-error .ant-picker {
  border: 1px solid #ec1314;
}
.eitje-form-2-row {
  display: flex;
  flex-direction: row;
}
.validation-error-mixin {
  color: #ec1314;
  font-size: 10px;
  line-height: 15px;
  display: block;
}
.eitje-form-2-field,
.eitje-form-2-fake-field {
  display: inline-block;
  flex: 1 1 0px;
  padding: 16px 24px;
  min-height: 100%;
  position: relative;
}
.eitje-form-2-field .anticon-close-circle,
.eitje-form-2-fake-field .anticon-close-circle,
.eitje-form-2-field .ant-picker-suffix,
.eitje-form-2-fake-field .ant-picker-suffix,
.eitje-form-2-field .ant-select-arrow,
.eitje-form-2-fake-field .ant-select-arrow {
  display: none;
}
.eitje-form-2-field p:not(.eitje-button-text, .eitje-tab, .eitje-text),
.eitje-form-2-field textarea,
.eitje-form-2-field input {
  color: #000000;
  font-size: 14px;
  line-height: 21px;
  display: block;
}
.eitje-form-2-field textarea {
  width: calc(100% - 16px);
}
.eitje-form-2-field textarea::placeholder,
.eitje-form-2-field input::placeholder {
  color: #828282;
}
.eitje-form-2-field p.eitje-form-2-label,
.eitje-form-2-field p.eitje-form-2-label span {
  color: #828282;
  font-size: 12px;
  line-height: 18px;
  display: block;
}
.eitje-form-2-field p.eitje-form-2-warning {
  color: #ffa405;
  font-size: 10px;
  line-height: 15px;
  display: block;
}
.eitje-form-2-field p.eitje-form-2-error {
  color: #ec1314;
  font-size: 10px;
  line-height: 15px;
  display: block;
}
.eitje-form-2-field p.eitje-form-2-placeholder {
  color: #828282;
}
.eitje-form-2-field .eitje-form-2-field-icon,
.eitje-form-2-field .eitje-form-2-field-clear,
.eitje-form-2-field .anticon-eye,
.eitje-form-2-field .anticon-eye-invisible {
  height: 12px;
  position: absolute;
  right: 24px;
  top: calc((100% - 12px) / 2);
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 9;
}
.eitje-form-2-field .eitje-form-2-field-clear {
  cursor: pointer;
  z-index: 10;
  display: none;
}
.eitje-form-2-field .eitje-form-2-field-clear * {
  cursor: pointer;
}
.eitje-form-2-field .eitje-form-2-field-clear * {
  cursor: pointer;
}
.eitje-form-2-field:hover .eitje-form-2-field-clear {
  display: inline-block;
}
.eitje-form-2-field .eitje-form-2-tooltip {
  cursor: pointer;
}
.eitje-form-2-field .eitje-form-2-tooltip * {
  cursor: pointer;
}
.eitje-form-2-field .eitje-form-2-tooltip * {
  cursor: pointer;
}
.eitje-form-2-field .eitje-form-2-tooltip {
  height: 12px;
  vertical-align: top;
  margin: 4px 0 0 4px;
  float: left;
}
.eitje-form-2-field .ant-picker,
.eitje-form-2-field .ant-select .ant-select-selector,
.eitje-form-2-field textarea {
  border-color: unset;
  border-width: unset;
  border-style: unset;
  padding: unset;
  background-color: unset;
}
.eitje-form-2-field .eitje-form-2-label,
.eitje-form-2-field .eitje-form-2-error,
.eitje-form-2-field .eitje-form-2-warning,
.eitje-form-2-field p,
.eitje-form-2-field textarea,
.eitje-form-2-field input {
  position: relative;
  z-index: 2;
  display: inline-block;
  float: left;
  clear: both;
}
.eitje-form-2-field.eitje-form-2-field-disabled {
  opacity: 0.3;
}
.eitje-form-2-read-only {
  pointer-events: none;
}
.eitje-form-2-row > .popout-card {
  width: 100% !important;
}
.form-debug * {
  opacity: 1 !important;
}
.form-debug2 * {
  opacity: 1 !important;
}
.form-debug2 .eitje-form-2-row {
  border: 1px solid black !important;
}
.eitje-form-2-field .eitje-layout.popout-card.list-picker {
  display: block !important;
}
.eitje-form-2-row .popout-card-trigger {
  width: 100%;
}
.eitje-form-2-use-borders .eitje-form-2-row {
  border-top: 1px solid #eeeeee;
}
.eitje-form-2-use-borders .eitje-form-2-row:first-child {
  border-top-color: unset;
  border-top-width: unset;
  border-top-style: unset;
}
.eitje-form-2-use-borders .eitje-form-2-row .eitje-form-2-field,
.eitje-form-2-use-borders .eitje-form-2-row .eitje-form-2-fake-field,
.eitje-form-2-use-borders .eitje-form-2-row .eitje-form-2-double-field {
  border-right: 1px solid #eeeeee;
}
.eitje-form-2-use-borders .eitje-form-2-row .eitje-form-2-field:last-child,
.eitje-form-2-use-borders .eitje-form-2-row .eitje-form-2-fake-field:last-child,
.eitje-form-2-use-borders .eitje-form-2-row .eitje-form-2-double-field:last-child {
  border-right-color: unset;
  border-right-width: unset;
  border-right-style: unset;
}
.eitje-form-2-row-form {
  display: flex;
  align-items: center;
  width: 100%;
}
.eitje-form-2-row-form .eitje-form-2-field {
  display: inline-flex;
}
.eitje-form-2-row-form button {
  cursor: pointer;
  min-width: unset;
}
.eitje-form-2-row-form button * {
  cursor: pointer;
}
.eitje-form-2-row-form button * {
  cursor: pointer;
}
.eitje-form-2-row-form button {
  height: 30px;
  padding: 0 12px;
}
.eitje-form-2-row-form .eitje-form-2-field {
  padding: unset;
}
.eitje-form-2-row-form .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-row-form:hover .eitje-form-2-field-clear,
.eitje-form-2-row-form:active .eitje-form-2-field-clear,
.eitje-form-2-row-form:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-row-form p.eitje-form-2-error {
  display: none;
}
.eitje-form-2-row-form .eitje-form-2-field.eitje-form-2-field-error .ant-picker {
  border: 1px solid #ec1314;
}
.eitje-form-2-row-form .eitje-form-2-field .eitje-form-2-label,
.eitje-form-2-row-form .eitje-form-2-field .ant-select-selection-placeholder {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 32px;
}
.eitje-form-2-row-form .eitje-form-2-field .ant-select-selector,
.eitje-form-2-row-form .eitje-form-2-field .ant-select-selector input.ant-select-selection-search-input {
  height: 32px;
}
.eitje-form-2-row-form .eitje-form-2-field .ant-select-selection-placeholder,
.eitje-form-2-row-form .eitje-form-2-field input::placeholder {
  padding: 0 8px;
}
.eitje-form-2-list-form .eitje-form-2-field {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.eitje-form-2-list-form button {
  cursor: pointer;
  min-width: unset;
}
.eitje-form-2-list-form button * {
  cursor: pointer;
}
.eitje-form-2-list-form button * {
  cursor: pointer;
}
.eitje-form-2-list-form button {
  height: 30px;
  padding: 0 12px;
}
.eitje-form-2-list-form .eitje-form-2-field {
  padding: unset;
}
.eitje-form-2-list-form .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-list-form:hover .eitje-form-2-field-clear,
.eitje-form-2-list-form:active .eitje-form-2-field-clear,
.eitje-form-2-list-form:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2-list-form p.eitje-form-2-error {
  display: none;
}
.eitje-form-2-list-form .eitje-form-2-field.eitje-form-2-field-error .ant-picker {
  border: 1px solid #ec1314;
}
.eitje-form-2-list-form .eitje-form-2-field .eitje-form-2-label,
.eitje-form-2-list-form .eitje-form-2-field .ant-select-selection-placeholder {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 32px;
}
.eitje-form-2-list-form .eitje-form-2-field .ant-select-selector,
.eitje-form-2-list-form .eitje-form-2-field .ant-select-selector input.ant-select-selection-search-input {
  height: 32px;
}
.eitje-form-2-list-form .eitje-form-2-label {
  width: 50%;
}
.eitje-form-2-list-form .eitje-form-2-field .ant-select-selection-placeholder,
.eitje-form-2-list-form .eitje-form-2-field input::placeholder {
  padding: 0 8px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container:not(.eitje-dropdown-container-multiple) textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple {
  cursor: pointer;
  display: block;
  min-height: 71px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple *:focus {
  outline: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .eitje-form-2-label {
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select {
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector {
  cursor: pointer !important;
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  margin-bottom: -14px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector .ant-select-selection-overflow {
  padding: 4px 0 14px 0;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
  align-self: flex-start;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item {
  border: 1px solid #dddddd;
  margin: 0 4px 4px 0;
  padding: 0 8px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-content {
  font-size: 14px;
  line-height: 26px;
  margin: 0 4px 0 0;
  padding: 0 2px 0 0;
  color: #000000;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove .anticon {
  line-height: 28px;
  font-size: 10px;
  color: #dddddd;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple .ant-select .ant-select-selector .ant-select-selection-placeholder {
  left: 0;
  top: 10px;
  height: 21px;
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all {
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  right: 24px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  padding: 0 16px;
  width: 130px;
  text-align: center;
  top: calc(16px + 18px + 4px);
  background-color: #ffffff;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple:not(.eitje-form-2-field-has-icon) .ant-select-selection-overflow,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-dropdown-container.eitje-dropdown-container-multiple:not(.eitje-form-2-field-has-icon) .ant-select-selection-placeholder {
  max-width: calc(100% - 130px - 4px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea textarea {
  height: 21px;
  min-height: 21px;
  resize: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea textarea::-webkit-scrollbar {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea textarea::-webkit-scrollbar {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container.eitje-input-container-textarea textarea::placeholder {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 2 * 24px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea):hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea):active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea):focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .ant-input-password {
  border-color: unset;
  border-width: unset;
  border-style: unset;
  background-color: unset;
  padding: 0;
  height: 21px;
  border: 0px solid;
  border-right-width: 0px !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) input.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) input.ant-input-affix-wrapper.ant-input-affix-wrapper-focused:hover,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-focused:hover {
  box-shadow: unset !important;
  border-right-width: 0px !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .ant-input-password .anticon-eye,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .ant-input-password .anticon-eye-invisible {
  top: -4px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .char-counter.char-counter-warning {
  color: #ffa405;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-container:not(.eitje-input-container-textarea) .char-counter.char-counter-danger {
  color: #ec1314;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small {
  min-height: 71px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small:focus .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small:hover .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small:active .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-small .eitje-button-select {
  position: absolute;
  left: 24px;
  top: 38px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large {
  height: 71px;
  text-align: center;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .eitje-button-select-large {
  position: absolute;
  right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-button-select-container.eitje-button-select-container-large .eitje-button-select-no-label.eitje-button-select-large {
  position: relative;
  right: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container:focus .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container:hover .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container:active .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .eitje-hour-minute-picker-input {
  background-color: unset;
  border: unset;
  justify-content: flex-start;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-hour-minute-picker-container .eitje-hour-minute-picker-input input {
  color: #000000;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-time-picker-container textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container {
  display: flex;
  align-items: center;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container:focus .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container:hover .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container:active .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-form-2-extra-label {
  position: absolute;
  top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-simple-input-number {
  border: 1px solid #dddddd;
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  border-radius: 4px;
  position: relative;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-simple-input-number:hover {
  border: 1px solid #dddddd;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-simple-input-number input {
  font-size: 12px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-simple-input-number .left-arrow {
  position: absolute;
  left: 4px;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-simple-input-number .right-arrow {
  position: absolute;
  right: 4px;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-simple-input-number {
  position: absolute;
  right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .eitje-simple-input-number input {
  width: 50px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-input-affix-wrapper {
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  width: auto;
  position: absolute;
  right: 24px;
  z-index: 2;
  background: #ffffff;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-input-affix-wrapper .ant-input {
  font-weight: 600;
  width: 30px;
  font-size: 12px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-input-number-container .ant-input-affix-wrapper .ant-input-suffix {
  font-weight: 600;
  font-size: 12px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container {
  display: flex;
  align-items: center;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container:focus .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container:hover .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container:active .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-form-2-extra-label {
  position: absolute;
  top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-simple-input-number {
  border: 1px solid #dddddd;
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  border-radius: 4px;
  position: relative;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-simple-input-number:hover {
  border: 1px solid #dddddd;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-simple-input-number input {
  font-size: 12px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-simple-input-number .left-arrow {
  position: absolute;
  left: 4px;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-simple-input-number .right-arrow {
  position: absolute;
  right: 4px;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-simple-input-number {
  position: absolute;
  right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .eitje-simple-input-number input {
  width: 50px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-input-affix-wrapper {
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
  width: auto;
  position: absolute;
  right: 24px;
  z-index: 2;
  background: #ffffff;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-input-affix-wrapper .ant-input {
  font-weight: 600;
  width: 30px;
  font-size: 12px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-simple-input-number-container .ant-input-affix-wrapper .ant-input-suffix {
  font-weight: 600;
  font-size: 12px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  padding-left: 24px;
  padding-right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker *,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea * {
  cursor: pointer !important;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-picker-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-select-selector,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-select-selector {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: calc(16px + 18px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-picker-input .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-select-selector .ant-select-selection-item,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-picker-input .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-select-selector .ant-select-selection-placeholder,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-select-selector .ant-select-selection-search-input {
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-picker-input .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker .ant-select-selector .ant-select-selection-search-input,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea .ant-select-selector .ant-select-selection-search-input {
  height: 21px;
  margin-left: calc(24px - 10px);
  margin-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .eitje-form-2-label {
  margin-bottom: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-select .ant-select-selector {
  height: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container .ant-picker-focused {
  box-shadow: unset;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-date-picker-container textarea {
  padding-top: calc(16px + 18px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container {
  min-height: 72px;
  display: inline-flex;
  align-items: center;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container:focus .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container:hover .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container:active .eitje-form-2-field-clear,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container:focus .eitje-form-2-field-clear {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container .eitje-form-2-label {
  width: calc(100% - 44px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-switch-container button {
  position: absolute;
  right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-field-upload_xlsx .eitje-form-2-field-title-upload-container {
  border: 1px solid #dddddd;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 24px;
  text-align: center;
  background-color: #fbfcfd;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-field-upload_xlsx .eitje-form-2-field-title-upload-container * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-field-upload_xlsx .eitje-form-2-field-title-upload-container * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-field-upload_xlsx .eitje-form-2-field-title-upload-container .eitje-form-2-field-title-upload-icon svg {
  font-size: 40px;
  color: #0096ff;
  margin: 0 0 12px 0;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-field-upload_xlsx .link-button-container {
  float: right;
  margin: 4px 0 0 0;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input {
  cursor: pointer;
  display: block;
  min-height: 71px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input *:focus {
  outline: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .eitje-form-2-label {
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select {
  width: 100%;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector {
  cursor: pointer !important;
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  margin-bottom: -14px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector .ant-select-selection-overflow {
  padding: 4px 0 14px 0;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
  align-self: flex-start;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item {
  border: 1px solid #dddddd;
  margin: 0 4px 4px 0;
  padding: 0 8px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-content {
  font-size: 14px;
  line-height: 26px;
  margin: 0 4px 0 0;
  padding: 0 2px 0 0;
  color: #000000;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove .anticon {
  line-height: 28px;
  font-size: 10px;
  color: #dddddd;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input .ant-select .ant-select-selector .ant-select-selection-placeholder {
  left: 0;
  top: 10px;
  height: 21px;
  line-height: 21px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all {
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  right: 24px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  padding: 0 16px;
  width: 130px;
  text-align: center;
  top: calc(16px + 18px + 4px);
  background-color: #ffffff;
  z-index: 10;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input:not(.eitje-form-2-field-has-icon) .eitje-form-2-select-all * {
  cursor: pointer;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input:not(.eitje-form-2-field-has-icon) .ant-select-selection-overflow,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-multi-text-input:not(.eitje-form-2-field-has-icon) .ant-select-selection-placeholder {
  max-width: calc(100% - 130px - 4px);
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-modal-link-container {
  position: relative;
  min-height: 72px;
  display: inline-flex;
  align-items: center;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-modal-link-container .link {
  position: absolute;
  top: 20px;
  right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-modal-link-container button {
  position: absolute;
  right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-tabs-container {
  height: 71px;
  text-align: center;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-tabs-container .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-tabs-container:hover .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-tabs-container:active .eitje-form-2-field-icon,
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-tabs-container:focus .eitje-form-2-field-icon {
  display: none;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-tabs-container .eitje-tabs {
  position: absolute;
  right: 24px;
}
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-tabs-container .eitje-form-2-tabs-no-label {
  position: relative;
  right: unset;
}

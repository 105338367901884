@import (reference) 'styles/app/app.less';

.planning-trade-status-trigger {
	&:not(.no-border) {
		.border;
	}
	.border-radius;
	background-color: @white;
	position: relative;
	margin: 0;
	height: 16px;
	padding: 0 2px;

	.trade-icon {
		@size: 8px;

		width: @size;
		height: @size;
	}

	.eitje-circle {
		position: absolute;
		left: -5px;
		top: -3px;
	}
}

@import (reference) 'styles/app/app.less';

.revenue-footer {
	.border(top, @light-border);
	margin: 24px 0 0 0;
	background-color: @white;

	.revenue-footer-header {
		.border(bottom, @light-border);
		.border(right, @light-border);
	}

	.revenue-footer-empty {
		.border(bottom, @light-border);
		text-align: center;
		padding: 12px 0;
	}
}

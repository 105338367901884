.checkbox-outer {
	.border;
	.border-radius(round);
	.transistion-quick;
	border-color: @medium-border;
	width: 12px;
	min-width: 12px;
	min-height: 12px;
	height: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	&.selected {
		border-color: @medium-blue;
	}

	.checkbox-inner {
		.border-radius(round);
		.transistion-quick;
		width: 8px;
		background-color: transparent;
		height: 8px;
	}
}

.selected {
	.checkbox-outer {
		border-color: @medium-blue;
	}
	.checkbox-inner {
		background-color: @medium-blue;
	}
}

.dangerous {
	.selected {
		.checkbox-outer {
			border-color: @medium-red;
		}
		.checkbox-inner {
			background-color: @medium-red;
		}
	}
	.easy-remove {
		background-color: @light-red;
	}
}

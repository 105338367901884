@import (reference) 'styles/app/app.less';
@import (reference) 'index.less';

.shift-modal .shift-form-footer {
	.eitje-modal-footer(@modal-footer-height);
}

.audits-popout {
	max-height: 400px;
	overflow: auto;

	.list-item {
		.border(top);
		position: relative;

		.audit {
			.pointer-cursor;
			padding: 12px 16px;
			min-width: 340px;

			.audit-edit-count {
				color: @dark-grey;
				margin: 0 0 4px 0;
			}

			.expander {
				position: absolute;
				top: 20px;
				right: 24px;
				height: 18px;

				&.expanded {
					transform: rotate(180deg);
				}
			}

			.audit-details {
				display: block;

				.audit-change-container {
					display: block;

					.audit-details-change {
						.border;
						.big-shadow;
						.border-radius;
						display: inline-block;
						line-height: 20px;
						padding: 0 12px;
						margin: 4px 0 0 0;
						color: @dark-grey;
					}
				}
			}
		}
	}
}

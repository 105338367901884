@import (reference) 'styles/app/app.less';

//////////////////////////////////////////////////////////
// Initial, default styling
//////////////////////////////////////////////////////////

.eitje-simple-input-number {
	.row;
	width: fit-content;

	img {
		.pointer-cursor;
	}

	img:first-child {
		transform: rotate(180deg);
	}

	.ant-input-number {
		width: fit-content;
		height: fit-content;
	}

	.ant-input-number-handler-wrap {
		display: none;
	}

	.ant-input-number-input-wrap {
		width: fit-content;
		height: fit-content;
		padding: 0px;

		input {
			.grey-text;
			.bold;
			text-align: center;
			height: 20px;
			width: 40px;
			padding: 0px;
		}
	}
}

@import (reference) 'styles/app/app.less';

@horizontal-padding: 12px;

.revenue-info {
	.children-except-last-border-bottom;
	width: 250px;

	& > * {
		width: 100%;
		padding: 4px 0px;
	}
}

@import (reference) 'styles/app/app.less';

.balance-bill-modal {
	// Set user select to none on all children, since you select text easily by clicking on and expanding rows,
	// which looks weird and distracting.
	p,
	div {
		user-select: none;
	}

	.bill-row {
		&-dotted-line {
			.border(@side: bottom, @color: @black, @style: dashed);
			position: relative;
			bottom: 4px;
		}

		&-right-side {
			min-width: min-content;

			.eitje-text {
				white-space: nowrap;
			}
		}
	}

	.bill-divider {
		.border(@side: top, @color: @black);
	}
}

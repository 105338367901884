@import (reference) 'styles/app/app.less';

.color-box {
	.border-radius;
	display: inline-block;
	height: 20px;
	width: 10px;
	position: relative;
	top: 2px;

	&.margin-right {
		margin: 0 6px 0 0;
	}
}

@import (reference) 'styles/app/app.less';

.add-button {
	min-width: 100px;

	.add-icon {
		.square-size(8px);
		opacity: 0.4;
		margin: 0 8px 2px 0;
	}
}

@import (reference) 'styles/app/app.less';

.invite-users-page {
	.modal-banner h5 span {
		color: @dark-grey;

		strong {
			.bold;
		}
	}

	.list-picker {
		margin: 0 8px;
	}
}

.eitje-form-2-modal-link {
	position: relative;
	min-height: 72px;
	display: inline-flex;
	align-items: center;

	.link {
		position: absolute;
		top: 20px;
		right: 24px;
	}

	button {
		position: absolute;
		right: @field-horizontal-padding;
	}
}

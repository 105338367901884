@import (reference) 'styles/app/app.less';

///////////////////////////////////
// General styling of the modal
///////////////////////////////////

#billing-setup-page {
	.row;
	height: 490px;
}

///////////////////////////////////
// Left column styling of the modal
///////////////////////////////////

#billing-setup-page .billing-setup-modal-left-side {
	.border(right);
	display: inline-block;
	width: 50%;
	height: 100%;

	.billing-setup-modal-title-container {
		.border(bottom);
		padding: 60px 40px 24px 40px;
	}

	.eitje-form {
		////////////////////////////////////////////
		// First input row: company & contact person
		////////////////////////////////////////////

		.eitje-field-company_name,
		.eitje-field-person_name {
			width: 50%;
			display: inline-block;
			height: 68px;

			input {
				border: none;
				padding: 0px;
			}

			&:first-child {
				.border(right);
			}
		}

		////////////////////////////////////////////
		// Second input row: location
		////////////////////////////////////////////

		.eitje-field-location {
			.location-label {
				padding: 16px 24px 0 24px;
			}

			.eitje-input-container {
				padding: 6px 24px 16px 24px;
			}

			.google-places {
				width: calc(100% - 48px);
				margin: 0 0 0 24px;
			}
		}

		////////////////////////////////////////////
		// Third input row: emails
		////////////////////////////////////////////

		.eitje-field-emails {
			border-bottom: none;

			.eitje-input-container {
				padding: 0;
				border-bottom: unset;

				.eitje-input {
					height: 40px;
					padding-right: 4px;
				}

				input.ant-input {
					background-color: transparent;

					&::placeholder {
						color: @dark-grey !important;
					}
				}

				button.ant-btn {
					height: 32px;
					padding: 0;
					min-width: unset;
					padding: 0 16px;

					span {
						font-size: 12px;
					}
				}
			}
		}
	}
}

///////////////////////////////////
// Right column of the modal
///////////////////////////////////

#billing-setup-page .billing-setup-modal-right-side {
	.column;
	justify-content: center;
	width: 50%;
	height: 100%;
	vertical-align: top;

	.shared-card-styling {
		.border;
		.big-shadow;
		.border-radius(large);
		width: 320px;
	}

	.billing-info-card {
		.column;
		.shared-card-styling;
		align-items: flex-start;
		height: 150px;
		padding: 24px 12px 12px 24px;
		justify-content: space-between;

		.recipients-count-card {
			.border;
			.border-radius;
			display: inline-flex;
			gap: 8px;
			line-height: 40px;
			padding: 8px 16px;
			align-self: flex-end;

			.mail-icon {
				height: 12px;
				margin: 0 12px 0 0;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

////////////////////////
// Helpers to move into / update in lib
////////////////////////
.hide-scrollbar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
}

////////////////////////
// Field styling
////////////////////////

.eitje-form-3-field {
	min-width: 100%;
	max-width: 100%;
	background: @white;
	overflow-x: hidden;

	.form-field-content-left {
		align-self: flex-start;
		flex-grow: 1;
		flex-shrink: 1;
		overflow-x: hidden;
	}

	.form-field-content-right {
		align-self: center;
		flex-shrink: 1;
	}
}

////////////////////////
// Icon styling
////////////////////////

.eitje-form-3-field:not(:hover) .cross-icon {
	// Usually setting display none would suffice, but in this case that would cause the bug that on a phone you would
	// only focus an input after the second click instead of the first.
	width: 0 !important;
	height: 0 !important;
	visibility: hidden;
}

////////////////////////
// Cursor styling
////////////////////////

.eitje-form-3-field {
	.pointer-cursor;

	&.disabled,
	&.read-only {
		.not-allowed-cursor;
	}
}

////////////////////////
// Read only logic
////////////////////////

.eitje-form-3-field.read-only .eitje-time-picker-container {
	pointer-events: none;
}

////////////////////////
// Rape Ant & HTML styling
////////////////////////

.unset(@prop) {
	@{prop}: unset !important;
}

.eitje-form-3-field {
	input,
	textarea,
	.ant-picker,
	.ant-picker-input,
	.ant-input-password {
		.unset(padding);
		.unset(border);
		.unset(background-color);
		.unset(box-shadow);
		.unset(transition);

		.ant-picker-suffix,
		.ant-picker-clear,
		.ant-input-suffix {
			display: none !important;
		}

		@media (max-width: 600px) {
			// If the font-size is below 16px (e.g. the default 14px) iPhones automatically zoom into the field and hide page content around the edges
			// of your screen to increase accessibility. Prevent zooming behaviour by setting the input font-sizes to the required threshold.
			font-size: 16px !important;
		}
	}

	textarea {
		.hide-scrollbar;
	}

	input {
		.unset(height);
	}

	.ant-checkbox-inner {
		.unset(transition);
	}
}

////////////////////////
// Border styling
////////////////////////

.eitje-form-3-grid {
	.border(top);
	.border(bottom);

	.eitje-form-3-row {
		.border(bottom);

		.eitje-form-3-field {
			.border(right);

			&:last-child {
				.unset-border(right);
			}
		}
	}
}

////////////////////////
// Color styling
////////////////////////

.paint(@background, @content) {
	background-color: @background;
	.border(@color: @content, @type: outline);
	outline-offset: -1px;

	input::placeholder,
	textarea::placeholder {
		color: @content !important;
	}

	// exclude delete button and exclude all children of the delete button as well
	:not(.delete-row-button):not(.delete-row-button *) {
		color: @content !important;
		border-color: @content !important;
		fill: @content !important;

		.info-popout-trigger * {
			fill: @white !important;
		}
	}
}

.eitje-form-3-field {
	&.disabled {
		.paint(@light-grey, @dark-grey);
	}

	&:hover:not(.disabled):not(.read-only) {
		.paint(@light-blue, @medium-blue);
	}

	&.error {
		.paint(@light-red, @medium-red);

		&:hover:not(.disabled) {
			.paint(@light-red, @dark-red);
		}
	}
}

.eitje-form-3-grid .eitje-form-3-field.disabled {
	// In a grid form, the dark grey outlines are visually a bit too heavy, thus unset them.
	// We still want to show red outlines for errors and blue for active though.
	outline: unset;
}

////////////////////////
// Grid only styling
////////////////////////

.eitje-form-3-grid .eitje-form-3-row {
	@columns: var(--columns);
	display: grid;
	grid-template-columns: @columns;
	gap: 0px;
	width: 100%;

	.eitje-form-3-field {
		min-height: 72px;
	}
}

////////////////////////
// Box only styling
////////////////////////

.eitje-form-3-box {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 400px;
	align-items: center;

	.eitje-form-3-field {
		min-height: 60px;
		.border;
		.border-radius;
	}
}

////////////////////////
// Modal styling
////////////////////////

.eitje-form-3-modal,
.side-menu-modal {
	.eitje-form-3 {
		border-top: unset !important; // assume that the top of the form always touches the modal header
		margin-bottom: -1px; // don't assume the same for the bottom: in fixed height modals, the form may float above the footer, thus solve in a different way
	}
}
@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .channel-list {
        >* {
            flex-shrink: unset !important;
        }

        .str-chat__channel-list {
            width: 100%;
            border-right: none;
        }

        .str-chat__load-more-button {
            padding: 0 12px;

            .str-chat__load-more-button__button {
                border-radius: 4px;
                height: 40px;
                font-weight: 600;
                width: 100%;
                background-color: @light-blue;
                color: @medium-blue;
                border: 1px solid @medium-blue;

                &:hover {
                    background-color: @medium-blue;
                    color: @white;
                    border: 1px solid @dark-blue;
                }
            }
        }
    }
}
////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are against our styling principle of NOT defining classes
// with only a single property being set, because mixing in one-line classes
// is less clear than defining the property itself. The reason they are still
// here is to support legacy, so DO NOT use them yourself! Eventually we want
// to delete them from the project.
////////////////////////////////////////////////////

.flex-grow-one {
	flex: 1;
}

.full-height {
	height: 100%;
}

.full-width {
	width: 100%;
}

.align-items-center {
	align-items: center;
}

.justify-content-flex-end {
	justify-content: flex-end;
}

.justify-content-center {
	justify-content: center;
}

.align-self-flex-start {
	align-self: flex-start;
}

@import (reference) 'styles/app/app.less';

#integrations-page .page-side-menu {
	@page-content-width: 800px;
	@gutter: 24px;
	position: fixed;
	left: 50%;
	transform: translateX(calc(-100% - (@page-content-width / 2) - @gutter));
	margin: 60px 0 0 0;
}

#integrations-page .integrations-page-content {
	padding: 30px 0 30px 0;
}

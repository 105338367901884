@import (reference) 'styles/app/app.less';

.ant-checkbox-wrapper {
	span span {
		.pointer-cursor;
		font-size: 400;
		padding-left: 4px;
	}

	.ant-checkbox-inner {
		border-color: @medium-border;
	}

	&.ant-checkbox-wrapper-checked {
		span span {
			font-weight: 600 !important;
		}
	}
}

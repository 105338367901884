@import (reference) 'styles/app/app.less';

// TO DO: remove all those fckin 100%'s... Chris made a consideration

.week-table-column {
	> a {
		// the button wrapper needs to be 100% width before the child button can become 100% width
		width: 100%;
	}

	&.has-zero-children > :first-child {
		// When the button is the only thing present in the cell, make it dynamically grow to the full size of the cell, so
		// that it is extremely easy for the user to click it.

		.eitje-button {
			min-height: 100%;
		}
	}

	&-can-drop {
		outline: 1px dashed @medium-blue !important;
		background-color: @light-blue !important;
		width: calc(100% - 1px) !important; // Decrease the width by 1px to make the right side's outline visible
	}

	&-cannot-drop {
		background-color: @light-grey;

		// We could display a grey CSS cross on disabled hover...
		// background: linear-gradient(45deg, transparent 49.5%, @midtone-grey 49.5%, @midtone-grey 50.5%, transparent 50.5%),
		// linear-gradient(-45deg, transparent 49.5%, @midtone-grey 49.5%, @midtone-grey, transparent 50.5%);
	}

	&-hover-children > a {
		// If a hover item is passed, its wrapper `&-hover-children` should grow before the hover items may grow to full size.
		width: 100%;
		height: 100%;

		.popout-card,
		.popout-card .popout-card-trigger {
			// If the button is disabled, the wrapper popout-card divs should also grow.
			height: 100%;
			width: 100%;
		}
	}
}

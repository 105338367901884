@import (reference) 'styles/app/app.less';

////////////////////////////////////
// Base styling
////////////////////////////////////

.team-panel .flat-list {
	padding: 0 12px;
}

////////////////////////////////////
// User row header styling
////////////////////////////////////

.team-panel .flat-list .user-row-header {
	.row;

	.pointer-cursor;
	justify-content: space-between;
	padding: 12px 12px 12px 16px;
	margin: 4px 0 0 0;

	.user-row-header-left {
		display: inline-flex;
		align-items: center;
		gap: 8px;

		.user-row-title {
			display: inline-block;

			h4 {
				font-size: 12px;
			}
		}
	}

	.user-row-header-right {
		display: inline-flex;
		gap: 12px;

		h5 {
			line-height: 24px;
		}

		.dropdown-button {
			.pointer-cursor;
			.border;
			.big-shadow;
			.border-radius;
			display: inline-block;
			background-color: @white;

			.dropdown-icon {
				height: 10px;
				padding: 0 12px;
			}
		}
	}
}

////////////////////////////////////
// State styling
////////////////////////////////////

.team-panel .flat-list .list-item-active .dropdown-icon {
	transform: rotate(180deg);
}

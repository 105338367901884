@import (reference) 'styles/app/app.less';

////////////////////////////////////
// Base styling
////////////////////////////////////

@header: 84px;
@body: 570px;
@footer: 72px;
@modal: calc(@header + @body + @footer);

.revenue-modal {
	.eitje-modal(@modal);

	.ant-modal-title {
		font-size: 18px;
	}

	.ant-modal-header {
		.eitje-modal-header(@header);
	}

	.ant-modal-body {
		.eitje-modal-body(@body);
		// Remove very aggressive :not selector styling from our former, highly
		// intelligent stylist.
		max-height: unset !important;
		height: unset;
		// overflow-y: hidden;
	}

	.revenue-modal-footer {
		.eitje-modal-footer(@footer);
		justify-content: flex-end;

		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
}

////////////////////////////////////
// Toggle in header styling
////////////////////////////////////

.revenue-modal .tabs-container {
	.row;
	justify-content: center;
	width: 100%;
	position: absolute;
	top: 16px;
	z-index: 10;
}

////////////////////////////////////
// Form styling: General
////////////////////////////////////

@days-column-width: 150px;
@team-column-width: 112px;
@total-column-width: 112px;
@sync-column-width: 112px;
@cell-height: 60px;
@header-height: 46px;
@footer-height: 74px;

.revenue-modal .revenue-form {
	.border(top);
	.row;
	overflow-x: scroll;
	position: relative;
	align-items: stretch;
	height: calc((7 * @cell-height) + @header-height + @footer-height);
}

////////////////////////////////////
// Form styling: Shared
////////////////////////////////////

.revenue-modal .revenue-form {
	.revenue-modal-column {
		.revenue-modal-header {
			height: @header-height;
			line-height: @header-height;
			padding: 0 0 0 24px;
			font-size: 12px;
		}

		.revenue-modal-cell {
			.border(top);
			height: @cell-height;
			text-align: center;
		}
	}
}

////////////////////////////////////
// Form styling: First column (dates)
////////////////////////////////////
.revenue-modal .revenue-form .revenue-modal-days {
	.border(right);
	width: @days-column-width;

	.revenue-modal-day {
		line-height: @cell-height;
		text-align: left;
		padding: 0 0 0 24px;
	}
}

////////////////////////////////////
// Form styling: Team columns
////////////////////////////////////

.revenue-modal .revenue-form .revenue-modal-groups {
	width: calc(100% - @days-column-width - @total-column-width - @sync-column-width);
	overflow-x: scroll;
	display: inline-flex;

	.revenue-modal-group {
		display: inline-block;
		width: @team-column-width;
		min-width: @team-column-width;

		.revenue-modal-cell {
			.eitje-input-number {
				.big-shadow;
				height: 32px;
				width: 92px;
				margin: 12px 0 0 0;

				&.ant-input-affix-wrapper-disabled {
					span,
					input {
						color: @dark-grey;
					}
				}
			}
		}
	}

	.empty-space-filler {
		flex-grow: 1;

		.empty-space {
			.border(top);
			height: @cell-height;

			&:first-child {
				.unset-border(top);
				height: @header-height;
			}
		}
	}
}

.revenue-modal .revenue-form.without-sync-row .revenue-modal-groups {
	// When no sync column is present due to a lacking POS integration.
	width: calc(100% - @days-column-width - @total-column-width);

	.revenue-modal-group-total {
		right: 0 !important;
	}
}

.revenue-forecast .revenue-form .revenue-modal-groups {
	// This modal tab lacks the "sync" column, adapt.
	width: calc(100% - @days-column-width - @total-column-width - @sync-column-width);

	.revenue-modal-group-total {
		right: @total-column-width !important;
	}
}

////////////////////////////////////
// Form styling: Second last column (Total)
////////////////////////////////////

.revenue-modal .revenue-form .revenue-modal-groups {
	.revenue-modal-group-total {
		.border(left);
		position: absolute;
		top: 0;
		height: 100%;
		right: @sync-column-width;
	}
}

.revenue-modal .revenue-form.without-sync-row .revenue-modal-groups {
	.revenue-modal-group-total {
		// When no sync column is present due to a lacking POS integration.
		right: 0;
	}
}

.revenue-forecast .revenue-modal-column.revenue-modal-group.revenue-modal-group-total {
	// This modal tab lacks the "sync" column, adapt.
	right: 0;
}

////////////////////////////////////
// Form styling: Last column (Sync)
////////////////////////////////////
.revenue-modal .revenue-form .revenue-modal-sync {
	.border(left);
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: @sync-column-width;
	align-self: stretch;

	.revenue-modal-cell {
		.center-wrapper;

		.ant-btn {
			.center;
			.big-shadow;
			min-width: unset;
			height: 32px;
			padding: 0 20px;
		}

		.sync-button {
			.sync-icon {
				.button-icon;
			}

			span {
				font-size: 12px;
			}
		}
	}
}

.revenue-modal-cell input {
	// Bugfix!! Due to the line-height being bigger than the input, problems were
	// were caused in Safari only (and only when loosing focus on the input)...
	// Add line-height: 1; to prevent the text from jumping
	line-height: 1;
}

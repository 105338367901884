@import (reference) 'styles/app/app.less';

////////////////////////////////////
// Base layout of page
////////////////////////////////////

@pdf-modal-width: 900px;
@pdf-modal-height: 780px;

.fullscreen-pdf-page {
	width: @pdf-modal-width;
	height: @pdf-modal-height;
	margin-left: calc((100% - @pdf-modal-width) / 2);

	.ant-modal-body {
		height: unset !important;
	}

	.modal-fullscreen {
		padding-bottom: unset;
	}

	canvas.react-pdf__Page__canvas {
		width: @pdf-modal-width !important;
		height: unset !important;
	}

	div.react-pdf__Document {
		width: @pdf-modal-width !important;
		max-width: @pdf-modal-width !important;
	}
}

.fullscreen-pdf-page .pdf-controls-container {
	width: 220px !important;
	position: fixed;
	top: calc(100vh - 100px);
	height: 48px;
	left: calc((100% - 220px) / 2);
}

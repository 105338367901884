@import (reference) 'styles/app/app.less';

///////////////////////
// General footer
///////////////////////

.export-tables-footer {
	.border(top);
	border-color: @black;
	width: 100%;
	position: fixed;
	height: 64px;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	z-index: 10;
	background-color: @white;
	padding: 0 24px;
	align-items: center;
}

///////////////////////
// Left side filters
///////////////////////

.export-tables-footer .export-tables-footer-left {
	display: flex;
	gap: 4px;
}

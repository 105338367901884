@import (reference) 'styles/app/app.less';

.set-weather-location {
	position: relative;

	&-form {
		position: absolute;
		top: 0px;
		z-index: 10;

		.google-places {
			width: 250px;
		}
	}
}

.set-weather-location-form {
	margin-left: 170px;
}

@import (reference) 'styles/app/app.less';

form.eitje-form-3 {
	.eitje-hour-minute-picker-input {
		display: inline-flex;

		.eitje-hour-minute-picker-input-left {
			width: calc(var(--hour-digits) * 1ch);
		}

		.eitje-hour-minute-picker-input-right {
			width: 2.1ch; // slightly tweak width to prevent truncation of placeholder in Safari
		}

		&.hours-entered .eitje-hour-minute-picker-input-right::placeholder {
			color: @black;
		}

		&.no-hours-entered {
			.eitje-hour-minute-picker-input-left {
				width: 1ch;
			}

			.eitje-hour-minute-picker-divider {
				color: @medium-grey;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) 'index.less';

////////////////////////////////////
// Base styling
////////////////////////////////////

@team-panel-header-height: 76px;
@team-panel-footer-height: 112px;

.team-panel .team-panel-sick {
	height: calc(100vh - @team-panel-header-height - @team-panel-footer-height);
	position: relative;
	// margin-bottom: @team-panel-footer-height;

	.search-footer {
		position: fixed;
		bottom: 0;
		background-color: @white;
		z-index: 10;
		width: 504px;
	}

	.past-sick-periods {
		padding-bottom: @team-panel-footer-height;
	}
}

.team-panel-sick .current-sick-periods {
	width: calc(100% - 24px);
	margin: 0 12px 12px 12px;

	.current-sick-periods-label {
		.team-panel-sick-label-mixin;
	}

	.sick-period-group {
		.row;
		justify-content: space-between;
		align-items: flex-start;
		margin: 0 0 4px 0;

		.current-sick-period {
			.border;
			.big-shadow;
			.border-radius(large);
			width: calc((100% - 8px) / 2);
			padding: 20px 16px 12px 16px;
			position: relative;

			.eitje-avatar {
				display: inline-flex;
			}

			.current-sick-periods-user-name {
				.prevent-text-overflow;
				display: inline-block;
				margin: 0 0 0 4px;
				line-height: 28px;
				vertical-align: top;
				max-width: 124px;
			}

			.eitje-form-2 {
				margin: 4px 0 0 0;

				.eitje-form-2-row {
					margin: 0 0 4px 0;

					.ant-picker {
						.border;
						.big-shadow;
						.pointer-cursor;

						input {
							.bold;
							font-size: 11px;
							padding: 0 0 0 8px;
							width: 110px;
						}
					}

					.eitje-form-2-field-icon {
						right: 10px;
					}
				}

				.popout-card {
					.big-shadow;
					position: absolute;
					right: 18px;
					top: 12px;

					.eitje-button {
						padding: 0px 8px !important;
					}
				}

				.submit-button {
					width: 100%;
					margin: 4px 0 0 0;
				}
			}
		}
	}
}

////////////////////////////////////
// State styling
////////////////////////////////////

.current-sic-periods-empty {
	text-align: center;
	margin: 12px 0 0 0;
}

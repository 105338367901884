@import (reference) 'styles/app/app.less';

@page-width: 900px;
@page-margin: calc((100vw - @page-width) / 2);

.export-tables-show-header {
	.border(bottom);
	padding: 12px 0;
	position: relative;
	width: 100%;

	.export-tables-show-title {
		margin: 16px 0 0 @page-margin;
		display: inline-block;
		max-width: @page-width;

		& > p {
			margin: 4px 0 12px 0;
		}
	}

	.export-tables-show-header-actions {
		position: absolute;
		display: inline-flex;
		gap: 4px;
		right: 24px;
	}
}

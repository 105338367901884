@import (reference) 'styles/app/app.less';

.availability-form-wrapper {
	width: 700px;

	.availability-form-footer {
		.row-with-justify-between;
		padding: 24px;
	}
}

.av-time-field-container {
	.row-with-justify-between;
	padding: 0px 24px 12px 24px;

	.av-time-field-label {
		.row;
		.eitje-text-secondary;
	}

	.av-time-field-picker-container {
		.row-with-justify-end;
		gap: 4px;

		button {
			width: 40px;
			font-weight: 400;
			min-width: 40px;
			padding: 0px;

			.rotate {
				transform: rotate(45deg);
			}
		}
	}
}

&.first-form {
	padding-top: 12px;
}

.av-leave-user-field-container {
	.border(bottom);
	.row;
	padding: 12px 24px;
	min-height: 68px;

	.av-leave-user-field-text {
		flex: 1;
		.eitje-label {
			.eitje-text-secondary;
			flex: 1;
		}
	}

	.av-leave-user-field-user-container {
		width: 140px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		> :not(:first-child),
		> :not(:nth-child(6n + 0)) {
			margin-left: -8px;
		}
	}
}

.availability-select-filters-dropdown {
	.nested-dropdown-component-title {
		.border(bottom);
		.bold;
		padding: 0 0 0 35px;
		height: 40px;
		line-height: 40px;
	}
}

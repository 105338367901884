.eitje-time-picker-panel {
	.ant-picker-footer .ant-picker-ranges {
		display: none;
	}

	.ant-picker-panel-container {
		margin-top: -4px !important;

		.ant-picker-time-panel {
			padding-top: unset !important;
		}
	}
}

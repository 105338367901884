@import (reference) 'styles/form/settings/variables.less';

.eitje-form-2-inputs-list {
	margin: 12px 24px;

	.inputs-list-wrapper {
		display: inline-block;

		&-active input {
			.bold;
		}

		.eitje-form-2-field {
			display: block;
			padding: 0px;
		}

		.eitje-input {
			padding: 0px;
		}

		input {
			margin-top: 4px;
			color: @black;
		}
	}

	.eitje-checkbox-container {
		padding: 4px 8px;
	}

	.eitje-form-2-row {
		.border;
		border-bottom-width: 0px;
		padding: 4px 8px;
		height: 42px;

		&:first-of-type {
			border-radius: 4px 4px 0px 0px;
		}

		&:last-of-type {
			border-bottom-width: 1px;
			border-radius: 0px 0px 4px 4px;
		}
	}
}

////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason why we don't want to use classes for typography sizes,
// is that we would much rather use HTML tags like h4 and p instead –
// because they allow for more intuitive HTML / code & strongly force
// consistency on developers.
////////////////////////////////////////////////////

@font-size: {
	xxl: 20px;
	xl: 18px;
	md: 14px;
	sm: 12px;
	xs: 10px;
};

.eitje-heading(@size) {
	.eitje-text-primary;
	.bold;
	font-size: @size;
	line-height: @size + 4px;
}

// The fact that below classes are written per class quite stupidly so,
// instead of being generated in a loop, is due to the fact that dynamically
// generated classes are NOT allowed to be mixed in within other classes –
// you may only use them within HTML. This is against our philosophy of using
// as least possible CSS selectors within HTML, therefore write them out in
// a dumb yet practical way.

.font-size-xs {
	font-size: @font-size[xs];
}

.font-size-sm {
	font-size: @font-size[sm];
}

.font-size-md {
	font-size: @font-size[md];
}

.font-size-xxl {
	font-size: @font-size[xxl];
}

.eitje-heading-md {
	.eitje-heading(@font-size[md]);
}

.eitje-heading-xl {
	.eitje-heading(@font-size[xl]);
}

.eitje-heading-xxl {
	.eitje-heading(@font-size[xxl]);
}

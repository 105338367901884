@import (reference) 'styles/app/app.less';

.auth-page-layout {
	.eitje-form-3 {
		width: 100%;
	}
}

@media (max-width: 600px) {
	.auth-page-layout {
		// The AuthPageLayout inherits the PageLayout's height calculation, but mobiles misinterpret the 100vh after opening the
		// keyboard on form focus, making it impossible to scroll after opening the keyboard once + having automatically scrolled
		// down to the form field as default mobile behaviour.
		height: unset !important;

		.no-auth-header .no-auth-header-right {
			margin-left: auto;
			gap: 4px !important;

			// Hide text in buttons to make the buttons fit on mobile
			.eitje-button {
				.eitje-button-text,
				.icon-chevron-small-down-right {
					display: none;
				}
			}
		}

		.page-body {
			margin-top: 40px !important;

			.back-button {
				display: none;
			}

			.auth-page-layout-column {
				@horizontal-margin: 20px;
				width: calc(100vw - (2 * @horizontal-margin)) !important;
			}
		}
	}
}

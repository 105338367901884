@import (reference) 'styles/app/app.less';

////////////////////////////////////
// Left title column styling (column 1)
////////////////////////////////////

#planning-page .planning-availability-row .planning-availability-title {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 0 0 24px;

	.planning-availability-title-content {
		.prevent-text-overflow;

		.planning-availability-title-text {
			display: inline-block;
			line-height: 55px; // functions as a min-height
		}
	}
}

////////////////////////////////////
// Day column styling (column 2-8)
////////////////////////////////////

#planning-page .planning-availability-row .planning-day-availability {
	vertical-align: top;
	padding: 0 0 4px 0;

	.availability-list {
		padding: 0 4px 0 4px;

		.availability-list-label {
			.border;
			.border-radius;
			font-size: 12px;
			text-align: center;
			margin: 2px 0 0 0;
			line-height: 26px;
			font-weight: normal;
		}
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/index.less';

.leave-hour-min-picker {
	.border(bottom);

	.eitje-form-2-field-total_mins {
		.eitje-form-2-unfield;

		.eitje-hour-minute-picker-input {
			.border-radius(large);
			.big-shadow;
			margin: 8px 0;

			.eitje-hour-minute-picker-input-left,
			.eitje-hour-minute-picker-input-left::placeholder,
			.eitje-hour-minute-picker-input-right,
			.eitje-hour-minute-picker-input-right::placeholder,
			.eitje-hour-minute-picker-divider {
				.bold;
				font-size: 48px;
				color: @black;
			}

			@hour-digits: var(--hour-digits);
			.eitje-hour-minute-picker-input-left {
				width: calc(@hour-digits * 30px + 20px);
			}

			.eitje-hour-minute-picker-input-right {
				width: 80px;
			}
		}
	}

	.eitje-hour-minute-picker-input .eitje-hour-minute-picker-arrows {
		height: 70px;
	}
}

// This is for when a field contains two fields in a data sense, but should
// visually look like one. Together, they span a single space just like any
// other .eitje-form-2-field.

// Everything written here needs a lot of specificity to concur with the styling
// applied by the form row.
.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field {
	.hide-all-icons;
	display: flex;

	.eitje-form-2-field {
		border-right: 0px;
		padding: 0;
	}

	/////////////////////////////
	// In case an simple input number occurs
	/////////////////////////////

	.eitje-simple-input-number {
		.border;
		.big-shadow;
		.border-radius;
		position: absolute;
		right: 0;
		top: 18px;

		&:hover {
			.border;
		}

		input {
			font-size: 12px;
			height: 22px;
		}

		.left-arrow {
			position: absolute;
			left: 4px;
			z-index: 10;
		}

		.right-arrow {
			position: absolute;
			right: 4px;
			z-index: 10;
		}
	}
}

/////////////////////////////
// Style specific occurrences of different fields within the double field
/////////////////////////////

.eitje-form-2:not(.eitje-form-2-ignore-styling) .eitje-form-2-row .eitje-form-2-double-field {
	.eitje-button-select.eitje-button-select-small {
		left: 0;
		top: 22px;
	}
}

.transition {
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.transistion-quick {
	transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.transition-none {
	transition-property: none;
	transition-duration: 0s;
	transition-delay: 0s;
}

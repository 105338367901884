@import (reference) 'fields/index.less';

.eitje-form-2:not(.eitje-form-2-ignore-styling) {
	.eitje-dropdown-container:not(.eitje-dropdown-container-multiple) {
		.eitje-form-2-dropdown;
	}

	.eitje-dropdown-container.eitje-dropdown-container-multiple {
		.eitje-form-2-multi-input;
	}

	.eitje-input-container.eitje-input-container-textarea {
		.eitje-form-2-multiline-input;
	}

	.eitje-input-container:not(.eitje-input-container-textarea) {
		.eitje-form-2-text-input;
	}

	.eitje-button-select-container.eitje-button-select-container-small {
		.eitje-form-2-days-picker;
	}

	.eitje-button-select-container.eitje-button-select-container-large {
		.eitje-form-2-button-select;
	}

	.eitje-hour-minute-picker-container {
		.eitje-form-2-hour-minute-picker;
	}

	.eitje-time-picker-container {
		.eitje-form-2-time-picker;
	}

	.eitje-input-number-container {
		.eitje-form-2-number-input;
	}

	.eitje-simple-input-number-container {
		.eitje-form-2-number-input;
	}

	.eitje-date-picker-container {
		.eitje-form-2-date-picker;
	}

	.eitje-switch-container {
		.eitje-form-2-toggle;
	}

	.eitje-form-2-field-upload_xlsx {
		.eitje-form-2-file-upload;
	}

	.eitje-multi-text-input {
		.eitje-form-2-multi-input;
	}

	.eitje-modal-link-container {
		.eitje-form-2-modal-link;
	}

	.eitje-tabs-container {
		.eitje-form-2-tabs;
	}
}

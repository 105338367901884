@import (reference) 'styles/app/app.less';

.user-details-column {
	.list-picker-dropdown-container {
		width: 100%;
	}
	.user-details-column-general-info-block {
		p {
			.prevent-text-overflow;
		}
	}
}

.user-profile-environment-teams-container {
	margin-bottom: 8px;

	.easy-remove-area {
		flex-direction: row-reverse !important;
	}

	.list-picker-dropdown-container {
		min-width: 200px;
	}
}

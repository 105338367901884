@import (reference) 'styles/app/app.less';

.export-table-firm-names {
	text-transform: capitalize;
}

.table-edit .table-edit-filters {
	padding: 0 0 140px 0; // prevent content from disappearing behind footer

	.table-edit-filters-header {
		.border(bottom);
		.big-shadow;
		height: 68px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: @white;
		margin: 0 0 24px 0;

		span,
		b,
		p {
			font-size: 14px;
		}

		.column-firm-info {
			margin: 0 0 0 4px;

			&:first-letter {
				text-transform: lowercase;
			}
		}

		.footer-card-icon {
			.square-size(14px);
			margin: 0 4px;
		}
	}
}

.table-edit .filter-block {
	padding: 0 60px;

	.filter-block-title {
		.border(bottom);
		display: flex;
		padding: 32px 0 12px 0;
		justify-content: space-between;

		.filter-block-title-left {
			display: inline-flex;
			flex-direction: column;

			.subtitle-and-tour {
				display: flex;
				gap: 4px;
			}

			.entity-info {
				line-height: 32px;

				.info-popout-container {
					display: inline-flex;
				}
			}

			.footer-card-icon {
				.square-size(14px);
				margin: 0 0 0 8px;
			}
		}
	}

	.table-edit-record-filters {
		display: flex;
		gap: 12px;
		margin: 12px 0 0 0;

		.table-edit-record-filters-card {
			.border;
			.big-shadow;
			.border-radius;
			.pointer-cursor;
			min-height: 120px;
			width: calc((100% - 4 * 12px) / 5);
			background: @white;
			padding: 16px;

			&.active {
				background: @medium-blue;
				h4,
				h5 {
					color: @white;
				}
			}

			.list-picker-trigger {
				margin: 8px 0 0 0;
			}
		}
	}

	.extra-filters {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin: 16px 0 0 0;

		.remove-card {
			.border;
			.big-shadow;
			.border-radius;
			.pointer-cursor;
			white-space: nowrap;
			line-height: 40px;
			height: 40px;
			padding: 0 16px;
			background: @light-grey;
		}
	}
}

@import (reference) 'styles/app/app.less';

.export-modal-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(271px, 430px));
	column-gap: 4px;
	place-content: space-evenly;
	padding: 12px 24px 72px 24px;
}

.export-img-default {
	.border;
	.border-radius;
	width: 320px;
	max-height: 160px;
	object-fit: contain;
}

.export-preview {
	.row;
	.align-items-center;
	justify-content: space-between;
	height: 160px;
	padding: 24px 40px 0px 40px;

	.export-preview-image {
		.export-img-default;
	}

	.export-header-container {
		.column;
		align-items: flex-start;
		width: 350px;

		.export-header-title {
			.bold;
		}
		.export-header-subtitle {
			.eitje-text-secondary;
			.text-multi-line;
		}
	}

	.export-preview-link {
		.row;
		padding-top: 12px;
		gap: 4px;
	}
}

.export-tile {
	width: 100%;
	max-height: 320px;
	// padding: 12px;
	padding: 24px 12px;
	border: 1px solid transparent;
	.transition;

	&:hover {
		box-shadow: 0 5px 15px rgba(170, 170, 170, 0.25);
		.border-radius(large);
		border: 1px solid @medium-border;
		box-sizing: border-box;
	}

	img {
		width: 100%;
	}

	.export-tile-text-container {
		padding: 12px 24px 24px 12px;

		.export-tile-text-title {
			.bold;
			margin-bottom: 4px;
		}

		.export-tile-text-sub-title {
			.eitje-text-secondary;
			.text-multi-line;
		}
	}
}

// TO MOVE: BELONGS TO THE NEW CONTRACTS PAGE?

.new-contract-modal {
	.eitje-field-amount {
		.flex-field-1;
	}

	.eitje-hour-minute-picker-input input {
		color: @black;
	}

	.ant-modal-body .form-row {
		&:last-child {
			border-bottom: none; // prevent double border from occurring
		}
	}
}

.ant-modal {
	.modal-banner {
		.border(bottom);
		.row;
		background-color: @medium-background;
		padding: 24px;
		text-align: center;
	}
}

.ant-modal {
	.emoInputHolder {
		position: absolute;
		right: 14px;
		bottom: 14px;
	}
}

.modal-info-banner {
	.border-radius;
	.column;
	gap: 8px;
	padding: 12px 24px;
	margin: 12px 0 0 0;
	background-color: @light-grey;
	text-align: center;

	.modal-info-banner-text-container {
		width: 480px;
	}
}

@import (reference) 'styles/app/app.less';

.user-availability-options-button {
	.border;
	.border-radius;
	.pointer-cursor;
	.row-with-justify-center;
	.align-items-center;
	height: 34px;
	width: 100%;
	gap: 8px;

	.user-availability-options-button-text {
		.eitje-text-secondary;
		font-size: 12px;
	}
}

.user-availability-options-dropdown {
	.apply-dropdown-styles;
}

.user-day.has-checkbox .user-availability-options-button {
	// make card smaller if in edit mode with checkbox
	@checkbox-area-width: 34px;
	width: calc(100% - @checkbox-area-width);
}

@import (reference) 'styles/app/app.less';

.data-display-header-container {
	.row-with-border-and-padding(24px, 12px);
	margin-bottom: 12px;
}

.data-display-header {
	p {
		.bold;
		font-size: 10px;
		text-transform: uppercase;
	}
}

.data-display-row {
	.row-with-border-and-padding(24px, 20px);
	margin-bottom: 12px;
	cursor: pointer;

	* {
		cursor: pointer;
	}

	.data-display-cell {
		font-size: 12px;
		.grey-text;
		.prevent-text-overflow;
	}
	& > :first-child,
	& > .data-display-cell:first-of-type {
		.bold;
		font-size: 14px;
	}
}

.user-skill-row {
	.border;
	.border-radius(large);
	.big-shadow;
	background: @white;
	margin-bottom: 12px;
	cursor: pointer;
	padding: 20px 24px;

	.information-row {
		.row;
		flex-basis: wrap;
	}

	* {
		cursor: pointer;
	}

	.skill-title {
		color: @dark-grey;
	}

	.skill-title,
	.last-achieved-skill,
	.ant-progress-line {
		flex: 1 1 100%;
	}

	.data-display-cell {
		font-size: 12px;
		.grey-text;
	}
	& > :first-child {
		.bold;
	}

	.ant-progress-text {
		color: @dark-grey !important;
	}
}

.data-display-cell-main {
	.bold;
	color: @black;
	font-size: 14px;
}

.list-container-draggable {
	.data-display-row {
		cursor: grab !important;

		* {
			cursor: grab !important;
		}
	}
}

@import (reference) 'styles/app/app.less';

///////////////////////////////
// Header styling
///////////////////////////////

.activate-integration-modal {
	.ant-modal-close {
		display: none;
	}

	.activate-integration-modal-header {
		.border(bottom);
		display: flex;
		justify-content: space-between;
		padding: 24px 12px 24px 36px;
	}
}

///////////////////////////////
// Body styling
///////////////////////////////

.activate-integration-modal {
	.provider-info {
		display: flex;
		gap: 30px;
		padding: 40px;

		.provider-info-logo {
			.border;
			.border-radius;
			height: 96px;
			padding: 28px;

			.provider-logo {
				height: 38px;
			}
		}

		.provider-info-text {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			overflow: hidden;

			h3,
			p {
				.prevent-text-overflow;
			}
		}
	}

	// Error message styling

	.eitje-error {
		height: auto;
	}

	.eitje-error,
	.eitje-error .eitje-error-text {
		text-overflow: unset;
		white-space: unset;
		overflow: visible;
		line-height: 1.5;
		padding: 4px;
	}
}

///////////////////////////////
// Form styling
///////////////////////////////

.activate-mplus-form,
.activate-twelve-form,
.activate-untill-form,
.activate-staffable-form {
	.eitje-form-2-row {
		// always show border on row, since it is second child in case of an error, and first child in case
		// of no error; thus the automatically skipping borders on the first child doesn't work.
		border-top: 1px solid @medium-border !important;
	}
}

@import (reference) 'styles/app/app.less';

.trigger-wrapper {
	.pointer-cursor;
	.border;
	.border-radius;
	.small-shadow;
	display: inline-flex;
	margin: 0 8px;
	flex-direction: row;
	align-items: center;

	// The trigger text

	h5.eitje-default-trigger {
		.bold;
		.text-capitalize;
		color: @black;
		margin: 0 8px 0 12px;
		line-height: 30px;
		display: inline-block;
	}

	// Special error display for trigger wrappers: instead of displaying the regular
	// message "this field is required", we only show a red border and make the text
	// that is already present red.
	&.error {
		.border;
		border-color: @medium-red;

		h5 {
			color: @medium-red;
		}
	}

	// These are all different 'variants' of our inputs, because inputs inside a sentence picker don't need their own triggers
	// we should move this to a class once we know all variants, there even might be only one!
	.ant-picker {
		border: none;
		padding: 0px;
		width: 0px;
		height: 0px;
		line-height: 0;
		visibility: hidden;
		display: block;
	}
}

@import (reference) 'mixins/stretch';

.eitje-form-2-hour-minute-picker {
	.strech-input-to-full-size;
	.hide-all-icons;

	.eitje-hour-minute-picker-input {
		background-color: unset;
		border: unset;
		justify-content: flex-start;

		input {
			color: @black;
		}
	}
}

.progression-block {
	.box-with-border-and-padding(24px, 24px);
	.border-radius(large);
	width: 100%;

	.ant-progress-text {
		color: #828282;
	}

	.progression-block-percent-container {
		.row;
		padding-top: 24px;
		gap: 2px;

		.progression-block-number {
			.eitje-heading(40px);
		}
		.progression-block-percent {
			.align-self-flex-start;
			.grey-text;
		}
	}
}

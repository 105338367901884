.center-wrapper {
	position: relative;

	.center {
		margin: 0;
		position: absolute;
		top: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

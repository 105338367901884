@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .str-chat__quoted-message-preview {
        --str-chat__quoted-message-bubble-background-color: var(--str-chat__own-message-bubble-background-color);

        &.mine {
            --str-chat__quoted-message-bubble-background-color: var(--str-chat__message-bubble-background-color);
        }

        width: stretch;

        .str-chat__quoted-message-bubble {
            max-width: unset;
            border: 1px solid @medium-border;
        }
    }

    .str-chat__message-input {
        .str-chat__quoted-message-preview {
            --str-chat__quoted-message-inside-message-input-max-height: 100px;
            margin: 8px;

            * {
                cursor: default !important;
            }

            .str-chat__quoted-message-bubble {
                overflow: auto;
                background: @light-grey;
                padding-right: 0px;
                pointer-events: unset;
                cursor: default !important;

                .str-chat__quoted-message-text {
                    min-height: unset;
                }
            }
        }
    }
}
@import (reference) 'mixins/stretch';
@import (reference) 'styles/app/app.less';

@height: 40px;
@padding: 20px;

.eitje-form-2-dropdown-as-button {
	.strech-input-to-full-size;
	.border;
	.border-radius;
	.big-shadow;
	.pointer-cursor;

	height: @height;
	padding: unset;
	min-height: unset;
	display: inline-flex;
	flex: unset;

	.ant-select {
		position: relative;
		padding: unset;
		display: flex; // ?
		height: unset; // >
		height: @height;

		.ant-select-selector {
			position: unset;
			padding: unset;
			height: unset; // >

			.ant-select-selection-item {
				.bold;
				padding: 0 calc(@padding + 10px) 0 @padding;
				line-height: @height;
			}
		}
	}

	.eitje-form-2-field-icon {
		right: 12px;
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) 'mixins/stretch';

@eitje-simple-input-number-width: 50px;

.eitje-form-2-number-input {
	.strech-input-to-full-size;
	.hide-all-icons;
	display: flex;
	align-items: center;

	.eitje-form-2-extra-label {
		position: absolute;
		top: calc(@field-vertical-padding + @field-label-height);
	}

	/////////////////////////////
	// General styling, duplicated since mixing in doesn't work
	/////////////////////////////

	.eitje-simple-input-number {
		.border;
		.big-shadow;
		.border-radius;
		position: relative;

		&:hover {
			.border;
		}

		input {
			font-size: 12px;
		}

		.left-arrow {
			position: absolute;
			left: 4px;
			z-index: 10;
		}

		.right-arrow {
			position: absolute;
			right: 4px;
			z-index: 10;
		}
	}

	/////////////////////////////
	// Layout styling specific to forms
	/////////////////////////////

	// Simple number input
	// User for example in the revenue settings modal

	.eitje-simple-input-number {
		position: absolute;
		right: @field-horizontal-padding;

		input {
			width: @eitje-simple-input-number-width;
		}
	}

	// Ant inout affix
	// User for example time registration settings modal

	.ant-input-affix-wrapper {
		.big-shadow;
		width: auto;
		position: absolute;
		right: 24px;
		z-index: 2;
		background: @white;

		.ant-input {
			.bold;
			width: 30px;
			font-size: 12px;
		}

		.ant-input-suffix {
			.bold;
			font-size: 12px;
		}
	}
}

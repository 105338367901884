@import (reference) 'styles/app/app.less';

.registration-errors-row {
	.row;
	.hover-effect;
	height: 50px;
	padding: 0 12px;
	gap: 8px;

	.registration-errors-user-info {
		width: 120px;

		.registration-errors-user-name {
			.bold;
			.prevent-text-overflow;
			color: @black;
		}

		.registration-errors-shift-date {
			color: @dark-grey;
		}
	}

	.reg-status-card {
		max-width: 100px;
	}

	.registration-errors-error {
		color: @medium-red;
		text-align: right;
		margin-left: auto;
		width: 200px;
	}
}

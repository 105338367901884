@import (reference) 'styles/app/app.less';

.work-week-row {
	.border(bottom);
	padding: 12px 24px;
	min-height: 73px;
}

.contracts {
	.modal-form-field-area {
		.eitje-field-hours {
			.flex-field-1;
		}

		.eitje-field-amount {
			.flex-field-1;
		}
	}
}

.contracts.modal-wrapper {
	.file-item-text-container {
		width: calc(100% - 160px);

		p {
			max-width: calc(100% - 20px);
		}
	}
}

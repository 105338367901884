@import (reference) 'styles/app/app.less';

.user-availability-table-button {
	.border;
	.border-radius(large);
	.small-shadow;
	.column;
	padding: 12px;
	align-items: center;
	width: calc(100% - 48px);
	padding: 12px;
	margin: 24px;
}

@import (reference) 'styles/app/app.less';

//////////////////////////
// Period variant
//////////////////////////

.export-table-modal {
	.display-period {
		.border(top);
		height: 82px;
		display: flex;
		padding: 0 24px;
		flex-direction: column;
		justify-content: center;
	}
}

@import (reference) 'styles/app/app.less';

@view-period-button-container-height: 56px;

.approve-leave-modal {
	.eitje-modal-2-right-panel {
		overflow-x: hidden;
		.approve-leave-right-panel {
			padding-bottom: @view-period-button-container-height;
			max-height: 100%;
			overflow-y: scroll;

			.view-period-button-container {
				.top-shadow;
				.border(top);
				background-color: @white;
				padding: 12px 0;
				position: absolute;
				bottom: 0;
				width: 100%;
				z-index: 10;

				.eitje-button .eitje-text {
					white-space: nowrap;
				}
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.side-menu {
	width: 100%;

	.side-menu-container {
		.row-with-justify-between;
		.border(@color: @white);
		.border-radius;
		.horizontal-padding(12px);
		.vertical-padding(8px);
		.transistion-quick;

		.side-menu-item {
			.transistion-quick;
			.prevent-text-overflow;
			font-size: 12px;
			line-height: 2;
			display: flex;
			flex: 1;
			justify-content: space-between;
		}

		&.selected {
			.border(@color: @medium-blue);
			.border-radius;
			background-color: @medium-blue;

			.side-menu-item {
				.bold;
				color: @white;
			}
		}

		&.error {
			.border(@color: ~'@{medium-red}50');
			.border-radius;
			background-color: @light-red;

			.side-menu-item {
				.bold;
				color: @medium-red;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.advanced-table-body-cell {
	.border(right);
	background-color: white;
	// overflow-x: hidden; // The cell should always show an ... ellipses, but keep this overflow as extra guard for if the ellipses is broken.

	.eitje-layout-grow {
		.prevent-text-overflow;
		display: inline-block; // The text wrapper has to be block level for the truncation to work
	}

	&-pinned {
		.var(left-offset);

		position: sticky;
		left: @var-value;
		z-index: 11;
	}

	&-last-pinned {
		border-right-color: @black;
	}

	&:last-of-type {
		.unset-border(right);
	}
}

.advanced-table-body-cell-disabled {
	// A disabled layout's border dissapears, thus overwrite with force, yay!
	border-right: 1px solid @medium-border !important;

	&.advanced-table-body-cell-last-pinned {
		border-right-color: @black !important;
	}
}

@import (reference) 'styles/app/app.less';

.integration-redirect-modal {
	.ant-modal-body {
		padding: 112px 76px;
		text-align: center;

		.integration-redirect-image {
			width: 100%;
			margin: 0 0 64px 0;
		}

		.integration-redirect-title {
			margin: 0 0 12px 0;
		}

		.integration-redirect-text {
			// ...
		}
	}
}

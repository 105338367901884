@import (reference) 'styles/app/app.less';

.week-table-days-header {
	// Below lines are required for the set-weather-location form, which doesn't use a popout (with its own z-index logic), but an
	// extra, z-positioned div instead. Once we get rid of this (with the new settings, when we will link to the environment location form),
	// we may delete below two lines.
	position: relative;
	z-index: 100;

	.popout-card-trigger {
		height: 100%;

		.week-table-tile {
			.arrow-icon {
				position: absolute;
				right: 4px;
			}
		}
	}
}

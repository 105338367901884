@import (reference) 'styles/app/app.less';

#chat-window {
    .center-shadow;
    overflow: hidden !important;

    .channel {
        min-height: 0;
    }

    .str-chat__message-input {
        .str-chat__attachment-list-scroll-container {
            max-height: 86px; // 2 items + 1 gaps
            overflow-y: auto;
            flex-direction: column;
            row-gap: 4px;
        }
    }
}
.list-row {
	.row;
	.border(bottom);
	.transistion-quick;
	padding: 12px 8px;
}

.list-image {
	width: 12px;
	height: 12px;
	min-width: 12px;
}

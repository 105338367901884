@import (reference) 'styles/app/app.less';

.revenue-group-day {
	position: relative;

	.no-contract-users {
		@adjustment: 6px;

		position: absolute;
		left: 16px;
		top: calc(((100% - 14px) / 2) - @adjustment);

		.no-contract-users-icon {
			height: 14px;
		}

		.no-contract-users-count-wrapper {
			position: relative;

			.no-contract-users-count {
				.bold;
				position: absolute;
				background-color: @medium-red;
				color: @white;
				width: 16px;
				height: 16px;
				line-height: 16px;
				text-align: center;
				.border-radius(round);
				left: -4px;
				top: -18px;
			}
		}
	}
}

.no-contract-users-popout {
	.no-contract-popover-title {
		.border(bottom);
		line-height: 40px;
		padding: 0 12px;
	}

	.no-contract-popover-user {
		&:hover {
			background-color: @light-blue;
		}
	}
}

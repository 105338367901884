@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/index.less';

.add-shift div.time-registration-modal-header {
	.border(bottom);
	.row;
	gap: 8px;
	justify-content: flex-start; // requires the above specificity
	padding: 0 32px;

	.time-registration-shift-date {
		margin: 6px 0 0 0;
	}

	.eitje-form-2-field-environment_id {
		.eitje-form-2-dropdown;
		margin-left: auto;
		min-width: 150px;
		flex-grow: 0;

		.eitje-form-2-field-icon {
			margin: 4px 0 0 0;
		}

		.ant-select-selector {
			.ant-select-selection-search {
				.border;
				height: 40px;
				.border-radius;
				padding: 12px;
				margin-top: 22px;
			}

			.ant-select-selection-item {
				@non-text-input-width: 55px;

				.prevent-text-overflow;
				max-width: calc(100% - @non-text-input-width);
				position: absolute;
				bottom: 30px;
			}
		}

		.eitje-form-2-field-icon {
			margin: 2px 12px 0 0;
		}
	}
}

@import (reference) 'styles/app/app.less';

// TMP hack together form field, since the new form doesn't support this multi form yet, thus we can't use
// our new fields... sad

form.setup-balance-modal .eitje-hour-minute-picker-container .eitje-hour-minute-picker-input {
	position: absolute;
	left: 22px;
	top: 33px;

	.eitje-hour-minute-picker-input-left {
		width: calc(var(--hour-digits) * 1ch);
	}

	&.no-hours-entered {
		.eitje-hour-minute-picker-input-left {
			width: 1ch;
		}

		.eitje-hour-minute-picker-divider {
			color: @medium-grey;
		}
	}

	&.hours-entered .eitje-hour-minute-picker-input-right::placeholder {
		color: @black;
	}
}

// quick hack to prevent double layout fucking up children equal that is aggressively present in forms
.setup-balance-modal .eitje-form-2-row.eitje-layout-children-equal > *  {
	width: 100% !important;
}
@import (reference) 'styles/app/styles/elements/borders.less';
@import (reference) 'styles/app/styles/colors/colors.module.less';
@import (reference) 'styles/app/styles/elements/state_effects.less';
@import (reference) 'styles/app/styles/elements/shadows.less';

.eitje-modal(@height) {
	height: @height;
	overflow: hidden; // This helps prevent small, awkward and strange scrolls.

	// Hide the closing button by default, since it is absent in most modal designs.
	.eitje-modal-hide-close;

	// Use the default search container styling.
	.eitje-modal-search-container;

	.ant-modal-content {
		// The sticky positioned header and footer calculate their `top: 0;` and `bottom: 0;`
		// offsets relative to the nearest scrolling anchor and containing block. Therefore
		// set the .ant-modal-content div, which spans the whole modal area, to a scroll property.
		overflow: hidden;
	}
}

.eitje-modal-header(@height) {
	.border(bottom);
	height: @height;
	background-color: @white;
	position: sticky;
	top: 0;
	width: 100%; // overwrite?
	padding: 0px 24px;
	display: flex; // overwrite?
	justify-content: space-between;
	align-items: center;
	z-index: 10; // Always place the sticky header, with its background color, above the scrolling body contents.

	button {
		.big-shadow;
		.pointer-cursor;
	}
}

.eitje-modal-body(@height) {
	.vertical-scroll;
	height: @height;
	vertical-align: top;
	width: 100%;
}

.eitje-modal-footer(@height) {
	.border(top);
	height: @height;
	position: sticky;
	bottom: 0;
	background-color: @white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
	z-index: 2; // Always place the sticky footer, with its background color, above the scrolling body contents.

	.footer-buttons-container {
		display: inline-flex;
		gap: 4px;
	}

	// By default, unset the min-width for buttons as provided by Ant.
	.eitje-modal-flexbible-button-width;
}

.eitje-modal-hide-close {
	button.ant-modal-close {
		display: none;
	}
}

.eitje-modal-show-close {
	button.ant-modal-close {
		display: inline-block;
	}
}

.eitje-modal-search-container {
	.search-input-container {
		.border(bottom);
		padding: 0px;
		height: 72px; // Set default height.
		background-color: @white;
		width: 100%;
		display: inline-flex;
		align-items: center;
	}
}

.eitje-modal-flexbible-button-width {
	button {
		min-width: unset;
	}
}

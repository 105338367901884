// Added by Jurr as experiment, do we want to keep this classes and create alike classes?

.vertical-modal-distribution {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.remove-extra-space {
	font-size: 0;
	line-height: 0;
}

.center-anchor {
	position: relative;
}

.center-item {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

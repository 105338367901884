@import (reference) 'styles/app/app.less';

@input-height: 40px;

.work-weeks {
	.border-radius;
	background: @light-blue;
	padding: 12px 16px;
	margin: 0 0 12px 0;

	.work-weeks-title {
		font-size: 12px;
	}
}

.work-week {
	.work-week-title {
		margin: 4px 0 0 0;
	}

	.work-week-days {
		display: flex;
		gap: 4px;

		.eitje-hour-minute-picker-input-left,
		.eitje-hour-minute-picker-input-left::placeholder,
		.eitje-hour-minute-picker-input-right,
		.eitje-hour-minute-picker-input-right::placeholder,
		.eitje-hour-minute-picker-divider {
			height: @input-height;
			line-height: @input-height;
		}
	}
}

@import (reference) 'styles/app/app.less';

.successfully-signed-notification {
	.ant-notification-notice-icon img {
		.square-size(20px);
	}

	.successfully-signed-notification-text {
		margin: 0 0 12px 0;
	}
}

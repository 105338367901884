@import (reference) 'styles/app/app.less';

.basic-table {
	&-body {
		.popout-card {
			width: 100%;
		}

		a {
			display: contents;
		}

		// To have the same border colors as the table
		.eitje-layout-disabled {
			outline-color: @medium-border !important;
			border-color: @medium-border !important;
		}
	}

	&-row,
	&-row *,
	&-row-cell,
	&-row-cell * {
		// Forcefully set the weight of all texts, since different Components, Text instances and HTML structures are used for
		// all different cells.
		font-weight: 300 !important;
	}

	&-row {
		.border(@type: outline);
		position: relative;
		user-select: none;

		&.eitje-layout-has-hover:hover {
			z-index: 2; // to have the outline overlay the next sibling
		}

		&-cell {
			&-with-borders {
				.border(right);
			}

			&:last-child {
				.unset-border(right);
			}
		}

		.reorder-arrows {
			position: absolute;
			right: 20px; // This gives problems with the scroll
		}
	}
}

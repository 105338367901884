@import (reference) 'styles/app/app.less';

@avatar-offset: 100px;

.approve-pickup {
	width: 400px;

	.list-container {
		width: 100%;
	}

	.approve-pickup-body {
		padding: 16px 16px 0 16px;
		min-height: 100px;
		max-height: calc(100vh - 420px);
		overflow-y: auto;
		.flat-list {
			.list-item {
				&:last-child {
					margin: 0 0 12px 0;

					&.list-item-active {
						background-color: @light-blue;
					}
				}

				.bid {
					.border;
					.border-radius;
					.row;
					.pointer-cursor;
					.hover-effect;
					margin: 0 0 4px 0;
					padding: 0 16px;
					gap: 16px;
					position: relative;

					&-user-name {
						.bold;
						.prevent-text-overflow;
						color: @black;
					}
				}

				.bid-accepted {
					margin: 0 0 4px 0;
					height: 74px;

					.accepted-bid-info {
						.accepted-bid-timestamp {
							color: @dark-grey;
						}
					}

					.eitje-checkbox {
						margin-left: auto;

						.ant-checkbox-inner {
							.square-size(20px);
						}
					}
				}

				.bid-not-accepted {
					margin: 4px 0 0 0;
					height: 48px;
				}
			}
		}
	}

	.approve-pickup-footer {
		.border(top);
		padding: 8px 12px;
		justify-content: flex-end;
		display: flex;
		gap: 4px;

		.popout-card-trigger {
			width: 100%;
		}
	}
}

.eitje-dropdown {

	.list-picker-dropdown-container,
	form {
		width: 100% !important;
	}

	.dropdown-element-wrapper {
		flex: 1;
		min-width: 0;
	}
}
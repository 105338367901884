@import (reference) 'styles/app/app.less';
@import (reference) 'mixins.less';

////////////////////////////////////////////////
// Beware: this file is pretty fucked: unreadable
// yet often used.... Handle with care.
// new, better formatted definitions at the bottom
////////////////////////////////////////////////

.dropdown-list-container {
	width: 250px;
	&:not(.height-unset) {
		max-height: 400px;
	}

	.column-with-border {
		.border-radius;
	}

	.dropdown-list-search {
		.row;
		padding: 0px 12px 8px 12px;
	}
}

// User Lists left of the Users Page
.user-list-base {
	width: 100%;
	overflow-y: auto;
	flex-grow: 1;

	.list-container,
	.flat-list {
		height: 100%;
	}

	.list-item {
		position: relative;
		.row;
		.horizontal-padding(8px);
		.vertical-padding(8px);
		width: 100%;

		.warningCircle {
			position: absolute;
			left: 16px;
		}

		.list-item-text-container {
			overflow: hidden;
			padding-left: 8px;

			.list-item-title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 12px;
				.grey-text;
			}

			.list-item-sub {
				font-size: 10px;
				line-height: 12px;
				.grey-text;
			}

			.list-item-sub-italic {
				font-size: 10px;
				line-height: 12px;
				.grey-text;
				font-style: italic;
			}
		}

		&:hover {
			background-color: @light-blue;
		}

		&.selected {
			background-color: @light-blue;
		}
	}
	// when the user list is given list-active-right classname wrapper. the nested user row will have a solid right border (users page, right user list)

	&.list-active-right {
		.list-item {
			&.list-item-active {
				border-right: 3px solid @medium-blue;

				.list-item-title {
					color: @black;
					.bold;
				}
			}
		}
	}

	&:not(.disable-pointer) {
		.list-item {
			.pointer-cursor;
		}
	}
}

//
.dangerous {
	.list-item,
	.flat-list-picker-item {
		&:hover {
			background-color: @light-red;
		}

		&-selected {
			background-color: @light-red;
		}
	}
}

////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason is very simple: since LESS does not allow us to
// reuse dynamically created classes within other CSS classes as mixins,
// they may only be used directly within HTML – but that goes against
// our philosophy of keeping the JS files clean and concise.
////////////////////////////////////////////////////

.user-list-variant-base {
	.user-list-base;
	&.selected-bg {
		.list-item.list-item-active {
			background-color: @light-blue;
		}
	}
}

.user-list-sm {
	.list-item {
		.horizontal-padding(12px);
	}

	.user-list-variant-base;
}

.user-list-xl {
	.list-item {
		.horizontal-padding(24px);
	}

	.user-list-variant-base;
}

////////////////////////////////////////////////////
// THE END OF THE VERY IMPORTANT NOTE :-)
////////////////////////////////////////////////////

// Hover Box
.base-box {
	.border;
	.border-radius;
	.white-background-and-light-shadow;
	padding: 16px;
	min-width: 160px;
	max-width: 160px;
	height: 73px;
	display: flex;
	flex-direction: column;
	.transition;
}

.h-list(@value) {
	overflow-x: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	:is(.flat-list) {
		.vertical-padding(12px); // required to keep the box shadow
		.row;
		column-gap: @value; // space in between each
	}
}

////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason is very simple: since LESS does not allow us to
// reuse dynamically created classes within other CSS classes as mixins,
// they may only be used directly within HTML – but that goes against
// our philosophy of keeping the JS files clean and concise.
////////////////////////////////////////////////////

////////////////////////////////////////////////////
// THE END OF THE VERY IMPORTANT NOTE :-)
////////////////////////////////////////////////////

.hover-item {
	.list-item {
		.transition;
		&:hover {
			.active-background-color;
		}
	}
}

.hover-boxed-item {
	.hover-item;
	.list-item {
		.white-background-and-big-shadow;
		.border-radius(large);
		& > :first-child {
			.base-box;

			&:hover {
				.active-background-color;
			}

			.list-item-title {
				.eitje-text-bold;
				.grey-text;
			}

			.list-item-sub {
				.eitje-text-secondary;
				margin-bottom: -4px;
			}
		}

		& a {
			cursor: pointer;

			* {
				cursor: pointer;
			}
		}
		&.list-item-active {
			& > :first-child {
				.active-background-color;

				.list-item-title {
					color: @black;
				}
			}
		}
		&:last-child {
			& > :first-child {
				margin-right: 4px;
			}
		}
	}
}

.dashed-boxed-item {
	.base-box;
	box-shadow: none;
	background-color: @white;
	.border(@style: dashed, @width: 4px);
	.border-radius(large);
	align-items: center;
	justify-content: center;

	&:hover {
		.white-background-and-light-shadow;
		border-color: @medium-blue;
	}
}

// Grouped List
// // Small item (what we use in the article list)
.list-row(@verticlePad, @horizontal-padding) {
	.row;
	.border(bottom);
	background-color: initial;
	.horizontal-padding(@verticlePad);
	.vertical-padding(@horizontal-padding);
	justify-content: space-between;
}

.grouped-list-sm {
	.grouped-list-group {
		// insert here the style for the group head class
		.list-item {
			& > :first-child {
				.list-row(24px, 12px);
				* {
					cursor: inherit;
				}
			}
		}
	}
}

// Handle Arrows
// Row
.list-container-draggable {
	&.drag-direction-vertical {
		.order-arrows {
			position: absolute;
			right: -32px;
			top: 0px;
		}
	}

	:is(.list-item) {
		position: relative;
	}
}

////////////////////////////////////////////////
// New, better formatted definitions
////////////////////////////////////////////////

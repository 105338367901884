@import (reference) 'styles/app/app.less';

/////////////////////////////////////////
// General layout & styling
/////////////////////////////////////////

.shift-card.planning-shift {
	.shift-name {
		.prevent-text-overflow;
		line-height: 14px;
		width: calc(100% - 8px);

		&.shift-name-with-trade {
			width: calc(100% - 30px);
		}
	}

	.shift-image {
		vertical-align: top;
		display: inline-block;
		position: relative;
		margin: 6px 0 0 0;
	}

	.shift-drag-handle {
		padding: 4px !important;
		margin: 4px 2px 0 2px;
	}

	.shift-user-shifts-count {
		.square-size(14px);
		.bold;
		.border;
		.border-radius(round);
		text-align: center;
		position: absolute;
		background-color: @medium-blue;
		color: @white;
		top: -4px;
		left: -2px;
		line-height: 1;
		padding: 1px 0 0 1px;
		font-size: 8px;
		line-height: 10px;
		letter-spacing: 1px; // helps the non-monospaced font to position correctly
	}

	.shift-note-type-row {
		display: flex;
		width: 100%;
		gap: 4px;
		margin: 4px 0 0 0;

		.shift-note,
		.shift-type {
			.prevent-text-overflow;
			.border;
			.small-shadow;
			flex: 1 1 0px;
			.border-radius;
			display: inline-block;
			padding: 2px 4px 2px 8px;
			line-height: 1;
			margin: -2px 0 2px 0;
		}

		.shift-note {
			.border;
			background-color: @white;

			.shift-note-icon {
				.square-size(12px);
				margin: 0 8px 0 0;
			}
		}

		.shift-type {
			background-color: @white;
			text-align: center;
		}
	}

	.shift-hours {
		.border-radius;
		.big-shadow;
		width: 40px;
		margin: 6px 0px 6px 4px;
		background: @white;
		padding: 10px 4px;
		vertical-align: middle;
		text-align: center;

		h6 {
			.bold;
		}
	}

	.shift-icons {
		display: flex;
		flex-direction: column;
		position: absolute;
		justify-content: center;
		text-align: right;
		gap: 4px;
		right: 0;
		top: 0;
		height: 100%;
		margin: 2px 2px 0 0;

		&-move-up {
			height: calc(100% - 24px);
		}

		img {
			height: 14px !important;
			width: 14px !important;
		}
	}

	.drop-to-schedule {
		.bold;
		.border-radius;
		.border;
		line-height: 26.8px;
		position: absolute;
		width: calc(100% - 16px);
		left: 8px;
		top: 5.5px;
		z-index: 2;
		color: @black;
		background-color: @white;
		text-align: center;
	}
}

// Only on the per team page should the handle be slightly smaller to match the avatar size
.env-table .shift-drag-handle {
	height: 32px !important;
}

/////////////////////////////////////////
// State styling
/////////////////////////////////////////

.shift-card {
	&.conflict-shift {
		border: @medium-red 2px solid;

		.shift-name,
		.shift-title-container .shift-title,
		.shift-title-addition {
			color: @medium-red;
		}
	}

	&.with-modal {
		.hover-effect;
		pointer-events: all;
	}

	&.open-shift {
		background-color: @white;
	}

	&.improductive-shift {
		background-color: @light-background;
		pointer-events: none;

		a {
			pointer-events: all;
		}

		&.with-hours .shift-info {
			width: calc(100% - 80px);

			&-per-member {
				width: calc(100% - 50px);
			}
		}

		.shift-info-per-member {
			display: flex;
			align-items: center;
		}

		&.with-modal {
			.hover-effect;
			pointer-events: all;
		}
	}

	&.disabled-shift {
		opacity: 0.25;
		pointer-events: none;
	}
}

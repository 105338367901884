@import (reference) 'styles/app/app.less';
@import (reference) 'components/shared/user_card/styles/index.less';

.approve-leave-modal .approve-leave-body {
	.border(bottom);
	height: 448px;
	position: relative;

	.user-card {
		.user-card-as-form-field;
	}
}

@import (reference) 'styles/app/app.less';

/////////////////////
// Basic row styling
/////////////////////

.advanced-table-body .advanced-table-row {
	.border(bottom);
	position: relative;
	box-sizing: content-box;

	.on-hover-focus({
			box-shadow: 0 -1px 0 0 @medium-blue; /* Top shadow */
			background-color: @light-blue;
			position: relative; // to ensure top boxshadow is not overlayed by previous sibling

			.advanced-table-body-cell {
				background-color: @light-blue;

				// Keep the darker border at the end of the stickies
				&:not(.advanced-table-body-cell-last-pinned) {
					border-right-color: transparent;
				}
			}

			.more-scroll-arrow-icon svg path {
					fill: @medium-blue !important;
			}
		});
}

/////////////////////
// Grouped row styling
/////////////////////

.advanced-table-row-expandable {
	.advanced-table-body-cell {
		background-color: @medium-background;
	}
}

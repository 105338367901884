@import (reference) 'styles/app/app.less';

.availability-cell-faded-by-filter {
	opacity: 0.1;
}

.availability-cell-date-popout {
	.ant-popover-inner-content {
		padding: 0;

		.availability-cell-date-popout-title {
			.bold;
			.border(bottom);
			padding: 8px 16px;
		}

		.availability-cell-date-popout-content {
			padding: 8px 16px;
		}
	}
}

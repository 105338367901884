@import (reference) 'styles/app/app.less';

@height: 60px;

.multi-action {
	.border-radius;
	.border();
	position: fixed;
	bottom: 88px;
	left: 50%;
	transform: translateX(-50%);
	display: inline-flex;
	height: @height;
	z-index: 20;

	.multi-action-description {
		color: @white;
		line-height: @height;
	}

	.delete-cross-wrapper {
		.pointer-cursor;
	}
}

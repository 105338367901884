@import (reference) 'styles/app/app.less';

.availability-cell-content {
	.availability-cell-remark-box,
	.availability-cell-time-box,
	.availability-cell-date-box {
		.column;
		.justify-content-center;
		.align-items-center;
		.border-radius;
		.border;
		background-color: @white;
		width: 40px;
		height: 34px;

		.placeholder {
			min-width: 15px;
		}

		p {
			.eitje-text-secondary;
			font-size: 10px;
			line-height: 12px;
		}

		img {
			width: 15px;
		}

		&:first-child {
			position: absolute;
			z-index: 2;
		}

		&:last-child {
			position: absolute;
			right: 8px;
		}
	}

	.availability-cell-date-box,
	.availability-cell-time-box {
		left: 8px;
	}

	.availability-cell-remark-box {
		top: 8px;
	}
}

.default-availability-cell-wrapper {
	.multiple-color-circles,
	.multiple-color-circles-trigger {
		.row-with-justify-center;
		gap: 4px;
	}

	.availability-cell {
		.border;
		.border-radius;
		.multiple-color-circles;
		.transition;
		.availability-cell-content;
		position: relative;
		height: 50px;

		.availability-cell-date-trigger {
			position: relative;
			top: -16px;
		}

		&.no-border {
			border: none;
		}
	}
}

// This styling applies to both the avaialbility page and the planning per member page
// It is scoped because it should not apply to user-page
.users-availability-table,
.planning-per-member {
	.availability-cell-date {
		position: absolute;
		top: 2px;
		left: 2px;
		bottom: 20px;
	}

	.availability-cell-date-box,
	.availability-cell-time-box {
		left: 2px;
	}

	.availability-cell-remark-box {
		top: 2px;
	}

	.availability-cell-date-trigger {
		position: relative;
		top: 10px !important;
	}

	.availability-cell-remark-box-trigger {
		width: unset !important; // to overwrite inline trigger style
	}
}

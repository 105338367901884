@import (reference) 'styles/app/app.less';
@import (reference) 'index.less';

.shift-modal .planning-shift-form-header {
	.eitje-modal-header(@modal-header-height);
	.border(right);
	.transition;
	padding: 0 12px 0 24px;
	display: inline-flex;
	width: @modal-left-width;

	&-full-width {
		width: calc(@modal-left-width + @modal-right-width);
	}

	.checkbox-button {
		.big-shadow;
	}

	.eitje-form-2-field-published {
		margin-top: -16px;
		min-width: 120px;

		.eitje-form-2-label {
			top: 28px;
			left: 30px;
		}

		.ant-checkbox-wrapper,
		.eitje-form-2-label {
			// negative z-index, since the checkbox itself is not clickable, but the wrapper around instead....
			// thus enable clicking on the checkbox by putting it below its wrapper.
			z-index: -2;
			font-size: 14px;
		}
	}
}

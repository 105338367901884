@import (reference) 'styles/app/app.less';

#planning-page .availability-card .availability-menu {
	.border;
	.big-shadow;
	.border-radius;
	background-color: @white;
	position: absolute;
	z-index: 10;
	width: 100%;
	margin: 10px 0 0 -4px;

	.user-info {
		.user-info-details {
			flex: 1 1;
			max-width: calc(100% - 44px);

			.user-info-name,
			.user-info-time {
				.prevent-text-overflow;
				text-align: left;
				max-width: 100%;
				line-height: 1.3;
			}

			.user-info-name {
				.bold;
				color: @black;
			}

			.user-info-time {
				color: @dark-grey;
			}
		}
	}

	p.availability-menu-open-chat,
	p {
		// select all p's nested within modal links, giving them the same class name doesn't work due to differing HTML structure
		.border(top);
		.pointer-cursor;
		line-height: 40px;
		padding: 0px 16px;
	}

	a:nth-child(2) p {
		// dynamically select the first row, in order to reset the border styling
		border-top: unset;
	}
}
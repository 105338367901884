@import (reference) 'styles/app/app.less';

#planning-page .availability-card {
	.border;
	.pointer-cursor;
	margin: 2px 0 0 0;
	.border-radius;
	padding: 4px 4px 4px 4px;
	position: relative;
	min-height: 24px;

	.availability-card-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 0 0;

		.username,
		.hours-in-week {
			display: inline-block;
			font-size: 10px;
		}
		.username {
			.bold;
			.prevent-text-overflow;
			color: @black;
			flex: 1 1;
		}
	}
}

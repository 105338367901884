@import (reference) 'styles/app/app.less';

.emoji-picker {
	width: 80px;
	background: @light-background;
	height: 30px;
	cursor: pointer;

	.emoji-picker-trigger {
		display: inline-block;
		vertical-align: middle;
		padding: 2px;

		p {
			.border;
			.border-radius;
			background: @white;
			height: 26px;
			width: 24px;
			line-height: 26px;
			text-align: center;
		}
	}

	.emoji-arrow-down {
		cursor: pointer;
		position: relative;
		top: 8px;
		display: inline-block;
	}
}

// Overwrite emoji library styles

.emoji-mart-scroll {
	border-radius: 0px 5px 5px 0px;
}

.emoji-mart-bar {
	display: none;
}

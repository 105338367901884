@import (reference) 'styles/app/app.less';

.week-table-tile {
  .on-hover-focus({
    // Unset the border to prevent a double borderRight (together with the outline hover defined above)
    .unset-border(right);
     // Decrease the width by 1px to prevent a jump caused by the disappearing border
     width: calc(100% - 1px) !important;
   });
}

@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .str-chat__channel {
        min-width: 0;
        flex: 1;
        width: 100%;

        * {
            .unset-text-transform;
        }
    }

    .str-chat__unread-messages-separator {
        background-color: unset;
        border-bottom: 1px solid @medium-border;
        color: @black;
    }

    .str-chat__list {
        scrollbar-color: @medium-grey transparent;
        scrollbar-width: thin;
    }

    .str-chat__channel-list-react .str-chat__channel-list-messenger-react {
        padding-bottom: unset;
    }

    .str-chat__message-actions-list-item {
        font-size: 14px;
        height: 40px;
    }

    .str-chat__message-attachment-actions-form {
        margin: 8px 8px 0 4px;
        gap: 4px;
        justify-content: center;

        .str-chat__message-attachment-actions-button {
            padding: 0 8px;
            height: 32px;
            font-size: 14px;
            color: @black;
            border-radius: 4px;
            width: unset;
            border-inline: unset;
            box-shadow: unset;
            .border;

            &:hover,
            &:active {
                background-color: @light-blue;
                border-color: @medium-blue;
                color: @medium-blue;
            }
        }
    }

    // the notification div always has margin, even if its empty
    // remove this margin when empty to beautify the layout 
    .str-chat__list-notifications:not(:has(*)) {
        margin: 0;
    }
}
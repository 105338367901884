@import (reference) 'styles/app/app.less';

.approve-leave-right-panel .leave-summary {
	.border(bottom);
	.top-shadow;
	position: relative;

	.eitje-date-picker-container {
		width: 100%;
	}

	.edit-button {
		position: absolute;
		right: 12px;
		top: 12px;
		white-space: nowrap;
		pointer-events: all;
	}

	> span {
		// give the tooltip's wrapping span a width to place the tooltip correctly
		width: 100%;
	}
}

@import (reference) 'styles/app/app.less';

.eitje-rich-text-editor-content {
    .grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        margin: 12px 0;

        +.grid {
            margin-top: 24px;
        }

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            &.has-focus {
                border-radius: 3px;
                outline-offset: 4px;
                outline: 2px solid @medium-blue !important;
            }
        }

        // Generate .column-1 to .column-12
        each(range(1, 12, 1), {
            .column-@{index} {
                grid-column-end: span @value;
            }

        })
}
}
@import (reference) 'styles/app/app.less';

.mollie-left-container {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	display: flex;
	flex-direction: column;
	width: 50%;
	align-items: center;
	height: 540px;
	padding: 24px 40px;
	background-color: @medium-background;

	h3 {
		text-align: center;
	}

	h5 {
		.text-center;
	}

	img {
		margin-top: 100px;
		margin-bottom: 48px;
		width: 96px;
	}
}

.mollie-right-container {
	width: 50%;
	height: 540px;
	padding: 56px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.mollie-heading {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		padding-bottom: 24px;
	}

	.mollie-sub-heading-container {
		.column-with-justify-center;
		.full-width;
		.align-items-center;
		text-align: center;
		padding-top: 8px;
	}

	.mollie-footer-container {
		.column-with-justify-center;
		.full-width;
		.align-items-center;
		padding-top: 80px;

		a {
			width: 100%;

			.setup-bank-account-button {
				width: 100%;
				margin-bottom: 4px;
			}
		}

		// the copy payment url container... should have its own classname
		// but how should it be passed to the component?

		.fRow {
			width: 100%;
		}

		div {
			justify-content: space-between;
		}

		h5 {
			text-align: center;
			padding-top: 12px;
		}

		.linkUrl {
			width: 204px;
		}
	}
}

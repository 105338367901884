.modal-active {
	.ant-spin-spinning {
		max-height: unset !important;
		z-index: 9999 !important;
		background: rgba(0, 0, 0, 0.2);
	}
}

.ant-modal {
	.ant-spin-container {
		height: unset !important;
	}
}

.ant-spin-nested-loading > div > .ant-spin {
	position: fixed;
}

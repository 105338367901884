@import (reference) 'styles/app/app.less';

//Index Page

.user-index {
	.user-list-base {
		.list-item {
			padding-left: 40px;
		}
	}
}

//User Page

.user-skills-list {
	.row;
	padding-top: 12px;

	.list-picker-regular-container {
		width: 100%;

		.flat-list-picker {
			.row;
			gap: 4px;
			flex-wrap: wrap;
			width: 100%;

			.flat-list-picker-item {
				.row;
				.border;
				.border-radius;
				width: 33%;
				gap: 8px;
			}
		}
	}
}

.user-banner-avatar-container {
	display: flex;
	align-items: center;
	position: relative;

	.eitje-circle {
		position: absolute;
		left: -8px;
	}
}

.user-banner-alert {
	text-align: center;
	border: 1px solid @medium-red;
	.border-radius;
	padding: 2px 12px;
	background-color: ~'@{medium-red}10';
	max-height: 27px;
	width: 160px;
	margin-bottom: 4px;
}

@import (reference) 'mixins/stretch';

@selection-card-height: 28px;
@selection-card-margin: 4px;
@select-all-button-width: 130px;
@select-all-button-height: 28px;

.eitje-form-2-multi-input {
	.pointer-cursor;
	display: block;
	// TODO: We want to add this minheight to every form field, but this will require some more testing
	min-height: @field-height;

	*:focus {
		outline: none;
	}

	.eitje-form-2-label {
		width: 100%; // prevents easy-selects being placed next to the title
	}

	.ant-select {
		width: 100%;

		.ant-select-selector {
			cursor: pointer !important; // disable the not selector "cursor: text" with force
			position: relative;
			float: left;
			clear: both;
			width: 100%;
			margin-bottom: -14px;

			// The actual overflow contents
			.ant-select-selection-overflow {
				padding: 4px 0 14px 0;

				.ant-select-selection-overflow-item {
					align-self: flex-start;

					.ant-select-selection-item {
						.border;
						margin: 0 @selection-card-margin @selection-card-margin 0;
						padding: 0 8px;

						.ant-select-selection-item-content {
							font-size: 14px;
							line-height: 26px;
							margin: 0 @selection-card-margin 0 0;
							padding: 0 2px 0 0;
							color: @black;
						}

						// The remove icon
						.ant-select-selection-item-remove {
							.anticon {
								line-height: @selection-card-height;
								font-size: 10px;
								color: @medium-border;
							}
						}
					}

					// The suffix item with a search: hide it all together, since we don't
					// need it and only makes the .ant-select-selection-overflow grow bigger
					// vertically.
					&.ant-select-selection-overflow-item-suffix {
						display: none;
					}
				}
			}

			// The placeholder
			.ant-select-selection-placeholder {
				left: 0;
				top: 10px; // this 10px is mysterious... why do we need it?
				height: @field-input-height;
				line-height: @field-input-height;
			}
		}
	}
}

//////////////////////////////////////
// Field variant with the select all button present
//////////////////////////////////////

.eitje-form-2-multi-input {
	&:not(.eitje-form-2-field-has-icon) {
		.eitje-form-2-select-all {
			.border;
			.border-radius;
			.bold;
			.pointer-cursor;
			position: absolute;
			right: @field-horizontal-padding;
			height: @select-all-button-height;
			line-height: @select-all-button-height;
			font-size: 14px;
			padding: 0 16px;
			width: @select-all-button-width;
			text-align: center;
			top: calc(@field-vertical-padding + @field-label-height + @selection-card-margin);
			background-color: @white;
			z-index: 10;
		}

		.ant-select-selection-overflow,
		.ant-select-selection-placeholder {
			max-width: calc(100% - @select-all-button-width - @selection-card-margin);
		}
	}
}

@import (reference) 'styles/app/app.less';

.eitje-legend {
	.grid-layout-child {
		.border(bottom);
	}

	// Because we are in a grid, there is no real row on which we can unset the border
	// instead the last 3 items together form this row, so that is why we need this selector
	.grid-layout-child:nth-last-child(-n + 3) {
		.unset-border();
	}

	&-color-bar {
		.border-radius(small);
	}
}

@import (reference) 'styles/app/app.less';

/////////////////////////////////////////
// General layout & styling
/////////////////////////////////////////

@shift-title-line-height: 20px;

.shift-card-skeleton {
	.border;
	.border-radius;
	.pointer-cursor;
	display: flex;
	flex-wrap: wrap;
	margin: 4px 4px;
	padding: 0px 4px;
	position: relative;
	min-height: 42.8px;
}

.shift-card {
	.shift-card-skeleton;
	user-select: none;
	background-color: @light-blue;

	.multi-edit-shifts-checkbox {
		vertical-align: top;
		margin: 10px 4px 0px 8px;
	}

	.shift-info {
		display: inline-block;
		margin: 0 0 0 6px;
		width: calc(100% - 36px); // 36 px is the width + margin of the image icon

		.shift-sub-info {
			height: 16px;
		}

		.shift-title-container {
			.prevent-text-overflow;

			.shift-title,
			.shift-title-addition {
				display: inline-block;
				vertical-align: top;
				line-height: @shift-title-line-height;

				.break-time {
					line-height: @shift-title-line-height;
				}
			}

			.shift-title {
				.bold;
				color: @black;
				margin: 2px 0 0 0;
			}

			.shift-title-addition {
				margin: 2px 0 0 4px;
				color: @dark-grey;
			}
		}
	}

	&.per-member {
		.shift-title-container {
			height: 20px;

			.shift-title {
				margin: 0;
			}

			.shift-title-addition {
				margin: 0 0 0 4px;
			}
		}
	}
}

/////////////////////////////////////////
// State styling
/////////////////////////////////////////

.shift-card {
	&.is-over {
		.border(@width: 2px, @style: dashed, @color: @medium-blue, @type: outline);
	}

	&.is-over-disabled {
		background: @light-background;
		opacity: 0.25;
	}

	&.no-manager-shift,
	&.no-manager-shift * {
		cursor: default;
	}
}

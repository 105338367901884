@import (reference) 'styles/app/app.less';


.eitje-rich-text-editor-content {
    img {
        display: block;
        max-width: 100%;
        height: auto;

        &.has-focus {
            .border-radius();
            .border(@width: 2px, @color: @medium-blue, @type: outline);
            outline-offset: 4px;
        }
    }
}
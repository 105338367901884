@import (reference) 'styles/app/app.less';

.period-select-picker {
	.period-select-picker-checkbox-cards {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 4px;
	}

	.period-select-picker-checkbox-card {
		width: calc(50% - 2px);
	}

	.period-select-picker-period-title {
		.bold;
		color: @black;
	}

	.period-select-picker-period-title,
	.period-select-picker-period-shifts {
		display: inline-block;
	}
}

.eitje-form-2-field .eitje-form-2-label.period-select-picker-period-list-label {
	float: none;
}

@import (reference) 'styles/app/app.less';

// We have defined a general, application wide @size variable, but in the
// case of avatars we have deviated and define an extra one. Whether all the
// given key value pairs are required (or are legacy), is very much up to the question.
// But it is needed for sure, since the xxl (64px) is used quite often, and that
// size is much bigger than the largest size stored in the @size variable.

@eitje-avatar-bg-color: @light-blue;
@eitje-avatar-sizes: xxxl, xxl, xl, lg, md, sm, xs, xss;

@eitje-avatar-size: {
	xxxl: 64;
	xxl: 56;
	xl: 48;
	lg: 40;
	md: 36;
	sm: 32;
	xs: 28;
	xss: 24;
};

@eitje-avatar-font-size: {
	xxxl: 24;
	xxl: 20;
	xl: 18;
	lg: 16;
	md: 14;
	sm: 12;
	xs: 10;
	xss: 10;
};

.row-with-justify-center {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
}

.bold {
	font-weight: 600;
}

.eitje-avatar-overlay(@size) {
	@borderValue: if((@size <= 36), 2, 3);
	.row-with-justify-center;
	border: ~'@{borderValue}px' solid @white;
	min-width: ~'@{size}px';
	width: ~'@{size}px';
	min-height: ~'@{size}px';
	height: ~'@{size}px';
	box-shadow: 0 0px 8px ~'@{black}26';
	.border-radius(round);
	overflow: hidden;
}

.eitje-avatar-inner(@size, @font-size) {
	width: inherit;
	height: inherit;
	object-fit: cover;
	text-align: center;
	line-height: ~'@{size}px';
	background-color: @eitje-avatar-bg-color;
	.bold;
	color: @medium-blue;
	font-size: ~'@{font-size}px';
}
// this is copied over from rows.less as LESS files with loops can't be imported in other LESS files due to styles being duplicated

////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason is very simple: since LESS does not allow us to
// reuse dynamically created classes within other CSS classes as mixins,
// they may only be used directly within HTML – but that goes against
// our philosophy of keeping the JS files clean and concise.
////////////////////////////////////////////////////
each(@eitje-avatar-sizes, {
  .eitje-avatar-overlay-@{value} {
    .eitje-avatar-overlay(@eitje-avatar-size[$@value]);
    .eitje-avatar-inner {
      .eitje-avatar-inner(@eitje-avatar-size[$@value], @eitje-avatar-font-size[$@value]);
    }
  }
});
////////////////////////////////////////////////////
// THE END OF THE VERY IMPORTANT NOTE :-)
////////////////////////////////////////////////////

@import (reference) 'styles/app/app.less';

.export-filter-block-sentence {
	margin: 4px 0 0 0;
	display: flex;

	&.export-filter-block-sentence-horizontal {
		flex-direction: row;
		gap: 4px;

		.entity-info,
		.column-firm-info,
		p {
			.text-capitalize;
		}

		.entity-info {
			.info-popout-container {
				display: inline-flex;
			}
		}
	}

	&.export-filter-block-sentence-vertical {
		flex-direction: column;
	}
}

.export-filter-block-sentence,
.export-filter-block-sentence * {
	font-size: 12px;

	.footer-card-icon {
		.square-size(12px);
		margin: 0 4px;
	}
}

.remove-pop-padding {
	.ant-popover-inner-content {
		padding: 0px;
		border-radius: 4px;
		overflow: hidden;
	}
}

.light-pop-padding {
	.ant-popover-inner-content {
		padding: 8px 10px;
	}
}

.ant-popover-disabled-compatible-wrapper {
	opacity: 0.4;
}

.ant-popover {
	.ant-popover-arrow {
		display: none;
	}
}

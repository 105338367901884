@import (reference) 'styles/app/app.less';

// Unscope the styling for the new form

.work-weeks,
.approve-leave-modal,
.setup-balance-modal {
	.eitje-hour-minute-picker-input {
		.border;
		.border-radius;
		display: inline-flex;
		justify-content: center;
		background: @light-background;

		.eitje-hour-minute-picker-input-left,
		.eitje-hour-minute-picker-input-right {
			padding-right: unset !important;
			width: 35px;
		}

		.eitje-hour-minute-picker-input-left,
		.eitje-hour-minute-picker-input-left::placeholder,
		.eitje-hour-minute-picker-input-right,
		.eitje-hour-minute-picker-input-right::placeholder,
		.eitje-hour-minute-picker-divider {
			color: @dark-grey;
		}

		.eitje-hour-minute-picker-input-left {
			text-align: right;
		}
	}

	//////////////////////
	// Arrow styling
	//////////////////////

	.eitje-hour-minute-picker-input .eitje-hour-minute-picker-arrows {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		gap: 4px;
		// overwrite height locally in order to make the height and position of arrows grow along correctly (% height of arrow requires fixed height container)
		// FYI: this default value is not used yet, so may still be changed once the right default has been designed
		height: 30px;
		margin: 0 16px 0 0;

		img.eitje-image {
			.pointer-cursor;
			height: 10%;
		}
	}
}

.approve-leave-modal .eitje-hour-minute-picker-input .eitje-hour-minute-picker-input-left {
	width: calc(var(--hour-digits) * 32px) !important;
	min-width: 62px !important;
}

.debug {
	background-color: red !important;
	color: yellow !important;
	text-decoration: underline !important;
	font-weight: 800 !important;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span {
		color: yellow !important;
		text-decoration: underline !important;
		font-weight: 800 !important;
	}
}

.debug2 {
	color: green !important;
	background-color: blue !important;
	font-weight: 800;
}

.debug-hide {
	display: none !important;
	visibility: hidden !important;
	height: 0px !important;
	width: 0px !important;
}

.debug-red {
	background-color: red;
}

.debug-blue {
	background-color: blue;
}

.debug-yellow {
	background-color: yellow;
}

.debug-green {
	background-color: green;
}

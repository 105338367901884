@import (reference) 'styles/app/app.less';

.eitje-avatar {
	.var(radius);

	.border-radius(round);
	.border;
	flex-shrink: 0;
	overflow: hidden;
}

@import (reference) 'styles/app/app.less';

@team-panel-header-height: 76px;
@team-panel-sub-header-height: 50px;
@team-panel-footer-height: 64px;

////////////////////////////////////
// Base styling
////////////////////////////////////

.team-panel {
	.hide-ant-close;

	.ant-drawer-body {
		padding: 0;
	}
}

////////////////////////////////////
// Header styling
////////////////////////////////////

.team-panel .team-panel-header {
	.border;
	.row;
	width: 100%;
	justify-content: space-between;
	padding: 16px 12px 16px 24px;
}

////////////////////////////////////
// Body styling
////////////////////////////////////

.team-panel .team-panel-balance {
	height: calc(100vh - @team-panel-header-height);

	.list-container {
		overflow-y: scroll;

		height: calc(100% - @team-panel-sub-header-height - @team-panel-footer-height);
		padding: 4px 0 40px 0;
	}
}

////////////////////////////////////
// Footer styling
////////////////////////////////////

.team-panel .search-footer {
	.border(top);
	padding: 12px;
	gap: 4px;
	position: sticky;
	bottom: 0;
	width: 100%;
	background-color: @white;

	.search-footer-dropdowns {
		display: flex;
		gap: 4px;
		margin-bottom: 8px;
		flex-wrap: wrap;
	}
}

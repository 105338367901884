/*

/////////////////////////////////////////////////////////////////

IMPORTANT

Below files are merely legacy support, don't add to them, or depend
on then, since the goal is to get rid of them as soon as possible!

/////////////////////////////////////////////////////////////////

*/

.loadingDudes {
  margin: auto;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: white;
}

.logoLoading {
  width: 220px;
}

.loadingDudes p {
  font-size: 16px !important;
  font-weight: bold;
  margin-bottom: 0px;
}

.loadingBubble {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 4px;
}

.loadingDudes span {
  color: #828282;

  font-size: 14px !important;
}

.loadingTitle {
  font-size: 58px;
  font-weight: bold;
  color: #2b2b2b;
  line-height: 50px;
  text-align: center;
  margin-bottom: 24px;
}

.loadingTip {
  font-size: 22px;
  font-weight: bold;
  color: #828282;
  line-height: 28px;
  text-align: center;
  margin-bottom: 80px;
  max-width: 40%;
}

.loadingMore {
  font-size: 14px;
  font-weight: bold;
  color: #828282;
  text-align: center;
  margin-top: 8px;
}

/* loading dots */

.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #2b2b2b;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #2b2b2b, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #2b2b2b, 0.5em 0 0 #2b2b2b;
  }
}

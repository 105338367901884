@import (reference) 'styles/app/app.less';

.env-table * {
	.hide-select;
}

.env-table {
	width: 100%;
	margin: 0 0 160px 0;
}

////////////////////////////////////
// Header layout styling
////////////////////////////////////

#planning-page {
	.env-header {
		.border(top);
		.border(bottom);
		background-color: @white;
		margin: 12px 0 0 0;
		padding: 12px 24px 12px 12px;
		position: relative;
		height: 64px;

		.env-info,
		.pick-teams {
			float: left;
		}

		.env-info {
			display: inline-flex;
			gap: 12px;
			margin: 0 124px 0 0;

			.env-info-icon {
				.square-size(14px);
				display: inline-block;
				margin: 14px 0 0 0;
			}
		}
	}
}

////////////////////////////////////
// Planning row layout styling
////////////////////////////////////

#planning-page {
	.planning-row {
		.border(top, @light-border);
		background-color: @white;
		margin: 12px 0 0 0;

		.planning-shifts-row,
		.planning-hours-row,
		.planning-availability-row {
			.border(bottom, @light-border);
			display: flex;
			width: 100%;
		}

		.planning-availability-row {
			margin: 0 0 40px 0;
		}

		.planning-day-shifts,
		.planning-day-shifts-title,
		.planning-day-hours,
		.planning-day-hours-title,
		.planning-day-availability,
		.planning-availability-title,
		.planning-title {
			.border(right, @light-border);
			vertical-align: top;
			display: inline-block;
			width: calc(12.5%);
			position: relative;
			display: inline-block;
		}

		.team-name {
			flex-grow: 1; // grow so that the team title centers vertically
			justify-content: center;
		}
	}
}

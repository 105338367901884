@import (reference) 'styles/app/app.less';

.topic-container {
	width: 100%;
	max-width: 1110px;
	padding-top: 32px;

	&.wide {
		max-width: 1315px;
	}
}

// Index page

#show-topic-page {
	.topic-settings-column {
		.training-overview-box {
			.training-textual-information {
				.horizontal-padding(12px);

				p {
					margin-bottom: 12px;
				}
			}
		}

		.analytics-links {
			margin-bottom: 4px;
		}
	}
}

// Topic Index
.topics-list {
	:is(.flat-list) {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(268px, 268px));
		column-gap: 12px;
		row-gap: 12px;
		place-content: center;
		margin-bottom: 8px;
	}
}

.training-container {
	.ant-btn {
		min-width: 0;
		width: 100%;
	}

	.analytics-links {
		.row;
		gap: 4px;
		:is(.modalLink) {
			flex: 1;
		}
	}

	.modal-links {
		.row;
		gap: 4px;
		:is(*) {
			.flex-grow-one;
		}
	}
}

.topic-tile-container {
	.column-with-border-and-padding(0px, 0px);
	position: relative;
	padding-bottom: 8px;
	min-height: 351px;

	.topic-tile-image {
		border-radius: 8px 8px 0px 0px;
		width: 100%;
		height: 160px;
		object-fit: cover;
	}

	.topic-tile-options {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	.topic-tile-text-container {
		width: 100%;
		flex: 1;
		padding: 15px 24px 8px 24px;
		:first-child {
			width: inherit;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			margin-bottom: 3px;
			.bold;
		}
		:last-child {
			.eitje-text-secondary;
			display: -webkit-box;
			-webkit-line-clamp: 2; /* number of lines to show */
			-webkit-box-orient: vertical;
			height: 2.2rem;
			overflow: hidden;
		}
	}

	.topic-tile-footer {
		width: 100%;
		padding: 8px 12px 0px 12px;

		.modalLink {
			width: 100%;
		}

		.eitje-form-2-field-published,
		.eitje-form-2-field-team_ids {
			.border;
			.border-radius;
			width: 100%;
			min-height: 50px;
			padding: 0 8px;
		}

		// TO DO: abstract below line away (Chris made a consideration)
		// drill 100%, also so other "TO DO" comments like these
		.popout-card-trigger {
			width: 100% !important;
		}
	}

	&.placeholder {
		width: 268px;
		.border(@style: dashed, @width: 4px);
		.border-radius(large);
		.row;
		width: 268px;
		box-shadow: none;
		background-color: transparent;
		height: 351 px;
		place-content: center;
		opacity: 0.5;
		.transition;
		&:hover {
			opacity: 1;
			background-color: @white;
			.white-background-and-big-shadow;
		}
	}
}

// TopicInfoLabel
.topic-info-label {
	.row-with-justify-between;
	padding-left: 12px;
	padding-right: 8px;
	margin-bottom: 8px;

	.topic-info-label-text {
		.eitje-text-secondary;
	}
}

//
.topic-training-check {
	.row;
	.align-items-center;
	gap: 4px;

	.topic-training-check-text {
		.eitje-text-secondary;
		line-height: 12px;
	}
}

.loose-articles-container {
	.data-display-row p.data-display-cell:first-child div {
		.unset-text-transform;
	}
}

// This popout is used to display a modal, so we hide the popout
.edit-topic-popout-popout {
	display: none !important;
}

// This file is very small and was initially created during the
// great cleanup of the CSS, to move things here which did not
// belong elsewhere. Eventually, when every page has its own
// styling file, this should probably move there.

.very-large-padding-top {
	padding-top: 64px;
}

.very-large-padding-bottom {
	padding-bottom: 64px;
}

@import (reference) 'styles/app/app.less';

.video-collection .video-preview {
	.video-placeholder {
		.border-radius;
		.big-shadow;
		aspect-ratio: 16 / 9;
		background: @light-blue-background;
		position: relative;
		overflow: hidden;

		img {
			.border;
			.border-radius;
			.big-shadow;
			width: 100%;
			height: 100%;
		}
	}

	&:hover {
		* {
			color: @medium-blue !important;
			border-color: @medium-blue !important;
		}
	}
}
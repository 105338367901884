@import (reference) 'styles/app/app.less';

// This file overwrites some of the aggressive and incorrect behaviour of the form library styling,
// which is packed with strong nesting and :not selectors. Change here instead of globally in order
// to avoid problems > eventually change the whole global system.

.ant-modal-root .ant-modal-wrap.edit-post-modal {
	div.eitje-field-title,
	div.eitje-field-content {
		border-bottom: none;
		position: relative;
	}

	div.poll-form-asset-details-container {
		padding: 0;

		.elementContainer {
			padding: 0px 24px;
			border-bottom: none;
		}
	}

	.eitje-modal-footer {
		display: flex;
		gap: 8px;
		justify-content: flex-end;
	}
}

@import (reference) 'styles/app/app.less';

.set-color(@color) {
	color: @color;
	.eitje-text {
		color: @color;
	}
}

.eitje-link.hyperlink {
	.set-color(@medium-blue);

	&:hover {
		.set-color(@dark-blue);
		.underline;
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) '../../styles/index.less';

.exports-tables-index-header {
	width: 100%;
	max-width: @exports-table-index-page-width;
	margin: 0 auto;
	position: relative;

	.exports-tables-title {
		margin: 32px 0 0 0;
	}

	.exports-tables-subtitle {
		margin: 0 0 12px 0;
		color: @dark-grey;
	}
	.exports-tables-index-header-docs {
		display: flex;
		gap: 4px;
	}

	.create-button {
		position: absolute;
		right: 0;
		top: 16px;

		.eitje-text {
			white-space: nowrap; // Because of absolute positioning
		}
	}
}

///////////////////////
// scroll to type
///////////////////////

.exports-tables-type-picker {
	position: sticky;
	top: 0;
	z-index: 2;
	display: flex;
	gap: 4px;
	margin: 0px auto;
	padding: 12px 0 8px 0;
	width: @exports-table-index-page-width;
	background-color: @light-background;

	.exports-tables-type {
		.border;
		.border-radius;
		.big-shadow;
		.pointer-cursor;
		.hover-effect;
		background-color: @white;
		padding-right: 16px;
		display: flex;

		&.active {
			background-color: @light-blue;
		}

		.exports-table-type-count {
			.border;
			.border-radius;
			display: inline-block;
			margin: 6px;
			background-color: @white;
			font-size: 16px;
			height: 32px;
			min-width: 32px;
			line-height: 32px;
			padding: 0 10px;
		}

		.exports-table-type-title {
			line-height: 44px;
		}
	}
}

.full-width-divider {
	.border(bottom);
	width: 100%;
	height: 0px;
}

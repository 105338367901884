@import (reference) 'styles/app/app.less';

.edit-team-member-popover-content {
	p.defaultRow {
		width: 100%;
	}

	p.defaultRow {
		padding: 12px 24px;
	}

	.delete-button-container {
		padding: 6px;
	}
}

.rehire-popout {
	min-width: 150px;
}

.edit-team-member-popover-content .list-picker-trigger {
	width: 100%;
}

@import (reference) 'mixins/stretch';
@import (reference) 'styles/app/app.less';

@toggle-width: 44px;

.eitje-form-2-toggle {
	.hide-all-icons;
	min-height: 72px;
	display: inline-flex;

	// A tooltip field's text should be centered vertically, therefore use this flex solution.
	// Its questionable if this will work with multiple text elements (i.e. warning) in combination
	// with a tooltip's span. Test later.
	align-items: center;

	.eitje-form-2-label {
		width: calc(100% - @toggle-width);
	}

	button {
		position: absolute;
		right: @field-horizontal-padding;
	}
}

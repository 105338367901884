// Styling for boxes, e.g. container classes which do not necessarily have a
// specific direction – thus being different than related .column and .row
// classes.

.box-with-border {
	.border;
	.white-background-and-big-shadow;
}

.box-with-border-and-radius {
	.box-with-border;
	.border-radius(large);
}

.box-with-border-and-padding(@horizontal-padding, @vertical-padding) {
	.box-with-border;
	padding-left: @horizontal-padding;
	padding-right: @horizontal-padding;
	padding-top: @vertical-padding;
	padding-bottom: @vertical-padding;
}

@import (reference) 'styles/app/app.less';

.users-availability-table-row {
	.row;
	background-color: @white;
	z-index: 10;

	.default-availability-cell-wrapper {
		.border(right);
		// .debug;
		padding: 0px 4px 4px 4px;
	}

	& > :first-child {
		// This is the first cell of each row.
		min-width: 196px;
		max-width: 196px;

		p.eitje-text-secondary {
			.prevent-text-overflow;
			max-width: 135px;
		}
	}

	&.days-header-row {
		.border(bottom);
		height: 64px;
		position: sticky;
		background-color: @white;
	}

	.default-availability-cell-wrapper {
		.border(right);
		.row-with-justify-center;
		.align-items-center;
		height: 54px;
		padding: 2px 4px;
	}

	&:hover .availability-cell,
	.availability-cell.av-cell-active {
		background-color: @light-blue;
	}

	.availability-cell.av-cell-conflict {
		background-color: @light-red;
	}
}

.users-availability-table-row.days-header-row {
	top: 0px;
}

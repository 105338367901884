@import (reference) 'styles/app/app.less';

.export-tables-show-excels {
	margin: 32px 0 0 0;

	h3 {
		.border(bottom);
		padding: 0 0 8px 0;
		margin: 0 0 12px 0;
	}

	.table-audit,
	.export-tables-show-excel {
		.border;
		.border-radius;
		.big-shadow;
		padding: 8px 16px;
		margin: 8px 0 0 0;
	}

	.table-audit {
		min-height: 60px;
		display: flex;
		align-items: center;
	}

	.export-tables-show-excel {
		display: flex;
		align-items: center;
		gap: 16px;
		height: 60px;

		.exported-period {
			.bold;
			color: @black;
			width: 220px;
		}

		.excel-actions {
			margin-left: auto;
			display: inline-flex;
			gap: 4px;
		}

		&.excel-failed {
			.status-string {
				color: @medium-red;
			}
		}
	}
}

.mac-scrollbar {
	&::-webkit-scrollbar {
		.border(left);
		-webkit-appearance: none;
		width: 12px;
		background-color: @white;
	}

	&::-webkit-scrollbar-thumb {
		.border(@color: transparent, @width: 3px);
		border-radius: 12px;
		background-color: rgba(0, 0, 0, 0.3);
		background-clip: padding-box;
	}
}

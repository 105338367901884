@import (reference) 'styles/app/app.less';

.notification {
	.border();
	.border-radius(large);
	.small-shadow;

	&-right-content {
		position: relative;
		top: 2px;
	}

	&-content,
	&-content * {
		font-weight: 600;
	}
}

@media (max-width: 600px) {
	// Make notification responsive for errors in the non authed forms
	.notification-large {
		width: calc(100vw - 32px) !important;
	}
}

@import (reference) 'styles/app/app.less';

.skill-set-icon {
	@color: var(--initial-background);
	border-radius: 2px;

	// Prevent from being coloured with a inherited colorSet.
	background-color: @color !important;

	.eitje-text {
		color: @white !important;
	}
}

@import 'styles/lib/ant/index.less';
@import 'styles/lib/forms/index.less';

body {
	// This used to come front ant. Before we will fully remove ant we will keep the styling below to prevent unwanted changes
	// Once ant is removed we can take this out and update all issues that arise (from texts that are not wrapped in eitje-text)
	line-height: 1.5715 !important;
}

@import '@eitje/web_components/src/styles/index.less';
// HACK: change to relative web_components when working on color_sets
// @import '../../../../libs/web_components/src/styles/index.less';

// application styles
@import 'styles/index.less';

// legacy styles
@import '../legacy/app.scoped.css';

// component specific styles
@import 'components/index.less';

// general styles which aren't mixed in elsewhere, where should we put those kind of style files?
@import '../to_move/fields.less';
@import '../to_move/layouts.less';
@import '../to_move/navbar.less';

@import 'styles/modal/index.less';
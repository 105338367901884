@import (reference) 'styles/app/app.less';

.sub-head-nav {
	.column;
	position: relative;
	align-items: flex-start;

	.nav-link-tab {
		.row;
		width: 80%;
		height: 48px;
		padding-left: 40px;
		min-width: 224px;
		font-size: 14px;
		opacity: 0.8;
		border-top-right-radius: 32px;
		border-bottom-right-radius: 32px;
		.transition;

		.nav-link-text {
			.bold;
			color: @dark-grey;
		}

		.nav-link-image {
			width: 28px;
			height: 28px;
			margin-right: 8px;
		}

		&.active {
			opacity: 1;
			background-color: @light-blue;

			.nav-link-text {
				color: @medium-blue;
			}
		}
	}
}

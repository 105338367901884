@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/fields/mixins/stretch.less';

///////////////////////////
// Overwrites of default form styling
///////////////////////////

.multi-edit-hours-form .eitje-form-2-field-columns {
	min-height: 112px;
	padding-top: 24px;

	.eitje-button {
		top: 73px;
	}
}

.eitje-time-picker-panel {
	.use-planned-time-text {
		.pointer-cursor;
		color: @medium-blue;
		white-space: nowrap;
		padding: 0 12px;
		line-height: 32px;
	}
}

.multi-edit-hours-form .eitje-form-2-field-from,
.multi-edit-hours-form .eitje-form-2-field-till {
	.delete-cross-wrapper {
		display: inline-block;
		margin: 24px 0 0 8px;

		.delete-cross {
			.pointer-cursor;
			vertical-align: center;
			width: 14px;
		}
	}

	&:has(.delete-cross-wrapper) .eitje-form-2-field-icon {
		display: none;
	}
}

@import (reference) 'styles/app/app.less';

.file-item-extention {
	.row;
	.border;
	.white-background-and-small-shadow;
	.border-radius;
	.justify-content-center;
	.bold;
	max-width: 40px;
	min-height: 40px;
}

.file-item-container.large {
	.file-item-text-container {
		flex: 1;
		width: 100%;
	}
	.file-item-links-container {
		.row;
		width: fit-content;
		gap: 4px;
	}
	&.extended {
		.row;
		padding: 12px 24px;
		.border(bottom);
	}
	&:not(.extended) {
		.row-with-border-and-padding(12px, 12px);
	}
}

.file-item-container {
	&.small {
		.row;
		.align-items-center;
		.border;
		.white-background-and-big-shadow;
		.border-radius;
		padding: 16px 22px;
		background-color: @light-grey;
		height: 57px;
		padding-left: 24px;
		margin-bottom: 12px;

		* {
			cursor: pointer;
		}

		.file-item-icon {
			width: 20px;
			margin-right: 12px;
		}

		.file-item-text-container {
			flex: 1;
			min-width: 0;
			margin-right: 12px;
			.file-item-title {
				.bold;
			}

			.file-item-subtitle {
				.eitje-text-secondary;
				.prevent-text-overflow;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

@column-width: 312px;

.table-edit .left-page-column {
	.border(right);
	.vertical-scroll;
	height: 100%;
	width: @column-width;
	background-color: @white;
	padding: 32px 0 0 0;
	left: 0;
	z-index: 10;
	position: relative;

	h3 {
		margin: 0 12px;
	}

	.tour-button {
		position: absolute;
		right: 12px;
		top: 12px;
	}

	.search-container {
		margin: 8px 4px;
	}

	.list-picker-regular-container {
		height: calc(100% - 32px);
	}
}

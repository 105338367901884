@import (reference) 'styles/app/app.less';

.eitje-rich-text-document-content {
    overflow-y: auto;
    width: 100%;
    scrollbar-color: @medium-grey transparent;
    scrollbar-width: thin;

    .eitje-rich-text-editor-content {
        margin: 1.5rem 0;

        :first-child {
            margin-top: 0;
        }

        .ProseMirror-focused {
            outline: none;
        }

        .tiptap.ProseMirror {
            width: 100%;
        }

        // text in selected nodes gets selected, 
        // with this styles, we make sure this is not visible to users
        .ProseMirror-hideselection *::selection {
            color: inherit;
            background: transparent;
        }
    }


    .floating-action-menu {
        .center-shadow;
    }
}

// adding this to allow <strong> elements as childs of <span> to be colored
// using specificity over !important since that doesn't work for inherit
.eitje-layout.eitje-layout-color-set .eitje-rich-text-editor-content :not(.button-bar) {
    color: inherit;
}
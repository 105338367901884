.eitje-input-container.add-item-input {
	&.ant-input-affix-wrapper {
		padding: 4px 4px 4px 8px;
	}

	input {
		margin-top: 5px;
	}

	.char-counter {
		position: unset;
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) 'index.less';

.shift-modal .planning-shift-form .eitje-user-list {
	.eitje-modal-body(calc(@modal-header-height + @modal-body-height));
	.transition;
	width: @modal-right-width;
	display: inline-block;
	position: absolute;
	top: 0;

	&-hidden {
		width: 0px;
	}

	.search-input-container {
		padding: 0 12px;
		height: @modal-header-height;
		position: sticky;
		top: 0;
	}

	.grouped-list-picker {
		.list-picker-item-group-label {
			.border;
			.border-radius;
			margin: 4px;
			width: calc(100% - 8px) !important; // to overwrite inline layout style
			background-color: @white !important;
			justify-content: center !important;
			height: 28px !important;
			font-size: 12px;
			line-height: 28px;
			min-height: unset;

			.eitje-layout {
				justify-content: center;
			}
		}

		.flat-list-picker {
			overflow-x: hidden;

			.flat-list-picker-item {
				.pointer-cursor;

				.user-row-text-container {
					flex: 1 1;
					overflow: hidden;
				}

				.username {
					.bold;
					.prevent-text-overflow;
					max-width: calc(@modal-right-width - 70px);
					color: @black;
				}

				.availability-skills {
					display: inline-flex;
					flex-wrap: wrap;
					max-width: 70px;
					margin: -2px 0 0 0;

					.availability-skill {
						margin: 2px 0 0 2px;
						.border-radius(small);
						background-color: @white;

						.availability-skill-letter {
							.bold;
							float: right;
							width: 14px;
							height: 14px;
							line-height: 14px;
							text-align: center;
							font-size: 8px;
							.border-radius(small);
							color: @dark-grey;

							&:not(.has-skill-set) {
								.border;
								line-height: 12px;
							}

							&.has-skill-set {
								color: @white;
							}
						}
					}
				}

				&-selected {
					background-color: @light-blue;
				}
			}
		}
	}
}

///////////////////////////////////
// Empty state styling
///////////////////////////////////

.shift-modal .user-picker-placeholder {
	.column;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	width: @modal-right-width;
	height: calc(@modal-header-height + @modal-body-height);
	background-color: @light-blue;

	.user-picker-placeholder-image {
		width: 100px;
		margin: 0 0 24px 0;
	}

	.user-picker-placeholder-title,
	.user-picker-placeholder-text {
		width: 180px;
		text-align: center;
	}
}

///////////////////////////////////
// Written hours state styling
///////////////////////////////////

.shift-modal .shift-uneditable {
	position: absolute;
	right: 0;
	top: 0;
	width: @modal-right-width;
	height: calc(@modal-header-height + @modal-body-height);
}

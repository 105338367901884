@import (reference) 'styles/app/app.less';
@import (reference) '../../footer/styles/index.less';

@block-width: 468px;

.exports2-edit-save {
	text-align: center;
	// This should overwrite the last child logic of pagelayout
	// We made the pagelayout's bodyBottomPadding prop '0' so that other steps can display full body size
	padding: 0 0 24px 0 !important;

	.title-and-tour {
		display: flex;
		justify-content: space-between;
		width: @block-width;
		margin: 0 0 8px 0;

		.exports-edit-save-title {
			margin: 0 0 12px 0;
		}
	}

	.eitje-form-2 {
		padding: 60px 0 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		.eitje-form-2-field-name,
		.eitje-form-2-field-description {
			.border;
			width: @block-width;
			margin: 0 0 16px 0;
			background-color: @white;
		}

		.eitje-form-2-field-roles {
			width: @block-width;
			padding: unset;
			display: flex;
			flex-direction: column;
			align-items: center;

			.eitje-form-2-label {
				margin: 12px 0 !important;
				color: @black;
			}

			.eitje-button-select {
				@gap: 8px;
				display: inline-block;
				box-shadow: unset;
				width: @block-width;
				position: unset !important; // override default absolute, as inherited from the forms

				.eitje-button-select-button {
					.border;
					.border-radius;
					padding: 0 16px;
					min-width: unset;
					width: calc((@block-width - (2 * @gap)) / 3);
					margin: 0 @gap @gap 0;
					text-align: left;
					background-color: @white;

					&:nth-child(3n) {
						margin-right: unset;
					}

					.ant-checkbox-wrapper {
						margin: 0 4px 0 0;
					}
				}
			}
		}
	}

	.access-sentence-block {
		.border;
		.border-radius;
		.big-shadow;
		padding: 8px 12px;
		display: inline-block;
		background-color: @light-grey;
		width: @block-width;
		text-align: center;
		margin: 12px 0 0 0;
	}

	// TODO: Will be removed once we remove the old list select and its hack to display it correctly

	.list-picker-regular-container {
		width: @block-width;
	}
}

.access-sentence span {
	font-size: 12px;
	color: @dark-grey;
}

@import (reference) 'styles/app/app.less';

.label {
	.border();
	.border-radius();
	display: inline-flex;
	user-select: none;

	&-absolute {
		position: absolute;
	}
}

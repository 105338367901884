////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason why we don't want to use classes for typography,
// is that we would much rather use HTML tags like h4 and p instead –
// because they allow for more intuitive HTML / code & strongly force
// consistency on developers.
////////////////////////////////////////////////////

.eitje-text-primary {
	color: @black;
	font-size: 14px;
	letter-spacing: -0.2px;
	margin-bottom: 0px;
	&.dangerous {
		color: @medium-red;
	}
	&.eitje-blue {
		color: @medium-blue;
	}
}

.eitje-text-bold {
	.eitje-text-primary;
	.bold;
}

.eitje-text-secondary {
	.eitje-text-primary;
	.grey-text;
	font-size: 12px;
}

.emphasized-text-block {
	span {
		.eitje-text-secondary;
	}
	strong {
		color: @black;
	}
}

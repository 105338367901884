// In regards to inputs / switchs / etc
.default_label_style {
	.eitje-text-secondary;
	flex: 1;
}

.elementContainer {
	font-size: 14px;

	.eitje-label {
		.default_label_style;
	}
	.field-tooltip-container {
		position: absolute;
		right: 13px;
		bottom: 16px;
	}
}

// Default character counter
.char-counter {
	align-self: center;
	font-size: 10px;
	color: @dark-grey;
	margin-bottom: 0px;
	text-align: center;
}

.eitje-form-2-field {
	.char-counter {
		position: absolute;
		right: 24px;
		top: 50%;
		font-size: 10px;
		color: @dark-grey;
	}
}

.char-counter-nested {
	.char-counter;
	position: absolute;
	right: 0px;
	bottom: 0px;
	line-height: 22px;
}

// Boxed Field Container (bottom of Teams page)
.boxed-field-container {
	&.elementContainer {
		.row-with-border-and-padding(16px, 8px);
		.border;
		.border-radius;
		background-color: @white;
		box-shadow: none;
	}
}

.form-container-right {
	position: relative;

	p.char-counter {
		.char-counter-nested;
	}
}

.eitje-input-with-counter {
	p.char-counter {
		.char-counter-nested;
		right: 24px;
	}
}

// Standard Inputs
.default_popover_field_container {
	min-height: 68px;
	.border(bottom);
	.horizontal-padding(24px);
	.vertical-padding(12px);

	&.eitje-input-container {
		p {
			margin-bottom: 4px;
		}
	}

	p.char-counter {
		.char-counter;
	}
}

//Field that needs flexing
.flex-field-1 {
	.elementContainer {
		display: flex;
		width: 100%; // need to confirm this
		align-items: center;

		& > :first-child {
			flex: 1;
			&.eitje-label {
				margin-bottom: 0px;
			}
		}
	}
}

.flex-field-1 {
	display: flex;
	width: 100%; // need to confirm this
	align-items: center;

	& > :first-child {
		flex: 1;
		&.eitje-label {
			margin-bottom: 0px;
		}
	}
}

.flex-field-2 {
	.elementContainer {
		display: flex;
		align-items: center;
	}
}

// Color Picker
.elementContainer.eitje-color-picker-container {
	display: flex;
	align-items: center;
}

// Switch
.elementContainer.eitje-switch-container {
	display: flex;
	align-items: center;
}

// Input Number
.elementContainer.eitje-input-container:not(.default-field) {
	.eitje-input-number {
		width: 80px;
	}
}

// Color Picker
.elementContainer.eitje-color-picker-container {
	display: flex;
	align-items: center;
}

// Date Picker
.elementContainer.eitje-date-picker-container {
	.eitje-date-picker {
		width: 100%;
	}
}

// Password Input
.elementContainer.eitje-input-container {
	.ant-input-affix-wrapper-borderless {
		padding-left: 0px;
	}
}

//Hour Minute Picker
.elementContainer.eitje-hour-minute-picker-container {
	display: flex;
	align-items: center;
	min-height: fit-content;
	padding: 0px;

	.eitje-hour-minute-picker-input {
		display: flex;
		background-color: @light-grey;

		align-items: center;
		.border;
		.border-radius;
		min-width: 60px;
		max-width: 60px;

		.ant-input.ant-input-borderless {
			padding-right: 0px;
			min-height: 32px;
			&::placeholder {
				color: @black;
			}
		}

		.eitje-hour-minute-picker-input-left {
			text-align: right;
		}

		.eitje-hour-minute-picker-input-right {
			text-align: left;
		}
	}
}

// Input for Quiz items
.eitje-check-mark-input {
	display: flex;
	.transition;
	.elementContainer {
		.row;
		background-color: transparent;
		align-items: flex-end;
		.form-container-left {
			position: relative;
			flex: 1;
			p.char-counter {
				.char-counter-nested;
			}
		}
		.form-container-right {
			width: fit-content;
			display: grid;
			grid-template-columns: 33% 33% 33%;
			text-align: center;
			min-width: 75px;
			margin-bottom: 1px;
			p {
				.eitje-text-secondary;
				&:first-child {
					font-size: 9px;
					line-height: 20px;
					&.active-text-color {
						.active-text-color;
					}
				}
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

////////////////////////////////////
// General styling
////////////////////////////////////

.team-panel .user-row * {
	position: relative;
}

.team-panel .user-row {
	.border;
	.big-shadow;
	.border-radius(large);
}

.team-panel .shifts-overview div.list-container {
	padding: unset;
	overflow: unset;
}

////////////////////////////////////
// Shift row styling
////////////////////////////////////

.team-panel .shifts-overview .shift-item {
	.border;
	.border-radius;
	.big-shadow;
	.row;
	padding: 12px;
	justify-content: space-between;
	gap: 4px;
	margin: 4px 0 0 0;
	position: relative;

	&:hover {
		background: @light-blue;
	}

	.shift-item-day {
		width: 120px;
		font-size: 12px;
	}

	.shift-item-duration {
		width: 80px;
	}

	.shift-item-type {
		width: 90px;
	}

	.shift-item-env {
		.prevent-text-overflow;
		width: 100px;
		text-align: right;
	}
}

////////////////////////////////////
// No shifts styling
////////////////////////////////////

.team-panel .shifts-overview .no-shifts-placeholder {
	line-height: 40px;
	margin: 0 0 12px 0;
	text-align: center;
}

////////////////////////////////////
// State row styling
////////////////////////////////////

.team-panel .user-row .shifts-overview .flat-list {
	.list-item:last-child .shift-item {
		margin: 4px 0 12px 0;
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) './index.less';

@modal-footer-height: 72px;

/////////////////////////////
// general footer styling
/////////////////////////////

.eitje-modal-2-footer {
	.border(top);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	position: absolute;
	bottom: 0;
	z-index: 10; // to ensure eitje-form-2-field-icon doesnt go over
	background-color: @white;

	&-right {
		margin-left: auto;
	}
}

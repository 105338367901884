////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are against our styling principle of NOT defining classes
// with only a single property being set, because mixing in one-line classes
// is less clear than defining the property itself. The reason they are still
// here is to support legacy, so DO NOT use them yourself! Eventually we want
// to delete them from the project.
////////////////////////////////////////////////////

@import (reference) 'styles/app/styles/colors/colors.module.less';

.text-center {
	text-align: center;
}

.line-height-1 {
	line-height: 1;
}

.nowrap {
	white-space: nowrap;
}

.grey-text {
	color: @dark-grey;
}
.red-text {
	color: @medium-red;
}

.text-underlined {
	text-decoration: underline;
}

.text-italic {
	font-style: italic;
}

.text-multi-line {
	line-height: 18px !important;
}

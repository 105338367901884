@import (reference) 'styles/app/app.less';

///////////////////////////////
// General layout
///////////////////////////////

@modal-header-height: 64px;
@modal-body-height: 600px;
@modal-footer-height: 64px;

.registration-errors-modal {
	.eitje-modal-body(@modal-body-height);

	.registration-errors-header {
		.eitje-modal-header(@modal-header-height);
	}

	.registration-errors-footer {
		.eitje-modal-footer(@modal-footer-height);
	}
}

///////////////////////////////
// Modal styling
///////////////////////////////

.registration-errors-modal {
	.ant-modal-body {
		.border-radius;
		.registration-errors-description {
			margin: 24px 24px 0 24px;
		}

		.flat-list {
			margin: 0 24px 24px 24px;

			.list-item {
				.border;
				.border-radius;
				.big-shadow;
				margin: 0 0 4px 0;
			}
		}
	}
}

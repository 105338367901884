.empty-message {
	text-align: center;
	padding: 24px;
}

.block-title {
	.bold;
	.border;
	.border-radius;
	font-size: 12px;
	text-align: center;
	line-height: 28px;
	width: 100%;
}

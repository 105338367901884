@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/index.less';

@emoji-width: 75px;
@options-width: 110px;
@active-width: 48px;

.shift-types-modal {
	.eitje-modal-2-body {
		.modal-info-banner {
			margin: 8px 24px -8px 24px;
			width: auto;
		}

		.shift-types {
			.eitje-form-2-inputs-list;
			margin: 12px 24px;

			.eitje-form-2-row,
			.shift-type-header {
				display: flex;
				justify-content: space-between;

				h6 {
					color: @dark-grey;
				}
			}

			.shift-type-header {
				margin: 0 0 6px 0;
			}

			.eitje-form-2-field {
				padding: 0px;
				flex: initial;
			}

			.inputs-list-wrapper {
				width: 100%;

				.eitje-input input {
					.text-capitalize;
					margin-top: -3px;
				}
			}

			.shift-row-right {
				display: flex;

				h6 {
					&:nth-child(1) {
						width: @emoji-width;
					}

					&:nth-child(2) {
						width: @options-width;
					}

					&:nth-child(3) {
						width: @active-width;
					}
				}

				.checkbox-container {
					width: @active-width;
					text-align: right;
					margin: 6px 0 0 0;
				}

				.emoji-picker-dropdown {
					position: absolute;
					inset: auto auto 24px auto;
				}

				.list-picker-dropdown-container {
					display: flex;
					align-items: center;

					.popout-card-trigger {
						width: 104px;
					}
				}
			}
		}
	}
}

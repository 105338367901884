.hide-all-icons {
	.eitje-form-2-field-icon,
	.eitje-form-2-field-clear {
		display: none;
	}

	&:hover,
	&:active,
	&:focus {
		.eitje-form-2-field-icon,
		.eitje-form-2-field-clear {
			display: none;
		}
	}
}

.hide-icon {
	.eitje-form-2-field-icon {
		display: none;
	}

	&:hover,
	&:active,
	&:focus {
		.eitje-form-2-field-icon {
			display: none;
		}
	}
}

.hide-clear {
	.eitje-form-2-field-clear {
		display: none;
	}

	&:hover,
	&:active,
	&:focus {
		.eitje-form-2-field-clear {
			display: none;
		}
	}
}

@import (reference) 'styles/app/app.less';

.eitje-rich-text-editor-content {
    .tableWrapper {
        margin: 3rem 0;

        table {
            border-collapse: collapse;
            border-color: @medium-grey;
            border-radius: 0.25rem;
            box-sizing: border-box;
            width: 100%;
            margin: 24px 0 12px 0;
        }

        td,
        th {
            .border(@color: @medium-grey);
            min-width: 100px;
            padding: 0.5rem;
            position: relative;
            text-align: left;
            vertical-align: top;

            &:first-of-type:not(a) {
                margin-top: 0;
            }

            p {
                margin: 0;

                &+p {
                    margin-top: 0.75rem;
                }
            }
        }

        th {
            font-weight: bold;
        }

        td {
            &.has-focus {
                .border-radius(small);
                .border(@width: 2px, @color: @medium-blue, @type: outline);
                z-index: 10;
            }
        }

        // copied from: https://github.com/ueberdosis/tiptap-templates/tree/main/templates/next-block-editor-app
        .column-resize-handle {
            bottom: -2px;
            display: flex;
            pointer-events: none;
            position: absolute;
            right: -1px;
            top: 0;
            width: 2px;

            &::before {
                background-color: @dark-grey;
                height: 100%;
                width: 1px;
                margin-left: 2px;
                content: '';
            }
        }

        .selectedCell {
            background-color: @light-grey;
        }

        .grip-column,
        .grip-row {
            align-items: center;
            background-color: @light-grey;
            cursor: pointer;
            display: flex;
            justify-content: center;
            position: absolute;
            z-index: 10;

            &.selected {
                background-color: @midtone-grey;
                color: @dark-grey !important;
            }
        }

        .grip-column {
            .border(@side: left, @color: @midtone-grey);
            width: 100%;
            height: 12px;
            left: 0;
            margin-left: -1px !important;
            top: -14px;

            &:hover,
            &.selected {
                &::before {
                    width: 10px;
                    content: '';
                }
            }

            &:hover {
                background-color: @midtone-grey;

                &::before {
                    .border(@side: bottom, @width: 2px, @style: dotted, @color: @dark-grey);
                }
            }

            &.first {
                border-color: transparent;
                border-top-left-radius: 0.125rem;
            }

            &.last {
                border-top-right-radius: 0.125rem;
            }

            &.selected {
                &::before {
                    .border(@side: bottom, @width: 2px, @style: dotted, @color: @dark-grey);
                }
            }
        }

        .grip-row {
            .border(@side: top, @color: @midtone-grey);
            height: 100%;
            right: -14px;
            width: 12px;
            top: 0;
            margin-top: -1px !important;

            &:hover,
            &.selected {
                &::before {
                    height: 10px;
                    content: '';
                }
            }

            &:hover {
                background-color: @midtone-grey;

                &::before {
                    .border(@side: left, @width: 2px, @style: dotted, @color: @dark-grey);
                }
            }

            &.first {
                border-color: transparent;
                border-top-left-radius: 0.125rem;
            }

            &.last {
                border-bottom-left-radius: 0.125rem;
            }

            &.selected {
                &::before {
                    .border(@side: left, @width: 2px, @style: dotted, @color: @dark-grey);
                }
            }
        }
    }
}
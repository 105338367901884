.rounded-row {
	height: 50px;
	.row-with-border;
	.border-radius(large);

	.rounded-row-icon {
		width: 24px;
		min-width: 24px;
		height: 24px;
		margin-right: 12px;
	}

	.rounded-row-text {
		.prevent-text-overflow;
		color: @dark-grey;
		flex: 1;
	}

	.rounded-row-dropdown-icon {
		display: none;
		width: 12px;
		min-width: 12px;
		height: 12px;
		align-self: center;
	}

	&.dropdown {
		.pointer-cursor;
		.rounded-row-dropdown-icon {
			display: block;
		}
	}

	&.shadow {
		.white-background-and-big-shadow;
	}

	&.rounded-disabled {
		background: rgb(238, 238, 238) !important;
		text-align: center;
		justify-content: center;
		border: none;
		color: rgba(0, 0, 0, 0.25) !important;
		cursor: not-allowed !important;
		width: 100% !important;
		* {
			cursor: not-allowed !important;
		}
	}
}

@import (reference) 'styles/app/app.less';

.users-availability-table-row-first-cell {
	.border(right);
	height: 54px;
	padding-top: 2px;

	.user-hover-card {
		width: 100%;
	}

	.inner-cell-contents-wrapper {
		margin-top: 0px;
	}
}

////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are against our philosophy of defining
// multiple things within a class which BARELY occur to
// be both required at the same time, leading to using
// various styling aspects merely to intentionally using
// one. Therefore no longer use below classes and, eventually,
// delete them.
////////////////////////////////////////////////////

@import '../shadows.less';

.white-background-and-big-shadow {
	.big-shadow;
	background-color: @white;
}

.white-background-and-small-shadow {
	.small-shadow;
	background-color: @white;
}

.white-background-and-light-shadow {
	.light-shadow;
	background-color: @white;
}

@import (reference) 'styles/app/app.less';

//////////////////
// Shared item styling
//////////////////

.side-menu-v2 {
	.side-menu-group > .eitje-layout,
	.side-menu-item > .eitje-layout {
		.border(bottom);
	}

	.side-menu-item > .eitje-layout {
		padding-left: 32px !important;
	}

	.popout-card-trigger {
		flex-direction: column !important;
		gap: 0 !important;
	}
}

//////////////////
// Make all items 100% width
//////////////////

.side-menu-v2 {
	.popout-card,
	.popout-card a,
	.popout-card-trigger,
	.popout-card-trigger a {
		width: 100%;
	}
}

//////////////////
// type = "modal"
//////////////////

.modal-side-menu {
	.border(right);
	justify-content: flex-start !important;
	height: 100% !important;
	width: 100% !important;
	overflow-y: auto;
}

//////////////////
// type != "modal" (on page)
//////////////////

.page-side-menu {
	.border;
	.border-radius;
	background-color: @white;

	.popout-card {
		display: flex;
		width: 100%;
	}

	> .popout-card:last-child .eitje-layout {
		.unset-border;
		border-radius: 0 0 4px 4px;
	}
}

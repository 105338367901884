.boxed-row {
	.border-radius;
	.align-items-center;
	.border;
	.border-radius;
	display: flex;
	width: 100%;
	padding: 8px 16px;
	max-height: 40px;
	height: 40px;

	.boxed-row-icon {
		width: 24px;
		min-width: 24px;
		height: 24px;
		margin-right: 12px;
	}

	.boxed-row-text {
		.eitje-text-secondary;
		.prevent-text-overflow;
		flex: 1;
	}

	.boxed-row-dropdown-icon {
		display: none;
		width: 12px;
		min-width: 12px;
		height: 12px;
		align-self: center;
	}

	&.dropdown {
		.boxed-row-dropdown-icon {
			display: block;
		}
	}

	&.shadow {
		.white-background-and-big-shadow;
	}

	&.box-disabled {
		background: rgb(238, 238, 238) !important;
		text-align: center;
		justify-content: center;
		border: none;
		color: rgba(0, 0, 0, 0.25) !important;
		cursor: not-allowed !important;
		width: 100% !important;
		* {
			cursor: not-allowed !important;
		}
	}
}

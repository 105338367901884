@import (reference) 'styles/app/app.less';
@import (reference) 'components/shared/user_card/styles/index.less';

.approve-leave-right-panel .users-with-leave {
	.users-with-leave-title {
		padding: 24px 24px 0 24px;
	}

	.users-with-leave-subtitle {
		padding: 0 24px;
	}
}

/////////////////////////
// Style user list
/////////////////////////

.approve-leave-right-panel .list-container {
	padding: 0 12px;

	.grouped-list-group {
		.group-field-list-item {
			.border;
			.border-radius;
			background: @white;
			text-align: center;
			margin: 12px 0;

			h5 {
				.bold;
				color: @black;
				line-height: 28px;
			}
		}
	}

	&.list-container-empty {
		margin: 0 0 60px 0;
	}
}

/////////////////////////
// Style user list item
/////////////////////////

.approve-leave-right-panel .list-container .list-item {
	position: relative;

	.user-card .inner-cell-contents-wrapper {
		.user-card-as-list-item;
	}
}

@import (reference) 'styles/app/app.less';

.user-availability-table-container {
	.box-with-border;
	justify-content: space-between;
	margin-bottom: 12px;
}

////////////////////////////////////////////
// Row of the table
////////////////////////////////////////////

.user-availability-table-row {
	.border(bottom);
	display: flex;

	& > * {
		flex: 1;

		&:last-child {
			border: unset;
		}
	}

	.default-availability-cell-wrapper,
	.user-availability-table-row-item {
		.border(right);

		&:last-child {
			border: unset;
		}

		.availability-cell {
			border: unset;
		}
	}

	.default-availability-cell-wrapper.av-day-disabled {
		.availability-title-cell {
			background-color: @light-grey;
		}
	}
}

////////////////////////////////////////////
// Title cell: with day of current column
////////////////////////////////////////////

.availability-title-cell {
	.row-with-justify-center;
	.border(bottom);
	position: relative;
	height: 40px;

	.lock-icon {
		.square-size(11px);
		position: absolute;
		left: 20px;
	}

	p {
		.eitje-text-secondary;
	}
}

// Overwrite specifics for the availability cells on the user profile page,
// to fix problems on smaller screens

.user-page {
	width: 100%;
	.availability-title-cell .lock-icon {
		position: unset;
		margin: 0 4px 2px 0;
	}

	.availability-cell-remark-box,
	.availability-cell-time-box,
	.availability-cell-date-box {
		height: 18px !important;
		left: 8px;
		top: 46px !important;
	}

	.availability-cell-remark-box {
		width: calc(100% - 16px) !important;
	}

	.availability-cell-time-box,
	.availability-cell-date-box {
		top: 2px !important;
		flex-direction: row !important;
		gap: 4px;
		left: 2px;
	}

	.availability-cell-time-box {
		flex-wrap: wrap;
		min-height: 40px !important;
	}
}

////////////////////////////////////////////
// Regular cell of the table
////////////////////////////////////////////

.availability-status-cell {
	.row-with-justify-center;
	.align-items-center;
	position: relative;
	height: 80px;

	.av-template-return {
		.row-with-border;
		.justify-content-center;
		width: 46px;
		height: 34px;
		.border-radius;
	}
}

////////////////////////////////////////////
// Button within the cell
////////////////////////////////////////////

.user-availability-table-button {
	.justify-content-center;
	.pointer-cursor;
	margin: 12px 24px !important;
	padding: 0 !important;
	border: unset !important;
	box-shadow: none !important;

	.user-availability-table-row-item-button {
		.column;
		.border;
		.justify-content-center;
		.border-radius(large);
		.big-shadow;
		padding: 20px 12px;

		h5 {
			margin-top: 8px;
		}
	}
}

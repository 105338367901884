.text-lowercase {
	text-transform: lowercase;
	&:first-letter {
		text-transform: lowercase;
	}
}

.text-capitalize {
	// The first-letter selector requires the element being block or inline-block in order to work.
	// Also, the first letter should really be the first content (no `content: ''` insertion with ::before).
	// If you don't manage with CSS, use the JS utils.capitalize() helper instead.
	text-transform: lowercase;
	&:first-letter {
		text-transform: capitalize;
	}
}

.text-capitalize-first-letter {
	// The first-letter selector requires the element being block or inline-block in order to work.
	// Also, the first letter should really be the first content (no `content: ''` insertion with ::before).
	// If you don't manage with CSS, use the JS utils.capitalize() helper instead.
	&:first-letter {
		text-transform: capitalize;
	}
}

.unset-text-transform {
	text-transform: unset !important;
	&:first-letter {
		text-transform: unset !important;
	}
}

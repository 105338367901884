@import (reference) 'styles/app/app.less';

.debug-container {
	position: fixed;
	left: 0;
	right: 0;
	bottom: -100px;
	background: white;
	max-height: 0px;
	z-index: 100;
	background-color: @white;
	box-shadow: 0px 18px 58px 14px rgba(0, 0, 0, 0.4);
	transition: max-height 0.2s linear;
	padding: 24px;
	overflow: auto;
	resize: vertical;

	h2 {
		position: sticky;
		float: right;
		top: 0px;
	}

	& > ul > li > ul {
		columns: 2;

		li {
			break-inside: avoid-column;
		}
	}

	&-open {
		bottom: 0;
		max-height: 800px;
	}
}

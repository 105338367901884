@import (reference) 'styles/app/app.less';

.wage-period-picker {
	.eitje-button:first-of-type {
		z-index: 2;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.list-picker-trigger > div {
		border-radius: 0;
		margin: 0 -2px;

		&:hover {
			margin: 0;
			padding: 4px 6px !important;
		}
	}

	.eitje-button:last-of-type {
		z-index: 2;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}

.react-pdf__Document,
.react-pdf__Page {
    width: 100%;

    .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
    }
}

.react-pdf__Page {
    margin-bottom: 12px; // add margin to bottom of page to add margin between pages
}
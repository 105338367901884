@import (reference) 'styles/app/app.less';

////////////////////////////////////
// General layout styling
////////////////////////////////////

.revenue-group-week {
	.border(bottom, @light-border);
	height: 66px;

	.revenue-group-week-total,
	.revenue-group-day {
		.border(right, @light-border);
		vertical-align: top;
		width: 12.5%;
		display: inline-block;
		height: 66px;
	}
}

////////////////////////////////////
// Revenue group week styling (first column)
////////////////////////////////////

.revenue-group-week .revenue-group-week-total {
	display: inline-flex;
	justify-content: space-between;
	padding: 10px 12px 10px 16px;

	.revenue-group-week-total-title {
		.prevent-text-overflow;
		line-height: 46px;
		flex-grow: 1;
		font-size: 12px;
	}

	.labour-data {
		flex-grow: 0;
		flex-shrink: 0;
		text-align: right;

		.revenue-group-week-total-amount {
			font-size: 12px;
		}

		h6 {
			color: @dark-grey;

			b {
				.bold;
			}
		}
	}
}

////////////////////////////////////
// Revenue day styling (other columns)
////////////////////////////////////

.revenue-group-week .revenue-group-day {
	text-align: right;
	padding: 10px 12px;

	h4 {
		font-size: 12px;
	}

	h6 {
		color: @dark-grey;

		b {
			.bold;
		}
	}
}

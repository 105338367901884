@import (reference) 'styles/app/app.less';

@column-width: 312px;

.table-edit {
	.page-contents {
		display: flex;
		height: 100%;
		width: 100%;

		.center-page {
			padding: 140px 80px 0 36px;
			width: calc(100vw - @column-width);
			overflow-x: auto;
		}

		.table-header {
			position: fixed;

			.sort-select {
				display: flex;
				gap: 12px;
				margin: 20px 0 0 0;
				align-items: center;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.article-side-panel {
	width: fit-content;
	max-width: 280px;
	position: absolute;
	height: calc(100vh - 120px);
	top: 64px;
	padding-top: 33px;
	right: 0;

	.article-side-panel-container {
		.column;
		.horizontal-padding(20px);
		gap: 12px;

		.eitje-field-published {
			.flex-field-1;
		}

		.list-picker-dropdown-container .list-picker-trigger {
			max-width: 100%;
			min-width: 100%;
		}

		.emphasized-text-block .popout-card-trigger {
			max-width: 188px;
		}

		>div {
			width: 100%;
		}

		.rounded-row {
			.horizontal-padding(12px);

			.rounded-row-text {
				font-size: 12px;
			}
		}

		.file-item-container {
			.border-radius(large);
		}
	}
}

.article-list-footer {
	.row;
	gap: 24px;
	height: 68px;

	>* {
		flex: 1;
	}

	// TO DO: abstract below line away (Chris made a consideration)
	// drill 100%, also so other "TO DO" comments like these

	.popout-card-trigger {
		width: 100%;
	}
}

.article-editor-container {
	margin: 32px 0px;
	width: 880px;
	padding-bottom: 120px;

	.text-input-container {
		height: 50px;
	}

	.article-title-header {
		.rounded-row {
			.horizontal-padding(12px);
		}
	}
}

.article-edit-page-content {
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 1400px) {
	.article-edit-page-content {
		justify-content: flex-start;
		padding-left: 24px;
	}
}
@import (reference) 'styles/app/app.less';

.ant-select-selector,
.ant-select-selector:hover,
.ant-select-selector:active,
.ant-select.ant-select-focused {
	border-color: @medium-border;
	box-shadow: none;
}

.ant-select-arrow .dropdown_icon {
	background-image: url('/background_images/dropDown1.png');
	height: 12px;
	background-repeat: no-repeat;
	width: 12px;
}

.ant-select-borderless > * {
	padding-left: 0px !important;
	padding-right: 0 !important;
}

.ant-select-borderless {
	.ant-select-selection-placeholder {
		left: 0px;
	}
}

.ant-select-multiple {
	.ant-select-selection-item {
		.border-radius;
		.border;
		background-color: @light-grey;

		padding-inline-end: 8px;
		padding-inline-start: 12px;
		border: 1px solid transparent;
		.ant-select-selection-item-content {
			padding-top: 2px;
		}
	}
	.ant-select-selector {
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

.ant-select-arrow {
	top: 51%;
	background-size: 13px;
	background-repeat: no-repeat;
	background-image: url('/background_images/dropDown1.png');
	svg {
		display: none;
	}
}

.ant-select-item-option-state {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@import (reference) 'mixins/stretch';

.eitje-form-2-file-upload {
	.eitje-form-2-field-title-upload-container {
		.border;
		.transition;
		.pointer-cursor;
		height: 120px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0 24px;
		text-align: center;
		background-color: @light-background;

		.eitje-form-2-field-title-upload-icon svg {
			font-size: 40px; // Font size actually determines the size of the icon.
			color: @medium-blue;
			margin: 0 0 12px 0;
		}
	}

	.link-button-container {
		float: right;
		margin: 4px 0 0 0;
	}
}

@import (reference) 'styles/app/app.less';

.eitje-form-2-field.eitje-popout {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.popout-picker-items {
	display: flex;
	flex-wrap: wrap;
	width: 180px;
	gap: 4px;
	padding: 8px;
}

// Ant has a specific z-order in mind for their stock components, giving each
// component a differing z-index to control its display. But this does not work
// within eitje, since we don't always follow their recommended use. Thus set
// all z-indices equal, so that their z-position is determined by clicking &
// rendering order.

.ant-popover,
.ant-dropdown,
.ant-select-dropdown,
.ant-modal-wrap,
.ant-drawer,
.popout-wrapper {
	z-index: 1000 !important;
}

.center-notifications,
.notifications-container {
	z-index: 1002 !important;
}

@import (reference) 'styles/app/app.less';
@import (reference) 'index.less';

////////////////////////////////////
// Base styling
////////////////////////////////////

.team-panel-sick .past-sick-periods {
	width: 100%;
	height: 100%;

	.past-sick-periods-label {
		.team-panel-sick-label-mixin;
		margin: 12px 12px 4px 12px;
	}

	.past-sick-header {
		.row;
		padding: 12px 24px 12px 62px;

		.past-sick-header-title {
			width: 40%;
			font-size: 12px;
		}

		.past-sick-header-start,
		.past-sick-header-end {
			width: 30%;
			text-align: center;
			font-size: 12px;
		}
	}

	.flat-list {
		padding: 0;

		.past-sick-period {
			.border;
			.big-shadow;
			.border-radius(large);
			.row;
			width: calc(100% - 24px);
			margin: 0 12px 4px 12px;
			height: 54px;
			padding: 0 12px;
			position: relative;
			background-color: @white;

			.past-sick-period-title {
				width: 40%;

				h4,
				h5 {
					.prevent-text-overflow;
					font-size: 12px;
				}
			}

			.past-sick-period-start,
			.past-sick-period-end {
				width: 30%;
				text-align: center;
			}

			.past-sick-period-delete-button {
				position: absolute;
				right: 4px;
				top: 3px;

				.eitje-button-text {
					line-height: unset;
				}
			}
		}
	}
}

////////////////////////////////////
// State styling
////////////////////////////////////

.past-sick-empty {
	.empty-message;
}

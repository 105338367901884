@import (reference) 'styles/form/index.less';

.meal-allowance .meal-form-items {
	.eitje-form-2-inputs-list;

	.inputs-list-wrapper {
		width: calc(100% - 30px);
	}

	.meal-allowance-header {
		display: flex;
		justify-content: space-between;
		margin: 0px 8px 4px 0px;
	}

	.eitje-checkbox-container {
		text-align: right;
	}
}

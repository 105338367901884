@import (reference) 'styles/app/app.less';

.fixed-footer {
	position: fixed;
	bottom: 0;
	z-index: 12;
	width: 100vw;
	display: flex;
	align-self: flex-end;
}

// Page Menu
.page-menu-container {
	position: relative;
	height: @subheader-height;

	// not needed if we keep the 94px height of the submenu
	&.lg {
		height: 74px;

		.page-menu {
			height: 74px;
		}
	}

	.page-menu {
		.row;
		.border(bottom);
		width: 100%;
		height: @subheader-height;
		justify-content: space-between;
		z-index: 99;
		background-color: @white;
		position: fixed;
		padding: 0px 24px;
		.white-background-and-big-shadow;

		.page-menu-options {
			.row;
			width: fit-content;
			gap: 4px;
		}
	}
}

// // // // // right CONTAINER // // // // //
.right-container {
	.var(page-top-offset);
	top: @var-value;
	height: calc(100vh - @var-value) !important;
	position: fixed;
	height: inherit;
	background-color: @white;
	.border(left);
	width: 272px;
	right: 0;
}

// // // // // Section Header // // // // //
.section-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 24px 0px 26px 12px;
	.section-header-text-container {
		max-width: 648px;
		.section-header-text {
			.eitje-heading(@font-size[xl]);
			margin-bottom: 4px;
		}
		.section-header-sub-text {
			.eitje-text-secondary;
			.increased-line-height;
		}
	}
	&.md {
		.section-header-text-container {
			max-width: 648px;
		}
	}
	&.sm {
		.section-header-text-container {
			max-width: 550px;
		}
	}
}

// PERSONAL SETTINGS MENU //
.personal-settings-container {
	width: 200px;
	padding: 22px 12px;

	.personal-settings-links-container {
		.border(bottom);
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding: 12px 0px;

		> * {
			.pointer-cursor;
		}
	}

	> :last-child {
		border: none;
	}
}

@import (reference) 'styles/app/app.less';

.youtube {
  position: relative;
  // This is a common hack for responsive youtube videos
  // The percentage might differ based on the aspect ratio of your video
  // https://www.avexdesigns.com/blog/responsive-youtube-embed/
  padding-bottom: 51.85%;

  // For some unexplainable reason the only EXACT width that this hack does not work correctly is 390px
  // It shows a little dark line on the right side, because the ratio is a tiny bit off
  // We added a different percentage there for it to work as expected
  @media screen and (width: 390px) {
    padding-bottom: 52%;
  }

  iframe {
    .small-shadow;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
@import (reference) 'styles/app/app.less';

@header: 74px;

.planning-settings-modal {
	.settings-header {
		.eitje-modal-header(@header);
		.border-radius;

		div.eitje-dropdown-container {
			padding-right: unset;
		}
	}
}

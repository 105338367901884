@import (reference) 'mixins/stretch';
.eitje-form-2-tabs {
	.hide-icon;
	height: @field-height;
	text-align: center;

	.eitje-tabs {
		position: absolute;
		right: @field-horizontal-padding;
	}

	.eitje-form-2-tabs-no-label {
		position: relative;
		right: unset;
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) 'index.less';

.shift-modal .planning-shift-form-body {
	// don't use eitje-modal-body since that hides the scrollbar which cant be overwritten
	.border(right);
	.transition;
	height: @modal-body-height;
	margin-bottom: -6px;
	width: @modal-left-width;
	display: inline-block;
	overflow-x: hidden;
	overflow-y: auto;

	.planning-trade-status-trigger {
		width: 100%;
	}

	&-full-width {
		width: calc(@modal-left-width + @modal-right-width);
	}
}

.ant-steps-vertical {
	flex-grow: 4;
	justify-content: center;

	.ant-steps-item {
		flex-grow: 0;

		.ant-steps-item-icon,
		.ant-steps-item-tail {
			margin-top: 10px;
		}

		.ant-steps-item-title {
			.bold;
			font-size: 14px;
			height: 20px;
		}

		.ant-steps-item-description {
			font-size: 12px;
			margin-top: 5px;
			color: @dark-grey;
		}
	}
}

@import (reference) 'styles/app/app.less';

.provider-connection .eitje-title {
	max-width: 450px;

	// The size is set with .square-size(14px) from within components lib, probably done by Chris for the icon...
	// Simply unset for now. Eventually it shouldn't be set at all, but this influences many components, thus choose
	// this simply, local fix for now.
	.info-popout-container .eitje-text-wrapper {
		height: unset;
	}
}

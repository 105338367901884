@import (reference) 'styles/app/app.less';

////////////////////////////////////
// Left title column styling (column 1)
////////////////////////////////////

#planning-page .planning-shifts-row .planning-day-shifts-title {
	padding: 0 8px 0 0;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;

	.planning-day-shifts-title-content {
		display: flex;
		align-items: center;
		overflow: hidden;

		.planning-day-shifts-title-left-content {
			display: flex;
			align-items: center;
			width: calc(100% - 48px);
			padding: 0 0 0 6px;
			gap: 4px;

			.planning-day-shifts-team-title {
				width: calc(100% - 10px);
				display: flex;
				align-items: center;
				flex: 1;
				gap: 4px;
			}

			.planning-day-shifts-team-color {
				.square-size(20px);
				.border;
				.border-radius;
				border-color: @dark-grey;
				display: inline-block;
			}

			.team-name-text {
				max-width: calc(100% - 30px);
			}

			h4 {
				.prevent-text-overflow;
			}
		}

		.multi-edit-shifts-checkbox {
			float: right;
			height: 18px;
		}

		.modal-link-button {
			float: right;
			padding: 4px 8px;
			text-align: center;
			margin: 2px 0 0 0;
		}
	}
}

////////////////////////////////////
// Day column styling (column 2-8)
////////////////////////////////////

#planning-page .planning-shifts-row .planning-day-shifts {
	min-height: 47px;
	vertical-align: top;

	.new-shift-button {
		.border;
		.pointer-cursor;
		margin: 2px 4px 0 4px;
		.border-radius;
		height: 42.8px;
		display: block;
		background-color: @white;

		.new-shift-button-inner {
			.border;
			.bold;
			line-height: 26.8px;
			.border-radius;
			margin: 5.5px 0 0 8px;
			display: block;
			width: calc(100% - 16px);
			text-align: center;
			padding: 0 8px;
			white-space: nowrap;
			overflow: hidden;

			img {
				.button-icon;
			}

			h5 {
				.bold;
				display: inline-block;
				color: @black;
				line-height: 26.8px;
			}
		}

		.plus-icon {
			.square-size(10px);
			display: inline-block;
		}
	}

	.popout-card-trigger:not(.planning-trade-status-trigger) {
		display: block;
	}

	&-droppable {
		z-index: 2;
		outline: 2px dashed @medium-border;
	}

	&-undroppable {
		background: @light-background;

		.planning-shift {
			opacity: 0.25;
		}
	}

	&-drag-is-over {
		.border(@width: 2px, @style: dashed, @color: @medium-blue, @type: outline);
		z-index: 2;
		background: @light-blue;
	}
}

.spinner-container {
	position: relative;
	width: 100vw;
	height: calc(100vh - 63px);
	background-color: white;

	.ant-spin {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.has-onboarding-header .spinner-container {
	height: calc(100vh - 63px - 48px);
}

@import (reference) 'styles/app/app.less';

.eitje-date-picker {
	.big-shadow;

	// Hide the original ant picker, replace with a Layout of our own.
	.ant-picker {
		border: none;
		padding: 0px;
		width: 0px;
		height: 0px;
		line-height: 0;
		visibility: hidden;
		display: block;
	}
}

form .eitje-date-picker {
	box-shadow: unset;
}

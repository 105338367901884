@import (reference) 'styles/app/app.less';
@import (reference) './index.less';
@import (reference) 'styles/form/layouts/field.less';

////////////////////////////
// General styling
////////////////////////////

.time-registration-modal-body-right-column {
	.vertical-scroll;
	height: 100%;

	.user-picker {
		.border(left);
		margin-top: -@header-height;
		z-index: 10;
		position: absolute;
		overflow-y: scroll;
		height: calc(100% - @footer-height);

		.list-picker-regular-container {
			height: calc(100% - @header-height);
		}
	}

	.hours-overview {
		.border(left);
		height: 100%;
	}
}

////////////////////////////
// User search styling
////////////////////////////

.time-shift-modal .time-registration-modal-body-right-column {
	.search-input-container {
		height: @header-height;
		width: 240px;
		top: 0;
		position: sticky;
		padding: 0 12px;
	}

	.list-picker-regular-container .flat-list-picker {
		height: 100% !important;
	}
}

////////////////////////////
// User list styling
////////////////////////////

.time-registration-modal-body-right-column {
	.eitje-form-2-error {
		.validation-error-mixin;
		margin: 8px 8px 0 8px;
		text-align: center;
	}

	.flat-list-picker-item {
		.pointer-cursor;

		.user-picker-user-info {
			h5 {
				.bold;
				.prevent-text-overflow;
				color: @black;
				max-width: 176px;
			}

			p {
				font-size: 10px;
				color: @dark-grey;
			}
		}

		&-selected {
			background-color: @light-blue;
		}
	}
}

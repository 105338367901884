@import (reference) 'styles/app/app.less';

///////////////////////
// Info cards
///////////////////////

.approve-leave-modal .contract-info {
	.info-cards-top-row {
		.border(bottom);
	}

	.info-cards-bottom-row {
		margin-top: -1px;
		background: @white;
	}
}

///////////////////////
// Contracts warning
///////////////////////

.approve-leave-modal .contract-warnings {
	.border(bottom);

	// This logic cannot be handled by just the Layout since both the layout and
	// message have a width !important. The inline style that is used for the message  margin
	// is the reason we need an important here
	.message {
		flex: 1;
		width: unset;

		&:nth-of-type(2) {
			margin-left: 0px !important;
		}
	}
}

@import (reference) 'styles/app/app.less';

.confirm-modal {
	.confirm-modal-header {
		.border(bottom);
		padding: 24px;

		.modal-info-banner {
			margin-top: 0;
		}
	}
}

@import (reference) 'styles/app/app.less';

.period-settings-modal {
	.eitje-field-amt_periods,
	.eitje-field-min_periods_future,
	.eitje-field-max_periods_future,
	.eitje-field-close_monthday,
	.eitje-field-notify_days {
		.flex-field-1;

		.form-container-right .eitje-input-number {
			width: 100px !important;
		}
	}

	.ant-input-suffix {
		.eitje-text-secondary;
		line-height: 20px;
		align-items: flex-end;
	}

	.elementContainer.eitje-input-number {
		.eitje-input-container .ant-input-affix-wrapper.eitje-input-number {
			width: 99px;
		}
		.eitje-input-container .ant-input.eitje-input-number {
			width: 45px;
		}
	}

	.eitje-form .ant-select.ant-select-borderless.eitje-dropdown .ant-select-selector span.ant-select-selection-item {
		.book;
		line-height: 38px;
	}
}

.av-settings-banner {
	.row;
	justify-content: space-evenly;
	.border-radius;
	height: 98px;
	border: 2px dashed @medium-border;
	background-color: @light-grey;
}

.av-settings-date-block {
	display: flex;
	flex-direction: column;
	align-items: center;

	.av-settings-date-block-header {
		.row-with-justify-center;
		.eitje-text-secondary;

		margin-bottom: 4px;
		background-color: @white;
		height: 32px;
		min-width: 102px;
		.border;
		.border-radius;
	}
}

// Styling for rows, e.g. container classes in which elements are placed
// next to each other in a horizontal direction.

@import (reference) 'containers.less';
@import (reference) 'boxes.less';
@import (reference) '../elements/borders.less';

.row {
	display: flex;
	align-items: center;
	width: 100%;
}

.row-with-justify-center {
	.row;
	justify-content: center;
}

.row-with-justify-between {
	.row;
	justify-content: space-between;
}

.row-with-justify-start {
	.row;
	justify-content: flex-start;
	align-items: flex-start;
}

.row-with-justify-end {
	.row;
	justify-content: flex-end;
}

.row-with-border {
	.row;
	.box-with-border;
	// height: inherit; // skipped in taking over during changes: (broke shit?)
}

.row-with-border-and-padding(@horizontal-padding, @vertical-padding) {
	.row-with-border;
	.border-radius(large);
	padding-left: @horizontal-padding;
	padding-right: @horizontal-padding;
	padding-top: @vertical-padding;
	padding-bottom: @vertical-padding;
}

/*

/////////////////////////////////////////////////////////////////

IMPORTANT

Below files are merely legacy support, don't add to them, or depend
on then, since the goal is to get rid of them as soon as possible!

/////////////////////////////////////////////////////////////////

*/

.urenTd .regKaartje {
  display: block !important;
}

.unclickable {
  opacity: 0.5;
}

.Table {
  width: 100%;
  min-width: 1164px;
  border-collapse: collapse;
  display: inline-table;
}

.verlofContainer table {
  width: 100%;
  min-width: 1100px;
}

.dayShiftTime p {
  font-size: 10px;
  color: #828282;
  font-weight: 500;
}

.meerdereShifts {
  width: 140px;
  overflow: hidden;
}

.meerdereShifts .cardUserRooster {
  padding-top: 2px;
  overflow: hidden;
  width: 100%;
}

.clash.actief {
  opacity: 1 !important;
}

.toevoegen {
  display: none;
}

.border {
  border-spacing: 0px;
  border: 1px solid #eee;
}

.tableBody {
  border-bottom: 1px solid #eee;
  background-color: white;
  height: 42px;
  width: 100% !important;
  display: table;
}

.tableBodyWeather {
  height: 32px;
  background-color: white;
  margin-bottom: 4px;
}

.tableHeader {
  height: 52px;
  background-color: white;
}

.tableHeader td td p {
  font-weight: 600;
}

.marginLeft {
  margin-left: 12px;
}

.userTotalHours {
  text-align: center;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 11px;
  font-weight: 500;
  vertical-align: middle;
  cursor: default;
}

.tableBody td,
.daysText td {
  width: 12.5%;
}

.tableBody td p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.urenTd,
.cardUser {
  width: 11.11% !important;
  height: 42px !important;
  border-bottom: 1px solid #eee;
}

.plusMin td {
  width: 12.66% !important;
}

.td9 td {
  width: 11.11% !important;
}

.roosterBtn {
  margin-top: 4px;
  float: left;
  margin-left: 4px;
}

.roosterBtnRight {
  margin-top: 4px;
  margin-left: 4px;
}

.modalDagBox {
  width: 148px;
  border: 1px solid #eee;
  height: 40px;
  border-radius: 4px;
  padding-top: 8px;
  padding-left: 8px;
}

.modalDagBox180 {
  width: 180px;
  border: 1px solid #eee;
  height: 32px;
}

.modalDagBox336 {
  width: 324px;
  border: 1px solid #eee;
  height: 32px;
}

.modalDagBox368 {
  width: 368px;
  border: 1px solid #eee;
  height: 32px;
}

.modalDagBoxLarge {
  width: 220px;
  border: 1px solid #eee;
  height: 32px;
  border-radius: 4px;
  padding-top: 4px;
  padding-left: 8px;
}

.modalOpmerkingBoxLarge {
  width: 448px;
  border: 1px solid #eee;
  min-height: 56px;
  border-radius: 4px;
  padding-top: 4px;
  padding-left: 8px;
}

.modalDagBox p,
.modalDagBoxLarge p,
.modalOpmerkingBoxLarge p {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.65);
  padding-left: 8px;
  padding-top: 4px;
}

.btnsRight {
  margin-right: 6px;
}

.delShift:hover {
  color: #0496ff !important;
}

.teamP {
  float: left;
  margin-top: 13px;
  margin-left: 4px;
  color: #2b2b2b;
  font-size: 12px;
}

.teamBox {
  margin-left: 16px;
  float: left;
}

.notif {
  margin-top: 5px;
  margin-right: 2px;
  float: right;
  cursor: pointer;
  font-size: 8px;
  color: #797979;
  font-weight: bold;
  line-height: 0px;
  padding-top: 4px;
  padding-left: 2.4px;
}

.activeTeams {
  margin-left: 32px;
  margin-right: -8px;
}

.daysText {
  cursor: default;
  text-align: center;
  color: #828282;
  font-weight: 600;
  text-transform: uppercase;
}

.noCaps {
  text-transform: initial !important;
}

.weatherText {
  cursor: default;
  padding-top: 2.5px;
  font-size: 9px;
  text-align: center;
  text-transform: uppercase;
}

.MTop {
  margin-top: 12px;
}

.cardText {
  font-weight: medium;
}

.meerdereShifts {
  display: table-cell;
  width: 11.11%;
}

.meerdereShifts td {
  display: contents;
}

.cardUser {
  cursor: default;
  padding-left: 2px;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 0px;
  border: 1px solid #eee;
  background-color: #fff;
  width: 98%;
  height: calc(98% - 4px);
  margin-top: 2px;
  margin-left: 1%;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 2px;
  border-radius: 4px;
}

.cardUserBesch {
  cursor: default;
  padding-left: 2px;
  overflow: hidden;

  padding-bottom: 0px;
  border: 1px solid #eee;
  background-color: #fff;
  width: 98%;
  height: calc(98% - 2px);
  margin-top: 1px;
  margin-left: 1%;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 2px;
  border-radius: 4px;
}

.centerUserCard {
  display: table-cell;
  vertical-align: middle;
  word-break: break-word;
}

.avatarTable {
  width: 24px;
  float: left;
  height: 24px;
  border-radius: 12px;
  margin-top: 1px;
  margin-right: 6px;
  object-fit: cover;
}

.avatarVerlof {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #005c9e;
}

.avatarLines {
  width: 28px;
  float: left;
  height: 28px;
  border-radius: 14px;
  margin-top: 3px;
  margin-right: 8px;
  border: 1px solid #005c9e;
}

.cardUserRooster {
  cursor: pointer;
  background-color: #ebf6ff;
  padding-left: 8px;
  padding-top: 4px;
  border: 1px solid #eee;
  width: 98%;
  height: 36px;
  margin-top: 2px;
  margin-left: 1%;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 2px;
  border-radius: 4px;
}

.cardEventRooster {
  cursor: pointer;
  background-color: #fbf5ef;
  padding-left: 4px;
  padding-top: 4px;
  border: 1px solid #eee;
  width: 98%;
  height: 36px;
  margin-top: 2px;
  margin-left: 1%;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 2px;
  border-radius: 4px;
}

.cardUserDetails {
  cursor: default;
  background-color: #ebf6ff;
  padding-left: 8px;
  padding-top: 4px;
  border: 1px solid #eee;
  width: 98%;
  height: 36px;
  margin-top: 2px;
  margin-left: 1%;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 2px;
  border-radius: 4px;
}

.cardOpenRooster {
  cursor: pointer;
  background-color: #fff;
  padding-left: 8px;
  padding-top: 4px;
  border: 1px solid #eee;
  width: 98%;
  height: 36px;
  margin-top: 2px;
  margin-left: 1%;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 2px;
  border-radius: 4px;
}

.cardConflictRooster {
  background-color: #ffe8e9;
}

.cardUserRooster:hover,
.cardEventRooster:hover {
  background-color: #fcfaed;
}

.cardOpenRooster:hover {
  background-color: #fcfaed;
}

.cardUserUren {
  cursor: pointer;
  padding-left: 8px;
  border: 1px solid #eee;
  background-color: white;
  width: 98%;
  height: 36px;

  font-size: 11px;
  font-weight: 500;

  border-radius: 4px;
}

.cardUserUren:hover {
  background-color: #fcfaed;
}

.cardUserUrenDetails {
  cursor: default;
  padding-left: 8px;
  padding-top: 4px;
  border: 1px solid #eee;
  background-color: white;
  width: 98%;
  height: 36px;
  margin-top: 2px;
  margin-left: 1%;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 2px;
  border-radius: 4px;
}

.detailTD td {
  width: 12.5%;
}

.avatarDropShift {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: -4px;
  margin-right: 8px;
  border: 1px solid #005c9e;
  object-fit: cover;
}

.skillsPublish {
  text-align: right;
  vertical-align: middle;
  height: 100%;
}

.skillsPublish img,
.skillsPublish div {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-right: 4px;
}

.notPublished {
  width: 12px;
  height: 12px;
}

.skillSetBadge {
  margin-top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  text-align: center;
  vertical-align: center;
}

.skillSetBadge p {
  font-size: 8px;
  color: white;
  font-weight: 600;
  margin-left: -1px;
}

.typeShiftImg {
  float: left;
  margin-top: -10px;
  margin-left: 10px;
  width: 10px !important;
  height: 10px !important;
}

.noIcon .ant-time-picker-icon {
  display: none !important;
}

.noUserAvatar {
  cursor: pointer;
  margin-top: 1px;
  margin-left: -4px;
  margin-right: 8px;
  background-color: #ff7c7c;
}

.newShiftPop {
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 1px;
  width: 192px;
  padding: 4px 4px 4px 12px;
}

.footerPopShift {
  padding: 4px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  text-align: center;
  margin-bottom: 4px;
}

.w33 {
  width: 33%;
}

.smallShiftOverflow {
  max-height: 220px;
  overflow-y: scroll;
  padding-top: 4px;
}

tr.plusMin .detailsCard.correcties:hover {
  /*  background-image: url("/images/hoverCor.png");
*/
  background-size: 18px 16px;
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-color: #fcfaed;
  cursor: pointer;
}

tr.plusMin .detailsCard:hover {
  background-color: #fff;
  cursor: default;
}

.tableTop tr {
  vertical-align: top !important;
}

.inactiveDay {
  color: #2b2b2b;
  margin-right: 8px;
  cursor: pointer;
  font-size: 10px;
  text-transform: uppercase;
}

.activeDay {
  color: #0496ff;
  margin-right: 8px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.totalShiftUser {
  position: relative;
  margin-top: -10px;
  margin-left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f5f8fa;
  border: 1px solid #ccc;
}

.totalShiftUser p {
  font-size: 7px;
  margin-left: 3.2px;
  margin-top: -0.5px;
}

.userTimeName p {
  cursor: pointer;
  margin-top: -1px;
  font-size: 11px;
  font-weight: 500;
  /* line-height: 15px; this was breaking line height for the hoursWorked card on uren pages */
}

.cardToevoegen {
  padding-top: 10px;
  border: 1px solid #eee;
  background-color: #fff;
  width: 98%;
  height: 36px;
  margin-left: 1%;
  text-align: center;
  display: none;
  border-radius: 4px;
}

.cardToevoegen:hover {
  background-color: #fcfaed;
}

.cardToevoegen p {
  margin-top: -1px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
}

.cardTeam {
  cursor: default;
  padding-left: 8px;
  width: 98%;
  margin-left: 1%;
  font-size: 11px;
  font-weight: 500;
  word-wrap: break-word;
}

#planning-page .Table .cardTeam span {
  color: #828282;
}

.emptyBody {
  height: 8px;
  background-color: #fff;
  border-collapse: separate;
}

.tdBorderRB {
  border-right: 1px solid #eee;
  vertical-align: top;
  max-width: 12.5%;
}

.tdBorderRB:first-child {
  vertical-align: middle;
}

.shiftsPerPersoon {
  font-size: 9px;
  color: #797979;
  font-weight: normal;
}

.cardBeschikbaar {
  cursor: pointer;
  padding-left: 8px;
  border: 1px solid #eee;
  background-color: #eef9f6;
  width: 98%;
  height: 36px;
  margin-top: 0px;
  margin-left: 1%;
  border-radius: 4px;
}

.vd {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 4px;
  display: inline-block;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.groenVD {
  background-color: #25d9af;
}

.oranjeVD {
  background-color: #ff9a2a;
}

.roodVD {
  background-color: #ff2a38;
}

.conflictBol {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: -16px;
  top: 17px;
  position: relative;
  margin-right: 0px;
  padding-right: 0px;
}

.blauw {
  background-color: #0496ff;
}

.purple {
  background-color: #b56ff7;
}

.bolletjeGroen {
  vertical-align: middle;
  margin-top: 11px;
  margin-right: 12px;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: #25d9af;
}

.bolletjePaars {
  vertical-align: middle;
  margin-top: 11px;
  margin-right: 12px;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: #b56ff7;
}

.cardOnbeschikbaar {
  cursor: pointer;
  padding-left: 8px;
  border: 1px solid #eee;
  background-color: #fbf0f0;
  width: 98%;
  height: 36px;
  margin-top: 0px;
  margin-left: 1%;
  border-radius: 4px;
}

.bolletjeRood {
  vertical-align: middle;
  margin-top: 11px;
  margin-right: 12px;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: #ff2a38;
}

.notifRood {
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: #ff0020;
}

.notifBlauw {
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: #0496ff;
}

.kleinFont {
  font-size: 8px !important;
}

.cardDeelBeschikbaar {
  cursor: pointer;
  padding-left: 8px;
  border: 1px solid #eee;
  background-color: #fbf5ef;
  width: 98%;
  height: 36px;
  margin-top: 0px;
  margin-left: 1%;
  border-radius: 4px;
}

.bolletjeOranje {
  vertical-align: middle;
  margin-top: 11px;
  margin-right: 12px;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  margin-top: 10.5px;
  width: 12px;
  background-color: #ff9a2a;
}

.vasteDagen p {
  font-weight: 500;
}

.vasteDagen span {
  font-size: 10px;
  cursor: pointer;
  color: #ddd;
  margin-right: 35px;
}

.vasteDagenAantal {
  font-weight: 500;
  color: #0496ff !important;
}

.vasteDagenAantal span {
}

.divBeschikbaar {
  margin-top: 6px;
}

.beschikbaarheidOpen {
  font-size: 9px;
  display: inline;
  width: 100%;
  font-weight: 400;
  color: #828282;
  padding-top: 2px;
}

.vanTot {
  font-size: 8px;
  margin-top: -4px;
}

.headerRoosterBar {
  width: 100%;
  min-width: 1100px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 46px;
}

.weekSwitch {
  float: left;
  width: 40px !important;
}

.weekView {
  float: left;
  background-color: #fff;
  border: 0.5px solid #eee;
  width: 136px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.weekView p {
  color: #2b2b2b;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

.shiftsText {
  color: #0496ff;
  text-transform: uppercase;
  font-size: 10px;
  padding-right: 4px;
  cursor: pointer;
}

.noLBorder {
  margin-left: -1px;
}

.open div {
  cursor: pointer;
}

.modalStyle {
  padding: 12px 24px;
}

.modalStyle .ant-modal-body {
  padding: 12px 24px;
}

.modalStyle .ant-modal-body .modalOverflow {
  padding: 0px;
}

.modalStyle p {
  font-size: 10px;
  color: #2b2b2b;
}

.w49 {
  width: 49%;
}

.marginModal {
  margin-bottom: 12px;
}

.beschikbaarheid {
  cursor: pointer;
  margin: 2px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 4px;
  background-color: white;
}

.beschikbaarStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  top: 1px;
  float: right;
}

.alreadyWritten {
  border-radius: 4px;
  background-color: #ff0020;
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.beschikbaarheid p {
  cursor: pointer;
  font-size: 10px;
  color: #2b2b2b;
  font-weight: 500;
  line-height: 12px;
}

.beschikbaarheid span {
  cursor: pointer;
  font-size: 10px;
  color: #2b2b2b;
  font-weight: 400;
}

.NothingTeamPanel {
  font-size: 10px;
  color: #2b2b2b;
  font-style: italic;
  text-align: center;
  padding-top: 8px;
}

.flexCard {
  padding: 4px;
  text-align: center;
  border: 1px solid #eee;
  background-color: white;
  font-weight: 500;
  font-size: 10px;
}

.functionBeschHolder {
  width: 13px;
  height: 13px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: white;
  display: inline-block;
  margin-left: 2px;
  text-align: center;
}

.functionActive {
  background-color: #5546c9;
}

.functionBeschHolder span {
  color: #828282;
  font-size: 7px;
  font-weight: 500;
  top: -2px;
  position: relative;
}

.functionActive span {
  color: white;
  font-weight: 600;
}

.beheerTeams {
  font-size: 8px;
  font-style: italic;
  position: relative;
  top: 12px;
}

.groen {
  background-color: #f6fdf6;
}

.rood {
  background-color: #ff002015;
}

.oranje {
  background-color: #fbf5ef;
}

.lichtblauw {
  background-color: #f4faff;
}

.opacityDisabled {
  opacity: 0.2;
}

.can-drop {
  border-color: #85e0cc;
}

.centerBeschikbaar {
  margin-left: 50px;
}

.invalid {
  border: 1px red solid;
}

.roodReg {
  color: red;
  background-color: #f7b9bb;
}

.groenReg {
  background-color: #bdf3e7;
  color: #25d9af;
}

.exportReg {
  background-color: white;
  color: #25d9af;
}

.checkinReg {
  background-color: #ffedac50;
  color: #25d9af;
  cursor: default !important;
}

.oranjeReg {
  background-color: #ffe0bf;
  color: #ff8d2a;
}

.blauwReg {
  background-color: #b3dfff;
  color: #0496ff;
}

.activeGreen .activeRed {
  opacity: 1 !important;
}

.hoursWorked {
  margin-top: -3px !important;
  margin-left: 0px;
  line-height: 26px;
  font-weight: normal !important;
}

.diffTime {
  margin-top: -6px !important;
}

.detailsCard {
  padding-top: 9px;
  text-align: center;
  background-color: white;
}

.detailsCard {
  padding-top: 9px;
  text-align: center;
  background-color: white;
}

tr.total td div {
  background-color: #e2f0fb;
}

.activeButton {
  background-color: #ecf6fd !important;
  cursor: default !important;
}

.activeText {
  color: #0496ff !important;
  font-weight: 600;
}

.urenContainer {
  display: inline-block;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: #f5f8fa;
  text-align: center;
}

.userUren {
  height: 100%;
  position: fixed;
  width: 100%;
}

.omzetBar {
  float: right;
  height: 36px;
  margin-top: -36px;
  background-color: #fff;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-top-left-radius: 120px;
  padding-left: 12px;
}

.omzetBar img {
  width: 24px;
  height: 24px;
  margin-top: 6px;
  margin-left: 12px;
  cursor: pointer;
}

.moneyFooterData {
  text-align: right;
  display: inline-block;
  border-left: 1px solid #eee;
  height: 60px;
  max-height: 60px;
  vertical-align: middle;
}

.moneyFooterData .omzetData {
  padding: 8px 8px;
}

.moneyFooterData p {
  font-weight: 600;
  color: #828282;
  font-size: 11px !important;
  line-height: 11px;
}

.moneyFooterData span {
  color: #828282;
  font-size: 10px;
  line-height: 14px;
}

.totalFooterData span {
  font-size: 8px;
  color: #828282;
  display: inline-block;
  margin-right: 2px;
  line-height: 10px;
}

.moneyFooterData img {
  width: 12px;
  height: 14px;
  margin-left: 6px;
  margin-top: 14px;
}

.noContractLink p:hover {
  color: #0496ff !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.moneyFooterData .countEmployee {
  color: white;
  font-size: 6px;
  font-weight: 600;
  line-height: 15px;
}

.moneyFooterData .noContractWarning {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff0020;
  text-align: center;
  position: relative;
  top: -11px;
  left: 15px;
}

.moneyFooterData .dataEmpty {
  padding: 6px 0px;
}

.moneyFooterData .dataEmpty p {
  font-size: 7px !important;
}

.footerDataUren {
  width: 11.11%;
}

.omzetBar .ant-btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.urenOutline {
  width: 33.33%;
  background-color: #fff;
  float: left;
  margin-top: -5px;
}

.filterOmzet {
  padding: 7px 22px;
  color: #0496ff;
  border: 1px solid #0496ff;
  margin-left: -1px;
  cursor: pointer;
  font-size: 9px;
  background-color: #0496ff21;
}

.omzetBar .arrowDown {
  border: solid #828282;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 8px;
  cursor: pointer;
  margin-top: 7px;
}

.omzetBar .arrowUp {
  border: solid #828282;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin-left: 8px;
  cursor: pointer;
  margin-top: 12px;
}

.omzetDownButton {
  float: right;
  width: 28px;
  height: 27px;
  margin-top: 4px;
  margin-right: 15px;
  border-left: 1px solid #eee;
  padding-left: 8px;
}

.omzetDownButton:hover .arrowDown {
  border-color: #0496ff;
}

.omzetDownButton:hover .arrowUp {
  border-color: #0496ff;
}

.filterOmzet:first-child {
  margin-left: 0px;
}

.omzetInactive {
  opacity: 0.4;
  background-color: transparent;
}

.omzetRooster {
  height: 32px;
}

.headerUrenUser {
  padding: 16px;
  text-align: center;
  background-color: white;
  border-bottom: 1px solid #eee;
}

.headerUrenUser p {
  font-weight: 500;
  color: #0496ff;
}

.regKaartUser p {
}

.openReg div p {
  cursor: pointer;
}

table.beschikbaarheidTD td {
  width: 11% !important;
}

.Table td {
  background-color: white;
}

table.saldoHeader td:nth-child(2) {
  width: 50%;
}

td.saldoGraph {
  width: 50% !important;
}

div.pmLijntje {
  position: relative;
  display: inline-block;
  height: 42px;
  width: 1px;
  float: left;
  border-left: 1px solid #eee;
}

div.pmLijntje span {
  color: #0496ff;
}

div.saldoLijntje {
  transition: 1.5s all;
  width: 0;
  border-radius: 0px 50px 50px 0px;
}

@media screen and (max-width: 2000px) {
  .centerBeschikbaar {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1900px) {
  .centerBeschikbaar {
    margin-left: 44px;
  }
}

@media screen and (max-width: 1800px) {
  .centerBeschikbaar {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1700px) {
  .centerBeschikbaar {
    margin-left: 38px;
  }
}

@media screen and (max-width: 1600px) {
  .centerBeschikbaar {
    margin-left: 32px;
  }
}

@media screen and (max-width: 1500px) {
  .centerBeschikbaar {
    margin-left: 28px;
  }
}

@media screen and (max-width: 1400px) {
  .centerBeschikbaar {
    margin-left: 22px;
  }
}
@media screen and (max-width: 1300px) {
  .centerBeschikbaar {
    margin-left: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .centerBeschikbaar {
    margin-left: 14px;
  }
}

@media screen and (max-width: 1100px) {
  .centerBeschikbaar {
    margin-left: 10px;
  }
  .body_overzicht .rightContainer {
    left: 97%;
  }
}

@media screen and (max-width: 1100px) {
  .verlofContainer table {
    width: 97%;
    min-width: 1100px;
  }

  .plusverlofNav {
    width: 97%;
    min-width: 1100px;
  }
}

.planModusBox {
  max-width: 184px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 40px;
}

.teamPanel p {
  font-size: 12px;
}

.upDownPerfect img {
  width: 16px;
  height: 16px;
  margin-top: 8px;
}

.headerContractUren {
  padding: 12px 0px 2px 0px;
  border-bottom: 1px solid #eee;
}

.headerContractUren p {
  display: inline-block;
  font-size: 10px;
  color: #828282;
}

.footerFilterPanel {
  position: sticky;
  bottom: 0px;
  padding: 12px;
  background-color: white;
  border-top: 1px solid #eee;
  width: 340px;
  margin-left: -24px;
  margin-top: 12px;
}

.footerFilterPanel p {
  color: #0496ff;
  font-size: 12px;
  font-weight: 500;
  margin-top: 6px;
}

.envRoosterHeader {
  background-color: white;
  padding: 12px 12px;
  border-top: 1px solid #eee;
  width: 100%;
  margin-bottom: -4px;
  margin-top: 4px;
}

.envRoosterHeader h1 {
  font-size: 15px;
  margin: 0px;
}

.Select--multi .Select-value {
  display: none;
}

#planning-page table tbody tr td.tdBorderRB {
  max-width: 160px;
}

.manage-team-status-modal .ant-transfer-operation button {
  background-color: #e8f5ff !important;
}

.manage-team-status-modal .ant-transfer-operation button svg {
  color: #828282 !important;
}

.manage-team-status-modal .ant-transfer-list {
  max-width: 160px;
}

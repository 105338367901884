@import (reference) 'styles/app/app.less';

.conflict-form {
	// height: auto !important; // grow automatically
	// max-height: 460px;

	.conflict-form-subheader {
		.row;
		justify-content: space-between;
		align-items: center;
		margin: 0 24px 0 36px;
		width: calc(100% - 60px);
		height: 40px;

		.select-all-container {
			display: inline-block;

			h6 {
				display: inline-block;
				color: @dark-grey;
				margin: 0 4px 0 0;
			}
		}
	}
	.conflict-row {
		.border;
		.border-radius;
		.big-shadow;
		.row;
		margin: 0 24px 4px 24px;
		width: calc(100% - 48px);
		padding: 8px 24px 8px 12px;

		.conflict-row-text-container {
			flex: 1 1;
		}

		.eitje-form-2-field {
			padding: unset;
			min-width: unset;
			flex: unset;

			.eitje-form-2-field-icon,
			.eitje-form-2-field-clear {
				display: none;
			}
		}
	}
}

@import (reference) 'styles/app/styles/colors/colors.module.less';

@color-circle-base-size: 28px;
@color-circle-base-text-size: 12px;
@color-circle-small-size: 16px;
@color-circle-small-text-size: 10px;
@color-circle-xs-size: 12px;
@color-circle-xs-text-size: 8px;

.color-letter-circle {
	.row-with-justify-center;
	width: @color-circle-base-size;
	min-width: @color-circle-base-size;
	height: @color-circle-base-size;
	min-height: @color-circle-base-size;
	.border-radius(round);

	.bold;
	text-transform: uppercase;
	color: @white;
	font-size: @color-circle-base-text-size;

	&.rounded {
		.border-radius;
	}
	&.sm {
		width: @color-circle-small-size;
		min-width: @color-circle-small-size;
		height: @color-circle-small-size;
		min-height: @color-circle-small-size;

		font-size: @color-circle-small-text-size;
	}

	&.xs {
		width: @color-circle-xs-size;
		min-width: @color-circle-xs-size;
		height: @color-circle-xs-size;
		min-height: @color-circle-xs-size;

		font-size: @color-circle-xs-text-size;
	}
	&.outline {
		border-width: 1px;
		border-style: solid;
	}
}

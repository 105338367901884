@import (reference) 'mixins/stretch';

.eitje-form-2-days-picker {
	.hide-all-icons;
	// TODO: We want to add this minheight to every form field, but this will require some more testing
	min-height: @field-height;

	.eitje-button-select {
		position: absolute;
		left: 24px;
		top: 38px;
	}
}

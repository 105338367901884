@import (reference) 'styles/app/app.less';

.drag-drop-container {
	height: 96px;
	border: 3px dashed @medium-border;
	background-color: transparent;
	text-align: center;
	display: flex;
	.border-radius(large);
	align-items: center;
	box-shadow: none;
	.transition;
	.grey-text;

	* {
		.transition;
		.grey-text;
	}

	.drag-drop-container-title {
		.bold;
		.grey-text;
	}
	.drag-drop-container-sub-title {
		.eitje-text-secondary;
	}

	&:hover {
		.white-background-and-big-shadow;

		.drag-drop-container-title {
			color: @black;
		}
	}
}

@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .green-icon {
        .icon-dot-small-left * {
            fill: @medium-green;
        }
    }

    .red-icon {
        .icon-dot-small-left * {
            fill: @medium-red;
        }
    }
}
@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .str-chat {
        --str-chat__background-color: 'transparent';
        --str-chat__message-input-background-color: 'transparent';
        --str-chat__primary-color: @medium-blue;
        --str-chat__active-primary-color: @dark-blue;
        --str-chat__surface-color: @medium-border;
        --str-chat__message-textarea-background-color: @white;
        --str-chat__message-textarea-border-radius: 4px;
        --str-chat__message-bubble-border-radius: 8px;
        --str-chat__message-bubble-background-color: @white;
        --str-chat__own-message-bubble-background-color: @light-blue;
        --str-chat__channel-search-input-wrapper-border-radius: 4px;
        --str-chat__channel-search-input-wrapper-active-border-radius: 4px;
        --str-chat__channel-search-input-wrapper-active-color: @medium-blue;
        --str-chat__channel-search-input-wrapper-active-background-color: @light-blue;
        --str-chat__attachment-preview-list-border-radius: 0px;
    }
}
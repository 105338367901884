@trash-confirm-base-width: 46px;
@trash-confirm-base-height: 40px;
@trash-confirm-small-width: 46px;
@trash-confirm-small-height: 32px;

.trash-confirm {
	.row-with-justify-center;
	.box-with-border;
	.pointer-cursor;
	.transition;
	.border-radius;
	width: @trash-confirm-base-width;
	min-width: @trash-confirm-base-width;
	height: @trash-confirm-base-height;
	min-height: @trash-confirm-base-height;
	img {
		height: 24px;
		width: 24px;
	}

	&.small {
		width: @trash-confirm-small-width;
		min-width: @trash-confirm-small-width;
		height: @trash-confirm-small-height;
		min-height: @trash-confirm-small-height;
	}

	&:hover {
		height: @trash-confirm-base-height;
		min-height: @trash-confirm-base-height;
	}
}

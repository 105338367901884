@import (reference) 'styles/app/app.less';

////////////////////////////////////
// Base styling
////////////////////////////////////

.team-panel-sick .new-sick-period {
	.blue;
	.border;
	.border-radius(large);
	.big-shadow;
	background-color: @light-blue;
	margin: 12px;
	padding: 16px 12px;
	width: calc(100% - 24px);
	margin: 12px;

	.eitje-form-2-row-form {
		justify-content: space-between;
	}

	.eitje-form-2-field {

		.ant-select,
		.ant-picker {
			background-color: @white;
		}
	}

	.eitje-form-2-field {
		flex-shrink: unset;
	}

	.eitje-form-2-label {
		.prevent-text-overflow;
		margin: 0 4px 0 0;
	}

	.eitje-form-2-field-user_id .eitje-form-2-field-icon {
		right: 38px;
	}

	.eitje-form-2-field {
		height: 32px;
	}

	.list-picker-dropdown-container {
		margin: 0 4px 0 0;
	}

	.list-picker-dropdown-container,
	.eitje-form-2-field-start .ant-picker {
		flex-grow: 1;
	}

	.eitje-form-2-field.eitje-form-2-field-user_id {
		.ant-select {
			flex-grow: 1;
		}

		.eitje-form-2-field-icon {
			right: 12px;
		}

		.ant-select-selection-search {
			padding: unset;
		}
	}

	.eitje-form-2-field-icon {
		right: 12px;
	}

	.ant-picker-input {
		width: 110px;
	}

	.eitje-date-picker-container {
		margin: 0 8px 0 0;
	}

	.ant-select,
	.ant-picker {
		.border-radius;
		.border;
		.pointer-cursor;
		position: relative;

		.ant-select-selection-placeholder,
		.ant-select-selection-item,
		.ant-select-selection-search,
		input {
			.bold;
			.pointer-cursor;
			color: @black;
			font-size: 12px;
			padding: 0 8px;
			line-height: 32px;
		}
	}
}
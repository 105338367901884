.eitje-rich-text-editor-content {
    ul[data-type="taskList"] {
        list-style: none;
        margin-left: 0;
        padding: 0;

        li {
            align-items: flex-start;
            display: flex;

            >label {
                flex: 0 0 auto;
                margin-top: 6px !important;
                margin-right: 0.5rem;
                user-select: none;

            }

            >div {
                flex: 1 1 auto;
            }
        }

        // custom checkbox styling based on https://sentry.io/answers/how-to-style-a-checkbox-using-css/
        input[type="checkbox"] {
            appearance: none;
            -webkit-appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 16px;
            height: 16px;
            border: 1px solid @black;
            border-radius: 3px;
            background-color: transparent;
            transition: background-color 0.3s, border-color 0.3s;

            &::before {
                content: "";
                display: block;
                margin-top: 2px;
                width: 14px;
                height: 14px;
                clip-path: polygon(10% 50%, 40% 80%, 100% 10%, 90% 0%, 40% 60%, 20% 40%, 0% 60%);
                background-color: @white;
                transform: scale(0);
                transition: transform 0.2s ease-in-out;
            }

            &:checked {
                border-color: @medium-blue;
                background-color: @medium-blue;
            }

            &:checked::before {
                transform: scale(1);
            }
        }

        ul[data-type="taskList"] {
            margin: 0;
        }
    }
}
@import (reference) 'styles/app/app.less';

.training-for-users-list {
	.popover {
		position: absolute;
		z-index: 2;

		.dropdown-list-container;

		.popover-contents {
			.column;
			.box-with-border;
			.border-radius(large);

			h3,
			h5 {
				display: block;
				width: 100%;
			}

			h3 {
				padding: 20px 0 0 12px;
			}

			h5 {
				padding: 0 0 12px 12px;
			}

			.search-container {
				width: calc(100% - 24px);
				margin: 0 12px 12px 12px;
			}

			.list-of-users {
				.vertical-scroll;
				flex: 1;
				padding: 0 12px;
				max-height: 200px;

				.list-item .list-item-title {
					font-size: 14px;
				}
			}
		}
	}
}

@import (reference) '../colors/colors.module.less';

.big-shadow {
	box-shadow: 0px 5px 15px 0px ~'@{medium-grey}25';
}

.small-shadow {
	box-shadow: 0px 4px 8px 4px ~'@{medium-grey}25';
}

.light-shadow {
	box-shadow: 0px 4px 8px 4px ~'@{medium-grey}25';
}

.only-downwards-shadow {
	box-shadow: 0 5px 4px -2px ~'@{medium-grey}25';
}

.top-shadow {
	box-shadow: 0px -5px 15px 0px ~'@{medium-grey}20';
}

.top-inset-shadow {
	box-shadow: inset 0 8px 10px ~'@{medium-grey}20';
}

.center-shadow {
	box-shadow: 0 0 10px 10px ~'@{medium-grey}25';
}
@import (reference) 'styles/app/app.less';
@import (reference) 'cores/planning/pages/team_panel/styles/index.less';

////////////////////////////////////
// Base styling
////////////////////////////////////

.team-panel-sick {
	.column;
	overflow-y: scroll;
	height: calc(100vh - @team-panel-header-height);
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
}

////////////////////////////////////
// Mixins
////////////////////////////////////

.team-panel-sick-label-mixin {
	.border;
	.border-radius;
	text-align: center;
	line-height: 28px;
	margin: 0 0 4px 0;
	font-size: 12px;
}

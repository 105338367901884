@import (reference) 'styles/app/app.less';

.shift-card-placeholder {
	.border;
	.border-radius;
	background-color: @light-grey;
	height: 42.8px;
	margin: 4px;
	padding: 0px 4px;

	&-avatar {
		.square-size(28px);
		.moving-gradient;
		margin: 6px 0 0 0;
		.border-radius(round);
		display: inline-block;
		vertical-align: middle;
	}

	&-content {
		display: inline-block;
		vertical-align: middle;
		margin: 0 0 0 6px;
		width: calc(100% - 36px);
	}

	&-title {
		.moving-gradient;
		height: 14px;
		width: 80px;
		margin: 6px 0 3px 0;
	}

	&-subtitle {
		.moving-gradient;
		height: 12px;
		width: 60px;
	}
}

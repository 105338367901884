@import (reference) 'styles/app/app.less';

.back-arrow-button {
	.row;
	.pointer-cursor;
	gap: 8px;
	img {
		width: 24px;
		height: 24px;
		min-width: 24px;
		min-height: 24px;
	}
}

.options-button-popout {
	a {
		width: 100%;
		button.eitje-button {
			padding: 8px;
			border-width: 0px;
			align-items: center;
			justify-content: center;
			display: flex;
		}
	}

	.modalLink p,
	.options-button-item {
		.border(bottom);
		padding: 8px;
	}
}

.image-button {
	.border;
	.border-radius;
	.pointer-cursor;
	overflow: hidden;
	img {
		.square-size(28px);
		object-fit: cover;
		opacity: 0.8;
		.transistion-quick;
	}
	&.shadow {
		.white-background-and-big-shadow;
	}

	&:hover {
		img {
			opacity: 1;
		}
	}
}

.soft-button {
	.row-with-border-and-padding(8px, 8px);
	.border-radius(large);
	justify-content: center;
	height: 40px;
	transition: height 0.3s ease;
	gap: 4px;
	.pointer-cursor;

	.soft-button-text {
		font-size: 12px;
	}

	&:hover {
		height: 44px;
	}
}

button.ant-btn.ant-btn-sm.small-button {
	.row;
	.align-items-center;
	width: 96px !important;
	min-width: 96px !important;
	height: 28px !important;
	padding: 0px !important;
	justify-content: center;
}

.hover-button {
	.row-with-justify-center;
	.border(@style: dashed, @width: 4px);
	.border-radius(large);
	.grey-text;
	.transition;
	flex: 1;
	opacity: 0.5;

	&:hover {
		box-shadow: 0 5px 15px rgba(170, 170, 170, 0.15);
		background-color: @white;
		.bold;
		opacity: 1;
	}

	&.md {
		height: 68px;
	}

	* {
		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}

.rounded-button {
	display: flex;
	justify-content: center;
	align-items: center;
	.white-background-and-big-shadow;
	background-color: @medium-blue;
	width: 40px;
	height: 40px;
	.border-radius(round);
	img {
		width: 14px;
		object-fit: cover;
	}
}

.back-button {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	box-shadow: 0 5px 15px hsl(0deg 0% 67% / 25%);
	.border-radius;
	border: 1px solid @medium-border;
	background-color: @white;
}

.checkbox-button {
	display: flex;
	padding: 0px 8px;
	gap: 8px;
	width: 100%;
	border: 1px solid @medium-border;
	.border-radius;
	align-items: center;
	height: 40px;
	.pointer-cursor;
	.checkbox-button-text {
		.eitje-text-primary;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		height: 23px;
	}
}

.eitje-dropdown {
	// TO DO: once we modernise checkbox/ move to common/components we should delete all styling above and handle through Layout,
	// for now disable height in scope to prevent app wide problems
	.checkbox-button .checkbox-button-text {
		height: unset;
	}
}

.plus-float-action-button {
	display: flex;
	background-color: @medium-blue;
	border-width: 0px;
	height: 40px;
	width: 40px;
	min-width: 40px;
	min-height: 40px;
	align-items: center;
	justify-content: center;
	z-index: 10;
	.border-radius(round);
	box-shadow: 0 0px 8px ~'@{medium-grey}50';
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

	&:hover {
		background-color: white;
		transform: scale(1.08);
		fill: @medium-blue;
		svg path {
			fill: @medium-blue;
		}
	}
}

.button-message-box {
	.row-with-justify-center;
	width: fit-content;
	height: 40px;
	background-color: @light-grey;
	.border;
	.border-radius;
	padding: 0 24px;
	p {
		.eitje-text-secondary;
		.line-height-1;
	}
}

.danger-filter-button {
	border-color: @medium-border;
	&:focus {
		border-color: @medium-border;
	}
	&.danger-filter-button-active {
		border-color: @medium-red;
		background-color: @light-red;
		&:focus {
			border-color: @medium-red;
		}
	}
}

button.blue span {
	// This is legacy support: since making all spans consistently black (as they
	// should be), some blue buttons were screwed due to getting black text. Now
	// make them white instead, if they also bear the legacy class .blue
	color: @white;
}

button.ant-btn.wider-and-lighter-primary-button {
	background-color: @light-blue;

	span {
		color: @black;
	}

	&:hover,
	&:active {
		background-color: @medium-blue;

		span {
			color: @white;
		}
	}
}

@import (reference) 'styles/app/app.less';

#planning-page .planning-per-member {
	// background: @light-background;
	width: 100%;

	> div {
		// automatically handle the spacing between the present rows & tables, of which some may or may not be visible and present in the DOM.
		margin-top: 32px;
	}

	.planning-per-member-virtuoso {
		.border(top);
	}
}

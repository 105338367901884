@import (reference) 'styles/app/app.less';

.availability_users_list_popover {
	.column-with-border;
}

.load-template-for-users-popover,
.set-shifts-per-week-for-users-popout {
	.remove-pop-padding;

	.search-container {
		width: 100%;
	}

	.popover-list-of-users {
		.vertical-scroll;
		max-height: 240px;
	}
}

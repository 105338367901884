@import (reference) 'mixins/stretch';

.eitje-form-2-button-select {
	.hide-icon;
	.strech-input-to-full-size;
	height: @field-height;
	text-align: center;

	.eitje-button-select-large {
		position: absolute;
		right: @field-horizontal-padding;
	}

	.eitje-button-select-no-label.eitje-button-select-large {
		position: relative;
		right: unset;
	}
}

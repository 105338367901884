.ant-btn {
	min-width: 132px;
}

.ant-btn:not(.ant-btn-primary):hover,
.ant-btn:not(.ant-btn-primary).active {
	background-color: @primary-color-hover;
	color: @text-color;
}

.ant-btn-primary span {
	color: @white;
	.pointer-cursor;
	.bold;
}

.ant-btn-dangerous.ant-btn-primary span {
	color: @black;
}

.ant-btn-dangerous.ant-btn-primary:hover {
	background-color: @btn-danger-color;
	color: @white;
}

.ant-btn,
.ant-btn-primary,
.ant-btn-dangerous,
.ant-btn:hover,
.ant-btn-primary:hover,
.ant-btn-dangerous:hover,
.ant-btn.ant-btn-primary.ant-btn-dangerous,
.ant-btn.ant-btn-primary.ant-btn-dangerous:hover {
	border-color: @medium-border;

	span {
		.bold;
	}
}

.ant-btn-dangerous:hover {
	span {
		color: @white;
	}
}

.ant-modal-footer .ant-btn + .ant-btn {
	margin-left: 4px;
}

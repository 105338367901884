@import (reference) 'styles/app/app.less';

.eitje-form-2-text-input {
	// This input may not be stretched completely to cover to field 100%,
	// since the HTML element 'input' does not offer the flexibility to
	// position the inner text, thus loosing the required control.
	.hide-icon;
	.pointer-cursor;

	input,
	.ant-input-password {
		.unset-border;
		background-color: unset;
		padding: 0;
		height: @field-input-height;
		border: 0px solid;

		////////////////
		// Unset a strange ant default which they set with !important.
		////////////////

		border-right-width: 0px !important;
		&.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
		&.ant-input-affix-wrapper.ant-input-affix-wrapper-focused:hover {
			box-shadow: unset !important;
			border-right-width: 0px !important;
		}
	}

	////////////////
	// Position eye icon (relative to its own input)
	////////////////

	.ant-input-password {
		.anticon-eye,
		.anticon-eye-invisible {
			top: -4px;
		}
	}

	///////////
	// Coloring the maxLength counter based on length
	//////////

	.char-counter {
		&.char-counter-warning {
			color: @medium-orange;
		}

		&.char-counter-danger {
			color: @medium-red;
		}
	}
}

@import (reference) 'styles/app/app.less';

.navbar-header {
	.border(bottom);
	position: fixed;
	z-index: 100;
	background-color: @white;

	.eitje-logo {
		height: 34px;
	}

	.nav-item {
		.pointer-cursor;
		padding: 0 16px;

		&-has-icon {
			padding: 0 24px 0 12px;
		}

		&.nav-item-subtitle {
			.center-anchor;
			padding-top: 8px; // to center vertically with absolute positioned subtitle
			justify-content: flex-start !important; // to center vertically with absolute positioned subtitle

			.subtitle {
				.center-item;
				margin-top: 18px;
				white-space: nowrap;
			}
		}

		&:hover:not(.nav-item-disabled) .eitje-text {
			text-decoration: underline;
		}
	}

	// TO DO: abstract below code away (Chris made a consideration)
	// drill 100% to stretch the clickable area for the trigger, like the other nav items
	.list-picker-dropdown-container {
		height: 100%;
		.list-picker {
			height: 100%;
			.list-picker-trigger {
				height: 100%;
			}
		}
	}
}

.Canny_BadgeContainer .Canny_Badge {
	// Hide the badge that is inserted by canny, since we would prefer to do it with enumerations in parenthesis, but they
	// don't offer a count
	visibility: hidden;
}

@import (reference) 'styles/app/app.less';

.training-list {
	.flat-list {
		display: flex;
		overflow-x: scroll;
		align-items: center;
		gap: 8px;
		padding: 12px 0px;
	}

	.training-card {
		.row-with-border;
		.border-radius(large);
		align-items: flex-start;
		position: relative;
		width: 308px;
		height: 121px;
		background-color: @white;
		padding: 24px 12px 24px 24px;

		.training-card-header {
			.prevent-text-overflow;
			.bold;
			max-width: 180px;
		}

		.training-card-right {
			.column;
			background-color: transparent;
			.align-items-center;
			width: fit-content;

			.training-card-day-count {
				.row-with-justify-center;
			}

			.training-image {
				border: 3px solid @white;
				object-fit: cover;
				height: 48px;
				width: 78px;
				box-shadow: 0px 5px 15px ~'@{medium-grey}33';
				border-radius: 35px;
			}
		}

		&.highlighted {
			padding: 20px 12px 24px 24px;
			height: 133px;

			.active-background-color;
			.training-card-day-count {
				display: none;
			}
			.trainButton {
				bottom: 16px;
			}
		}

		.trainButton {
			position: absolute;
			bottom: 24px;
			width: 100%;
			padding-right: 36px;
		}
	}
}

.training-article-container {
	width: 720px;
	padding-bottom: 80px;
	&.wide {
		width: unset;
	}

	.pdf-controls-container.modal-view {
		width: calc(100% - 24px);
		bottom: -40px;

		.pdf-toggle-container {
			.border-radius;

			&:last-child {
				// the previous button
				margin-left: -4px;
			}

			&:last-child {
				// the next button
				margin-right: -4px;
			}
		}
	}
}

.training-file-list-container {
	position: absolute;
	right: 24px;
	top: 180px;
	max-width: 214px;
	height: 100%;
}

.training-modal {
	.form-container-split {
		.flex-field-1;
	}
}

.quiz-answer-box {
	.user-answer {
		color: #ff0020;
	}

	.correct-answer {
		color: #25d9af;
	}
}

@import (reference) 'styles/app/app.less';

.day-header {
	.row-with-justify-center;
	.border(right);
	text-align: center;
	position: relative;
	height: 100%;

	.lock-icon {
		.square-size(11px);
		position: absolute;
		left: 20px;
	}

	.day-header-text-container {
		.day-header-title {
			.bold;
			color: @medium-blue;
			text-transform: uppercase;
		}
		.day-header-subtitle {
			font-size: 10px;
			.grey-text;
		}
	}

	&.day-header-closed .day-header-text-container .day-header-title {
		.grey-text;
	}

	&.day-header-active {
		background-color: @light-blue;
	}

	&.info-box {
		.row;
		padding: 0px 12px;
		gap: 4px;

		.day-header-date-box {
			.border;
			.border-radius;
			.column;
			.justify-content-center;
			height: 44px;
			flex: 1;

			.day-header-date-box-title,
			.day-header-date-box-date {
				.eitje-text-secondary;
				.text-center;
				.text-lowercase;
				font-size: 10px;
			}
			.day-header-date-box-date {
				.bold;
			}
		}

		.day-header-title {
			.eitje-text-secondary;
			line-height: 1.35;
		}
	}
}

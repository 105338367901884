@import (reference) 'mixins/stretch';
@import (reference) 'styles/app/app.less';

.eitje-form-2-multiline-input {
	.hide-icon;

	textarea {
		.hide-scrollbar;
		height: 21px;
		min-height: 21px;
		resize: none;

		&::placeholder {
			.prevent-text-overflow;
			max-width: calc(100% - 2 * @field-horizontal-padding);
		}
	}
}

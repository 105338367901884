@import (reference) 'styles/app/styles/colors/colors.module.less';
@import (reference) 'styles/form/settings/variables.less';

.validation-error-mixin {
	color: @medium-red;
	font-size: 10px;
	line-height: @field-error-height;
	display: block;
}

/////////////////////////
// General field styling
/////////////////////////

.eitje-form-2-field,
.eitje-form-2-fake-field {
	display: inline-block;
	flex: 1 1 0px; // automatically divide all fields equally over the horizontal axis
	padding: @field-vertical-padding @field-horizontal-padding;
	min-height: 100%; // let a field grow automatically if its neighbouring field grows
	position: relative; // enable absolute positioning of inner elements

	.anticon-close-circle,
	.ant-picker-suffix,
	.ant-select-arrow {
		// Hide all Ant icons, since we will implement our own.
		display: none;
	}
}

/////////////////////////
// Field text styling
/////////////////////////

.eitje-form-2-field {
	// Set all possible "main text" elements to the default style, while maintaining a
	// lower specificity than below styles, which should take precedence above this
	// wildcard targetting.
	p:not(.eitje-button-text, .eitje-tab, .eitje-text),
	textarea,
	input {
		color: @black;
		font-size: 14px;
		line-height: @field-input-height;
		display: block;
	}

	textarea {
		width: calc(100% - 16px);
	}

	textarea,
	input {
		&::placeholder {
			color: @dark-grey; // To align the color of the remaining Ant inputs with our new colors
		}
	}
}

.eitje-form-2-field p.eitje-form-2-label,
.eitje-form-2-field p.eitje-form-2-label span {
	color: @dark-grey;
	font-size: 12px;
	line-height: @field-label-height;
	display: block;
}

.eitje-form-2-field p.eitje-form-2-warning {
	color: @medium-orange;
	font-size: 10px;
	line-height: @field-warning-height;
	display: block;
}

.eitje-form-2-field p.eitje-form-2-error {
	.validation-error-mixin;
}

.eitje-form-2-field p.eitje-form-2-placeholder {
	color: @dark-grey;
}

/////////////////////////
// Field icon styling
/////////////////////////

.eitje-form-2-field {
	.eitje-form-2-field-icon,
	.eitje-form-2-field-clear,
	.anticon-eye,
	.anticon-eye-invisible {
		height: @field-icon-height;
		position: absolute;
		right: @field-horizontal-padding;
		top: calc((100% - @field-icon-height) / 2);
		.border-radius(round);
		background-color: @white;
		z-index: 9;
	}

	.eitje-form-2-field-clear {
		.pointer-cursor;
		z-index: 10; // Must have a z-index higher than the icon, so that it lays over it.
		display: none; // Hide by default, only show on hover.
	}

	&:hover .eitje-form-2-field-clear {
		display: inline-block;
	}

	.eitje-form-2-tooltip {
		.pointer-cursor;
	}
}

/////////////////////////
// Tooltip styling
/////////////////////////

.eitje-form-2-field .eitje-form-2-tooltip {
	height: 12px;
	vertical-align: top;
	margin: 4px 0 0 4px;
	float: left; // fix to prevent the tooltip's presence from pushing the form field value much lower, required for new load (day) template modal
}

/////////////////////////
// Remove Ant styling
/////////////////////////

// Use the least possible specificity per element.

.eitje-form-2-field {
	.ant-picker,
	.ant-select .ant-select-selector,
	textarea {
		.unset-border;
		padding: unset;
		background-color: unset;
	}
}

/////////////////////////
// Enable 100% height and 100% width input elements
/////////////////////////

.eitje-form-2-field {
	.eitje-form-2-label,
	.eitje-form-2-error,
	.eitje-form-2-warning,
	p,
	textarea,
	input {
		// without a position, it cannot be stacked upon the absolute input
		// which is 100% and absolute
		position: relative;

		// position all elements above the "100% absolute input", as to make
		// them visible even when they appear lower in the DOM
		z-index: 2;

		// style elements as inline instead of block, to maximise the click through area
		// on the below "100% absolute input. Also clear, as to mimick block styling and
		// prevent elements from being placed next to each other.
		display: inline-block;
		float: left;
		clear: both;
	}
}

/////////////////////////
// State styling
/////////////////////////

.eitje-form-2-field {
	&.eitje-form-2-field-disabled {
		opacity: 0.3;
	}
}

.eitje-form-2-read-only {
	pointer-events: none;
}

/////////////////////////
// Sometimes a field is wrapped within a popout card,  which must grow for the field to be visible
/////////////////////////

.eitje-form-2-row > .popout-card {
	width: 100% !important;
}

/////////////////////////
// Debugging helpers, only meant to be used in development
/////////////////////////

.form-debug {
	* {
		opacity: 1 !important;
	}
}

.form-debug2 {
	.form-debug;

	.eitje-form-2-row {
		border: 1px solid black !important;
	}
}

/////////////////////////
// Popout styling
/////////////////////////

.eitje-form-2-field .eitje-layout.popout-card.list-picker {
	display: block !important;
}

// TO DO: abstract below line away (Chris made a consideration)
// drill 100%, also so other "TO DO" comments like these

.eitje-form-2-row .popout-card-trigger {
	width: 100%;
}

@import (reference) 'styles/app/app.less';

#chat-page,
#chat-window {
    .str-chat {
        --str-chat__message-actions-box-border-radius: 4px;
        --str-chat__message-actions-box-item-hover-background-color: @light-blue;

        .str-chat__message-bubble {
            border: 1px solid @medium-border;
        }

        .str-chat__message-actions-box .str-chat__message-actions-list-item-button {
            border-bottom: 1px solid @medium-border;

            &:hover {
                color: @medium-blue;
                border-bottom: 1px solid @medium-blue;
            }

            &:last-child {
                border-bottom: unset;
            }
        }

        .str-chat__message-reaction {
            border: 1px solid @medium-border;

            * {
                cursor: pointer;
            }
        }

        // used to add some spacing between grouped messges
        .str-chat__message-metadata {
            padding-bottom: 12px;
            gap: 8px;
        }

        .str-chat__message-simple-status.str-chat__message-status .str-chat__message-sender-avatar {
            align-self: unset;
        }
    }
}
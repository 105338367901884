@import (reference) 'styles/app/app.less';

.internal-hide-class-names-footer {
	.eitje-form-2 {
		.border(right);
	}

	.eitje-form-2.add-classnames-form {
		.border(left);
		width: 500px;
	}
}

@import (reference) 'styles/app/app.less';
@import (reference) '/src/components/shared/user_card/styles/index.less';

@modal-header-height: 84px;
@modal-body-height: 440px;
@modal-footer-height: 72px;
@modal-height: calc(@modal-header-height + @modal-body-height + @modal-footer-height);
@modal-left-width: 600px;
@modal-right-width: 240px;

.shift-modal {
	.ant-modal-content .ant-modal-body {
		max-height: 600px !important;
		overflow-y: hidden !important;
	}
	.planning-shift-form {
		.eitje-modal(@modal-height);

		overflow: visible;
	}
}

.shift-modal {
	.eitje-modal-hide-close;
}

/////////////////////////////
// Radius styling
/////////////////////////////

.shift-modal {
	.planning-shift-form {
		.border-radius;

		.eitje-user-list {
			border-radius: 0 4px 0 0;
			.list-picker-regular-container {
				height: calc(100% - @modal-header-height) !important; // to overwrite inline
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.eitje-button-select-container p.eitje-form-2-error {
	position: absolute;
	inset: 0px 16px auto auto;
}

.eitje-button-select {
	//////////////////////////////////////
	// Small variant, see new shift modal for example (multi day select)
	//////////////////////////////////////

	&-small {
		display: inline-flex;
		gap: 4px;

		.eitje-button-select-button {
			.square-size(24px);
			.big-shadow;
			.pointer-cursor;
			display: inline-block;
			padding: 0;
			min-width: unset;

			span {
				font-size: 12px;
				line-height: 1;
			}

			&.active {
				background-color: @medium-blue !important;

				span {
					color: @white;
				}
			}
		}
	}

	//////////////////////////////////////
	// Large variant, see new leave request modal (leave type select)
	//////////////////////////////////////

	@border-radius: 4px;

	&-large {
		display: inline-flex;

		span {
			.book;
			font-size: 12px;
			color: @dark-grey;
		}

		button.eitje-button-select-button {
			.pointer-cursor;
			border-radius: unset;
			box-shadow: unset;
			transition: unset;
			background: @light-grey;

			&::before {
				transition: unset;
				transition-duration: unset !important;
			}

			&.active,
			&:hover,
			&:active,
			&:focus {
				.border;
				background: @white;

				span {
					.bold;
					color: @black;
				}
			}

			&:first-child {
				border-top-left-radius: @border-radius;
				border-bottom-left-radius: @border-radius;
			}

			&:last-child {
				border-top-right-radius: @border-radius;
				border-bottom-right-radius: @border-radius;
			}

			&:nth-child(1) {
				border-right: unset;
			}

			&:nth-last-child(1) {
				border-left: unset;
			}

			&:nth-child(2) {
				.border(right);
			}

			&.eitje-button-select-button-flex {
				flex: 1;
				min-width: 0px;
			}

			&.eitje-button-select-button-disabled {
				pointer-events: none;
			}
		}
	}
}

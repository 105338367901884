@import (reference) 'styles/app/app.less';
@import (reference) 'mixins.less';

////////////////////////////////////////////////
// Beware: this file is pretty fucked: unreadable
// yet often used.... Handle with care.
// new, better formatted definitions at the bottom
////////////////////////////////////////////////

.grid-select-container {
	@row-height: 45px;
	@left-pad: 16px;
	@label-col-width: 240px;
	@data-col-width: 96px;

	.grid-row {
		display: flex;
		height: @row-height;
		align-items: center;
		justify-content: flex-start;
	}

	// the top line of the grid. margin left is the the width of the column
	.grid-select-header {
		.row;
		margin-left: @label-col-width;
		height: 80px;

		> * {
			min-width: @data-col-width;
			max-width: @data-col-width;
		}
	}

	// first column displays a label for every other column, where its label value is set to null
	.grid-select-column:first-child {
		.grouped-list-picker {
			width: @label-col-width;
		}
	}

	.grid-select-column:not(:first-child) {
		min-width: @data-col-width;
		.list-item {
			justify-content: center !important; // To overwrite the inline styles of the layout

			&-selected {
				background-color: @white;
			}
		}
	}

	.list-picker-item {
		justify-content: center;
	}

	.flat-list-picker-item-selected {
		background-color: @white;
	}
}

////////////////////////////////////////////////
// New, better formatted definitions
////////////////////////////////////////////////

.grid-select-container {
	.grid-select-column {
		align-self: self-start;
	}
}

@import (reference) 'styles/app/app.less';

.color-picker-trigger {
	.border;
	.border-radius;
	.pointer-cursor;
	padding: 6px;
	display: flex;
	justify-content: space-between;

	.color-picker-arrow-down {
		margin: 4px 4px 0 8px;
	}
}

.color-picker-box {
	.border-radius;
	.border;
	.pointer-cursor;
	.square-size(40px);
	display: flex;
	justify-content: center;
	align-items: center;

	.color-picker-checkmark {
		width: 16px;
	}

	&-small {
		.square-size(25px);
	}
}

.popout-content {
	z-index: 2; // to overlay the hover-zone
	.color-picker-popout {
		display: flex;
		flex-wrap: wrap;
		width: 152px;
		gap: 8px;
	}
}

// This covers the case when something is a field and behaves like
// a field, but should not look and feel like a field – thus undo
// the automatic styling applied by the styling defaults.

@import (reference) 'index.less';

.eitje-form-2-unfield {
	.hide-all-icons;
	min-height: unset;
	padding: unset;
	flex: unset;
}

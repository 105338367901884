@import (reference) 'styles/app/app.less';

#planning-page .availability-card .availability-skills .availability-skill,
#planning-page .shift-skill-set {
	.square-size(14px);
	.border;
	margin: 0 2px 0 0;
	.border-radius(small);
	background-color: @white;
	display: inline-block;
	text-align: center;

	.availability-skill-letter {
		.bold;
		line-height: 12px;
		color: @dark-grey;
		font-size: 8px;

		&.has-skill-set {
			color: @white;
		}
	}
}

#planning-page .shift-skill-set {
	margin: unset;

	.availability-skill-letter {
		color: @white;
	}
}

#planning-page .availability-card .availability-skills {
	margin: -4px 0 -2px 0;
}

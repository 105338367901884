@import (reference) 'styles/app/app.less';
@import (reference) '../../styles/index.less';

///////////////////////
// General Card styling
///////////////////////

@gap: 12px;

.exports-tables-body .list-item {
	.border;
	.border-radius;
	.big-shadow;
	.hover-effect;
	.pointer-cursor;
	display: inline-block;
	width: calc((100% - @gap) / 2);
	background-color: @white;
	margin: @gap 0 0 0;
	padding: 12px 16px;
	position: relative;
	vertical-align: top;

	&:nth-child(odd) {
		margin-right: @gap;
	}

	.table-card {
		display: flex;
		justify-content: space-between;
		gap: 12px;

		.table-card-left {
			.type-and-name {
				display: inline-flex;
				gap: 4px;
				align-items: center;
			}

			.description {
				margin: 4px 0 0 0;
			}
		}

		.table-card-right {
			display: flex;
			gap: 4px;
			align-items: flex-end;
			flex-direction: column;
		}

		.table-filters-popout {
			display: inline-block;
		}
	}
}

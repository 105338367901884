// All containers different from 'row', 'column' and 'box'. You may want
// to write container classes here, before moving them to their own dedicated
// file.

@import (reference) '../spacing/sizes.less';
@import (reference) 'rows.less';
@import (reference) '../typography/legacy/default_styles.less';

.text-input-container {
	.row-with-border-and-padding(16px, 12px);
	.elementContainer {
		display: flex;
		width: 100%;

		:first-child {
			flex: 1;
		}

		.char-counter {
			.eitje-text-secondary;
			align-self: center;
		}
	}
}

// A global solution which prevents blue borders around focused inputs.

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-selector:target,
.ant-select-selector:focus-visible,
.ant-select-open .ant-select-selector,
.ant-select-focused .ant-select-selector,
.ant-select-focused {
	border-color: none !important;
	box-shadow: none !important;
}

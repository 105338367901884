@import (reference) 'styles/app/app.less';
@import (reference) './header.less';
@import (reference) './footer.less';

@modal-max-height: 660px;
@modal-max-body-height: calc(@modal-max-height - @modal-header-height); // exclude footer height cause its positioned absolutely within the body
@modal-double-border-prevention: 1px;

.eitje-modal-2-body {
	max-height: @modal-max-body-height;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: calc(@modal-footer-height - @modal-double-border-prevention); // 1.footer is positioned absolute and thus taken out of the flow
	// 2. use @modal-double-border-prevention form forms that a) are bordered at the bottom and b) have a scroll due to their height

	&-without-footer {
		padding-bottom: 16px;
	}

	.warning-message {
		margin: 8px 24px;
	}
}

.eitje-modal-2-body.eitje-modal-2-body-has-left-panel {
	display: flex;

	.side-menu {
		height: 100%;
	}

	> div,
	> form {
		flex-grow: 1 !important;
		overflow-x: hidden;

		.eitje-modal-2-body-has-left-panel {
			flex-grow: unset !important;
		}
	}

	> .eitje-form-2 {
		overflow-y: scroll;
	}
}

.eitje-modal-2.set-fixed-height .eitje-modal-2-main {
	@modal-height: calc(var(--modal-height) * 1px);

	.eitje-modal-2-body {
		height: calc(@modal-height - @modal-header-height) !important;

		.eitje-form-2-row:last-child {
			.border(bottom); // reset last border, since the form does not necessarily touch the footer's bottom anymore
		}
	}
}

// Automatically give all modal forms an overflow, so that the optional form message at the top of the form
// will always be visible on scroll.
.eitje-modal-2-body .eitje-form-2 {
	overflow-y: scroll;
}
@import (reference) 'styles/app/app.less';

.user-preferences {
	.left-navigation-column {
		padding: 8px;
	}

	.modal-col-body-area {
		.eitje-form {
			.eitje-field-BirthdayAlert,
			.eitje-field-ExpiringContract {
				border-bottom: 1px solid @medium-border !important;
			}
		}
	}
}

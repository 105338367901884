@import (reference) 'styles/app/app.less';

.remove-padding {
	.ant-dropdown-menu {
		padding: 0px;
	}
	.ant-dropdown-menu-item {
		padding: 0px;
		&:hover {
			background-color: unset;
		}
	}
}

.ant-menu {
	line-height: inherit;
}

////////////////////////////////////////
// BELOW IS JORDAN LEGACY, TRY TO REMOVE
////////////////////////////////////////

.bubble_input {
	border-radius: 32px;
}

.ant-btn.a .App {
	text-align: center;
}

.display-none-important {
	display: none !important;
}

.has-error {
	border-bottom-color: @medium-red;
}

.modalEitje .has-error {
	background-color: ~'@{medium-red}10';
	border-bottom-color: @medium-border;
}

////////////////////////////////////////
// ABOVE IS JORDAN LEGACY, TRY TO REMOVE
////////////////////////////////////////

@import (reference) 'styles/form/settings/variables.less';
@import (reference) 'styles/app/styles/elements/state_effects.less';

/////////////////////////////////
// General stretching logic
/////////////////////////////////

.strech-input-to-full-size {
	.ant-select,
	.ant-picker,
	textarea {
		// Make the input component take the whole size of .eitje-form-2-field,
		// so that you may click anywhere to focus.
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		// Add the pointer cursor as to emphasize that you can click. Not on the stacked elements
		// (such as label) since those won't make the form input active when clicked.
		.pointer-cursor-important;

		// Replicate the .eitje-form-2-field padding for this mixin's contents.
		padding-left: @field-horizontal-padding;
		padding-right: @field-horizontal-padding;

		.ant-picker-input,
		.ant-select-selector {
			position: absolute;
			top: 0;
			left: 0;

			padding-top: calc(@field-vertical-padding + @field-label-height);
			padding-left: @field-horizontal-padding;
			padding-right: @field-horizontal-padding;

			width: 100%;

			.ant-select-selection-item,
			.ant-select-selection-placeholder,
			.ant-select-selection-search-input {
				// Cover the case of .ant-select-selector, which sets a line-height for the input
				// text and its placeholder -> if leaving, the text does not align as we intend to.
				line-height: @field-input-height;
			}

			.ant-select-selection-search-input {
				// I don't know why, but this hack involving a hardcoded negative margin is required
				// for the search text of a dropdown field (when you type to find a dropdown item)...
				height: @field-input-height;
				margin-left: calc(@field-horizontal-padding - 10px);
				margin-top: calc(@field-vertical-padding + @field-label-height);
			}
		}
	}

	.eitje-form-2-label {
		margin-bottom: @field-input-height;
	}
}

/////////////////////////////////
// .ant-select (dropdown) tweaks
/////////////////////////////////

.strech-input-to-full-size {
	.ant-select .ant-select-selector {
		// Due to a differing HTML structure, .ant-select-selector (dropdown) requires a height
		// of 100%, while .ant-picker-input (input) requires not having a height of 100%.
		height: 100%;
	}
}

/////////////////////////////////
// .ant-picker (time picker) tweaks
/////////////////////////////////

.strech-input-to-full-size {
	.ant-picker-focused {
		// Remove blue border on being focused.
		box-shadow: unset;
	}
}

/////////////////////////////////
// textarea (multiline-input) tweaks
/////////////////////////////////

.strech-input-to-full-size {
	textarea {
		padding-top: calc(@field-vertical-padding + @field-label-height);
	}
}

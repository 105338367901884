@import (reference) 'styles/app/styles/elements/borders.less';
@import (reference) 'styles/app/styles/colors/colors.module.less';

.eitje-form-2-use-borders {
	.eitje-form-2-row {
		.border(top, @light-border);

		&:first-child {
			.unset-border(top);
		}
	}

	.eitje-form-2-row {
		.eitje-form-2-field,
		.eitje-form-2-fake-field,
		.eitje-form-2-double-field {
			.border(right, @light-border);

			&:last-child {
				.unset-border(right);
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

///////////////////////
// Table styling
///////////////////////

.table-edit .layout-preview-table {
	margin: 120px 0 0 0;
}

///////////////////////
// Column mixins
///////////////////////

@column-width: 200px;
@cross-icon-width: 20px;

.cell-styling {
	.border;
	background-color: @white;
	width: @column-width;
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
	border-right: unset;
}

///////////////////////
// Column styling
///////////////////////

.table-edit .layout-preview-table {
	.flat-list {
		display: flex;

		.list-item {
			display: inline-block;

			.drag-element {
				display: inline-block;

				.table-column {
					display: inline-flex;
					flex-direction: column;

					.table-header-cell {
						.cell-styling;
						.hover-effect;
						@header-height: 60px;
						height: @header-height;

						.popout-card-trigger {
							height: @header-height;
							line-height: @header-height;
							width: @column-width - @cross-icon-width;
							padding: 0 12px 0 16px;
						}

						.delete-cross-wrapper {
							position: absolute;
							right: 12px;
						}
					}

					.table-row-cell {
						.cell-styling;
						height: 40px;
						color: @dark-grey;
						border-top: unset;
						padding: 0 12px 0 16px;
					}
				}
			}

			.order-arrows {
				width: @column-width;
				height: 30px;
			}

			&:last-child .table-column {
				.border(right);
			}
		}
	}
}

/*

/////////////////////////////////////////////////////////////////

IMPORTANT

Below files are merely legacy support, don't add to them, or depend
on then, since the goal is to get rid of them as soon as possible!

/////////////////////////////////////////////////////////////////

*/

/* Initial CSS Setup */

[contenteditable] {
	-webkit-user-select: text;
	user-select: text;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

div#error_explanation h2 {
	display: none;
}

/* App.css (original) Classnames */

.flex {
	flex: 1;
}

.canClick {
	pointer-events: all !important;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}

/* LIBRARY FIXES END
*/

.noselect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

input:disabled {
	cursor: not-allowed !important;
}

a {
	text-decoration: none;
	color: inherit;
}

.unclickable {
	pointer-events: none;
}

html {
	height: 100%;
	width: 100%;
}

.newsContent a {
	color: #0496ff !important;
}

.w600 {
	font-weight: 600;
}

span {
	cursor: default;
}

div.pointer * {
	cursor: pointer !important;
}

.text--red {
	font-size: 10px;
	color: #ff0020;
	padding: 4px 4px;
}

.mB24 {
	margin-bottom: 24px;
}

.w100 {
	width: 100%;
}

.w90 {
	width: 90%;
}

.w50 {
	width: 50%;
}

.w40 {
	width: 40%;
}

.w35 {
	width: 35%;
}

.w33 {
	width: 33%;
}

.w30 {
	width: 30%;
}

.w25 {
	width: 25%;
}

.w22 {
	width: 22%;
}

.w20 {
	width: 20%;
}

.w15 {
	width: 15%;
}

.w12 {
	width: 12%;
}

.w10 {
	width: 10%;
}

.inlineB {
	display: inline-block;
}

.downArrow {
	width: 0;
	height: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-top: 3px solid #0496ff;
	display: inline-block;
	position: relative;
	top: 5px;
	left: -15px;
}

.upArrow {
	width: 0;
	height: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-bottom: 3px solid #0496ff;
	display: inline-block;
	position: relative;
	top: -8px;
	left: -15px;
}

.header {
	position: fixed;
	width: 100%;
	min-width: 1100px;
	background-color: white;
	border-bottom: 0.5px solid #eee;
	top: 0;
	z-index: 500;
	padding: 4px 24px;
	box-shadow: 0px 5px 15px 0px #aaaaaa20;
	z-index: 99;
}

.headerText span {
	/* font-size: 14px; */
	color: #82828295;
	font-weight: 600;
	padding: 16px;
	cursor: pointer;
}

.header .ant-select-arrow .ant-select-arrow-icon {
	margin-top: -16px;
	margin-right: -20px;
}

.daBoxBubble {
	position: absolute;
	top: 32px;
	margin-left: 120px;
	line-height: 16px;
	width: 22px !important;
	height: 22px;
	border-radius: 50%;
	border: solid white 3px;
	/* line-height: 15px; */
	/* font-size: 11px; */
	color: white;
	text-align: center;
	font-weight: 600;
	z-index: 9999;
	font-size: 11px;
}

.uitklappen {
	position: fixed;
	top: 51px;
	left: 0px;
	width: 100%;
	padding: 40px 40px 52px 40px;
	background-color: white;
	border-bottom: 1px solid #eee;
	box-shadow: 0px 5px 10px 0 rgba(34, 43, 55, 0.05);
	z-index: 1001;
}

.uitklappenSup {
	position: fixed;
	top: 51px;
	left: 0px;
	width: 100%;
	padding: 40px 40px 52px 40px;
	background-color: white;
	border-bottom: 1px solid #eee;
	box-shadow: 0px 5px 10px 0 rgba(34, 43, 55, 0.05);
	z-index: 1001;
	min-height: 232px;
}

.has-onboarding-header .uitklappen {
	top: calc(51px + 48px);
}

.has-onboarding-header .uitklappenSup {
	top: calc(51px + 48px);
}

.uitklapMenuItem {
	padding: 24px 24px;
	border-radius: 8px;
	width: 280px;
	margin-right: 40px;
	cursor: pointer;
}

.uitklapMenuItem-text {
	height: 57px;
	max-width: 220px;
}

.uitklapMenuItem:hover {}

.uitklappen h1 {
	/* font-size: 17px; */
	font-weight: bold;
}

.uitklappen p {
	/* font-size: 13px; */
	font-weight: 400;
	color: #828282;
}

#sup1Container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 200px;
}

#sup2Container {
	padding: 0px 36px 0px 0px;
}

#sup3Container {
	height: 200px;
}

.supportLink {
	width: 100%;
	padding: 12px 4px 12px 0px;
	border-bottom: 1px solid #eee;
	background-image: url('/background_images/dropDownRight.png');

	background-size: 4px;
	background-repeat: no-repeat;
	background-position: 96% 53%;
}

.topicLink:hover,
.supportLink:hover {
	background-color: #e8f5ff;
	cursor: pointer;
}

.topicLink *,
.supportLink * {
	cursor: pointer;
}

.topicLink {
	max-width: 146px;
	width: 146px;
	height: 82px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.downIcon {
	width: 12px;
	margin-left: -14px;
	margin-right: 8px;
	margin-top: 4px;
}

.navHeader {
	box-shadow: 0px 2px 3px #6f798010;
	position: fixed;
	width: 100%;
	min-width: 1100px;
	height: 42px;
	background-color: #fff;
	border: 0px;
	border-bottom-width: 1px;
	border-style: solid;
	border-bottom-color: #eee;
	top: 62px;
	z-index: 500;
}

.filterBar {
	height: 42px;
	background-color: #fff;
	border: 0px;
	border-bottom-width: 1px;
	border-style: solid;
	border-bottom-color: #eee;
	width: 100%;
}

.activeHeader {
	color: #0496ff !important;
	font-weight: 600 !important;
}

.c82 {
	color: #828282;
}

.cff {
	color: #fff;
}

.underHeader {
	margin-top: 42px;
}

.headerSpan {
	display: flex;
	justify-content: space-between;
}

.headerSpanRoom {
	max-width: 140px;
	height: 50px;
}

.headerSpanRoom span {
	display: block;
	width: 100%;
	text-align: center;
}

.textLeft {
	text-align: left;
}

.betweenFlex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.centerFlex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.columnCenterFlex {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.centerTopFlex {
	display: flex;
	justify-content: space-between;
	align-items: top;
}

.startFlex {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.fRow {
	display: flex;
	flex-direction: row;
}

.fCol {
	display: flex;
	flex-direction: column;
}

.fRowM {
	display: flex;
	flex-direction: row;
}

.jCen {
	justify-content: center;
}

.jSta {
	justify-content: flex-start;
}

.jEnd {
	justify-content: flex-end;
}

.jBetween {
	justify-content: space-between;
}

.aBetween {
	align-items: space-between;
}

.aCen {
	align-items: center;
}

.aSta {
	align-items: flex-start;
}

.aEnd {
	align-items: flex-end;
}

.wrap {
	flex-wrap: wrap;
}

.endFlex {
	display: flex;
	justify-content: flex-end;
}

.centerCenterFlex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.checkBox {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid #ddd;
}

.checkBoxIn {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: white;
}

.exportRows .elementContainer {
	min-height: 73px !important;
}

.exportRows .ant-switch {
	margin-top: 7px;
}

.scrollToTop:hover {
	background-color: #e8f5ff;
}

.scrollToTop {
	background-color: #e8f5ff;
}

.has-onboarding-header.scroll-to-top {
	top: 144px;
}

.arrowNav {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #0496ff;
	position: relative;
	top: -6px;
	left: 45%;
}

.headerSpan span {
	color: #828282;
	letter-spacing: 0.2px;
	/* special */
	line-height: 50px;
	cursor: pointer !important;
	text-decoration: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	margin-right: 48px;
}

.headerNavSpan span {
	color: #828282;
	letter-spacing: 0.2px;
	/* special */

	line-height: 42px;
	margin-right: 32px;
	cursor: pointer !important;
	text-decoration: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.headerSpan span:hover,
.headerNavSpan span:hover {
	color: #0496ff;
}

.modalHeader span {
	font-size: 11px;
	color: #828282;
}

.modalLineDivider {
	padding: 8px 0px;
	border-bottom: 1px solid #eee;
}

/* a {
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
} */

.headerSpan span :hover {
	color: #0496ff;
	font-weight: 600;
}

.ant-select {
	margin-right: 4px;
}

.capitalize {
	text-transform: capitalize;
}

ul li {
	font-size: 13px;
}

ol li {
	font-size: 13px;
}

.c04 {
	color: #0496ff;
}

.breakWord {
	word-break: break-word;
}

.formError {
	border-color: red;
}

.container1 {
	width: 100%;
}

.leftContainerUsers {
	width: 18%;
	min-height: 100vh;
	background-color: #fff;
	border-right: 1px solid #eee;
	position: fixed;
	left: 0px;
	overflow-y: auto;
	max-height: 100vh;
}

.itemContainer {
	width: 320px;
	background-color: white;
	position: fixed;
	left: 0px;
	bottom: 0;
	padding-bottom: 12px;
	top: 119px;
	overflow: scroll;
	border-right: 1px solid #eee;
}

.overflowHidden {
	overflow: hidden;
}

.middleContainer {
	width: calc(100vw - 320px);
	max-width: 1118px;
	margin-left: auto;
	margin-right: auto;
	min-height: calc(100vh - 63px);
	max-height: calc(100vh - 63px);
	overflow-y: auto;
}

.middleContainer .titleArea {
	padding-left: 120px;
	padding-right: 120px;
}

.middleContainerHandboekView {
	width: calc(100vw - 320px);
	margin-left: 320px;
	min-height: 100vh;
	max-height: 100%;

	padding: 120px 120px 160px 120px;
	background-color: white;
}

.middleContainerHandboekView img {
	width: 100%;
	padding-top: 16px;
	padding-bottom: 16px;
}

.middleContainerHandboekView p {
	margin-top: 16px;
	font-size: 14px;
}

.middleContainerHandboekView ul {
	margin-top: 16px;
	font-size: 14px;
}

.middleContainerHandboekView li {
	margin-top: 6px;
	margin-bottom: 6px;
	font-size: 14px;
}

.middleContainerUsers {
	background-color: white;
	position: fixed;
	top: 170px;
	min-height: 100vh;
	z-index: 100;
}

.userInfoContainer {}

.userInfoBox {
	width: 100%;
	padding: 32px 32px;
	border-bottom: 1px solid #eee;
	background-color: white;
}

.userInfoBox:hover {
	background-color: #e6f7ff;
	border-right: 4px solid #0496ff;
	cursor: pointer;
}

.userInfoBox p {
	font-size: 36px;
	color: #2b2b2b;
	font-weight: 700;
	cursor: pointer;
}

.userInfoBox span {
	font-size: 14px;
	color: #828282;
	position: relative;
	top: -8px;
	cursor: pointer;
}

.envContainerUsers {
	background-color: white;
	min-height: 100vh;
	max-height: 100vh;
	top: 170px;
	z-index: 100;
	position: absolute;
	right: 0px;
}

.rightContainer {
	width: 18%;
	background-color: white;
	border-left: 1px solid #eee;
	position: fixed;
	right: 0px;
	top: 104px;
	overflow: scroll;
	min-height: 100vh;
	max-height: 100vh;
}

.rightContainerTeams {
	width: 18%;
	background-color: #fff;
	border-left: 1px solid #eee;
	position: absolute;
	right: 0px;
	bottom: 0;
	top: 92px;
	overflow-y: scroll;
}

#rightInfo {
	position: relative;
}

.upperCase {
	text-transform: uppercase;
}

.floatL {
	float: left;
}

.floatR {
	float: right;
}

.clear {
	clear: both;
}

.center {
	text-align: center;
}

.inline {
	display: inline;
}

.IB {
	display: inline-block;
}

.inline {
	display: inline;
}

.MLeft {
	margin-left: 24px;
}

.MLeft12 {
	margin-left: 12px;
}

.MLefts {
	margin-left: 8px;
}

.MRights {
	margin-right: 4px;
}

.mR8 {
	margin-right: 8px;
}

.MRight {
	margin-right: 24px;
}

.MAuto {
	margin-left: auto;
	margin-right: auto;
}

.MTopss {
	margin-top: 2px;
}

.negML4 {
	margin-left: -4px;
}

.MTops {
	margin-top: 4px;
}

.MTop6 {
	margin-top: 6px;
}

.MTop {
	margin-top: 8px;
}

.MTopl {
	margin-top: 16px;
}

.MTopxl {
	margin-top: 24px;
}

.MTopxxl {
	margin-top: 64px;
}

.MBot {
	margin-bottom: 8px;
}

.MB12 {
	margin-bottom: 12px;
}

.overlap {
	border-right: 0px !important;
}

.pointer * {
	cursor: pointer !important;
	cursor: hand !important;
}

.default {
	cursor: default;
}

.block {
	display: block;
}

.shadow {
	box-shadow: 0px 2px 3px #eee;
}

.shadowTop {
	box-shadow: 0px -1px 5px #6f798024;
}

.shadowTopSoft {
	box-shadow: 0px -1px 5px #6f798015;
}

.border {
	border: 1px #eee solid;
}

.border90 {
	border: 1px #eeeeee90 solid;
}

.borderTop {
	border-top: 1px #eee solid;
}

.borderBot {
	border-bottom: 1px #eee solid;
}

.borderBot90 {
	border-bottom: 1px #eeeeee90 solid;
}

.help.ant-btn {
	width: 46px;
	height: 22px;
	font-size: 8px !important;
}

.help {
	margin-top: -1px;
	width: 35px;
	height: 17px;
	border: 1px solid #eee;
	cursor: pointer;
}

.help p {
	padding-top: 1px;
	font-size: 8px;
	color: #ccc;
	text-align: center;
}

.help:hover {
	background-color: #0496ff;
}

.help p:hover {
	color: #fff;
}

.inlineScrollContainer {
	width: 100%;
	overflow: scroll;
	height: 320px;
}

.overflow {
	overflow-y: auto;
}

/*  HEADER  */

.settingHeader {
	border: 1px solid #eee;
	border-radius: 4px;
	width: 32px;
	text-align: center;
	position: absolute;
	right: 16px;
	top: 7px;
	background-color: white;
	cursor: pointer;
}

.settingHeader:hover {
	background-color: #e4f7ff;
}

.logo {
	width: 92px;
	margin-left: -2px;
	display: inline;
	margin-right: 24px;
	margin-top: -2px;
}

.triangle {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #fff;
	float: right;
	margin-top: 1.6rem;
	margin-left: 0.5rem;
}

.flowAuto {
	overflow-x: auto;
}

.profile {
	display: inline-block;
	float: right;
	padding-right: 1.5rem;
}

.helpIcon {
	margin-top: -4px;
	width: 36px;
	height: 36px;
	cursor: pointer;
	margin-right: 16px;
	z-index: 900;
}

.inboxIcon {
	margin-top: -2px;
	width: 36px;
	height: 36px;
	margin-left: -8px;
	margin-right: 16px;

	cursor: pointer;
}

.userName {
	margin: 1.2rem 0rem 0rem 0rem;
}

.envName {
	float: left;
	margin: 1.15rem 0rem 0rem 1rem;
	cursor: pointer !important;
}

.docsify {
	border: 1px solid #fff;
	margin-top: 15px;
	margin-right: 16px;
}

.docsify p {
	padding: 3px 24px;
	font-weight: 100 !important;
	font-size: 10px !important;
}

.docsify:hover {
	background-color: #44b1ff;
	color: #fff;
}

.environment {
	float: left;
	cursor: pointer;
	margin: 1.2rem 0rem 0rem 1rem;
}

.userAvatar {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}

.avaBG {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
	object-fit: cover;
}

.avaBday {
	width: 24px;
	height: 24px;
	object-fit: cover;
}

.ava24 {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
	object-fit: cover;
}

.ava36 {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
	object-fit: cover;
}

.ava40 {
	width: 40px;
	min-width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 3px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
	object-fit: cover;
}

.ava48 {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 3px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
	object-fit: cover;
}

.ava64 {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	border: 4px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
	object-fit: cover;
}

.ava100 {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 6px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
	object-fit: cover;
}

.avaBGSmall {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
	object-fit: cover;
}

.avaBGXSmall {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
	object-fit: cover;
}

.avaBGLarge {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	border: 3px solid #fff;
	object-fit: cover;
}

.avaBG p {
	color: #0496ff !important;
	font-weight: bold !important;
	font-size: 14px;
}

.avaBGSmall p {
	color: #0496ff !important;
	font-weight: bold !important;
	font-size: 12px !important;
}

.avaBGXSmall p {
	color: #0496ff !important;
	font-weight: bold !important;
	font-size: 11px !important;
}

.avatarSkills p {
	color: #0496ff !important;
	font-weight: bold !important;
	font-size: 52px !important;
}

.avatar32 {
	width: 32px;
	height: 32px;
	border: 1px solid #005c9e;
	border-radius: 50%;
	object-fit: cover;
}

.avatar {
	border: 1px solid #005c9e;
	border-radius: 50%;
	object-fit: cover;
}

.navigatie {
	display: inline-block;
	margin-top: 1.1rem;
	margin-left: 1.5rem;
}

.navText {
	cursor: pointer;
	display: inline;
	color: #fff;
	margin-left: 1.5rem;
}

.navText:hover {
	color: #005c9e;
}

/* BOTTOM HEADER */

.red {
	color: #ff0020;
}

.blue {
	color: #0496ff;
}

.green {
	background-color: green;
}

.yellow {
	background-color: yellow;
}

.pink {
	background-color: pink;
}

.blueBG {
	background-color: #0496ff;
}

.bgf5 {
	background-color: #f5f8fa;
}

.bglb {
	background-color: #ecf6fd;
}

.headerBottom {
	width: 100%;
	min-width: 1100px;
	height: 50px;
	border-style: solid;
	border-width: 1px;
	border-color: #eee;
	background-color: #fff;
	top: 49px;
	position: absolute;
	z-index: 5000;
}

.headerBottomAdmin {
	width: 100%;
	min-width: 1100px;
	height: 42px;
	border-style: solid;
	border-width: 0px;
	border-top-width: 1px;
	border-color: #eee;
	background-color: #f5f8fa;
	position: relative;
	z-index: 499;
}

.title {
	float: left;
	margin-left: 96px;
	margin-top: 0.7rem;
}

.linkP {
	margin: 0;
	float: left;
	color: #0496ff;
	font-weight: 600;
}

.activeP {
	font-weight: normal;
	color: #2b2b2b;
}

.addButton {
	width: 1.5rem;
	margin: 0.5rem 1.5rem 0rem 0rem;
	float: right;
	cursor: pointer;
}

.nieuwsButton {
	width: 24px;
	margin-top: 7px;
	margin-right: 1.5rem;
	float: right;
	cursor: pointer;
}

.actionButton {
	margin-top: 0.6rem;
	color: #0496ff !important;
	float: right;
	margin-right: 1.5rem;
	cursor: pointer;
	font-weight: 500;
}

.spacing {
	height: 4rem;
}

.spacingLarge {
	height: 10rem;
}

.body_infos .headerBottom,
.body_show .headerBottom,
.body_users .headerBottom,
.body_teams .headerBottom,
.body_inwerk_items .headerBottom {
	display: block;
}

.pos-menu {
	width: 50%;
	margin-left: 13%;
	margin-right: auto;
}

.clearfix:after {
	content: '.';
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.clearfix {
	display: inline-block;
}

html[xmlns] .clearfix {
	display: block;
}

* html .clearfix {
	height: 1%;
}

.instellingen {
	width: 100%;
}

.instellingen p {
	display: inline-block;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.alert.alert-notice {
	width: 100%;
	height: 24px;
	padding-top: 4px;
	background-color: #fff;
	text-align: center;
	color: #0496ff;
	font-weight: 400;
}

.alert-alert {
	width: 100%;
	height: 24px;
	padding-top: 4px;
	background-color: #fff;
	text-align: center;
	color: #ff0000;
	font-weight: 400;
}

#error_explanation {
	text-align: center;
	margin-bottom: 24px;
	cursor: default;
}

#error_explanation li {
	border: 1px solid red;
	color: white;
	background-color: #ff0000c4;
	padding: 8px 24px;
	border-radius: 8px;
	margin-bottom: 4px;
}

.noContract {
	width: 12px;
	height: 12px;
	top: 1px;
	position: relative;
}

.envInfo:hover {
	background-color: #e5f4ff;
}

.newEnv {
	width: 30%;
	margin: auto;
	margin-top: 64px;
}

.newEnv input {
	padding: 8px 8px;
	height: 32px;
	margin: auto;
	display: block;
	width: 100%;
	margin-bottom: 12px;
}

.newEnv select {
	height: 32px;
	margin: auto;
	display: block;
	width: 100%;
	margin-bottom: 12px;
}

.hoverRed:hover {
	color: red;
}

.notifHover:hover {
	background-color: #f5f8fa;
}

button.activeLight {
	background-color: #ecf6fd !important;
}

.inboxShaker {
	animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

.personalRow span {
	font-size: 12px;
}

.overflowHidden {
	overflow-y: hidden;
	overflow-x: hidden;
}

.overflowPanel {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-bottom: 80px;
}

.paddingWelcomeText {
	padding: 16px 40px 4px 8px;
}

.paddingWelcomeText span {
	font-size: 12px;
}

.underLineCapital {
	margin-bottom: 4px;
}

.card {
	background-color: white;
	border: 1px solid #eee;
	border-left: 0px;
	height: 44px;
	display: flex;
	margin-bottom: -1px;
	align-items: center;
}

.cardSimple {
	background-color: white;
	border: 1px solid #eee;
	border-left: 0px;
	height: 44px;
	display: flex;
	margin-bottom: -1px;
}

.card .pointer * {
	cursor: pointer;
}

.defaultCursor {
	cursor: default;
}

.dbSwitch {
	margin: 12px 8px;
	height: 52px;
	border-radius: 8px;
	border: 1px solid #eeeeee90;
	background-color: white;
	box-shadow: 0 2px 8px #aaaaaa25;
	justify-content: center;
	cursor: pointer;
}

.dbSwitch *,
.dbSwitcher * {
	cursor: pointer;
}

.dbSwitcher {
	line-height: 52px;
	text-align: center;
	justify-content: center;
	flex: 1;
	font-weight: 600;
	cursor: pointer;
}

.dbSwitcher p {
	color: #828282;
	font-size: 14px;
	line-height: 52px !important;
}

.pBold {
	font-weight: 600;
	font-size: 14px;
	color: #2b2b2b;
}

.pLight {
	font-weight: 400;
	font-size: 12px !important;
	color: #828282 !important;
}

.panelImg {
	width: 48px;
	margin-bottom: 8px;
	object-fit: contain;
}

.panelImgXS {
	width: 22px;
	object-fit: contain;
}

.panelActive {
	color: #2b2b2b !important;
	text-decoration: underline;
}

.managerActiveDot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	position: absolute;
	background-color: #ff0020;
	top: 36px;
	left: 8px;
	border: 2px white solid;
	z-index: 10;
}

.agendaSync {
	background-color: white;
	border-radius: 8px;
	padding: 8px;
	padding-left: 16px;
	box-shadow: 0 4px 15px #aaaaaa25;
	flex-grow: 1;
	cursor: pointer !important;
	min-height: 40px;
	min-width: 220px;
}

.agendaSync:hover {
	background-color: #e8f5ff;
}

.agendaSync p {
	cursor: pointer;
}

.agendaSync {
	border: 1px solid #eeeeee90;
}

.dbClick {
	margin: 8px;
}

.dbClick p {
	cursor: pointer;
}

.dbAva {
	padding: 0px 0 0px 4px;
}

.tradeTogether {
	border-radius: 4px;
	border: 1px solid #eee;
	box-shadow: 0 2px 8px #aaaaaa25;
	width: 32px;
	padding: 4px;
	margin-top: -8px;
	margin-bottom: -8px;
	margin-left: 4px;
	position: relative;
	background-color: white;
}

.tradeTogether img {
	width: 12px;
	height: 12px;
	object-fit: contain;
}

.panelBoldText {
	font-size: 14px !important;
	color: #2b2b2b;
	font-weight: 600 !important;
	margin-bottom: 0px;
}

.smallBoldText {
	font-size: 12px !important;
}

.panelUnderlineText {
	font-size: 12px !important;
	color: #828282;
	margin-top: -3px;
}

.veedree h4 {
	font-size: 12px !important;
	color: #828282;
	font-weight: 400 !important;
}

.smallBoldText {
	font-size: 12px !important;
}

.outlineIcon {
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 8px;
	border: 1px solid #eee;
	background-color: white;
	box-shadow: 0 2px 8px #aaaaaa25;
	border-radius: 8px;
	cursor: pointer;
}

.outlineIcon img {
	cursor: pointer;
	width: 28px;
	height: 28px;
}

.icon28 {
	width: 28px;
	height: 28px;
}

.icon24 {
	width: 24px;
	height: 24px;
}

.icon20 {
	width: 20px !important;
	height: 20px !important;
}

.timeShiftLeft {
	font-size: 9px;
	color: #828282;
	text-align: center;
}

.avatarPanel {
	margin-left: 8px;
	margin-right: 8px;
}

.partyAvatar {
	position: absolute;
	width: 24px;
	margin-left: 16px;
	margin-top: -20px;
}

.avatarPanelOpen {
	background-color: #ff7c7c;
}

.panelFooterSticky {
	position: sticky;
	bottom: 88px;
	background-color: white;
	border-top: 1px solid #eee;
	width: 100%;
}

.squarePanel {
	width: 48%;
	float: left;
	background-color: #ebf6ff;
	border: 1px solid #eee;
	height: 60px;
	padding: 4px 0px 0px 12px;
	border-radius: 4px;
	margin: 2px;
}

.dashBoardHeader span {
	font-size: 12px;
	padding-left: 8px;
}

.bigSquarePanelText {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: -4px;
}

.smallSquarePanelText {
	font-size: 12px;
	color: #2b2b2b;
	font-weight: 500;
}

.subSquarePanelText {
	font-size: 8px;
	color: #828282;
}

.subBlueSquarePanelText {
	font-size: 8px;
	color: #0496ff;
	font-weight: 500;
}

.panelBlueCircle {
	background-color: #0496ff;
	width: 6px;
	height: 6px;
	border-radius: 3px;
	display: inline-block;
	margin: -2px 5px 0px 0px;
}

.panelGreyCircle {
	background-color: #828282;
	width: 6px;
	height: 6px;
	border-radius: 3px;
	display: inline-block;
	margin: -2px 5px 0px 0px;
}

.supportButton {}

.hoverUserSelect {
	padding-top: 4px;
	padding-bottom: 4px;
	margin-left: 8px;
	cursor: pointer;
	border-radius: 4px;
}

.hoverUserSelect:hover {
	background-color: #ecf6fd;
}

.page404 {
	height: 100%;
	width: 100%;
	background-color: #0496ff;
}

.inputBox {
	padding: 5px 8px;
	height: 32px;
	border: 1px solid #eee;
	border-radius: 4px;
	width: 320px;
	margin-right: 4px;
}

.inputBox p {
	color: #bbb;
}

.topicSelector p {
	color: rgb(130, 130, 130);
	font-size: 12px !important;
	margin-bottom: -2px !important;
	cursor: default;
}

.inputBox:hover {
	border-color: #0496ff;
}

.activeButton {
	background-color: #ecf6fd !important;
	cursor: default !important;
}

.stdTitle p {
	font-weight: 600;
	color: #828282;
	display: inline;
}

.notifRed {
	display: inline-block;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: #ff0020;
	color: white;
	text-align: center;
	font-size: 9px;
}

.eitjeDrop {
	border: 1px solid #eee !important;
	width: 160px;
	background-color: white;
	border-radius: 4px;
	cursor: pointer;
	height: 40px;
	display: inline-block;
	margin-right: 4px;
	padding-right: 12px;
	overflow: hidden;
	display: flex;
}

.eitjeDrop :first-child {
	width: 100%;
	display: flex;
}

.eitjeDrop:active {
	border-color: #0496ff50;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.teamMenuSelect .ant-menu-item,
.envMenuSelect .ant-menu-item,
.userMenuSelect .ant-menu-item,
.maaltijdenMenuSelect .ant-menu-item,
.labelHoverDropDown {
	padding: 0px !important;
}

.menu-group-class .ant-menu-item-group-title {
	color: #0496ff !important;
	font-size: 12px !important;
	margin-left: -4px !important;
	font-weight: 600 !important;
}

.bottomFooterSave {
	position: fixed;
	width: 100%;
	bottom: 0px;
	height: 48px;
	z-index: 100;
	margin-left: -7%;
	background-color: white;
	border-top: 1px solid #eee;
}

.eitjeDrop p {
	line-height: 40px;
	padding-left: 12px;
	white-space: nowrap;
	overflow: hidden;
}

.dropIcon {
	position: relative;
	top: -23px;
	font-size: 14px !important;
	right: -132px;
	color: #bfbfbf;
}

.labelHoverDropDown {
	margin: 0px !important;
	border-radius: 4px;
}

.labelHoverDropDown:hover>p {
	background-color: #e6f7ff;
}

.labelHoverDropDown:hover {
	border-radius: 0px;
}

.labelHoverDropDown:first-child:hover {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.labelHoverDropDown:last-child:hover {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.labelHoverDropDown p {
	padding: 0px !important;
	padding-left: 16px !important;
}

.ant-menu {
	border-radius: 4px !important;
	border: 1px solid #eee;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
	margin-top: -21px;
}

.alignMiddle {
	vertical-align: middle;
}

.marginDrawer {
	margin-left: 12px;
	margin-right: 12px;
	margin-top: 0px;
	padding: 12px 8px;
}

.overFlowY {
	overflow-y: auto !important;
}

.inrichtingModus {
	cursor: pointer;
	z-index: 1000000;
	width: 60px;
	height: 60px;
	border-radius: 28px;
	background-color: #0496ff;
	position: fixed;
	right: 26px;
	bottom: 120px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.inrichtingModus img {
	width: 32px;
	height: 32px;
}

.inrichtingMSG {
	border-radius: 4px;
	background-color: #f5f8fa;
	border: 1px solid #eee;
}

.modalOverflowTop {
	margin-top: 12px;
	padding-top: 12px;
	border-top: 1px solid #eee;
}

.inrichtingMSG p {
	text-align: center;
	padding: 12px 0px;
	font-size: 10px;
	color: #828282;
}

.inrichtingBol {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.posBolInrichting {}

.posBolInrichting img {
	width: 20px;
	height: 20px;
}

.inrichtingBol p {
	font-size: 12px;
	color: white;
	font-weight: 600;
}

.cardOnboarding {
	border-bottom: 1px solid #eee;
	padding-bottom: 12px;
	line-height: 1.2;
	border-radius: 4px;
	cursor: pointer;
	margin-bottom: 12px;
}

.cardOnboarding * {
	cursor: pointer;
}

.titleOnboarding p {
	font-weight: 600;
	color: #828282;
	font-size: 16px;
}

.greenBG {
	background-color: #55efc4;
}

.titleOnboarding span {
	color: #828282;
	padding-top: 2px;
	font-size: 10px;
	display: block;
	margin-bottom: 8px;
}

.onboardingSelected {
	padding-top: 16px;
	border: 1px solid #eee;
	background-color: #e6f7ff;
}

.imgOmlijningBoarding {
	background-color: #bdbdbd90;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imgOmlijningBoarding p {
	font-size: 7px;
	color: white;
	font-weight: 600;
}

.bottomOnboarding {
	display: flex;
	margin-top: 2px;
	justify-content: flex-start;
}

.bottomOnboarding span {
	margin-left: 4px;
	color: #bdbdbd;
	font-size: 8px;
}

.bottomOnboarding img {
	width: 14px;
	height: 14px;
}

.activeOnboarding span {
	color: #0496ff;
}

.imgOmlijningBoardingActive {
	background-color: #0496ff;
}

.progressOnboarding {
	margin-left: 12%;
	width: 80%;
	margin-top: 12px;
}

.onboardingStep {
	border: 1px solid #eee;
	padding: 16px;
	border-radius: 4px;
	margin-bottom: 4px;
}

.onboardingStep p {
	font-size: 16px;
	font-weight: 500;
	max-width: 85%;
	word-break: break-word;
}

.onboardingStep .ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #fff !important;
}

.onboardingFixed {
	background-color: white;
	border: 1px solid #0496ff;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 2px;
	margin-bottom: 2px;
}

.onboardingFinished {
	background-color: #0496ff;
}

.footerDrawer {
	position: fixed;
	bottom: 0px;
	width: 420px;
	padding: 24px;
	background-color: #f5f8fa;
	border-top: 1px solid #eee;
}

.drawerOnboarding .ant-drawer-body {
	padding: 0px !important;
}

.marBottom {
	margin-bottom: 4px;
}

.rowLeftBoard {
	width: 12%;
	padding: 16px;
}

.rowCenterBoard {
	width: 80%;
	padding-left: 8px;
	padding-right: 8px;
}

.rowRightBoard {
	display: flex;
	justify-content: flex-end;
	width: 8%;
	padding: 8px;
}

.footerDrawer p {
	font-weight: 600;
}

.onboardingStepsStatusRed {
	background-color: #ff0020;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	position: absolute;
	left: 36px;
	top: -12px;
	border: 3px solid white;
}

.onboardingStepsStatusRed p {
	color: white;
	font-size: 8px;
	font-weight: 700;
}

.sideDrawer {
	padding: 24px;
}

.sideDrawerLink {
	padding: 4px;
	margin-top: 8px;
	width: 100%;
	border: 1px;
	text-align: center;
	border: 1px solid #eee;
	border-radius: 4px;
	color: #828282;
	font-size: 10px;
}

.sideDrawerLink:hover {
	color: #0496ff;
	border-color: #0496ff;
	cursor: pointer;
}

.titleSideDrawer {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 8px;
}

.sideDrawerStep {
	margin-bottom: 2px;
	font-size: 12px;
	color: #828282;
}

.sideDrawerVideo {
	margin-top: 40px;
}

.onboardingStepActive {
	background-color: #ecf6fd;
}

.onboardingStep:hover {
	background-color: #ecf6fd;
}

.verlofNotif {
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #ff0020;
	position: relative;
	float: right;
	top: 20px;
	left: -46px;
}

.mplus .ant-input {
	margin-bottom: 4px;
}

.nowInput .ant-input-number {
	margin-left: 8px;
}

@media screen and (max-width: 768px) {
	.hideMob {
		display: none;
	}

	.fRowM {
		display: flex;
		flex-direction: column;
	}
}

button.weekSwitch {
	max-width: 32px !important;
	width: 32px !important;
	min-width: 32px !important;
	display: flex;
	justify-content: center;
}

.borderZeroRadius:focus .borderZeroRadius:hover {
	border-radius: 0px !important;
}

/* V3 CSS Classnames */

.pad0 {
	padding: 0px;
}

.pad24 {
	padding: 24px;
}

.pad1624 {
	padding: 16px 24px;
}

.pad1224 {
	padding: 12px 24px;
}

.pad12 {
	padding: 12px;
}

.pad24 {
	padding: 24px;
}

.pT4 {
	padding-top: 4px;
}

.pT5 {
	padding-top: 5px;
}

.pT12 {
	padding-top: 12px;
}

.pB12 {
	padding-bottom: 12px;
}

.pB24 {
	padding-bottom: 24px;
}

.pT2 {
	padding-top: 2px;
}

.pV8 {
	padding-top: 8px;
	padding-bottom: 8px;
}

.pV16 {
	padding-top: 16px;
	padding-bottom: 16px;
}

.pV24 {
	padding-top: 24px;
	padding-bottom: 24px;
}

.pH12 {
	padding-left: 12px;
	padding-right: 12px;
}

.pH24 {
	padding-left: 24px;
	padding-right: 24px;
}

.pL12 {
	padding-left: 12px;
}

.pL24 {
	padding-left: 24px;
}

.pR8 {
	padding-right: 8px;
}

.mar24 {
	margin: 24px;
}

.mar12 {
	margin: 12px;
}

.mR12 {
	margin-right: 12px;
}

.mR24 {
	margin-right: 12px;
}

.mT4 {
	margin-top: 4px;
}

.mT8 {
	margin-top: 8px;
}

.mT12 {
	margin-top: 12px;
}

.mT24 {
	margin-top: 24px;
}

.mB4 {
	margin-bottom: 4px;
}

.mB8 {
	margin-bottom: 8px;
}

.mB12 {
	margin-bottom: 12px;
}

.mB24 {
	margin-bottom: 24px;
}

.mL4 {
	margin-left: 4px;
}

.mL12 {
	margin-left: 12px;
}

.mL24 {
	margin-left: 24px;
}

.mR4 {
	margin-left: 4px;
}

.icon12 {
	width: 12px;
	height: 12px;
}

.icon16 {
	width: 16px;
	height: 16px;
}

.rowS {
	background-color: white;
	padding: 8px 24px;
	box-shadow: 0px 5px 15px #aaaaaa20;
}

/*renamed .row into .legacy-row, since new .row class would pick up border.
if there are places where we need to revert, use this class instead*/
.legacy-row {
	background-color: white;
	border-bottom: 1px solid #eee;
	padding: 8px 24px;
}

.fixed {
	position: fixed;
	display: flex;
	width: 100%;
}

.pageContainer {
	position: absolute;
	width: 100%;
}

.italic {
	font-style: italic;
}

.lowercase {
	text-transform: lowercase;
}

.teamContainer {
	position: fixed;
	overflow-x: scroll;
	max-height: calc(100vh - 63px);
	min-height: calc(100vh - 63px);
	background-color: white;
	border-right: 1px solid #eee;
	z-index: 99;
	display: flex;
	flex-direction: column;
}

#teamNavigation {
	max-height: calc(35vh - 31px);
	min-height: 230px;
}

.userListArea {
	overflow: scroll;
	flex: 1;
	padding-bottom: 24px;
}

.innerContainer {
	position: relative;
	left: 264px;
	width: calc(100vw - 264px);
	min-width: 1000px;
}

#rightInfo {
	min-width: 280px;
	max-width: 280px;
	padding: 0px 24px;
	position: relative;
	top: -136px;
}

.relative {
	position: relative;
}

.noOverflow {
	white-space: pre;
	overflow: hidden;
}

.placementChildMarR>div {
	margin-right: 4px;
}

.subHeaderv3 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	padding: 8px 24px;
	z-index: 998;
	min-width: 1235px;
	width: 100%;
	min-height: 56px;
	box-shadow: 0px 5px 15px #aaaaaa20;
}

.page-layout .veetwee32 {
	width: 100%;
}

.placementChildMarL>div {
	margin-left: 4px;
}

.bg1 {
	background-color: red;
}

.bg2 {
	background-color: green;
}

.bg3 {
	background-color: yellow;
}

.bg4 {
	background-color: blue;
}

.bg5 {
	background-color: #f5f8fa;
}

.white {
	background-color: white;
}

.c2b {
	color: #2b2b2b !important;
}

.c82 {
	color: #828282 !important;
}

.dangerP:hover {
	color: #ff0020;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

.sticky {
	position: sticky;
}

.r8 {
	border-radius: 8px;
}

.modalRowHeader {
	border-bottom: 1px solid #eee;
	display: flex;
	padding: 12px 24px;
	background-color: #e8f5ff;
}

.blockk {
	border: 1px solid #eeeeee90;
	background-color: white;
	padding: 12px;
}

.blocks {
	background-color: white;
	padding: 12px;
	box-shadow: 0px 5px 15px 0px #aaaaaa25;
	border-radius: 8px;
	border: 1px solid #eeeeee90;
}

.noBGblocks {
	padding: 12px;
	box-shadow: 0px 5px 15px 0px #aaaaaa25;
	border-radius: 8px;
	border: 1px solid #eeeeee90;
}

.shade {
	box-shadow: 0px 5px 15px 0px #aaaaaa25 !important;
}

.flex {
	display: flex;
	flex: 1;
}

.hRed:hover {
	color: #ff0020;
}

.hBlue:hover {
	color: #0496ff;
}

p.noCapital::first-letter,
.noCapital p::first-letter,
div.noCapital::first-letter,
span.noCapital::first-letter {
	text-transform: none !important;
}

.bold {
	font-weight: 600;
}

.underline {
	text-decoration: underline;
}

.strike-through {
	text-decoration: line-through;
}

.labLue {
	color: #0496ff;
	background-color: #e8f5ff;
}

.borderBot {
	border-bottom: 1px solid #eee;
}

.borderRight {
	border-right: 1px solid #eee;
}

.borderLeft {
	border-left: 1px solid #eee;
}

.actionBTN img {
	align-self: center;
	padding: 0px;
	width: 24px;
}

.checkBox {
	width: 12px;
	min-width: 12px;
	min-height: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.checkBoxIn {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #0496ff;
}

.listDropDown {
	margin-top: 4px;
	position: absolute;
	background-color: white;
	border: 1px solid #eee;
	border-top-width: 0px;
	border-radius: 4px;
	overflow: auto;
	max-height: 320px;
	width: inherit;
	z-index: 1000;
	box-shadow: 0px 4px 8px 4px #aaaaaa25;
}

.defaultRow,
.defaultRowPicker {
	padding: 12px 8px;
	border-bottom: 1px solid #eee;
}

#popover .defaultRowPicker {
	padding-left: 16px;
}

#teamSearch .ant-input {
	height: 40px;
	box-shadow: 0px 0px 10px 1px #aaaaaa25;
}

.searchHolder {
	width: 260px;
	z-index: 999;
	padding: 8px;
	padding-left: 40px;
}

.backButton * {
	cursor: pointer;
	width: 120px;
}

.backButton img {
	width: 24px;
	height: 24px;
	object-fit: cover;
}

.actieBtn button {
	margin-left: 4px;
}

.whiteBorder {
	box-shadow: 0px 5px 15px 0px #aaaaaa60;
	object-fit: cover;
}

.attachRow {
	min-height: 40px;
}

.modalLink * {
	cursor: pointer;
}

.defaultRow:hover {
	background-color: #e8f5ff;
}

#search-legacy {
	background-color: #f5f6f7;
	border: 1px solid #eeeeee50 !important;
	border-radius: 4px;
	background-image: url('/background_images/search.png');

	background-size: 20px;
	background-repeat: no-repeat;
	background-position: 10px 10px;
	padding-left: 36px !important;
	height: 40px !important;
}

.teamContainer #search {
	border-radius: 32px;
}

.errorMissing {
	padding: 4px 12px 4px 4px;
	border-radius: 24px;
	border: 1px solid #ff0010;
	background-color: #ff002025;
	margin-top: 4px;
}

.errorMissing p {
	color: #ff0020;
}

.warningCircle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid #ff0010;
	background-color: #ff002025;
	display: flex;
	justify-content: center;
	align-items: center;
}

.warningCircle p {
	color: #ff0020;
	font-size: 8px !important;
	font-weight: bold !important;
}

.yesCircle {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid #0496ff;
	background-color: #0496ff35;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url('/background_images/yesCircle.png');

	background-size: 8px;
	background-repeat: no-repeat;
	background-position: center;
}

.trash {
	background-image: url('/background_images/trash.png');

	background-size: 24px;
	background-repeat: no-repeat;
	background-position: center center;
	height: 30px;
	transition: height 0.1s ease;
	cursor: pointer;
}

.trash:hover {
	height: 32px;
	transition: height 0.1s ease;
}

.mollie .left-container {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.mollieMessage {
	border-radius: 32px;
	border: 1px solid #eee;
	background-color: white;
	padding: 12px 40px;
	margin-top: 24px;
	margin-bottom: 12px;
}

.secondCounter {
	height: 80px;
	width: 68px;
	border: 1px solid #eee;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.secondCounter p {
	font-size: 40px;
	text-align: center;
	font-weight: 600;
}

.urlCopy .linkUrl {
	width: 196px;
	height: 40px;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	background-color: #f5f8fa;
	overflow: hidden;
	white-space: nowrap;
	align-items: center;
}

.urlCopy .copyBtn {
	width: 72px !important;
	min-width: 72px !important;
	max-width: 72px !important;
	margin-left: -8px;
}

.urlCopy .linkUrl p {
	text-transform: lowercase !important;
	padding-left: 8px;
}

.element-border .form-container-right .ant-input {
	border: 1px solid #eee !important;
	padding-left: 12px !important;
}

.element50 .elementContainer .form-container-left,
.element50 .elementContainer .form-container-right {
	width: 50%;
}

.element7525 .elementContainer .form-container-left {
	width: 75%;
}

.element7525 .elementContainer .form-container-right {
	width: 25%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.form-container-right .deleteCross {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.saveButton {
	text-align: center;
	background-color: #0496ff25;
	float: right;
	width: 100%;
	padding: 6px;
	border-radius: 4px;
	max-width: 92px;
	cursor: pointer !important;
}

.saveButton p {
	font-weight: 600 !important;
	color: #0496ff !important;
}

.saveButton img {
	width: 20px;
	height: 20px;
}

.overflow-text {
	white-space: pre;
	overflow-wrap: break-word;
	overflow: scroll;
}

.emptyDocuments {
	background-color: #f5f8fa;
	padding: 12px;
	width: 94%;
	margin-top: 12px;
	margin-left: 3%;
	border-radius: 4px;
}

.addToNewsContainer {
	border: 1px solid #eee;
	border-radius: 4px;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	margin-right: 4px;
}

.manualCountNews {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #ff0020;
	border: 2px solid white;
	box-shadow: 0px 2px 8px #aaaaaa20;
	position: relative;
	margin-left: 28px;
	top: -2px;
	left: -50px;
	margin-top: -16px;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: row;
}

.manualCountNews p {
	color: white !important;
	font-weight: 600;
	font-size: 10px;
}

.vote:hover {
	background-color: #e8f5ff;
}

/* .manualItemWrapperMulti {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 320px;
  height: 320px;
  max-height: 320px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 4px;

}

.manualItemWrapperMulti .multi {
  background-color: white;
  box-shadow: 0px 5px 15px 0px #aaaaaa25 !important;
  border-radius: 8px;
  border: 1px solid #eee;
  width: 294px;
  height: 320px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}

.manualItemWrapperMulti .multi img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 250px;
  width: 292px;
  object-fit: cover;
  margin-bottom: 8px;
} */
/* Joris Update */
/* .imagePostContainer {
  padding-bottom: 12px;
} */

.imagePostContainer .listDropDown {
	width: 180px;
	margin-left: -140px;
}

.submitNewsContainer .listDropDown {
	width: 180px;
	margin-left: 0px;
}

.manualItemWrapper {
	background-color: white;
	box-shadow: 0px 5px 15px 0px #aaaaaa25;
	border-radius: 8px;
	border: 1px solid #eee;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	height: 96px;
	max-height: 96px;
	margin-bottom: 24px;
	margin-top: 4px;
}

.manualItemWrapper img {
	width: 160px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	height: 94px;
	object-fit: cover;
}

.emoInputComment .form-container-left {
	width: 97%;
}

.emoInputComment .form-container-right {
	width: 3%;
}

.newsFilterBox {
	background-color: white;
	box-shadow: 0px 5px 15px 0px #aaaaaa25;
	border-radius: 8px;
	left: calc(50% - 517px);
	top: 95px;
	position: fixed;
	z-index: 99;
	width: 180px;
	max-height: calc(100vh - 196px);
	overflow-y: scroll;
}

.newsFilterBox .defaultRowPicker:last-child {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.error-msg {
	color: #ff0020 !important;
	font-size: 10px !important;
	font-style: italic !important;
	/* Keep this */
}

.warning-msg {
	color: orange !important;
	font-size: 10px !important;
	font-style: italic !important;
	/* Keep this */
}

.bigWage .warning-msg {
	padding-right: 8px;
}

.photoViewer {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	border: 3px solid white;
	box-shadow: 0px 5px 5px 0px #aaaaaa50;
}

.photoViewerNoShadow {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	border: 3px solid white;
}

.listDropDown #search {
	width: 96% !important;
	margin-top: 4px !important;
	margin-left: 2% !important;
}

.noteEmoInput .elementContainer {
	display: flex !important;
	flex-direction: row !important;
	align-items: flex-start !important;
	justify-content: space-between !important;
}

.envPicker .elementContainer {
	min-width: 180px;
}

.invitePicker .elementContainer {
	margin-left: 0px !important;
}

.invitePicker .elementContainer .ant-select-selection-placeholder {
	line-height: 32px !important;
}

.easyRemoveImg {
	background-image: url('/background_images/easyRemove.png');

	background-size: 12px;
	background-repeat: no-repeat;
	background-position: right center;
}

.startValueContainer p {
	padding: 12px;
	text-align: center;
	border-bottom: 1px solid #eee;
}

.startValueContainer p:hover {
	background-color: #e8f5ff;
	cursor: pointer !important;
}

.settingMenu {
	width: 200px;
	padding: 24px 12px;
	border-radius: 8px !important;
}

.settingMenu .menuLink {
	width: 176px;
}

.settingMenu .menuLink p {
	width: 176px;
}

.dangerText p {
	color: #ff0020 !important;
}

.popOverHeader {
	padding: 8px 8px;
	display: flex;
	align-items: baseline;
}

.trainingControls button {
	padding-left: 0px !important;
	padding-right: 0px !important;
	min-width: 0px;
	width: 100%;
}

.trainingControls a {
	flex: 1;
}

.blocksContainer .titleContainer {
	margin-bottom: 24px;
}

.blocksContainer .subline {
	margin-bottom: 24px;
}

.trainingModal .inputNumber {
	display: flex;
	align-items: center;
}

.trainingModal .inputNumber p {
	display: flex;
	align-items: center;
	margin-right: 12px;
}

.trainingModal .form-container-right {
	display: flex;
	align-self: center;
	justify-content: flex-end;
}

.trainingModal .inputNumber input {
	width: 80px !important;
	border: 1px solid #eee !important;
	align-self: center;
}

#triggerButton {
	width: fit-content;
	min-width: fit-content;
	padding: 8px !important;
	margin-left: 4px;
}

/* .articleList .list-item.list-item-draggable {
  display: flex;
  position: relative;
} */

/* .articleList .list-item .drag-element {
  flex: 1;
} */

.createTopic.teamFilterContainer button {
	width: 150px !important;
}

.warningBanner {
	width: 100%;
	background: #f5f5f5;
	border: 1px solid #eee;
	padding: 16px 22px;
	border-radius: 4px;
	margin-bottom: 12px;
}

.warningBanner_attention {
	font-weight: 600;
	width: fit-content;
	white-space: nowrap;
	color: #2b2b2b;
	margin-right: 4px;
}

.topicPopover .ant-popover-inner-content {
	padding: 8px 10px;
	color: rgba(0, 0, 0, 0.85);
}

.fullscreenModal {
	min-width: 1200px;
	top: 24px !important;
}

.fullscreenModal .ant-modal-body {
	padding: 0px !important;
	/* width: 100vw !important;
  height: 100vh !important; */
}

.shadeButton {
	background-color: #f5f8fa !important;
}

.inactiveButtonText {
	color: #828282 !important;
}

.ant-btn:hover.ant-btn-sm.ant-btn-dangerous {
	color: #fff !important;
	background: red !important;
}

.ant-btn:hover.ant-btn-dangerous.marBottom {
	color: #fff !important;
	background: red !important;
}

.newQuizQuestion .form-container-right {
	display: flex;
	width: 100%;
}

.newQuizQuestion .form-container-right input {
	flex: 1;
	margin-right: 12px;
}

.newQuizQuestion .form-container-right .char-counter {
	align-self: center;
	font-size: 9px;
	margin-top: 2px;
	width: 21px;
	display: flex;
	justify-content: center;
	color: #828282 !important;
}

.textOverflow {
	width: inherit;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.titleInputCount .char-counter {
	align-self: center;
	font-size: 9px;
	margin-top: 2px;
	display: flex;
	justify-content: center;
	color: #828282 !important;
}

.tileSwitch .form-container-right {
	display: flex;
	justify-content: flex-end;
}

.newsFormAnswer .char-counter {
	display: none;
}

.publishPopover {
	width: 372px !important;
}

.publishPopover .ant-popover-buttons {
	justify-content: center;
	display: flex;
}

.publishPopover .ant-popover-buttons button {
	flex: 1;
}

/* Hide date picker input on news feed page */
.ant-picker.sync-moment-input .ant-picker-input {
	display: none;
}

.singleRowGrid {
	display: grid;
	gap: 4px;
	grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
}

.sync-moment-input .ant-picker.sync-moment-input {
	width: 0 !important;
	height: 0 !important;
}

/* .postFormTopPanel .listDropDown {
  width: 150px;
} */

.postFormTopPanel .list_select_trigger button {
	min-width: fit-content;
	height: fit-content !important;
	/* padding-right: 0px !important; */
	/* padding-left: 0px !important; */
	padding: 4px 4px !important;
	margin: 0px;
}

.postFormTopPanel .small {
	align-self: center;
}

/* hopefully able to remove this */
.scheduledDraftPosts .ant-modal-body {
	padding: 0px !important;
}

.w330 {
	width: 330px;
}

.w330 .ant-popover-buttons {
	justify-content: center;
}

/* .postImageUpload {
  width: 40px !important;
  height: 40px !important;
} */

.assetIcon {
	border: 1px solid #eee;
	border-radius: 4px;
	margin-right: 4px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.assetIcon--image {
	width: 40px;
	height: 40px;
	padding: 12px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	opacity: 0.5;
}

.assetIcon.iconhighlighted .assetIcon--image {
	opacity: 1;
}

.iconhighlighted {
	background-color: #e8f5ff;
}
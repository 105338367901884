@import (reference) 'styles/app/app.less';

.mobile-blocker {
	.light-gradient;
	position: relative;

	& > a {
		position: absolute;
		top: 40px;
		width: 100vw;
		text-align: center;
	}

	.eitje-logo {
		height: 40px;
	}

	&-content {
		margin: 0 auto;
		text-align: center;
		max-width: 400px;

		a {
			width: 100%;
		}
	}
	&-title {
		font-size: 48px;
		font-weight: 600;
	}
	&-subtitle {
		font-size: 16px;
		color: @dark-grey;
	}
}

@import (reference) 'mixins/stretch';

@checkbox-size: 16px;

.eitje-form-2-checkbox-input {
	.hide-icon;
	position: relative;

	.eitje-form-2-label {
		margin-right: calc(@checkbox-size + 36px);
	}

	.ant-checkbox-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;

		.ant-checkbox {
			position: absolute;
			width: @checkbox-size;
			height: @checkbox-size;
			right: 24px;
			top: calc((100% - @checkbox-size) / 2);
		}
	}
}

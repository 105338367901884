////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason is very simple: since LESS does not allow us to
// reuse dynamically created classes within other CSS classes as mixins,
// they may only be used directly within HTML – but that goes against
// our philosophy of keeping the JS files clean and concise.
////////////////////////////////////////////////////

@import (reference) '../sizes.less';

each(@size-names, {
  .gap-@{value} {
    gap: @size[$@value];
  }
});

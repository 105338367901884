@import (reference) 'styles/app/app.less';

//////////////////////////////////////
// General modal styling, TBD once system is fully integrated
//////////////////////////////////////

@header: 84px;
@body: 330px;
@footer: 72px;
@modal: calc(@header + @body + @footer);

.revenue-settings-modal {
	.eitje-modal(@modal);

	.tabs-container {
		.eitje-modal-header(@header);
		justify-content: center;
	}

	.revenue-modal-body {
		.eitje-modal-body(@body);
	}

	.revenue-modal-footer {
		.eitje-modal-footer(@footer);
		justify-content: flex-end;
	}
}

////////////////////////////
// Specific form styling
// To be kept
////////////////////////////

.revenue-settings-modal .eitje-form-2 .eitje-form-2-field-forecast_pct {
	.border(bottom);
}

@import (reference) 'styles/app/app.less';

.onboarding-popout {
	.popout-body .onboarding-step.has-hover-effect:hover {
		background: @light-blue;
		border-color: @medium-blue;
		* {
			color: @medium-blue;
		}
	}

	.popout-footer a {
		display: contents !important;
	}
}

// This file was initially a dumping ground for ALL availability styling code.
// To keep things understandable, we have split the files, but in order to support
// all the javascript imports, still import this file, which simply imports the
// other less files.

@import (reference) 'styles/app/app.less';

@import 'footer.less';

#rotate-dropdown-arrow {
	transform: rotate(270deg);
}

.users-availability-table-row-placeholder {
	display: flex;
	align-items: center;
	width: 100%;
	background-color: @white;
	z-index: 5;
	height: 56px;
	.border(bottom);

	.av-place-day-info {
		width: 196px;
		height: inherit;
		.border(right);
	}

	.av-place-day {
		flex: 1;
		height: inherit;
		.border(right);
		padding: 2px;
	}
}

.border(@side: all, @color: @medium-border, @width: 1px,  @style: solid, @type: border) {
	& when (@side = top) {
		@{type}-top: @width @style @color;
	}

	& when (@side = bottom) {
		@{type}-bottom: @width @style @color;
	}

	& when (@side = left) {
		@{type}-left: @width @style @color;
	}

	& when (@side = right) {
		@{type}-right: @width @style @color;
	}

	& when (@side = all) {
		@{type}: @width @style @color;
	}
}

.unset-border(@side: all) {
	& when not (@side = all) {
		border-@{side}-color: unset;
		border-@{side}-width: unset;
		border-@{side}-style: unset;
	}

	& when (@side = all) {
		border-color: unset;
		border-width: unset;
		border-style: unset;
	}
}

.border-radius(@size: default) {
	& when (@size = small) {
		border-radius: 2px;
	}

	& when (@size = default) {
		border-radius: 4px;
	}

	& when (@size = large) {
		border-radius: 8px;
	}

	& when (@size = round) {
		border-radius: 50%;
	}
}

// NOTE: We commented this out because we are not currently using it, but might need to in the future

// the specificity of some selectors cause the border-color to be overwritten if we would use border()

// .border-with-color-set-specificity(@side: all, @width: 1px, @style: solid, @type: border) {
// 	& when (@side = top) {
// 		@{type}-top-width: @width;
// 		@{type}-top-style: @style;
// 	}

// 	& when (@side = bottom) {
// 		@{type}-bottom-width: @width;
// 		@{type}-bottom-style: @style;
// 	}

// 	& when (@side = left) {
// 		@{type}-left-width: @width;
// 		@{type}-left-style: @style;
// 	}

// 	& when (@side = right) {
// 		@{type}-right-width: @width;
// 		@{type}-right-style: @style;
// 	}

// 	& when (@side = all) {
// 		@{type}-width: @width;
// 		@{type}-style: @style;
// 	}
// }

.children-except-last-border-bottom {
	& > * {
		&:not(:last-child) {
			.border(bottom);
		}
	}
}

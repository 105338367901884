@import 'application_variables.less';

@size-names: xxxl, xxl, xl, lg, md, sm, xs, xss, dn;

@size: {
	xxxl: 32px;
	xxl: 28px;
	xl: 24px;
	lg: 20px;
	md: 16px;
	sm: 12px;
	xs: 8px;
	xss: 4px;
	dn: 0px;
};

.size(@height, @width) {
	height: @height;
	width: @width;
}

.square-size(@size) {
	.size(@size, @size);
}

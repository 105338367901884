.moving-gradient {
	animation-duration: 2.4s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #fafafa 8%, #a8a8a8 38%, #fafafa 54%);
	background-size: 1000px 640px;
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

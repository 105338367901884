@import (reference) 'styles/app/app.less';

.react-pdf__Page__textContent {
	display: none;
}

.pdf-container {
	display: flex;
	position: relative;
	max-width: 880px;
	width: 880px;
	flex-direction: column;
	align-items: center;

	&.modal-view {
		position: unset;
		max-width: 720px;
		width: 720px;
	}

	.confirm-popover {
		position: absolute;
		top: 68px;
		right: 23px;
		z-index: 1;

		.pdf-destroy-icon {
			.border-radius(round);
			height: 30px;
			width: 30px;
			text-align: center;
			border: 1px solid @medium-border;
			background-color: @white;
			cursor: pointer;
			box-shadow: 0px 5px 15px 0px ~'@{medium-grey}25';
			padding: 7px;
		}
	}

	.pdf-body-container {
		width: 880px;
		height: 330px;
		align-items: center;
		justify-content: center;
		display: flex;
	}

	.document {
		margin: 1em 0;
	}

	.react-pdf__Document {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 24px;
	}

	.react-pdf__Page {
		/* max-width: calc(100% - 2em); */
		box-shadow: 0px 5px 15px ~'@{medium-grey}20';

		canvas {
			max-width: 100%;
			height: auto !important;
		}
	}
}

// Control container, get outside above wrapper, since it also occurs
// without it.

.pdf-controls-container {
	display: flex;
	width: 95%;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	max-width: 880px;
	bottom: 12px;

	&.modal-view {
		width: 45%;
		position: absolute;
		max-width: 720px;
		bottom: 24px;
	}

	.pdf-toggle-container {
		.pointer-cursor;
		.row-with-border;
		position: absolute;
		width: 48px;
		height: 48px;
		justify-content: center;
		.pdf-toggle-icon {
			width: 12px;
			height: 12px;
			transform: rotate(-90deg);

			&.return,
			&.left {
				transform: rotate(90deg);
			}
		}
	}

	:last-child {
		right: 0;
	}

	.pdf-count-container {
		.row-with-border;
		height: 48px;
		justify-content: center;
		flex: 1;
		text-align: center;
	}
}

@import (reference) 'styles/app/app.less';


.eitje-rich-text-editor-content {
    div[data-youtube-video] {
        cursor: move;
        padding: 3px 0;

        iframe {
            .border(@width: 2px, @color: @medium-grey);
            display: block;
            min-height: 200px;
            min-width: 200px;
        }

        &.has-focus iframe {
            .border-radius();
            .border(@width: 2px, @color: @medium-blue, @type: outline);
            outline-offset: 4px;
        }

    }

    .column div[data-youtube-video] iframe {
        width: 100%;
    }
}
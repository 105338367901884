@import (reference) 'styles/app/app.less';
@import (reference) 'styles/form/index.less';

// can not be nested as popout has its own DOM layer
.planning-shift-form .user-shift-card {
	.pickup-status-text {
		display: inline-block;
		color: @dark-grey;
	}
}

////////////////////////////
// Dropdown outside DOM
////////////////////////////

.allow_pickup-dropdown {
	.ant-dropdown-menu {
		padding: unset;

		.ant-dropdown-menu-item {
			.border(bottom);
			padding: 0;

			.eitje-form-2-field {
				.row;
				.eitje-form-2-checkbox-input;
				margin-left: 12px;
				height: 40px;

				padding: unset;
				justify-content: space-between;
				gap: 24px;

				.eitje-form-2-field-icon {
					display: none;
				}
			}
		}
	}
}

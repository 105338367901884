@import (reference) 'styles/app/app.less';

.day-placeholder {
	.border(right);
	width: 12.5%;
	min-height: 56px;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
}

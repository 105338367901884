// TO MOVE: BELONGS TO THE USER PREFERENCES PAGE?

@import (reference) 'styles/app/app.less';

.user-preferences {
	.ant-select-single {
		align-items: center;
		.ant-select-selector {
			line-height: unset !important;
		}
	}

	&.sync-agenda-container {
		.column;

		.eitje-label {
			flex: 1;
		}
	}
	.eitje-field-locale {
		.flex-field-1;
		.ant-select-selection-item {
			line-height: 38px !important;
		}
	}
}

.user-preferences .eitje-field-note {
	.row;
	justify-content: space-between;

	.form-container-left {
		width: 360px;
	}

	.form-container-right {
		align-self: flex-start;
	}

	.emoInputHolder {
		top: 10px;
	}
}

.user-preferences-page {
	.right-settings-column {
		.email-notifications-title,
		.browser-notifications-title {
			padding: 24px 24px 0 24px;
		}

		// The row in which you can upload a photo
		.eitje-field-remote_avatar_url {
			button {
				margin-left: 4px;
			}
		}

		.eitje-field-password,
		.eitje-field-password_confirmation {
			p {
				position: absolute;
			}
		}
	}
}

@import (reference) 'styles/app/app.less';

.event-modal {
	.ant-modal-content .ant-modal-body {
		max-height: 740px !important; // To overwrite ancient :not logic
	}
	.ant-modal-content .ant-modal-body .eitje-form {
		// Below is a bit of a trick: we HAVE to let DOM elements be present
		// while we don't want to show them. The reason has to deal with the HTML
		// requirement of an uploader input being present. Hide them through placing
		// them in an hidden overflow.
		.post-form {
			@current-modal-contents-height: 340px;
			height: @current-modal-contents-height;
			overflow: hidden;
		}

		.elementContainer {
			padding: 12px 24px;
		}
	}

	.ant-modal-footer {
		margin-top: -1px; // prevents double border
	}

	.eitje-field-title .ant-input {
		margin: 8px 0 0 0;
	}
}

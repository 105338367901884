@import (reference) 'styles/app/app.less';

.user-availability-header {
	.row;
	.align-items-center;
	.border(bottom);
	height: 69px;
	padding-left: 24px;
	padding-right: 12px;

	.availability-header-text-container {
		margin-right: 41px;

		.availability-header-title {
			.eitje-heading(20px);
		}

		.availability-header-sub-title {
			.eitje-text-secondary;
		}
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////////////
// Important note to anybody visiting this file:
// Below, the CSS technique of selecting elements through a dynamic `:not(.class_name)` selector
// is extensively used. This is NOT something we want to do; avoid it as much as possible and,
// ideally, eventually get rid of it all together. The reason is that this technique selects elements
// by means of a wildcard, which means future elements may suddenly and unintentionally be targeted
// in unexpected and mysterious ways. Especially in combination with the below nesting of selectors,
// this becomes very aggressive and hard to overwrite with even more selectors.
//
//  => Remove them if you see an opportunity!
//
//////////////////////////////////////////////////////////////////////////////////////////////////////

@import (reference) 'styles/app/app.less';

.base-modal-section {
	padding: 12px 24px;
	.border(bottom);
	min-height: 68px;
	position: relative;
	.eitje-extra-label {
		line-height: 22px;
	}
}

.ant-modal-root {
	.ant-modal-wrap:not(.eitje-modal-2) {
		.ant-modal:not(.modal-fullscreen):not(.eitje-modal-2) {
			top: 70px;
			.ant-modal-body {
				max-height: 540px;
				overflow-y: auto;
			}
		}

		.ant-modal-body {
			.eitje-form {
				&.last-child-no-border:last-child,
				.last-child-no-border:last-child {
					border-bottom: none;

					.elementContainer:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
}

.ant-modal-body {
	.elementContainer:not(.default-field) {
		padding: 16px 24px;
		.border(bottom);

		&.eitje-dropdown-container {
			.eitje-dropdown {
				width: 100%;
				&.ant-select-borderless {
					min-height: 22px;
				}
			}
		}
	}
}

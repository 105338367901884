.availability-footer {
	height: 64px;
	padding: 0px 24px;
	.border(top);
	display: flex;
	.align-items-center;
	justify-content: space-between;
	.top-shadow;
	background-color: @white;
	position: fixed;
	bottom: 0;

	div {
		.row;
		width: fit-content;
		gap: 4px;
	}
}

@import (reference) 'styles/app/app.less';

.time-registration-settings-modal {
	.eitje-modal('unset');

	.eitje-modal-hide-close;

	.settings-header {
		.eitje-modal-header(84px);

		.eitje-dropdown-container {
			.big-shadow;
			padding-right: unset;

			.ant-select {
				margin-right: unset;
			}
		}

		.elementContainer {
			padding: unset !important; // legacy support... keep as long as we have the not selectors active
		}
	}
}

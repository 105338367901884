@import (reference) 'styles/app/app.less';

.search-container {
	position: relative;
	flex-shrink: 1 !important;

	#search-filter {
		.border;
		.border-radius;
		background-color: @white;
		padding-left: 36px;
		padding-right: 32px;
		height: 40px;
		width: 100%;

		&::placeholder {
			color: @black;
		}

		&.search-filter-black {
			border-color: @black;
			color: @black;

			&::placeholder {
				color: @black;
			}
		}

		&:hover {
			background-color: @light-blue;
		}

		&.search-filter-active,
		&:active,
		&:focus {
			color: @dark-blue;
			border-color: @medium-blue;
			background-color: @light-blue;
			outline: none;

			&::placeholder {
				color: @medium-blue;
			}
		}

		&:focus:hover {
			background-color: @light-blue;
		}
	}

	.search-icon,
	.search-clear-icon {
		.square-size(16px);
		position: absolute;
		top: calc(50% - 8px);
		cursor: pointer;
		@z-index-above-input: 2;
		z-index: @z-index-above-input;
	}

	.search-icon {
		left: 12px;
		pointer-events: none;
	}

	.search-clear-icon {
		right: 12px;

		&:hover+#search-filter {
			background-color: @light-blue;
		}
	}
}
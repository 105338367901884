////////////////////////////////////////////////////
// A VERY IMPORTANT NOTE:
// Below classes are not to be used by you! they merely exist to support
// legacy. The reason is very simple: since LESS does not allow us to
// reuse dynamically created classes within other CSS classes as mixins,
// they may only be used directly within HTML – but that goes against
// our philosophy of keeping the JS files clean and concise.
////////////////////////////////////////////////////

@import (reference) '../sizes.less';
@import (reference) '../paddings.less';

each(@size-names, {
  .padding-top-@{value} {
    padding-top: @size[$@value];
  }
});

each(@size-names, {
  .padding-bottom-@{value} {
    padding-bottom: @size[$@value];
  }
});

each(@size-names, {
  .padding-right-@{value} {
    padding-right: @size[$@value];
  }
});

each(@size-names, {
  .padding-left-@{value} {
    padding-left: @size[$@value];
  }
});

each(@size-names, {
  .padding-@{value} {
    padding: @size[$@value];
  }
});

each(@size-names, {
  .vertical-padding-@{value} {
    .vertical-padding(@size[$@value]);
  }
});

each(@size-names, {
  .horizontal-padding-@{value} {
    .horizontal-padding(@size[$@value]);
  }
});

@import (reference) 'styles/app/styles/elements/borders.less';
////////////////////////////
// below are mixins to be used to reset the aggressive and mostly inconsistent
// styles coming from different HTML elements each having it's own default &
// inherited styles applied.
////////////////////////////

.reset-dropdown-styles {
	.ant-dropdown-menu,
	.ant-dropdown-menu-item,
	a.link,
	* {
		padding: unset;
		margin: unset;
		line-height: unset;
	}
}

.apply-dropdown-styles {
	.reset-dropdown-styles;

	.ant-dropdown-menu-item {
		.border(bottom);
		height: 40px;
		line-height: 40px;
		padding: 0 16px;
	}
}

.ant-dropdown-menu-item span:not(.ant-checkbox-inner) {
	// the .ant-checkbox-inner requires its default block display for its position and does not bear any letter to be capitalized
	.text-capitalize;
	display: inline-block;
}

////////////////////////////
// below is older code
////////////////////////////

.dropdown-toggle-count {
	.row;
	height: 40px;
	min-width: 180px;
	.border;
	.border-radius;
	border-right: none;

	padding-left: 16px;

	.pointer-cursor;
	.dropdown-toggle-title {
		flex: 1;
		white-space: nowrap;
	}

	.dropdown-toggle-icon-container {
		.row-with-justify-center;
		.border;
		.border-radius;
		.white-background-and-light-shadow;

		min-width: 40px;
		width: 40px;
		height: 40px;
		position: relative;

		.dropdown-toggle-icon-count {
			position: absolute;
			bottom: 0px;
			right: -8px;
		}
	}

	&-small {
		.border(right);
		font-size: 10px;
		min-width: 100px;
		height: 32px;
	}
}

.ant-dropdown-menu-item {
	&:empty {
		display: none !important;
	}
	.nested-dropdown-component {
		.row;
		.border(bottom);
		position: relative;
		padding-left: 12px;
		height: 40px;

		.nested-dropdown-component-title {
			.bold;
		}

		.nested-dropdown-component-count {
			.eitje-text-secondary;
			position: absolute;
			left: 16px;
		}

		&.dangerous {
			background-color: rgba(255, 0, 32, 0.05);
			.nested-dropdown-component-title {
				.red-text;
			}
		}
	}

	&:last-child {
		.nested-dropdown-component {
			border-bottom: unset;
		}
	}
}

// the :empty pseudo-selector only works if there's no whitespace in an element. That whitespace is NOT shown in the DOM, don't let that fool you.

li.ant-dropdown-menu-item:empty {
	display: none;
}

@import (reference) 'styles/app/app.less';

.message {
	@margin: var(--margin);

	.border-radius;
	border-width: 1px;
	border-style: solid;
	min-height: 32px;
	text-align: center;
	width: calc(100% - (@margin * 1px)) !important;

	* > {
		// When for example lokalise adds a span for markup purposes
		max-width: 100%;
	}
}

.form-row-message {
	&-inline {
		.border(top);
	}

	&-top {
		.border(bottom); // add border for scroll
		margin-bottom: -1px; // prevent double borderposition: sticky;
		z-index: 20; // if changing, beware that it is above all form elements, especially the clear icon on hover
		top: 0;
		position: sticky;
	}
}

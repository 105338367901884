@import (reference) 'styles/app/app.less';

.checkbox-card {
	.border;
	.border-radius;
	padding: 8px;

	&:hover {
		.pointer-cursor;
		background: @light-blue;
	}

	.checkbox-card-checkbox-wrapper,
	.checkbox-card-children {
		pointer-events: none;
		display: inline-block;
		vertical-align: middle;
	}

	.checkbox-card-checkbox-wrapper {
		padding: 0px 8px 0px 4px;
	}

	.checkbox-card-children {
		width: calc(100% - 36px);
	}
}

.checkbox-card-selected {
	background: @light-blue;
}

.checkbox-card-disabled {
	background: @light-grey;
	pointer-events: none;
}

@import (reference) 'styles/app/app.less';

#planning-page .availability-card {
	.time-blocks {
		.remove-extra-space;
		margin: 4px 0 0 0;
	}

	.time-block {
		display: inline-block;
		font-size: 10px;
		height: 14px;
	}

	.time-block-line {
		display: inline-block;
		margin: 0 8px;
		height: 14px;
		font-size: 10px;
	}
}

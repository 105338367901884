@import (reference) 'styles/app/app.less';

@search-container-margin: 24px;

.article-drawer {
	.ant-drawer-body {
		padding: 0px;
		margin-top: 12px;

		.drawer-header button:not(.clear-selection-button) {
			.row-with-justify-between;
			width: 180px;
		}

		.search-container {
			margin: 0px @search-container-margin;
			width: calc(100% - 2 * @search-container-margin);
		}
	}
}

// Below: legacy styling

div.drawer-header {
	position: absolute;
	top: 8px;
	right: 50px;
}

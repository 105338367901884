@import (reference) 'styles/app/app.less';

////////////////////////////////////
// General styling
////////////////////////////////////

#planning-page .planning-hours-row {
	.border(bottom);
}

////////////////////////////////////
// Left title column styling (column 1)
////////////////////////////////////

#planning-page .planning-hours-row .planning-day-hours-title {
	line-height: 42px;
	text-align: left;
	padding: 0 0 0 24px;
	display: inline-flex;
	align-items: center;

	p {
		display: inline-block;
		font-size: 12px;
		color: @dark-grey;
	}
}

////////////////////////////////////
// Day column styling (column 2-8)
////////////////////////////////////

#planning-page .planning-hours-row .planning-day-hours {
	display: inline-block;
	text-align: center;
	p {
		font-size: 12px;
		color: @dark-grey;
		line-height: 42px;
		text-align: center;
	}

	.planning-day-hours-drop-shift {
		padding: 4px;
		height: 42px;

		p {
			.bold;
			color: @black;
			border: 2px @medium-border dashed;
			.border-radius;
			border-spacing: 12px 12px;
			height: 36px;
			line-height: 32px;
		}

		&-is-over p {
			border-color: @medium-blue;
			background-color: @light-blue;
		}
	}
}

@import (reference) 'styles/app/app.less';

#integrations-page .new-integrations {
	.title-layout {
		.border(bottom);
	}

	.logo-layout img {
		height: 100%;
	}
}

@import (reference) 'styles/app/app.less';

.user-page .user-list-base .placeholder {
	// Quickly edit CSS instead of component; we'll hopefully soon delete this legacy code anyway
	height: unset !important;
}

.user-page {
	display: grid;
	grid-template-columns: 264px auto;
	height: 100%;

	// Left column, with navigation (contracts, skills, other users and more)

	.user-navigation-column {
		display: flex;
		flex-direction: column;
		height: 100%;
		background-color: @white;
		.border(right);
		overflow: scroll;

		.back-arrow-container {
			margin: 12px;
		}

		.user-search {
			.row;
			gap: 8px;
			padding-left: 36px;
			padding-right: 8px;
			margin-bottom: 8px;
			margin-top: 8px;
		}
		.navigation-container {
			min-height: 230px;
		}

		.search-container .ant-input {
			background: transparent;
		}
	}

	// Middle column, with all the user's details

	.user-information-column {
		height: 100%;

		.user-inner-grid {
			display: grid;
			margin-top: 40px;
			margin-left: 40px;
			grid-template-columns: minMax(760px, auto) 232px;
			column-gap: 24px;
			padding-right: 24px;

			& > :first-child {
				height: calc(100%);
				overflow: scroll;
				padding-bottom: 120px;
			}

			.user-banner {
				.row-with-border;
				.border-radius(large);
				.align-items-center;
				z-index: 10;
				height: 112px;
				position: sticky;
				top: 0;
				padding: 0 12px 0 24px;
				margin-bottom: 24px;
			}

			.user-availability-table-container {
				.border-radius(large);
			}

			// Right column, with details of the user

			.user-details-column {
				.column;
				background-color: transparent;
				gap: 12px;

				strong {
					.bold;
				}

				.list-container {
					width: 100%;

					.list-item {
						margin-bottom: 12px;
					}
				}
			}
		}
	}
}

.user-actions-popover-content {
	button {
		width: 100%;
	}
}

@import (reference) './legacy/sizes.less';
@import (reference) '../colors/colors.module.less';

// Below one-line classes are to be mixed into CSS selectors, not to be used
// directly within HTML. In this case we diver form our styling principle of
// not writing one line classes. The reason is that this way, we can easily
// manipulate all text within eitje from one centralized place. Say, if we
// want to change the weight of bold, we merely have to edit the below selector.

.bold {
	font-weight: 600;
}

// Within typography, "book" is the standard name for regular font weights.
.book {
	font-weight: 400;
}

.decreased-line-height {
	line-height: 1;
}

.default-line-height {
	line-height: 1.4;
}

.increased-line-height {
	line-height: 1.5;
}

.black {
	color: @black;
}

.grey {
	color: @dark-grey;
}

.red {
	color: @medium-red;
}

.white {
	color: @white;
}

.blue {
	color: @medium-blue;
}

.capitalize {
	text-transform: capitalize;
}

.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.base-typography {
	font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	color: @black;
	.default-line-height;
	letter-spacing: -0.2px;
	margin-bottom: 0px;
	font-weight: 400;
	white-space: pre-line;

	// no-first-letter-uppercase can be used to disable the first letter uppercasing
	// since this can break functionallity in some browsers. Known issues: 
	// tiptap rich text editor:
	// - chrome: deleting sentence with cmd+backspace
	// - safari: typing removes previous character, making it impossible to write text
	&:not(.no-first-letter-uppercase)::first-letter {
		text-transform: uppercase;
	}

	// Dont uppercase first letter in firefox by default because this pseudo class makes texts too wide in some cases,
	// which is a bigger problem then missing uppercases because it can mess with the layout
	@-moz-document url-prefix() {
		&:first-letter {
			text-transform: unset;
		}
	}
}

h1 {
	.base-typography;
	.bold;
	font-size: 40px;
	line-height: 1;
}

h2 {
	.base-typography;
	font-size: 24px;
	font-weight: 600;
}

h3 {
	.base-typography;
	font-size: 18px;
	font-weight: 600;
}

h4 {
	.base-typography;
	.bold;
	font-size: 14px;
}

p {
	.base-typography;
	font-size: 14px;
}

span {
	.base-typography;
	font-size: 14px;
}

// former .eitje-text-secondary
h5 {
	.base-typography;
	font-size: 12px;
	color: @dark-grey;
}

h6 {
	.base-typography;
	font-size: 10px;
}

button {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span {
		white-space: unset;
	}
}

/* Applies uppercase transformation to the first letter of a <div> 
   only if it:
   - is a direct child of another <div>,
   - does NOT contain a descendant with the class 'no-first-letter-uppercase'. */
div>div:not(:has(.no-first-letter-uppercase)):first-letter {
	text-transform: uppercase;
}

a {
	cursor: pointer;
	color: @medium-blue;

	* {
		cursor: pointer;
	}

	&[disabled] {
		pointer-events: none;
	}
}

.disabled-with-pointer {
	color: @dark-grey;
}

strong,
b {
	font-weight: 600;
}

em,
i {
	font-style: italic;
}

body {
	margin: 0;
	padding: 0;
	background-color: @light-background;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
}

thead,
tbody th,
tbody td {
	background-color: @white;
}

span:not([class], [id], [role], [type])>a,
span.ant-tooltip-open:not([id], [role], [type]) a {
	// tooltips are wrapper spans that require pointer events to work
	// this fixes that disabled buttons within a span still have pointer events (the button has to be disabled in another way)
	pointer-events: unset;
}

input {
	font-size: 14px; // This used to come fron ant
}